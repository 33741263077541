import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'Components/Blocks'
import { Fields } from '../FormFields'

const Phase1 = ({
  values,
  setValue,
  nextForm
}: {
  values: { [key: string]: any }
  setValue: Function
  nextForm: () => void
}) => {
  const { t } = useTranslation()
  return (
    <Fields>
      <div className="rows">
        <label>
          {t('first-name')} <span>*</span>
        </label>
        <input
          className="form-select"
          value={values.firstName}
          placeholder={t('first-name')}
          onChange={e => setValue('firstName', e.target.value)}
        />
        <label>
          {t('last-name')} <span>*</span>
        </label>
        <input
          className="form-select"
          value={values.lastName}
          placeholder={t('last-name')}
          onChange={e => setValue('lastName', e.target.value)}
        />
        <label>
          {t('phone')} <span>*</span>
          <Tooltip tooltipText="phone-number-tooltip" />
        </label>
        <input
          className="form-select"
          value={values.phone || ''}
          placeholder={t('phone-placeholder')}
          onChange={e => setValue('phone', e.target.value)}
        />
        <label>
          {t('email')} <span>*</span>
        </label>
        <input
          className="form-select"
          value={values.email}
          placeholder={t('write-email')}
          onChange={e => setValue('email', e.target.value)}
        />
      </div>
      <div className="additional-info">
        <button type="button" onClick={nextForm}>
          {t('next')}
        </button>
      </div>
    </Fields>
  )
}

export default Phase1
