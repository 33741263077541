import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

const FooterContainer = styled.footer`
  min-height: 221px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d6dce2;
  font-family: Barlow;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  background-color: #121212;
  color: #fff;
  width: 100%;
  position: relative;
  overflow: hidden;
  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 6.4rem 2rem;
  }
  .wrapper {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    @media screen and ${props => props.theme.breakpoints.lg} {
      flex-direction: row;
      > div,
      ul {
        width: 25%;
      }
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  p {
    font-size: 17px;
    line-height: 1.8;
    font-weight: 100;
  }
  .logo {
    margin: 0;
  }
  .social-media-icons {
    display: flex;
    border-bottom: 1px solid #bebebe;
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
    li {
      margin-right: 1.5rem;
      a {
        svg path {
          transition: all 0.33s;
          fill: #fff;
        }
        &:hover {
          svg path {
            fill: #aa2128;
          }
        }
      }
    }
  }
  a {
    color: #fff;
    font-weight: 100;
    text-decoration: none;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
    &.button {
      display: inline-flex;
      align-items: center;
      padding: 10px 20px;
      text-decoration: none;
      font-weight: 400;
      transition: all 0.33s;
      font-size: 14px;
      border-radius: 8px;
      background-color: transparent;
      border: 2px solid #fff;
      color: #fff;
      svg {
        color: #fff;
        fill: #fff;
        margin-right: 5px;
      }
    }
  }
  strong {
    font-weight: bolder;
  }
  .footer-links {
    a {
      font-weight: 600;
      line-height: 1.7;
      svg {
        transform: rotate(-90deg);
        fill: #fff;
      }
    }
  }
  .footer-buttons li {
    margin-bottom: 1rem;
    a {
      font-family: 'Source Code Pro';
    }
    a:hover {
      background-color: #3c3c3c;
      border: 2px solid #fff;
    }
  }
`

const Footer = () => {
  return (
    <FooterContainer>
      <div className="container">
        <figure className="logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="170"
            height="23.309"
            viewBox="0 0 170 23.309"
          >
            <path
              d="M7.437,0H23.574V15.539L16,23.109H0V7.5L7.437,0M3.852,6.84h8.832L8.3,2.391Zm3.254,7.836V9.1H2.258V19.523Zm1.727,1.461L3.984,20.984H14.277V16.137ZM21.316,3.984,16.535,8.766V19.457l4.781-4.848ZM9.363,14.012h4.848V9.164H9.363ZM11.156,2.059l4.383,4.449,4.383-4.449H11.156Z"
              fill="#d60c17"
              fillRule="evenodd"
            />
            <g transform="translate(32.672)">
              <path
                d="M58.7,6.773C58.7,2.324,57.1,0,53.716,0H49.2V23.109h2.855V13.281h1.66l2.656,9.895H59.36L56.305,12.617C58.1,11.422,58.7,9.43,58.7,6.773Zm-5.047,3.586H52.055V3.121h1.461c2.059,0,2.391,1.262,2.391,3.586S55.575,10.359,53.649,10.359Z"
                transform="translate(-49.2 0)"
                fill="#fff"
                fillRule="evenodd"
              />
              <path
                d="M72.483,9.7a3.922,3.922,0,0,0-3.055,1.395,5.969,5.969,0,0,0-1.262,3.586h2.723a1.717,1.717,0,0,1,1.594-1.66c1.063,0,1.395.863,1.395,1.926v1.727c-.6,0-1-.066-1.328-.066-3.52,0-4.449,1.793-4.449,5.578,0,3.652,1.727,4.383,3.055,4.383s1.992-.664,2.723-1.859h.066v1.66H76.8V14.946C76.733,11.493,74.873,9.7,72.483,9.7Zm1.395,11.82c0,1.328-.6,2.125-1.527,2.125s-1.461-.863-1.461-2.125A2.03,2.03,0,0,1,73.08,19.2a3.509,3.509,0,0,1,.8.066Z"
                transform="translate(-55.549 -3.259)"
                fill="#fff"
                fillRule="evenodd"
              />
              <path
                d="M95.4,6.641H92.609l-3.387,7.172h-.066V0H86.3V23.109h2.855V18.527L90.484,16l2.59,7.105h3.055l-4.051-9.762Z"
                transform="translate(-61.663)"
                fill="#fff"
                fillRule="evenodd"
              />
              <path
                d="M107.584,9.7a3.858,3.858,0,0,0-3.652,2.391,8.1,8.1,0,0,0-.531,3.32v5.445a7.9,7.9,0,0,0,.531,3.32,3.924,3.924,0,0,0,3.652,2.391,3.688,3.688,0,0,0,3.055-1.461,5.453,5.453,0,0,0,1.129-3.52h-2.855c-.066.73-.332,1.66-1.395,1.66-.863,0-1.395-.6-1.395-1.594V19.4H111.7V14.88C111.767,11.958,110.173,9.7,107.584,9.7Zm1.395,7.105h-2.789V14.946c0-1.262.531-1.859,1.395-1.859s1.395.664,1.395,1.859Z"
                transform="translate(-67.408 -3.259)"
                fill="#fff"
                fillRule="evenodd"
              />
              <path
                d="M126.778,9.7a2.9,2.9,0,0,0-2.656,1.793h-.066V9.9H121.2V26.368h2.855V15.278c0-1.461.332-2.258,1.395-2.258.863,0,1.395.73,1.395,1.926V26.368H129.7V13.751C129.7,10.762,128.173,9.7,126.778,9.7Z"
                transform="translate(-73.387 -3.259)"
                fill="#fff"
                fillRule="evenodd"
              />
              <path
                d="M145.078,9.7a2.9,2.9,0,0,0-2.656,1.793h-.066V9.9H139.5V26.368h2.856V15.278c0-1.461.332-2.258,1.395-2.258.863,0,1.395.73,1.395,1.926V26.368H148V13.751C148,10.762,146.473,9.7,145.078,9.7Z"
                transform="translate(-79.535 -3.259)"
                fill="#fff"
                fillRule="evenodd"
              />
              <path
                d="M163.544,21.09c0,1.461-.332,2.258-1.395,2.258-.863,0-1.395-.73-1.395-1.926V10H157.9V22.617c0,2.922,1.527,4.051,2.922,4.051a2.9,2.9,0,0,0,2.656-1.793h.066v1.594H166.4V10h-2.855Z"
                transform="translate(-85.716 -3.359)"
                fill="#fff"
                fillRule="evenodd"
              />
              <path
                d="M181.111,16.938l-1.66-.8a1.619,1.619,0,0,1-1.2-1.66,1.515,1.515,0,0,1,1.395-1.66,1.569,1.569,0,0,1,1.461,1.66h2.656c0-2.723-1.66-4.781-3.984-4.781-2.258,0-4.184,1.992-4.184,4.781A4.569,4.569,0,0,0,178.455,19l1.328.6a1.989,1.989,0,0,1,1.395,1.992,1.668,1.668,0,0,1-1.594,1.793c-1.063,0-1.395-.93-1.527-1.859H175.4c.133,3.254,1.992,4.98,4.184,4.98,2.457,0,4.184-2.258,4.184-4.98A4.536,4.536,0,0,0,181.111,16.938Z"
                transform="translate(-91.595 -3.259)"
                fill="#fff"
                fillRule="evenodd"
              />
              <path
                d="M195.555,18.461V0H192.7V18.727c0,3.852,1.594,4.582,4.184,4.582V20.187C195.821,20.055,195.555,19.723,195.555,18.461Z"
                transform="translate(-97.407)"
                fill="#fff"
                fillRule="evenodd"
              />
              <rect
                width="2.855"
                height="16.469"
                transform="translate(102 6.641)"
                fill="#fff"
              />
              <rect
                width="2.855"
                height="3.32"
                transform="translate(102)"
                fill="#fff"
              />
              <rect
                width="2.855"
                height="3.32"
                transform="translate(108.574)"
                fill="#fff"
              />
              <rect
                width="2.855"
                height="16.469"
                transform="translate(108.574 6.641)"
                fill="#fff"
              />
              <path
                d="M224.65,2.5h-2.855V7.48H220.4v2.59h1.395V20.1c0,1.727.863,3.852,3.387,3.852h1.2V20.828c-1.262,0-1.727-.066-1.727-1.594V10.07h1.727V7.48H224.65Z"
                transform="translate(-106.713 -0.84)"
                fill="#fff"
                fillRule="evenodd"
              />
              <path
                d="M235.25,2.5h-2.855V7.48H231v2.59h1.395V20.1c0,1.727.863,3.852,3.387,3.852h1.2V20.828c-1.262,0-1.727-.066-1.727-1.594V10.07h1.727V7.48H235.25Z"
                transform="translate(-110.273 -0.84)"
                fill="#fff"
                fillRule="evenodd"
              />
              <path
                d="M251.236,12.091A3.924,3.924,0,0,0,247.584,9.7a3.858,3.858,0,0,0-3.652,2.391,8.1,8.1,0,0,0-.531,3.32v5.445a7.9,7.9,0,0,0,.531,3.32,3.924,3.924,0,0,0,3.652,2.391,3.858,3.858,0,0,0,3.652-2.391,8.1,8.1,0,0,0,.531-3.32V15.411A8.1,8.1,0,0,0,251.236,12.091Zm-2.324,9.3c0,1.262-.531,1.859-1.395,1.859s-1.395-.664-1.395-1.859V14.946c0-1.262.531-1.859,1.395-1.859s1.395.664,1.395,1.859Z"
                transform="translate(-114.439 -3.259)"
                fill="#fff"
                fillRule="evenodd"
              />
            </g>
          </svg>
        </figure>

        <div className="wrapper wrapper-grid-4">
          <div className="col">
            <p>
              <strong>Rakennusliitto ry</strong>
              <br />
              Keskustoimisto
              <br />
              PL 307
              <br />
              Siltasaarenkatu 4<br />
              00531 Helsinki
              <br />
              Y-tunnus: 0213629-4
            </p>
            <p>
              <a
                style={{ fontWeight: 600 }}
                href="https://rakennusliitto.fi/yhteystiedot"
              >
                Palvelupisteet ja henkilöstö
              </a>
            </p>
          </div>

          <div className="col">
            <p style={{ fontWeight: 600 }}>Palvelunumerot</p>
            <p>
              <strong>Jäsenyysasiat</strong>
              <br />
              <a href="tel:020 690 231">020 690 231</a>
            </p>
            <p>
              <strong>Työehtoasiat</strong>
              <br />
              <a href="http://tel.020690232">020 690 232</a>
            </p>
            <p>
              <strong>Työttömyyskassa</strong>
              <br />
              <a href="tel:020290230">020 690 230</a>
            </p>
          </div>

          <ul className="footer-links">
            <li>
              <Link to="/">
                Etusivu{' '}
                <svg
                  fill="#333"
                  width="9"
                  height="9"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1683 808l-742 741q-19 19-45 19t-45-19L109 808q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                </svg>
              </Link>
            </li>
            <li>
              <Link to="/jobs">
                Työnvälitys{' '}
                <svg
                  fill="#333"
                  width="9"
                  height="9"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1683 808l-742 741q-19 19-45 19t-45-19L109 808q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                </svg>
              </Link>
            </li>
            <li>
              <Link to="/trainings">
                Koulutukset{' '}
                <svg
                  fill="#333"
                  width="9"
                  height="9"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1683 808l-742 741q-19 19-45 19t-45-19L109 808q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                </svg>
              </Link>
            </li>
            <li>
              <a href="https://rakennusliitto.fi">
                Rakennusliitto.fi{' '}
                <svg
                  fill="#333"
                  width="9"
                  height="9"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1683 808l-742 741q-19 19-45 19t-45-19L109 808q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                </svg>
              </a>
            </li>
          </ul>

          <ul className="footer-buttons">
            <li>
              <a
                href="https://easiointi.yap.fi/rakennusliitto"
                className="button button-gray button-transparent"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="13"
                  height="13"
                >
                  <path d="M14 16V5l-1 1v9H1V3h9l1-1H0v14h14z" />
                  <path d="M16 0h-5l1.8 1.8L6 8.6 7.4 10l6.8-6.8L16 5V0z" />
                </svg>
                eAsiointi
              </a>
            </li>
            <li>
              <a
                href="https://rakennuskassa.fi/"
                className="button button-gray button-transparent"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="13"
                  height="13"
                >
                  <path d="M14 16V5l-1 1v9H1V3h9l1-1H0v14h14z" />
                  <path d="M16 0h-5l1.8 1.8L6 8.6 7.4 10l6.8-6.8L16 5V0z" />
                </svg>
                Työttömyyskassa
              </a>
            </li>
            <li>
              <a
                href="https://rakennusliitto.fi/"
                className="button button-gray button-transparent"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="13"
                  height="13"
                >
                  <path d="M14 16V5l-1 1v9H1V3h9l1-1H0v14h14z" />
                  <path d="M16 0h-5l1.8 1.8L6 8.6 7.4 10l6.8-6.8L16 5V0z" />
                </svg>
                Rakennusliitto
              </a>
            </li>
          </ul>
        </div>

        <ul className="social-media-icons">
          <li>
            <a href="https://www.facebook.com/Rakennusliitto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M21.429-19.5a2.48,2.48,0,0,1,1.821.75A2.48,2.48,0,0,1,24-16.929V1.929a2.48,2.48,0,0,1-.75,1.821,2.48,2.48,0,0,1-1.821.75H14.089V-3.643H17.2L17.786-7.5h-3.7v-2.518q0-2.036,2.143-2.036h1.661v-3.268a16.74,16.74,0,0,0-2.946-.268A5.54,5.54,0,0,0,12.295-15a4.262,4.262,0,0,0-1.741,1.768,5.732,5.732,0,0,0-.643,2.786V-7.5H6.536v3.857H9.911V4.5H2.571A2.48,2.48,0,0,1,.75,3.75,2.48,2.48,0,0,1,0,1.929V-16.929A2.48,2.48,0,0,1,.75-18.75a2.48,2.48,0,0,1,1.821-.75Z"
                  transform="translate(0 19.5)"
                  fill="#fff"
                />
              </svg>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/rakennusliitto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M7.654-11.839a5.987,5.987,0,0,1,4.393-1.821,5.943,5.943,0,0,1,4.366,1.795A5.943,5.943,0,0,1,18.208-7.5a5.943,5.943,0,0,1-1.795,4.366,5.943,5.943,0,0,1-4.366,1.795A5.943,5.943,0,0,1,7.681-3.134,5.943,5.943,0,0,1,5.886-7.5,5.9,5.9,0,0,1,7.654-11.839ZM9.208-4.661a3.87,3.87,0,0,0,2.839,1.179,3.87,3.87,0,0,0,2.839-1.179A3.87,3.87,0,0,0,16.065-7.5a3.87,3.87,0,0,0-1.179-2.839,3.87,3.87,0,0,0-2.839-1.179,3.87,3.87,0,0,0-2.839,1.179A3.87,3.87,0,0,0,8.029-7.5,3.87,3.87,0,0,0,9.208-4.661ZM19.44-14.893a1.223,1.223,0,0,1,.429.964,1.438,1.438,0,0,1-.4,1.018,1.3,1.3,0,0,1-.991.429,1.391,1.391,0,0,1-1.018-.429,1.391,1.391,0,0,1-.429-1.018,1.3,1.3,0,0,1,.429-.991,1.438,1.438,0,0,1,1.018-.4A1.223,1.223,0,0,1,19.44-14.893Zm4.554,2.464q.054,1.446.054,4.929t-.054,4.929a7.388,7.388,0,0,1-1.955,5.063,7.2,7.2,0,0,1-5.062,1.9q-1.446.107-4.929.107T7.118,4.393A7.352,7.352,0,0,1,2.083,2.464,5.17,5.17,0,0,1,.958.964,7.58,7.58,0,0,1,.342-.7,8.317,8.317,0,0,1,.154-2.571Q.047-4.018.047-7.5t.107-4.982a7.28,7.28,0,0,1,1.929-4.982,7.218,7.218,0,0,1,5.036-1.982q1.446-.054,4.929-.054t4.929.054a7.388,7.388,0,0,1,5.063,1.955A7.388,7.388,0,0,1,23.993-12.429Zm-2.571,12a5.81,5.81,0,0,0,.268-1.1q.107-.67.161-1.607T21.9-4.661v-5.679q0-.589-.054-1.527t-.161-1.607a5.81,5.81,0,0,0-.268-1.1,3.866,3.866,0,0,0-2.3-2.3,5.81,5.81,0,0,0-1.1-.268q-.67-.107-1.607-.161t-1.5-.054H9.208q-.589,0-1.527.054t-1.607.161a5.81,5.81,0,0,0-1.1.268,3.866,3.866,0,0,0-2.3,2.3,5.81,5.81,0,0,0-.268,1.1q-.107.67-.161,1.607t-.054,1.5v5.143q0,.509.027,1.313T2.3-2.545q.054.563.161,1.152t.214.964a4.061,4.061,0,0,0,2.3,2.3,5.81,5.81,0,0,0,1.1.268q.67.107,1.607.161t1.5.054h5.705q.589,0,1.527-.054t1.607-.161a5.81,5.81,0,0,0,1.1-.268A4.061,4.061,0,0,0,21.422-.429Z"
                  transform="translate(-0.047 19.5)"
                  fill="#fff"
                />
              </svg>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/Rakennusliitto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M21.429-19.5a2.48,2.48,0,0,1,1.821.75A2.48,2.48,0,0,1,24-16.929V1.929a2.48,2.48,0,0,1-.75,1.821,2.48,2.48,0,0,1-1.821.75H2.571A2.48,2.48,0,0,1,.75,3.75,2.48,2.48,0,0,1,0,1.929V-16.929A2.48,2.48,0,0,1,.75-18.75a2.48,2.48,0,0,1,1.821-.75ZM18.8-10.982A7.794,7.794,0,0,0,20.571-12.8a7.164,7.164,0,0,1-2.036.536A3.6,3.6,0,0,0,20.089-14.2a6.662,6.662,0,0,1-2.25.857,3.26,3.26,0,0,0-2.518-1.125,3.4,3.4,0,0,0-2.732,1.286,3.42,3.42,0,0,0-.7,3.054,9.855,9.855,0,0,1-7.286-3.7,3.372,3.372,0,0,0-.482,1.768,3.074,3.074,0,0,0,.214,1.1,4.03,4.03,0,0,0,.589,1.045,4.276,4.276,0,0,0,.8.8,3.658,3.658,0,0,1-1.607-.482v.054a3.408,3.408,0,0,0,.8,2.25A3.565,3.565,0,0,0,6.964-6.054,4.8,4.8,0,0,1,5.357-6,3.279,3.279,0,0,0,6.589-4.259a3.354,3.354,0,0,0,2.036.67,6.888,6.888,0,0,1-4.339,1.5,6.116,6.116,0,0,1-.857-.054A9.7,9.7,0,0,0,8.839-.536,9.608,9.608,0,0,0,14.25-2.089a9.6,9.6,0,0,0,3.455-3.8,10.5,10.5,0,0,0,1.1-4.661Z"
                  transform="translate(0 19.5)"
                  fill="#fff"
                />
              </svg>
            </a>
          </li>
          <li>
            <a href="https://fi.linkedin.com/company/rakennusliitto-the-construction-trade-union-finland-">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M22.286-19.5a1.647,1.647,0,0,1,1.205.509A1.647,1.647,0,0,1,24-17.786V2.786a1.647,1.647,0,0,1-.509,1.205,1.647,1.647,0,0,1-1.205.509H1.714A1.647,1.647,0,0,1,.509,3.991,1.647,1.647,0,0,1,0,2.786V-17.786a1.647,1.647,0,0,1,.509-1.205A1.647,1.647,0,0,1,1.714-19.5ZM7.232,1.071h.054V-10.393H3.7V1.071ZM4.018-12.536a1.967,1.967,0,0,0,1.446.589,2.01,2.01,0,0,0,1.473-.616,1.98,1.98,0,0,0,.616-1.446,1.98,1.98,0,0,0-.616-1.446,2.01,2.01,0,0,0-1.473-.616,1.924,1.924,0,0,0-1.446.616,2.025,2.025,0,0,0-.589,1.446A2.118,2.118,0,0,0,4.018-12.536ZM20.571,1.071V-5.2a12.86,12.86,0,0,0-.161-2.2,5.415,5.415,0,0,0-.589-1.687,2.739,2.739,0,0,0-1.312-1.179,5.231,5.231,0,0,0-2.17-.4,4.006,4.006,0,0,0-2.116.536,3.207,3.207,0,0,0-1.259,1.286h-.054v-1.554H9.482V1.071h3.589V-4.607A4.566,4.566,0,0,1,13.5-6.75a1.706,1.706,0,0,1,1.661-.8,1.987,1.987,0,0,1,1.071.268,1.267,1.267,0,0,1,.563.8,8.655,8.655,0,0,1,.188.938A8.209,8.209,0,0,1,17.036-4.5V1.071Z"
                  transform="translate(0 19.5)"
                  fill="#fff"
                />
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/user/RakennusliittoRy">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M10.018-10.393,15.107-7.5,10.018-4.607ZM24-16.929V1.929a2.48,2.48,0,0,1-.75,1.821,2.48,2.48,0,0,1-1.821.75H2.571A2.48,2.48,0,0,1,.75,3.75,2.48,2.48,0,0,1,0,1.929V-16.929A2.48,2.48,0,0,1,.75-18.75a2.48,2.48,0,0,1,1.821-.75H21.429a2.48,2.48,0,0,1,1.821.75A2.48,2.48,0,0,1,24-16.929ZM21.75-7.5a19.12,19.12,0,0,0-.429-4.714,2.129,2.129,0,0,0-1.714-1.714,11.771,11.771,0,0,0-2.545-.321q-1.9-.107-3.509-.107H12a38.542,38.542,0,0,0-7.607.429,2.129,2.129,0,0,0-1.714,1.714,9.557,9.557,0,0,0-.321,1.875Q2.25-9.161,2.25-8.3v.8a18.73,18.73,0,0,0,.429,4.714A2.129,2.129,0,0,0,4.393-1.071,11.771,11.771,0,0,0,6.938-.75q1.9.107,3.509.107H12a38.542,38.542,0,0,0,7.607-.429,2.221,2.221,0,0,0,1.714-1.714,8.475,8.475,0,0,0,.321-1.821q.107-1.179.107-2.036Z"
                  transform="translate(0 19.5)"
                  fill="#3c3c3c"
                />
              </svg>
            </a>
          </li>
        </ul>

        <ul className="other-links">
          <li>
            <a href="https://rakennusliitto.fi/rakennusliitto/tietosuoja/">
              Tietosuoja
            </a>
          </li>
        </ul>
      </div>
    </FooterContainer>
  )
}

export default Footer
