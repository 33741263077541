/* eslint-disable no-console */
import React from 'react'
import styled from 'styled-components'
import { SuperAdminLayout } from 'Components/Layout/SuperAdminLayout'
import NewTraining from 'Components/SuperAdminPage/SuperAdminModifyTraining/ModifyTraining'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`

const SuperAdminModifyAd = (props: any) => {
  const { computedMatch } = props
  return (
    <SuperAdminLayout>
      <ContentContainer>
        <NewTraining adId={computedMatch.params.adId} />
      </ContentContainer>
    </SuperAdminLayout>
  )
}
export default SuperAdminModifyAd
