import React, { useEffect, useState } from 'react'
import { CarouselItemData } from 'Pages/FrontPage'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import YouTubePlayer from 'Components/YouTubePlayer'

const PAGE_SWAP_SPEED = 300

const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  overflow-x: hidden;
  padding: 10px 0;
  &:nth-of-type(odd) {
    background: #f4f5f7;
  }
  svg {
    position: absolute;
    top: calc(50% - 25px);
    height: 50px;
    width: 18px;
    color: #dcdddf;
    font-size: 50px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 51px;
    cursor: pointer;
    z-index: 999;
    &:first-of-type {
      left: 10px;
    }
    &:last-of-type {
      right: 10px;
    }
    @media screen and ${props => props.theme.breakpoints.md} {
      position: relative;
      top: unset;
      left: unset;
      right: unset;
      margin: 0 75px;
      height: 101px;
      width: 38px;
      font-size: 100px;
    }
  }
  .carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    overflow-x: hidden;
    transform: translateX(0);
    flex-grow: 1;
    &.carousel-video {
      padding: 0;
      .carousel-item-content {
        min-height: 0;
      }
      iframe {
        width: 100%;
        height: 50vh;
        @media screen and ${props => props.theme.breakpoints.md} {
          height: 500px;
        }
      }
    }
    &:not(.move-from-right):not(.move-from-left) {
      transition: transform ${PAGE_SWAP_SPEED / 1000}s ease-in-out;
    }
    &.move-to-left {
      transform: translateX(-100vw);
    }
    &.move-to-right {
      transform: translateX(100vw);
    }
    &.move-from-left {
      transform: translateX(-100vw);
    }
    &.move-from-right {
      transform: translateX(100vw);
    }
    .carousel-item-content {
      display: flex;
      align-items: center;
      max-width: 100%;
      min-height: 500px;
      flex-grow: 1;
      flex-direction: column-reverse;
      @media screen and ${props => props.theme.breakpoints.md} {
        flex-direction: row;
        max-width: 1200px;
      }
      .carousel-item-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        padding-right: 15px;
        h2 {
          color: #252525;
          font-family: Barlow;
          font-size: 32px;
          letter-spacing: 0;
          line-height: 38px;
          margin: 0;
          font-weight: 400;
        }
        p {
          color: #252525;
          font-family: Barlow;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 24px;
          margin-bottom: 0;
        }
        button {
          padding: 10px;
          line-height: 1;
          border-radius: 6px;
          background-color: #d60c17;
          border: none;
          outline: none;
          color: #ffffff;
          font-family: Barlow;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
          cursor: pointer;
          margin-top: 16px;
          max-width: 200px;
        }
      }
      .carousel-item-image {
        flex-grow: 1;
        padding-left: 15px;
        width: 100%;
        @media screen and ${props => props.theme.breakpoints.md} {
          width: 50%;
        }
      }
    }
  }
`
const CarouselIndexCircles = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  justify-content: center;
  .index-circle {
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #979797;
    border-radius: 100%;
    background: #fff;
    cursor: pointer;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    .select-circle {
      height: 7px;
      width: 7px;
      border-radius: 100%;
      background: #d60c17;
    }
  }
`

const Carousel = ({ data }: { data: Array<CarouselItemData> }) => {
  const [carouselIndex, setCarouselIndex] = useState<number>(0)
  const [timeout, setIntervalIndex] = useState<number | null>(null)
  const [switchPage, setSwitchPage] = useState<
    'to-left' | 'to-right' | 'from-left' | 'from-right' | null
  >(null)
  const setPreviousPage = () => {
    if (switchPage !== null) {
      return
    }
    setSwitchPage('to-right')
    setTimeout(async () => {
      setCarouselIndex(i => (i <= 0 ? data.length - 1 : i - 1))
      setSwitchPage('from-left')
      setTimeout(() => {
        setSwitchPage(null)
      }, 50)
    }, PAGE_SWAP_SPEED)
  }
  const setNextPage = () => {
    if (switchPage !== null) {
      return
    }
    setSwitchPage('to-left')
    setTimeout(async () => {
      setCarouselIndex(i => (i >= data.length - 1 ? 0 : i + 1))
      setSwitchPage('from-right')
      setTimeout(() => {
        setSwitchPage(null)
      }, 50)
    }, PAGE_SWAP_SPEED)
  }
  const autoPageSwap = () => {
    setNextPage()
  }
  useEffect(() => {
    setIntervalIndex(setInterval(() => autoPageSwap(), 7500))
  }, [])
  const renderCarouselItem = (index: number) => {
    const item = data[index]
    if (item.video_url) {
      return (
        <div
          className={`carousel-video carousel-item${
            switchPage !== null ? ` move-${switchPage}` : ''
          }`}
        >
          <div className="carousel-item-content">
            <YouTubePlayer
              url={item.video_url}
              onClick={() => {
                if (timeout === null) {
                  return
                }
                clearInterval(timeout)
                setIntervalIndex(null)
              }}
            />
          </div>
        </div>
      )
    }
    return (
      <div
        className={`carousel-item${
          switchPage !== null ? ` move-${switchPage}` : ''
        }`}
      >
        <div className="carousel-item-content">
          <div className="carousel-item-text">
            <h2>{item.title}</h2>
            <p>{item.description}</p>
            {item.action ? (
              <button
                type="button"
                onClick={() => window.open(item.action.url, '__blank')}
              >
                {item.action.name}
              </button>
            ) : null}
          </div>
          {item.image && (
            <img
              src={item.image}
              className="carousel-item-image"
              alt={item.title}
            />
          )}
        </div>
      </div>
    )
  }
  return (
    <CarouselContainer>
      <FontAwesomeIcon
        icon={['far', 'angle-left']}
        onClick={() => {
          setPreviousPage()
          if (timeout === null) {
            return
          }
          clearInterval(timeout)
          setIntervalIndex(null)
        }}
      />
      {renderCarouselItem(carouselIndex)}
      <FontAwesomeIcon
        icon={['far', 'angle-right']}
        onClick={() => {
          setNextPage()
          if (timeout === null) {
            return
          }
          clearInterval(timeout)
          setIntervalIndex(null)
        }}
      />
      <CarouselIndexCircles>
        {data.map((d, i) => (
          <div
            key={d.id}
            className="index-circle"
            onClick={() => {
              setCarouselIndex(i)
              if (timeout === null) {
                return
              }
              clearInterval(timeout)
              setIntervalIndex(null)
            }}
          >
            {i === carouselIndex && <div className="select-circle" />}
          </div>
        ))}
      </CarouselIndexCircles>
    </CarouselContainer>
  )
}

export default Carousel
