/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  getLanguages,
  LocationType
} from 'Features/MemberProfile/memberProfileSlice'
import { fetchProfessionalTitles } from 'Features/ProfessionalTitle/professionalTitleSlice'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'Store'
import styled from 'styled-components/macro'

const SummaryBackground = styled.div`
    height:100%;
    width 30%;
    min-width:300px;
    max-width:450px;
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    .description {
        font-weight: 600;
        color: #252525;
        font-family: Barlow;
        font-size: 14px;
    }
    .value-text {
        color: #363636;
        font-family: Barlow;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
    }
    .informant-text {
        color: #363636;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 500;
    }
`
const SummaryRowList = styled.div`
  padding: 10px 0px 10px 0px;
`
const SummaryRowItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 5px 0px;
`
const SummaryColumnItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .description {
    padding: 10px 0px 5px 0px;
  }
`
const CompanyLogo = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
`
const ImageArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 0px;
  min-height: 190px;
  max-height: 190px;
  border: 1px solid #d6dce2;
  border-radius: 3px;
  background-color: #e7eaef;
  text-align: center;
  .image-placeholder {
    display: flex;
    flex-direction: column;
  }
`
const Photo = styled.div`
  background-color: #e7eaef;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

type LanguageType = {
  id: number
  level: number
}
type SimplifiedLanguage = {
  name: string | undefined
  level: string
}
const FormTabs = ({
  values,
  companyLogo,
  companyName
}: {
  values: any
  companyLogo: string
  companyName: string
}) => {
  const { t } = useTranslation()
  const {
    memberProfile: { languages: profileLanguages }
  } = useSelector((state: RootState) => state)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchProfessionalTitles())
    dispatch(getLanguages())
  }, [dispatch])
  const { titles } = useSelector((state: RootState) => state.professionalTitle)
  const photoUrl = values.job_image ? `${values.job_image.url}` : ''
  const {
    competence: {
      data: { results: competenceResults }
    },
    drivingLicense: {
      data: { results: drivingLicenseResults }
    }
  } = useSelector((state: any) => state)
  const locations = values.locations
    ? values.locations.map((location: LocationType) => location.label)
    : undefined
  const offices = values.offices
    ? values.offices.map((office: LocationType) => office.label)
    : undefined
  const displayValue = (valueName: any) => {
    return valueName || t('not-selected')
  }
  const displayLanguage = (languages: Array<string>) => {
    const objects =
      languages?.length > 0
        ? languages.map((lang: string) => {
            return <div>{lang}</div>
          })
        : undefined
    return objects || t('not-selected')
  }

  const displayDrivingLicenses = (drivingLicenses: Array<number>) => {
    const selectedLicenses: Array<string> = []
    if (drivingLicenses) {
      drivingLicenses.map((license: any) => {
        if (values.driving_licenses) {
          if (values.driving_licenses.includes(license.id)) {
            selectedLicenses.push(license.license_type)
          }
        }
      })
    }
    return selectedLicenses
  }
  const displayProfessionalTitles = () => {
    const selectedTitles: Array<string> = []
    if (titles) {
      titles.map((title: any) => {
        if (values.professional_titles) {
          if (values.professional_titles.includes(title.id)) {
            selectedTitles.push(title.title)
          }
        }
      })
    }
    return selectedTitles
  }
  const displayCompetences = (competences: Array<number>) => {
    const selectedCompetences: Array<string> = []
    if (competences) {
      competences.map((competence: any) => {
        if (values.competences) {
          if (values.competences.includes(competence.id)) {
            selectedCompetences.push(competence.title)
          }
        }
      })
    }
    return selectedCompetences
  }
  const displayLanguages = (selectedLanguages: LanguageType[]) => {
    const languageObjects = selectedLanguages
      ?.map((lan: LanguageType) => {
        return {
          name: profileLanguages?.find((la: any) => la.id === lan.id)
            ?.full_name,
          level: lan.level === 0 ? t('basics') : t('good')
        }
      })
      .map((simplifiedLang: SimplifiedLanguage) => {
        return `${simplifiedLang.name} - ${simplifiedLang.level} \n`
      })

    return languageObjects
  }
  const shortenDescription = (description: string) => {
    let shortenedDescription
    if (description) {
      const descToUse = description.replace(/<\/?[^>]+(>|$)/g, '')
      shortenedDescription = `${descToUse.substring(0, 30)}...`
    }

    return shortenedDescription
  }
  return (
    <SummaryBackground>
      <span className="description">{t('ad-summary')}</span>
      <SummaryRowItem>
        <SummaryColumnItem>
          <span className="description">{t('informant')}</span>
          <span className="informant-text">{companyName}</span>
        </SummaryColumnItem>
        <SummaryColumnItem>
          <span className="description">{t('informant-logo')}</span>
          <div>
            <CompanyLogo
              style={{
                backgroundImage: `url(${companyLogo})`,
                height: '30px'
              }}
            />
          </div>
        </SummaryColumnItem>
      </SummaryRowItem>
      <SummaryColumnItem>
        <span className="description">{t('ad-image')}</span>
        <ImageArea>
          {values.job_image && values.job_image.url ? (
            <Photo style={{ backgroundImage: `url(${photoUrl})` }} />
          ) : (
            <div className="image-placeholder">
              <FontAwesomeIcon
                style={{ width: '100%' }}
                className="fa-2x"
                color="#D6DCE2"
                icon={['far', 'eye-slash']}
              />
            </div>
          )}
        </ImageArea>
      </SummaryColumnItem>
      <SummaryColumnItem>
        <span className="description">{t('title')}</span>
        <span className="value-text">{displayValue(values.ad_title)}</span>
      </SummaryColumnItem>
      <SummaryColumnItem>
        <span className="description">{t('description')}</span>
        <span className="value-text">
          {shortenDescription(values.ad_description)}
        </span>
      </SummaryColumnItem>
      <SummaryRowList>
        <SummaryRowItem>
          <span className="description">{t('location')}:</span>
          <span className="value-text">
            {displayValue(locations ? locations.join(', ') : undefined)}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('office')}:</span>
          <span className="value-text">
            {displayValue(offices ? offices.join(', ') : undefined)}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('salary')}:</span>
          <span className="value-text">
            {t('collective-agreement-contractual')}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('duration')}:</span>
          <span className="value-text">{displayValue(values.duration)}</span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('job_type')}:</span>
          <span className="value-text">
            {displayValue(values.job_type ? values.job_type.title : undefined)}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('employment_type')}:</span>
          <span className="value-text">
            {displayValue(
              values.employment_type ? values.employment_type.title : undefined
            )}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('start_date')}:</span>
          <span className="value-text">
            {displayValue(values.start_date ? values.start_date : undefined)}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('search_end_date')}:</span>
          <span className="value-text">
            {displayValue(values.search_end_date)}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('website')}:</span>
          <span className="value-text">{displayValue(values.website)}</span>
        </SummaryRowItem>
      </SummaryRowList>
      <SummaryRowItem>
        <SummaryColumnItem>
          <span className="description">{t('professional-titles')}</span>
          <span className="value-text">
            {displayValue(
              values.professional_titles
                ? displayProfessionalTitles().join(', ')
                : undefined
            )}
          </span>
        </SummaryColumnItem>
        <SummaryColumnItem>
          <span className="description">{t('competences')}</span>
          <span className="value-text">
            {displayValue(
              values.competences
                ? displayCompetences(competenceResults).join(', ')
                : undefined
            )}
          </span>
        </SummaryColumnItem>
      </SummaryRowItem>
      <SummaryRowItem>
        <SummaryColumnItem>
          <span className="description">{t('driving-license')}</span>
          <span className="value-text">
            {displayValue(
              values.driving_licenses
                ? displayDrivingLicenses(drivingLicenseResults).join(', ')
                : undefined
            )}
          </span>
        </SummaryColumnItem>
        <SummaryColumnItem>
          <span className="description">{t('language-skills')}</span>
          <span className="value-text">
            {displayLanguage(displayLanguages(values.languages))}
          </span>
        </SummaryColumnItem>
      </SummaryRowItem>
    </SummaryBackground>
  )
}

export default FormTabs
