import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { setShrinked } from 'Features/Recommender/recommenderSlice'
import { Tooltip } from 'Components/Blocks'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e7eaef;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 16px 18px;
  margin-bottom: 18px;
  input.smaller-input.form-select {
    max-width: none;
    padding: 12px;
  }
  .form-row {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px dashed #d6dce2;
    padding-bottom: 30px;
    margin-bottom: 30px;
    .form-field {
      display: flex;
      flex-direction: column;
      &:first-of-type {
        margin-right: 20px;
      }
      input.smaller-input.form-select {
        margin-bottom: 0;
        max-width: 100px;
      }
      label {
        white-space: nowrap;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    user-select: none;
    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
      }
    }
    span.delete {
      color: #d60c17;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1;
      svg {
        color: #d60c17;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
    span.shrink {
      color: #5c5f63;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 1;
      text-align: center;
      svg {
        color: #0a4ea2;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }
  .work-exp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    span {
      color: #0a4ea2;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }
    svg {
      color: #0a4ea2;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
`

const RecommenderForm = ({
  recommender,
  onChange,
  deleteRecommender
}: {
  recommender: any
  onChange: (exp: any) => void
  deleteRecommender: () => void
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const shrinked = useSelector(
    ({ recommender: recommenderState }: { recommender: any }) =>
      recommenderState.shrinked
  )
  const onRecommenderChange = ({
    target
  }: {
    target: { name: string; value: string | number }
  }) => {
    const newRecommender = { ...recommender }
    newRecommender[target.name] = target.value
    onChange(newRecommender)
  }
  if (shrinked.includes(recommender.id)) {
    return (
      <FormContainer>
        <div
          className="work-exp"
          onClick={() => dispatch(setShrinked(recommender.id))}
        >
          <FontAwesomeIcon icon={['fal', 'angle-down']} />
        </div>
      </FormContainer>
    )
  }
  return (
    <FormContainer>
      <label>{t('name')}</label>
      <input
        name="name"
        className="form-select smaller-margin"
        value={recommender.name || ''}
        onChange={onRecommenderChange}
      />
      <label>
        {t('phone')} <Tooltip tooltipText="phone-number-tooltip" />
      </label>
      <input
        name="phone"
        placeholder={t('phone-placeholder')}
        className="form-select smaller-margin"
        value={recommender.phone || ''}
        onChange={onRecommenderChange}
      />
      <label>{t('email')}</label>
      <input
        name="email"
        className="form-select smaller-margin"
        value={recommender.email || ''}
        onChange={onRecommenderChange}
      />
      <div className="actions">
        <span className="delete" onClick={deleteRecommender}>
          {t('remove-recommender')}{' '}
          <FontAwesomeIcon icon={['fal', 'times-circle']} />
        </span>
        <span
          className="shrink"
          onClick={() => dispatch(setShrinked(recommender.id))}
        >
          {t('shrink')} <FontAwesomeIcon icon={['fal', 'angle-up']} />
        </span>
      </div>
    </FormContainer>
  )
}

export default RecommenderForm
