/* eslint-disable camelcase */
import { JobsUnauthenticated } from 'Components/Jobs'
import MainLayout from 'Components/Layout/MainLayout'
import LoadingIndicator from 'Components/LoadingIndicator'
import Search from 'Components/Search'
import { fetchToken } from 'Features/Palkkaus/palkkausSlice'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'Store'
import styled from 'styled-components'

const IframeContainer = styled.div`
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`
const MemberPalkkaus = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchToken())
  }, [dispatch])
  const { palkkausToken, loading, error } = useSelector(
    (state: RootState) => state.palkkaus
  )
  if (!palkkausToken && loading !== 'fulfilled' && loading !== 'idle') {
    return <LoadingIndicator />
  }
  if (!palkkausToken) {
    return (
      <MainLayout>
        <Search type="job" />
        <JobsUnauthenticated salaryPayment error={error} />
      </MainLayout>
    )
  }
  const sourceUrl = `${process.env.REACT_APP_PALKKAUS_IFRAME_URL}${palkkausToken}`
  return (
    <IframeContainer>
      <iframe src={sourceUrl} title="PalkkausIframe" />
    </IframeContainer>
  )
}
export default MemberPalkkaus
