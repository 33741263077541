import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAxiosInstance } from 'Api'
import { SingleAdDataType } from 'Features/JobAdvertisements/advertisementSlice'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select, { OptionsType, ValueType } from 'react-select'
import AsyncSelect from 'react-select/async'
import { AsyncPaginate } from 'react-select-async-paginate'
import { JobApplicationType } from 'Features/JobApplication/jobApplicationSlice'
import { RootState, useAppDispatch } from 'Store'
import styled from 'styled-components/macro'
import { Selectable } from 'Components/NewAdvertisement/FieldComponents/Phase2'
import {
  createConversation,
  ConversationItem
} from 'Features/Conversation/conversationSlice'
import { createMessage, MessageItem } from 'Features/Message/messageSlice'
import {
  fetchProfessionalTitles,
  ProfessionalTitleType
} from 'Features/ProfessionalTitle/professionalTitleSlice'
import { fetchLocations } from 'Features/NewAdvertisement/newAdvertisementSlice'

interface AddConversationModalProps {
  show: boolean
  onClose: () => void
  organizationId?: number | null
  isAdmin?: boolean
  recipients?: Array<number>
  advertisement?: SingleAdDataType
}

interface ModalContainerProps {
  show: boolean
}

const ModalContainer = styled.div`
  position: fixed;
  visibility: ${(props: ModalContainerProps) =>
    props.show ? 'visible' : 'hidden'};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(37, 37, 37, 0.65);
  .content {
    position: relative;
    width: 100%;
    max-width: 845px;
    background-color: #ffffff;
    margin: auto;
    margin-top: 10vh;
    padding: 30px 60px;
    h1 {
      color: #252525;
      font-family: Barlow;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 29px;
      margin: 0;
      margin-bottom: 25px;
      font-weight: 400;
    }
    label {
      display: block;
      color: #333;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 10px;
    }
    .close-button {
      position: absolute;
      display: flex;
      align-items: center;
      color: #ffffff;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      top: -25px;
      right: 0;
      cursor: pointer;
      transition: color 0.3s ease-in-out;
      svg {
        margin-left: 5px;
      }
      &:hover {
        color: #ddd;
      }
    }
    .form-field {
      .quick-actions {
        display: flex;
        margin-top: 10px;
        .quick-select {
          color: #0a4ea2;
          font-family: Barlow;
          font-size: 12px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
      &:not(:last-of-type) {
        margin-bottom: 25px;
      }
    }
    .details {
      background: #f8f8f8;
      margin: 0 -60px;
      .receivers-box {
        overflow-y: auto;
        max-height: 91px;
      }
      .form-field {
        padding: 15px 60px;
        border-top: 1px solid #d6dce2;
        margin-bottom: 0;
        &:last-of-type {
          border-bottom: 1px solid #d6dce2;
        }
        label {
          margin-bottom: 10px;
        }
        span.info-text {
          color: #252525;
          font-family: Barlow;
          font-size: 14px;
          font-style: italic;
          letter-spacing: 0;
          line-height: 17px;
          margin-left: 15px;
        }
        input,
        textarea {
          margin-left: 15px;
          width: 100%;
          background: transparent;
          border: none;
          outline: none;
          color: #252525;
          font-family: Barlow;
          font-size: 14px;
          font-style: italic;
          letter-spacing: 0;
          line-height: 17px;
          resize: none;
        }
      }
      .receiver {
        display: inline-block;
        cursor: default;
      }
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      button {
        cursor: pointer;
        outline: none;
        text-transform: uppercase;
        border-radius: 6px;
        border: none;
        background-color: #d60c17;
        padding: 10px 30px;
        color: #ffffff;
        font-family: Barlow;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        &:first-of-type {
          background: transparent;
          color: #a8aeb4;
          font-family: Barlow;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          margin-right: 20px;
        }
      }
    }
  }
  .select-by-professional-title {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom 15px;
  }
  .by-professional-title-box {
    margin-top: 5px;
    margin-left: 4px;
  }
  .second-label {
    margin-top: 10px;
  }
  .get-users-btn {
    cursor: pointer;
        outline: none;
        text-transform: uppercase;
        border-radius: 6px;
        border: none;
        background-color: #d60c17;
        padding: 6px 18px;
        color: #ffffff;
        font-family: Barlow;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        margin-top: 18px;
  }
`
const CheckAll = styled.input``

type OptionType = {
  value: number
  data: SingleAdDataType
  label?: string
}

type ReceiverOptionType = {
  value: string
  label: string
}

type AdminReceiverOptionType = {
  data: {
    id: number | string
    type: 'user' | 'organization'
  }
  value: number | string
  label: string
}

const AddConversationModal = ({
  show,
  onClose,
  organizationId,
  isAdmin,
  recipients,
  advertisement
}: AddConversationModalProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [message, setMessage] = useState<string>('')
  const [subject, setSubject] = useState<string>('')
  const [titlesToggle, setTitlesToggle] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedLocation, setSelectedLocation] = useState<
    ValueType<OptionType>
  >(null)
  const [selectedProfession, setSelectedProfession] = useState<
    ValueType<OptionType>
  >(null)
  const [selectedAd, setSelectedAd] = useState<ValueType<OptionType>>(null)
  const [receivers, setReceivers] = useState<ValueType<ReceiverOptionType>>(
    null
  )
  const [adminReceivers, setAdminReceivers] = useState<
    ValueType<AdminReceiverOptionType>
  >(null)
  const { advertisementData } = useSelector(
    (state: RootState) => state.advertisement
  )
  useEffect(() => {
    if (recipients && advertisement && advertisement.id) {
      setSelectedAd({
        label: advertisement.title,
        value: advertisement.id,
        data: advertisement
      } as ValueType<OptionType>)
      const newReceivers: Array<ReceiverOptionType> = []
      advertisement.job_applications?.forEach((app: JobApplicationType) => {
        if (app.member_profile) {
          const { user } = app.member_profile
          if (recipients.includes(user.id)) {
            newReceivers.push({
              label: `${user.first_name} ${user.last_name}`,
              value: user.id.toString()
            })
          }
        }
      })
      setReceivers(newReceivers as ValueType<ReceiverOptionType>)
    }
  }, [recipients, advertisement])
  const sendMessage = async () => {
    if (isAdmin && adminReceivers) {
      const organizations = (adminReceivers as Array<AdminReceiverOptionType>)
        .filter(r => r.data.type === 'organization')
        .map(r => r.data.id)
      const users = (adminReceivers as Array<AdminReceiverOptionType>)
        .filter(r => r.data.type === 'user')
        .map(r => r.data.id)
      const conversationBody: ConversationItem = {
        subject,
        targeted_audience: {
          users,
          organizations
        }
      }
      const { payload } = await dispatch(createConversation(conversationBody))

      const messageBody: MessageItem = {
        content: message,
        conversation: payload.id
      }
      await dispatch(createMessage(messageBody))
      onClose()
      return
    } else if (isAdmin) {
      return
    }
    if (selectedAd && message && subject && receivers) {
      const conversationBody: ConversationItem = {
        subject,
        targeted_audience: {
          users: (receivers as Array<ReceiverOptionType>).map(r =>
            parseInt(r.value, 10)
          ),
          job_advertisements: [(selectedAd as OptionType).data.id]
        }
      }
      if (organizationId !== null) {
        conversationBody.organization = organizationId
      }
      const { payload } = await dispatch(createConversation(conversationBody))

      const messageBody: MessageItem = {
        content: message,
        conversation: payload.id
      }
      if (organizationId !== null) {
        messageBody.organization = organizationId
      }
      await dispatch(createMessage(messageBody))
      onClose()
    }
  }
  const loadOptions = (
    input: string,
    callback: (options: OptionsType<OptionType>) => void
  ) => {
    getAxiosInstance()
      .get(`/jobs/?title=${input}&organization_id=${organizationId}`)
      .then(result => {
        const { results } = result.data
        callback(
          results.map((ad: SingleAdDataType) => ({
            label: ad.title,
            value: ad
          }))
        )
      })
  }
  const loadAdminReceiverOptions = async (
    input: string,
    loadedOptions: Array<AdminReceiverOptionType>
  ) => {
    const { data } = await getAxiosInstance().post(
      '/conversation/receiver_options/',
      {
        search: input,
        current_options: adminReceivers,
        offset: loadedOptions.length
      }
    )
    return {
      options: data.results,
      hasMore: data.has_more
    }
  }

  const { locations } = useSelector(
    (state: RootState) => state.newAdvertisement
  )
  const { titles } = useSelector((state: RootState) => state.professionalTitle)

  useEffect(() => {
    dispatch(fetchLocations())
    dispatch(fetchProfessionalTitles())
  }, [dispatch])

  const loadProfessionalTitleOptions = titles
    ? titles.map((result: ProfessionalTitleType) => ({
        id: result.id,
        value: result.title,
        label: result.title
      }))
    : []

  const loadLocationOptions = locations
    ? locations.map(({ name, id }: { name: string; id: number }) => ({
        id,
        value: name,
        label: name
      }))
    : []

  const options: Array<ReceiverOptionType> = []
  const sAd = selectedAd as OptionType
  if (sAd) {
    sAd.data.job_applications?.forEach((app: JobApplicationType) => {
      if (app.member_profile) {
        const { user } = app.member_profile
        options.push({
          label: `${user.first_name} ${user.last_name}`,
          value: user.id.toString()
        })
      }
    })
  }

  const HandleTitlesToggle = () => {
    setTitlesToggle(!titlesToggle)
  }
  const allLocations = [
    {
      value: 'all-locations',
      label: t('all-locations')
    }
  ]

  const loadTitleReceiverOptions = async () => {
    setIsLoading(false)
    if (selectedProfession && (selectedLocation || allLocations)) {
      const { data } = await getAxiosInstance().post(
        '/conversation/professional_title_receivers/',
        {
          profession_title: selectedProfession,
          location: selectedLocation || allLocations
        }
      )

      const titleReceiverData: Array<AdminReceiverOptionType> = data.results.filter(
        (user: AdminReceiverOptionType) => ({
          data: {
            id: user.data.id,
            label: user.data.type
          },
          value: user.value,
          label: user.label
        })
      )
      setIsLoading(true)
      return setAdminReceivers(
        titleReceiverData as ValueType<AdminReceiverOptionType>
      )
    }
    setIsLoading(true)
    return setAdminReceivers(null)
  }

  return (
    <ModalContainer show={show}>
      <div className="content">
        <span className="close-button" onClick={onClose}>
          {t('close')} <FontAwesomeIcon icon={['fal', 'times-circle']} />
        </span>
        <h1>{t('add-new-message')}</h1>
        {isAdmin ? null : (
          <div className="form-field">
            <label>{t('ad')}</label>
            <AsyncSelect
              loadOptions={loadOptions}
              defaultOptions={advertisementData.results.map(ad => ({
                label: ad.title,
                value: ad.id as number,
                data: ad
              }))}
              onChange={(value: ValueType<OptionType>) => setSelectedAd(value)}
              placeholder={t('select')}
              value={selectedAd}
            />
          </div>
        )}
        {isAdmin ? (
          <div className="form-field">
            {titlesToggle ? null : <label>{t('receiver')}</label>}
            {titlesToggle ? (
              <div>
                <label>{t('district')}</label>
                <AsyncPaginate
                  isMulti
                  defaultValue={allLocations}
                  defaultOptions={loadLocationOptions}
                  onChange={(value: ValueType<OptionType>) =>
                    setSelectedLocation(value)
                  }
                  placeholder={t('select')}
                />
                <label className="second-label">
                  {t('professional-title')}
                </label>
                <AsyncPaginate
                  isMulti
                  defaultOptions={loadProfessionalTitleOptions}
                  onChange={(value: ValueType<OptionType>) =>
                    setSelectedProfession(value)
                  }
                  placeholder={t('select')}
                />
                <button
                  className="get-users-btn"
                  style={isLoading ? { opacity: '1.0' } : { opacity: '0.6' }}
                  type="button"
                  onClick={loadTitleReceiverOptions}
                >
                  {t('search-users')}
                </button>
              </div>
            ) : (
              <AsyncPaginate
                isMulti
                loadOptions={loadAdminReceiverOptions}
                onChange={(value: ValueType<AdminReceiverOptionType>) =>
                  setAdminReceivers(value)
                }
                placeholder={t('select')}
                value={adminReceivers}
                defaultOptions
              />
            )}
            {titlesToggle ? null : (
              <div className="quick-actions">
                <span
                  className="quick-select"
                  onClick={() =>
                    setAdminReceivers(value => {
                      const receiverArray = value as Array<AdminReceiverOptionType>
                      const allUsers: AdminReceiverOptionType = {
                        value: 'all-users',
                        data: {
                          id: 'all-users',
                          type: 'user'
                        },
                        label: t('all-users')
                      }
                      if (
                        !receiverArray ||
                        (receiverArray && !receiverArray.length)
                      ) {
                        return [allUsers]
                      }
                      const userIds = receiverArray.map(u => u.data.id)
                      if (userIds.includes('all-users')) {
                        return receiverArray.filter(
                          u => u.data.id !== 'all-users'
                        )
                      }
                      return [...receiverArray, allUsers]
                    })
                  }
                >
                  {t('select-all-users')}
                </span>
                <span
                  className="quick-select"
                  onClick={() =>
                    setAdminReceivers(value => {
                      const receiverArray = value as Array<AdminReceiverOptionType>
                      const allUsers: AdminReceiverOptionType = {
                        value: 'all-trainers',
                        data: {
                          id: 'all-trainers',
                          type: 'organization'
                        },
                        label: t('all-trainers')
                      }
                      if (
                        !receiverArray ||
                        (receiverArray && !receiverArray.length)
                      ) {
                        return [allUsers]
                      }
                      const userIds = receiverArray.map(u => u.data.id)
                      if (userIds.includes('all-trainers')) {
                        return receiverArray.filter(
                          u => u.data.id !== 'all-trainers'
                        )
                      }
                      return [...receiverArray, allUsers]
                    })
                  }
                >
                  {t('select-all-trainers')}
                </span>
                <span
                  className="quick-select"
                  onClick={() =>
                    setAdminReceivers(value => {
                      const receiverArray = value as Array<AdminReceiverOptionType>
                      const allUsers: AdminReceiverOptionType = {
                        value: 'all-employers',
                        data: {
                          id: 'all-employers',
                          type: 'organization'
                        },
                        label: t('all-employers')
                      }
                      if (
                        !receiverArray ||
                        (receiverArray && !receiverArray.length)
                      ) {
                        return [allUsers]
                      }
                      const userIds = receiverArray.map(u => u.data.id)
                      if (userIds.includes('all-employers')) {
                        return receiverArray.filter(
                          u => u.data.id !== 'all-employers'
                        )
                      }
                      return [...receiverArray, allUsers]
                    })
                  }
                >
                  {t('select-all-employers')}
                </span>
                <span
                  className="quick-select"
                  onClick={() =>
                    setAdminReceivers(value => {
                      const receiverArray = value as Array<AdminReceiverOptionType>
                      const allUsers: AdminReceiverOptionType = {
                        value: 'all-job-seekers',
                        data: {
                          id: 'all-job-seekers',
                          type: 'user'
                        },
                        label: t('all-active-job-seekers')
                      }
                      if (
                        !receiverArray ||
                        (receiverArray && !receiverArray.length)
                      ) {
                        return [allUsers]
                      }
                      const userIds = receiverArray.map(u => u.data.id)
                      if (userIds.includes('all-job-seekers')) {
                        return receiverArray.filter(
                          u => u.data.id !== 'all-job-seekers'
                        )
                      }
                      return [...receiverArray, allUsers]
                    })
                  }
                >
                  {t('select-all-active-job-seekers')}
                </span>
                <span
                  className="quick-select"
                  onClick={() =>
                    setAdminReceivers(value => {
                      const receiverArray = value as Array<AdminReceiverOptionType>
                      const allUsers: AdminReceiverOptionType = {
                        value: 'all-members',
                        data: {
                          id: 'all-members',
                          type: 'user'
                        },
                        label: t('all-members')
                      }
                      if (
                        !receiverArray ||
                        (receiverArray && !receiverArray.length)
                      ) {
                        return [allUsers]
                      }
                      const userIds = receiverArray.map(u => u.data.id)
                      if (userIds.includes('all-members')) {
                        return receiverArray.filter(
                          u => u.data.id !== 'all-members'
                        )
                      }
                      return [...receiverArray, allUsers]
                    })
                  }
                >
                  {t('select-all-members')}
                </span>
              </div>
            )}
            <div className="select-by-professional-title">
              <CheckAll
                type="checkbox"
                checked={titlesToggle}
                onChange={HandleTitlesToggle}
              />
              <label className="by-professional-title-box">
                {t('select-by-professional-title')}
              </label>
            </div>
          </div>
        ) : (
          <div className="form-field">
            <label>{t('receiver')}</label>
            <Select
              isMulti
              isDisabled={selectedAd === null}
              options={options}
              onChange={(value: ValueType<ReceiverOptionType>) =>
                setReceivers(value)
              }
              placeholder={t('select')}
              value={receivers}
            />
          </div>
        )}
        <div className="details">
          <div className="form-field receivers-box">
            <label>{t('receiver')}</label>
            {!isAdmin ? (
              <>
                {receivers && receivers !== null ? (
                  (receivers as Array<ReceiverOptionType>).map(
                    (r: ReceiverOptionType) => (
                      <Selectable
                        className="receiver"
                        selected={false}
                        key={r.value}
                      >
                        {r.label}
                      </Selectable>
                    )
                  )
                ) : (
                  <span className="info-text">{t('no-receivers')}</span>
                )}
              </>
            ) : (
              <>
                {adminReceivers && adminReceivers !== null ? (
                  (adminReceivers as Array<AdminReceiverOptionType>).map(
                    (r: AdminReceiverOptionType) => (
                      <Selectable
                        className="receiver"
                        selected={false}
                        key={r.data.id}
                      >
                        {r.label}
                      </Selectable>
                    )
                  )
                ) : (
                  <span className="info-text">{t('no-receivers')}</span>
                )}
              </>
            )}
          </div>
          <div className="form-field">
            <label>{t('subject')}</label>
            <input
              placeholder={t('message-subject-placeholder')}
              onChange={e => setSubject(e.target.value)}
              value={subject}
            />
          </div>
          <div className="form-field">
            <label>{t('message')}</label>
            <textarea
              placeholder={t('message-placeholder')}
              rows={7}
              onChange={e => setMessage(e.target.value)}
              value={message}
            />
          </div>
        </div>
        <div className="actions">
          <button type="button" onClick={onClose}>
            {t('cancel')}
          </button>
          <button type="button" onClick={sendMessage}>
            {t('send-message')}
          </button>
        </div>
      </div>
    </ModalContainer>
  )
}

AddConversationModal.defaultProps = {
  isAdmin: false,
  organizationId: null,
  advertisement: null,
  recipients: null
}

export default AddConversationModal
