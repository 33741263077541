import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { setShrinked } from 'Features/Education/educationSlice'
import {
  getDurationOptions,
  getYearOptions,
  selectStyles,
  borderlessSelectStyling
} from './JobExperinceForm'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e7eaef;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 16px 18px;
  margin-bottom: 18px;
  input.smaller-input.form-select {
    max-width: none;
    padding: 12px;
  }
  .form-row {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px dashed #d6dce2;
    padding-bottom: 30px;
    margin-bottom: 30px;
    &.no-border {
      border: none;
      margin-bottom: 0;
    }
    &.padding-top {
      padding-top: 15px;
    }
    .form-field {
      display: flex;
      flex-direction: column;
      &:first-of-type {
        margin-right: 20px;
      }
      input.smaller-input.form-select {
        margin-bottom: 0;
        max-width: 100px;
      }
      label {
        white-space: nowrap;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    user-select: none;
    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
      }
    }
    span.delete {
      color: #d60c17;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1;
      svg {
        color: #d60c17;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
    span.shrink {
      color: #5c5f63;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 1;
      text-align: center;
      svg {
        color: #0a4ea2;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }
  .work-exp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    span {
      color: #0a4ea2;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }
    svg {
      color: #0a4ea2;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
`

const EducationForm = ({
  education,
  onChange,
  deleteEducation
}: {
  education: any
  onChange: (ed: any) => void
  deleteEducation: () => void
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const shrinked = useSelector(
    ({ education: educationState }: { education: any }) =>
      educationState.shrinked
  )
  const onEducationChange = ({
    target
  }: {
    target: { name: string; value: string | number }
  }) => {
    const newEducation = { ...education }
    newEducation[target.name] = target.value
    onChange(newEducation)
  }
  if (shrinked.includes(education.id)) {
    const durationType = getDurationOptions(t)
      .find(dt => dt.value === education.durationType)
      ?.label.toLowerCase()
    const institution = education.educationInstitute || t('untitled-institute')
    const program = education.educationProgram || t('untitled-program')
    const duration =
      education.durationType && education.duration
        ? `${education.duration} ${durationType}`
        : null
    const educationString = [institution, program, duration]
      .filter((str: string) => str)
      .join(', ')
    return (
      <FormContainer>
        <div
          className="work-exp"
          onClick={() => dispatch(setShrinked(education.id))}
        >
          <span>{educationString}</span>
          <FontAwesomeIcon icon={['fal', 'angle-down']} />
        </div>
      </FormContainer>
    )
  }
  return (
    <FormContainer>
      <label>
        {t('education-institute')} <span>*</span>
      </label>
      <input
        className="form-select smaller-input"
        name="educationInstitute"
        value={education.educationInstitute}
        onChange={onEducationChange}
      />
      <label>
        {t('education-program')} <span>*</span>
      </label>
      <input
        className="form-select smaller-input"
        name="educationProgram"
        value={education.educationProgram}
        onChange={onEducationChange}
        style={{ marginBottom: 30 }}
      />
      <label>{t('description')}</label>
      <textarea
        className="form-select"
        name="educationDescription"
        value={education.educationDescription}
        rows={4}
        onChange={onEducationChange}
      />
      <div className="form-row padding-top">
        <div className="form-field">
          <label>
            {t('start-year')} <span>*</span>
          </label>
          <Select
            placeholder={t('select')}
            options={getYearOptions()}
            value={getYearOptions().find(
              year => year.value === education.startYear
            )}
            styles={selectStyles}
            onChange={(data: any) =>
              onEducationChange({
                target: { name: 'startYear', value: data.value }
              })
            }
          />
        </div>
        <div className="form-field">
          <label>{t('duration')}</label>
          <input
            className="smaller-input form-select"
            name="duration"
            value={education.duration}
            onChange={e => onEducationChange(e)}
            type="number"
            step="0.1"
          />
        </div>
        <Select
          placeholder={t('select')}
          options={getDurationOptions(t)}
          value={getDurationOptions(t).find(
            type => type.value === education.durationType
          )}
          styles={borderlessSelectStyling}
          onChange={(data: any) =>
            onEducationChange({
              target: { name: 'durationType', value: data.value }
            })
          }
        />
      </div>
      <div className="actions">
        <span className="delete" onClick={deleteEducation}>
          {t('remove-education')}{' '}
          <FontAwesomeIcon icon={['fal', 'times-circle']} />
        </span>
        <span
          className="shrink"
          onClick={() => dispatch(setShrinked(education.id))}
        >
          {t('shrink')} <FontAwesomeIcon icon={['fal', 'angle-up']} />
        </span>
      </div>
    </FormContainer>
  )
}

export default EducationForm
