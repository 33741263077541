import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import DatePicker, { registerLocale } from 'react-datepicker'
import fi from 'date-fns/locale/fi'
import format from 'date-fns/format'
import { Editor } from 'react-draft-wysiwyg'
import { parse } from 'date-fns'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Fields } from '../FormFields'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

registerLocale('fi', fi)

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .input-row-item {
    display: flex;
    flex-direction: column;
    width: 40%;
  }
`
const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #d6dce2;
  border-radius: 3px;
  padding: 15px;
  outline: none;
  width: 100%;
  margin-bottom: 10px;
`
const TextEditor = styled(Editor)``

const Salary = styled.div`
  font-family: 'Barlow';
`

const Phase4 = ({
  adId,
  values,
  setValue,
  editorState,
  setEditorState,
  createProfile
}: {
  adId: string | undefined
  values: { [key: string]: any }
  setValue: Function
  editorState: EditorState
  setEditorState: any
  createProfile: () => void
}) => {
  const { t } = useTranslation()
  const changeJobDate = (date: Date, valueName: string) => {
    setValue(valueName, format(date, 'dd.MM.yyyy'))
  }
  const convertEditorData = (currentEditorState: any) => {
    setEditorState(currentEditorState)
    setValue(
      'ad_description',
      draftToHtml(convertToRaw(currentEditorState.getCurrentContent()))
    )
  }

  return (
    <Fields style={{ flexDirection: 'column' }}>
      <div className="rows" style={{ width: '100%' }}>
        <InputRow>
          <div className="input-row-item">
            <label>
              {t('search_start_date')}
              <span>*</span>
            </label>
            <StyledDatePicker
              dateFormat="dd.MM.yyyy"
              locale="fi"
              placeholderText="01.01.2020"
              selected={
                values.search_start_date
                  ? parse(values.search_start_date, 'dd.MM.yyyy', new Date())
                  : null
              }
              onChange={(date: Date) =>
                changeJobDate(date, 'search_start_date')
              }
            />
          </div>
          <div className="input-row-item">
            <label>
              {t('search_end_date')}
              <span>*</span>
            </label>
            <StyledDatePicker
              dateFormat="dd.MM.yyyy"
              locale="fi"
              placeholderText="01.01.2020"
              selected={
                values.search_end_date
                  ? parse(values.search_end_date, 'dd.MM.yyyy', new Date())
                  : null
              }
              onChange={(date: Date) => changeJobDate(date, 'search_end_date')}
            />
          </div>
        </InputRow>
        <InputRow>
          <div className="input-row-item">
            <label>{t('website')}</label>
            <input
              className="form-select"
              size={30}
              value={values.website}
              placeholder="https://esimerkki.fi"
              onChange={e => setValue('website', e.target.value)}
            />
          </div>
          <div className="input-row-item">
            <label>
              {t('duration')}
              <span>*</span>
            </label>
            <input
              className="form-select"
              value={values.duration}
              placeholder={t('job-duration-placeholder')}
              onChange={e => setValue('duration', e.target.value)}
            />
          </div>
        </InputRow>
        <InputRow>
          <div className="input-row-item">
            <label>
              {t('salary')}
              <span>*</span>
            </label>
            <Salary>{t('collective-agreement-contractual')}</Salary>
          </div>
        </InputRow>

        <label>
          {t('description')} <span>*</span>
        </label>
        <TextEditor
          editorState={editorState}
          onEditorStateChange={e => convertEditorData(e)}
          placeholder={t('description')}
          editorClassName="rasfgedg"
          wrapperClassName="rasfasrwgedg"
        />
        <div>
          <div
            className="additional-info"
            style={{ width: '35%', float: 'right' }}
          >
            <button type="button" onClick={createProfile}>
              {adId ? t('save-changes') : t('create-jobad')}
            </button>
          </div>
        </div>
      </div>
    </Fields>
  )
}

export default Phase4
