/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select, { StylesConfig } from 'react-select'
import { useTranslation } from 'react-i18next'
import {
  GroupsResultsType,
  ProfessionalTitleType
} from 'Features/ProfessionalTitle/professionalTitleSlice'
import { SelectableContainer, Selectable } from './Phase2'
import { Fields } from '../FormFields'
import LanguageProficiencySelect from '../FormComponents/LanguageProficiencySelect'

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
`
const SearchBar = styled.div`
  margin-bottom: 10px;
`
type CompetenceResultType = {
  id: number
  created_at: string
  title: string
  updated_at: string
}
const Phase3 = ({
  groupResults,
  competenceResults,
  drivingLicenseResults,
  values,
  setValue,
  nextForm
}: {
  groupResults: { [key: string]: any }
  competenceResults: { [key: string]: any }
  drivingLicenseResults: { [key: string]: any }
  values: { [key: string]: any }
  setValue: Function
  nextForm: () => void
}) => {
  const [selectedTitleGroup, setSelectedTitleGroup] = React.useState<{
    value: any
    label: string
  } | null>(null)

  const [titleFilter, setTitleFilter] = React.useState<String>('')
  React.useEffect(() => {
    if (selectedTitleGroup === null && groupResults && groupResults.length) {
      setSelectedTitleGroup({
        value: groupResults[0].id,
        label: groupResults[0].title
      })
    }
  }, [groupResults, selectedTitleGroup])
  const { t } = useTranslation()
  const selectStyling: StylesConfig = {
    container: provided => ({
      ...provided,
      width: '205px'
    }),
    control: provided => ({
      ...provided,
      border: '1px solid #D6DCE2',
      borderRadius: '3px'
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    }),
    singleValue: provided => ({
      ...provided,
      color: '#252525',
      fontFamily: 'Barlow',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '17px'
    })
  }
  const allProfessionalTitles: ProfessionalTitleType[] = []
  groupResults.forEach((g: GroupsResultsType) => {
    g.professional_titles.forEach(p => {
      const newP = { ...p }
      newP.title = `${newP.title} / ${g.title}`
      allProfessionalTitles.push(newP)
    })
  })
  return (
    <Fields>
      <div className="rows" style={{ width: '100%' }}>
        <label>{t('professional-titles')}</label>
        <SearchBar className="search">
          <div className="search-bar">
            <input
              className="borderless form-select"
              placeholder={t('search-professional-titles-placeholder')}
              onChange={e => setTitleFilter(e.target.value)}
            />
            <SearchIcon icon={['fas', 'search']} />
          </div>
          <Select
            options={groupResults.map(
              (r: { [key: string]: string | number }) => ({
                value: r.id,
                label: r.title
              })
            )}
            value={selectedTitleGroup}
            onChange={(value: any) =>
              setSelectedTitleGroup({
                value: value.value,
                label: value.label
              })
            }
            styles={selectStyling}
          />
        </SearchBar>
        <SelectableContainer>
          {titleFilter.length
            ? allProfessionalTitles
                .filter(
                  (pt: any) =>
                    pt.title
                      .toLowerCase()
                      .indexOf(titleFilter.toLowerCase()) !== -1
                )
                .map((pt: any) => (
                  <Selectable
                    key={pt.id}
                    selected={(values.professional_titles || []).includes(
                      pt.id
                    )}
                    onClick={() => {
                      let newTitles = [...(values.professional_titles || [])]
                      if (newTitles.includes(pt.id)) {
                        newTitles = newTitles.filter(n => n !== pt.id)
                      } else {
                        newTitles.push(pt.id)
                      }
                      setValue('professional_titles', newTitles)
                    }}
                  >
                    {pt.title}
                  </Selectable>
                ))
            : (
                groupResults.find(
                  (r: any) => r.id === (selectedTitleGroup || {}).value
                ) || { professional_titles: [] }
              ).professional_titles
                .filter(
                  (pt: any) =>
                    pt.title
                      .toLowerCase()
                      .indexOf(titleFilter.toLowerCase()) !== -1
                )
                .map((pt: any) => (
                  <Selectable
                    key={pt.id}
                    selected={(values.professional_titles || []).includes(
                      pt.id
                    )}
                    onClick={() => {
                      let newTitles = [...(values.professional_titles || [])]
                      if (newTitles.includes(pt.id)) {
                        newTitles = newTitles.filter(n => n !== pt.id)
                      } else {
                        newTitles.push(pt.id)
                      }
                      setValue('professional_titles', newTitles)
                    }}
                  >
                    {pt.title}
                  </Selectable>
                ))}
        </SelectableContainer>
        <label>{t('competences')}</label>
        <SelectableContainer>
          {competenceResults.map((c: CompetenceResultType) => (
            <Selectable
              key={c.id}
              selected={(values.competences || []).includes(c.id)}
              onClick={() => {
                let newCompetences = [...(values.competences || [])]
                if (newCompetences.includes(c.id)) {
                  newCompetences = newCompetences.filter(n => n !== c.id)
                } else {
                  newCompetences.push(c.id)
                }
                setValue('competences', newCompetences)
              }}
            >
              {c.title}
            </Selectable>
          ))}
        </SelectableContainer>
        <label>{t('driving-license')}</label>
        <SelectableContainer>
          {drivingLicenseResults.map((c: any) => (
            <Selectable
              key={c.id}
              selected={(values.driving_licenses || []).includes(c.id)}
              onClick={() => {
                let newDrivingLicense = [...(values.driving_licenses || [])]
                if (newDrivingLicense.includes(c.id)) {
                  newDrivingLicense = newDrivingLicense.filter(n => n !== c.id)
                } else {
                  newDrivingLicense.push(c.id)
                }
                setValue('driving_licenses', newDrivingLicense)
              }}
            >
              {c.license_type}
            </Selectable>
          ))}
        </SelectableContainer>
        <label>{t('language-skills')}</label>
        <div>
          <LanguageProficiencySelect values={values} setValue={setValue} />
          <div
            className="additional-info"
            style={{ width: '35%', float: 'right' }}
          >
            <button type="button" onClick={nextForm}>
              {t('next')}
            </button>
          </div>
        </div>
      </div>
    </Fields>
  )
}

export default Phase3
