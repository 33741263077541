import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Toggle } from 'Components/Blocks'
import { JobGuardModal } from 'Components/JobGuard'
import { checkActiveSession } from 'Features/Auth/authSlice'
import { toggleMemberProfileOpenness } from 'Features/MemberProfile/memberProfileSlice'
import { fetchJobApplications } from 'Features/JobApplication/jobApplicationSlice'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import {
  fetchMemberContacts,
  PartialMemberContact,
  partialUpdateMemberContact
} from 'Features/MemberContact/memberContactSlice'
import { RootState } from 'Store'
import { fetchDetailedConversations } from 'Features/Conversation/conversationSlice'
import { format, parseISO } from 'date-fns'
import PersonalDetails from './PersonalDetails'

export interface ProfileProps {
  columnStyle?: boolean
}

const ProfileContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: ${(props: ProfileProps) => (props.columnStyle ? '0;' : '15px;')}
  @media screen and ${props => props.theme.breakpoints.lg} {
    padding: ${(props: ProfileProps) => (props.columnStyle ? '0;' : '30px;')}
  }
  .profile {
    box-sizing: border-box;
    border: ${(props: ProfileProps) =>
      props.columnStyle ? 'none;' : '1px solid #d6dce2;'}
    border-radius: 6px;
    padding: ${(props: ProfileProps) => (props.columnStyle ? '0;' : '15px;')}
    display: flex;
    flex-direction: column;
    @media screen and ${props => props.theme.breakpoints.lg} {
      flex-direction: ${(props: ProfileProps) =>
        props.columnStyle ? 'column;' : 'row;'}
      padding: padding: ${(props: ProfileProps) =>
        props.columnStyle ? '0;' : '25px 30px;'}
    }
    .column {
      display: flex;
      flex-direction: column;
      @media screen and ${props => props.theme.breakpoints.lg} {
        flex-direction: row;
      }
      &.full-width {
        flex-grow: 1;
        .info {
          width: 100%;
          margin-left: 0;
          svg {
            color: #a8aeb4;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 16px;
          }
          @media screen and ${props => props.theme.breakpoints.lg} {
            padding-left: 30px;
            margin-left: ${(props: ProfileProps) =>
              props.columnStyle ? '0;' : '30px;'}
            border-left: ${(props: ProfileProps) =>
              props.columnStyle ? 'none;' : '1px solid #ddd;'}
          }
        }
      }
      .info-container {
        display: flex;
        .info {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          margin-left: 30px;
          @media screen and ${props => props.theme.breakpoints.lg} {
            margin-top: ${(props: ProfileProps) =>
              props.columnStyle ? '20px;' : '0;'}
          }
          &:first-of-type {
            margin-left: 0;
            @media screen and ${props => props.theme.breakpoints.lg} {
              margin-left: 30px;
            }
          }
          strong {
            color: #252525;
            font-family: Barlow;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 19px;
            margin-top: 3px;
            white-space: nowrap;
          }
          button {
            color: #0a4ea2;
            font-family: Barlow;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 17px;
            text-decoration: underline;
            margin-top: 8px;
            cursor: pointer;
            background: none;
            border: none;
            outline: none;
            padding: 0;
            text-align: left;
          }
          .toggle {
            margin-top: 5px;
          }
        }
      }
    }
  }
`
const RelatedInfoRow = styled.div`
  margin-top: 3px;
  width: 100%;
  display: flex;
  &:not(:last-of-type) {
    border-bottom: 1px solid #d6dce2;
    padding-bottom: 20px;
  }
  &:first-of-type {
    border-top: 1px solid #d6dce2;
    margin-top: 20px;
    padding-top: 20px;
    @media screen and ${props => props.theme.breakpoints.lg} {
      padding-top: ${(props: ProfileProps) =>
        props.columnStyle ? '20px;' : '0;'}
      margin-top: ${(props: ProfileProps) =>
        props.columnStyle ? '20px;' : '0;'}
      border-top: ${(props: ProfileProps) =>
        props.columnStyle ? '1px solid #d6dce2;' : 'none;'}
    }
  }
  &:not(:first-of-type) {
    margin-top: 20px;
  }
  .related-content {
    flex-grow: 1;
    margin-left: 20px;
    .title {
      display: flex;
      justify-content: space-between;
    }
    .conversation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      p {
        margin: 0;
        font-style: normal;
      }
      .received {
        font-family: Barlow;
        font-size: 14px;
        font-style: italic;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-top: 5px;
        &:first-of-type {
          margin-top: 10px;
        }
      }
    }
    h6 {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 17px;
      margin: 0;
    }
    p {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      font-style: italic;
      letter-spacing: 0;
      line-height: 17px;
      margin: 10px 0 0 0;
    }
    a {
      color: #0a4ea2;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
`

const ContactRequest = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
  div.message {
    flex-grow: 1;
    p {
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
      margin: 0;
    }
  }
  button {
    border: none;
    border-radius: 6px;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    &.accept {
      background-color: #6ed663;
    }
    &.decline {
      margin-left: 10px;
      background-color: #ff6174;
    }
  }
`

const MemberProfile = ({ columnStyle }: { columnStyle?: boolean }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)
  const { results } = useSelector((state: RootState) => state.jobGuard)
  const { results: contactRequests } = useSelector(
    (state: RootState) => state.memberContact.list
  )
  const {
    detailedData: { results: conversations }
  } = useSelector((state: RootState) => state.conversation)
  const pendingContactRequests = contactRequests.filter(
    c => c.status === 'pending'
  )
  const [jobGuard] = results
  const [showJobGuardModal, setShowJobGuardModal] = useState<boolean>(false)
  const { t } = useTranslation()
  const isProfileOpen = user.member_profile?.is_open_profile

  const toggleProfileVisibility = async () => {
    await dispatch(toggleMemberProfileOpenness())
    await dispatch(checkActiveSession())
  }

  useEffect(() => {
    dispatch(fetchJobApplications())
    dispatch(fetchMemberContacts())
    dispatch(fetchDetailedConversations())
  }, [])
  const updateMemberContact = async (body: PartialMemberContact) => {
    await dispatch(partialUpdateMemberContact(body))
    dispatch(fetchMemberContacts())
  }
  if (!user || (user && !user.member_profile)) {
    return null
  }
  const renderSentApplications = () => (
    <RelatedInfoRow columnStyle={columnStyle}>
      <FontAwesomeIcon icon={['far', 'briefcase']} />
      <div className="related-content">
        <div className="title">
          <h6>{t('sent-applications')}</h6>
          <Link to="/profile?tabIndex=1">{t('to-applications')}</Link>
        </div>
        {user.member_profile &&
        (user.member_profile.job_applications || []).length ? (
          <ul>
            {user.member_profile.job_applications.map((app: any) => (
              <li key={app.id}>
                <Link to={`/jobs/${app.job_advertisement}/`}>
                  {app.job_advertisement_title}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>{t('no-applications-sent')}</p>
        )}
      </div>
    </RelatedInfoRow>
  )
  const renderConversationRequests = () => (
    <RelatedInfoRow columnStyle={columnStyle}>
      <FontAwesomeIcon icon={['far', 'envelope']} />
      <div className="related-content">
        <div className="title">
          <h6>{t('conversation-requests')}</h6>
          <Link to="/profile?tabIndex=2">{t('to-conversation-requests')}</Link>
        </div>
        {pendingContactRequests.length ? (
          pendingContactRequests.map(c => (
            <ContactRequest key={c.id}>
              <div className="message">
                <p>{c.contact_message}</p>
              </div>
              <button
                className="accept"
                type="button"
                onClick={() =>
                  updateMemberContact({ id: c.id, status: 'accepted' })
                }
              >
                {t('accept')}
              </button>
              <button
                className="decline"
                type="button"
                onClick={() =>
                  updateMemberContact({ id: c.id, status: 'rejected' })
                }
              >
                {t('decline')}
              </button>
            </ContactRequest>
          ))
        ) : (
          <p>{t('no-open-conversation-requests')}</p>
        )}
      </div>
    </RelatedInfoRow>
  )
  const renderMessages = () => (
    <RelatedInfoRow columnStyle={columnStyle}>
      <FontAwesomeIcon icon={['far', 'envelope']} />
      <div className="related-content">
        <div className="title">
          <h6>{t('new-messages')}</h6>
          <Link to="/profile?tabIndex=3">{t('to-messages')}</Link>
        </div>
        {conversations.length && conversations[0].messages.length ? (
          <div className="conversation">
            <p>{conversations[0].subject}</p>
            <div className="received">
              {t('received')}{' '}
              {format(
                parseISO(
                  [...conversations[0].messages].sort((a, b) =>
                    parseISO(a.created_at) > parseISO(b.created_at) ? -1 : 1
                  )[0].created_at
                ),
                'dd.MM.yyyy HH:mm'
              )}
            </div>
          </div>
        ) : (
          <p>{t('no-messages')}</p>
        )}
      </div>
    </RelatedInfoRow>
  )
  return (
    <ProfileContainer columnStyle={columnStyle}>
      <div className="profile">
        <div className="column">
          <PersonalDetails user={user} columnStyle={columnStyle} />
          <div className="info-container">
            <div className="info">
              <strong>{t('profile-visibility')}</strong>
              {isProfileOpen === undefined ? (
                <Toggle isOn={false} />
              ) : (
                <Toggle isOn={isProfileOpen} />
              )}
              <button type="button" onClick={toggleProfileVisibility}>
                {user.member_profile?.is_open_profile
                  ? t('disable-visibility')
                  : t('enable-visibility')}
              </button>
            </div>
            <div className="info">
              <strong>{t('job-guard')}</strong>
              <Toggle
                isOn={jobGuard && jobGuard.searches.some((s: any) => s.active)}
              />
              <button type="button" onClick={() => setShowJobGuardModal(true)}>
                {jobGuard && jobGuard.searches.some((s: any) => s.active)
                  ? t('modify-job-guard')
                  : t('enable-job-guard')}
              </button>
            </div>
          </div>
        </div>
        <div className="column full-width">
          <div className="info">
            {renderSentApplications()}
            {renderConversationRequests()}
            {renderMessages()}
          </div>
        </div>
        <JobGuardModal
          show={showJobGuardModal}
          closeModal={() => setShowJobGuardModal(false)}
        />
      </div>
    </ProfileContainer>
  )
}

MemberProfile.defaultProps = {
  columnStyle: false
}

export default MemberProfile
