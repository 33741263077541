import {
  createSlice,
  AnyAction,
  AsyncThunk,
  createAction,
  PayloadAction
} from '@reduxjs/toolkit'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

interface RecommenderState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  shrinked: Array<number>
}

const initialState: RecommenderState = {
  loading: 'idle',
  shrinked: []
}

const setShrinked = createAction('recommender/shrink', (id: number) => ({
  payload: id
}))

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending')
}
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected')
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled')
}

export const resetAction = createAction('reset-tracked-loading-state')

/* eslint-disable no-param-reassign */
const recommenderSlice = createSlice({
  name: 'recommender',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(setShrinked, (state, action: PayloadAction<any>) => {
        if (state.shrinked.includes(action.payload)) {
          state.shrinked = state.shrinked.filter(
            (id: number) => id !== action.payload
          )
          return
        }
        state.shrinked.push(action.payload)
      })
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})
/* eslint-enable no-param-reassign */

export default recommenderSlice.reducer
export { setShrinked }
