/* eslint-disable camelcase */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { RootState } from 'Store'
import styled from 'styled-components'
import { OfficeType } from 'Features/Organization/organizationSlice'

import { Fields } from '../FormFields'

const LocationSelect = styled(Select)`
  width: 100%;
  margin-bottom: 15px;
`
const CompanyLogoContainer = styled.div`
  border: 1px solid #d6dce2;
  border-radius: 3px;
  padding: 10px;
`
const CompanyLogo = styled.div`
  width: 90%;
  height: 90%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
`
const LeftBlock = styled.div`
  width: 65%;
  padding-right: 20px;
`
const RightBlock = styled.div`
  width: 35%;
  .logo-info {
    font-family: Barlow;
    font-size: 14px;
    padding-top: 11px;
  }
  .additional-info {
    width: 100%;
  }
`
const InfoTooltip = styled(FontAwesomeIcon)`
  margin-left: 10px;
`
const TooltipBackground = styled.span`
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
    color: #222;
    text-align: center;
    border: 1px solid #d6dce2;
    font-family: Barlow;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    font-weight: 500;
    z-index: 1;
    margin-left: 10px;
  }

  .tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 15px;
    color: #222;
    right: 100%;
    margin-top: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #00000040 transparent transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`

const Phase1 = ({
  values,
  logoUrl,
  setValue,
  offices,
  companyName,
  nextForm
}: {
  values: { [key: string]: any }
  setValue: Function
  offices: Array<OfficeType>
  logoUrl: string
  companyName: string
  nextForm: () => void
}) => {
  const { t } = useTranslation()
  const { locations } = useSelector(
    (state: RootState) => state.newAdvertisement
  )
  const jobLocations = locations
    ? locations.map(({ name, id }: { name: string; id: number }) => ({
        id,
        value: name,
        label: name
      }))
    : undefined
  const jobOffices = offices
    ? offices.map(({ name, id }: { name: string; id: number }) => ({
        id,
        value: name,
        label: name
      }))
    : undefined
  const displaySelection = (e: Event) => {
    setValue('locations', e)
  }
  return (
    <Fields style={{ flexDirection: 'column' }}>
      <div className="rows" style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: '32px'
          }}
        >
          <LeftBlock>
            <label>
              {t('informant')} <span>*</span>
              <TooltipBackground>
                <span className="tooltip">
                  <InfoTooltip color="#0859AC" icon={['fal', 'info-circle']} />
                  <span className="tooltiptext">
                    {t('informant-information')}
                  </span>
                </span>
              </TooltipBackground>
            </label>
            <p>{companyName}</p>
            <label>
              {t('office')} <span>*</span>
            </label>
            <LocationSelect
              placeholder="Valitse toimipiste"
              value={values.offices && jobOffices ? values.offices : undefined}
              isMulti
              options={jobOffices}
              onChange={(e: Event) => setValue('offices', e)}
            />
            <label>{t('location')}</label>
            <LocationSelect
              placeholder="Valitse sijainti"
              value={
                values.locations && jobLocations ? values.locations : undefined
              }
              isMulti
              options={jobLocations}
              onChange={(e: Event) => displaySelection(e)}
            />
          </LeftBlock>
          <RightBlock>
            <CompanyLogoContainer>
              <CompanyLogo
                style={{
                  backgroundImage: `url(${logoUrl})`,
                  height: '100px'
                }}
              />
            </CompanyLogoContainer>
            <div className="logo-info">
              {t('ad-logo-tooltip')}
              <Link to="/admin-panel/asetukset/tiedot">
                {t('ad-logo-tooltip-link')}
              </Link>
            </div>
            <div className="additional-info">
              <button type="button" onClick={nextForm}>
                {t('next')}
              </button>
            </div>
          </RightBlock>
        </div>
      </div>
    </Fields>
  )
}

export default Phase1
