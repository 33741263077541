/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadingIndicator from 'Components/LoadingIndicator'
import { netsLogin } from 'Features/Auth/authSlice'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState, useAppDispatch } from 'Store'
import styled from 'styled-components'

const HouseholdLoginContainer = styled.div`
  height: 60vh;
  background: white;
  display: flex;
  justify-content: center;
`
const StrongAuthInfoContainer = styled.div`
  display: flex;
  align-items: center;
`

const isString = (x: unknown) => {
  return Object.prototype.toString.call(x) === '[object String]'
}

const HouseholdLogin = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { netsUrl } = useSelector((state: RootState) => state.auth)
  useEffect(() => {
    if (netsUrl && isString(netsUrl)) {
      window.location.replace(netsUrl)
    } else {
      dispatch(netsLogin())
    }
  }, [netsUrl])
  return (
    <HouseholdLoginContainer>
      <StrongAuthInfoContainer>
        {t('going-to-strong-authentication')}
      </StrongAuthInfoContainer>
      <LoadingIndicator noBg />
    </HouseholdLoginContainer>
  )
}

export default HouseholdLogin
