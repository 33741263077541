/* eslint-disable react/require-default-props */
import {
  Fields,
  Selectable,
  SelectableContainer
} from 'Components/EducationPage/NewTraining/CommonTrainingCreationProps'
import {
  InfoTooltip,
  TooltipBackground
} from 'Components/EducationPage/NewTraining/FieldComponents/Phase1'
import {
  IsContinuousInput,
  ContinuousInput,
  handleChange
} from 'Components/EducationPage/NewTraining/NewTraining'
import { TrainingType } from 'Components/EducationPage/NewTraining/ModifyTrainingInitializers'
import React from 'react'
import { isTrainingTypeSelected, setAdValues } from 'Util/trainingUtils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import styled from 'styled-components'

const TitleBlock = styled.input`
  border: 1px solid #d6dce2;
  border-radius: 3px;
  padding: 15px;
  outline: none;
`
const CompanyLogoContainer = styled.div`
  border: 1px solid #d6dce2;
  border-radius: 3px;
  padding: 10px;
`
const CompanyLogo = styled.div`
  width: 90%;
  height: 90%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
`
const LeftBlock = styled.div`
  width: 65%;
  padding-right: 20px;
`
const RightBlock = styled.div`
  width: 35%;
  .logo-info {
    font-family: Barlow;
    font-size: 14px;
    padding-top: 11px;
  }
  .additional-info {
    width: 100%;
  }
`

const Phase1 = ({
  logoUrl,
  companyName,
  nextForm
}: {
  logoUrl: string
  companyName?: string
  nextForm: () => void
}) => {
  const { t } = useTranslation()
  const { values } = useSelector((state: RootState) => state.training)
  const trainingTypes: TrainingType[] = [
    {
      id: 0,
      title: t('educations'),
      type: 'training'
    },
    {
      id: 1,
      title: t('web-course'),
      type: 'web'
    }
  ]

  return (
    <Fields style={{ flexDirection: 'column' }}>
      <div className="rows" style={{ width: '100%' }}>
        <label>
          {t('ad-title')} <span>*</span>
        </label>
        <TitleBlock
          value={values.subject}
          placeholder={t('type-ad-title')}
          onChange={e => setAdValues('subject', e.target.value)}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: '32px'
          }}
        >
          <LeftBlock>
            <label>
              {t('informant')} <span>*</span>
              <TooltipBackground>
                <span className="tooltip">
                  <InfoTooltip color="#0859AC" icon={['fal', 'info-circle']} />
                  <span className="tooltiptext">
                    {t('informant-information')}
                  </span>
                </span>
              </TooltipBackground>
            </label>
            <p>{companyName}</p>
            <label>
              {t('select-execution-type')} <span>*</span>
            </label>
            <SelectableContainer className="margin-small">
              {trainingTypes
                ? trainingTypes.map((c: TrainingType) => (
                    <Selectable
                      key={c.id}
                      selected={isTrainingTypeSelected(values, c)}
                      onClick={() => {
                        setAdValues('training_type', c)
                      }}
                    >
                      {c.title}
                    </Selectable>
                  ))
                : null}
            </SelectableContainer>
            <IsContinuousInput>
              <ContinuousInput
                key="continuous_training"
                type="checkbox"
                checked={
                  values.continuous_training
                    ? values.continuous_training
                    : false
                }
                onChange={handleChange}
              />
              <label htmlFor="continuous-training">
                {t('continuous-training')}
              </label>
            </IsContinuousInput>
          </LeftBlock>
          <RightBlock>
            <CompanyLogoContainer>
              <CompanyLogo
                style={{
                  backgroundImage: `url(${logoUrl})`,
                  height: '100px'
                }}
              />
            </CompanyLogoContainer>
            <div className="additional-info">
              <button type="button" onClick={nextForm}>
                {t('next')}
              </button>
            </div>
          </RightBlock>
        </div>
      </div>
    </Fields>
  )
}

export default Phase1
