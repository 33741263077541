/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { useState } from 'react'
import styled from 'styled-components'
import DropdownMenu from 'Components/DropdownMenu'
import { returnApplication } from 'Features/JobApplication/jobApplicationSlice'
import { useAppDispatch } from 'Store'
import { fetchWithId } from 'Features/JobAdvertisements/advertisementSlice'
import ApplicationDeleteModal from '../ApplicationDeleteModal'

const RejectedList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  padding: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
type RejectedApplicationType = {
  id: number
  applicationId: number
  name: string
  profile_features: object
  received: string
  status: string
}
const RejectedApplication = ({
  rejectedApplications,
  adId,
  modalToggle,
  setSelectedApplication
}: {
  rejectedApplications: Array<RejectedApplicationType>
  adId: string
  modalToggle: any
  setSelectedApplication: any
}) => {
  const RejectedApplicationObject = ({
    rejectedApplication
  }: {
    rejectedApplication: RejectedApplicationType
  }) => {
    const dispatch = useAppDispatch()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const placeholderAction = () => {
      console.log('Send massmessage')
    }
    const openApplicationModal = (objectId: number) => {
      modalToggle(true)
      setSelectedApplication(objectId)
    }
    const openDeleteModal = () => {
      setDeleteModalOpen(true)
    }
    const rejectedSettings = [
      {
        icon: 'envelope',
        title: 'Lähetä viesti',
        action: placeholderAction
      },
      {
        icon: 'eye',
        title: 'Näytä hakemus',
        action: (objectId: number) => {
          openApplicationModal(objectId)
        }
      },
      {
        icon: 'undo',
        title: 'Palauta saapuneisiin',
        action: (objectId: number) => {
          const currentApplication = rejectedApplications.find(
            (application: any) => application.id === objectId
          )
          if (currentApplication) {
            dispatch(
              returnApplication({
                id: currentApplication.applicationId
              })
            ).then(() => dispatch(fetchWithId(parseInt(adId, 10))))
          }
        }
      },
      {
        icon: 'trash',
        title: 'Poista hakemus',
        action: openDeleteModal
      }
    ]
    return (
      <>
        {deleteModalOpen ? (
          <ApplicationDeleteModal
            modalToggle={setDeleteModalOpen}
            applicationDetails={rejectedApplication}
            adId={adId}
          />
        ) : null}
        <RejectedList key={rejectedApplication.id}>
          {rejectedApplication.name}
          <DropdownMenu
            horizontal
            options={rejectedSettings}
            menuPosition="leftTop"
            objectId={rejectedApplication.id}
          />
        </RejectedList>
      </>
    )
  }

  return (
    <>
      {rejectedApplications.length > 0 ? (
        rejectedApplications.map(rejectedApplication => (
          <RejectedApplicationObject
            key={rejectedApplication.id}
            rejectedApplication={rejectedApplication}
          />
        ))
      ) : (
        <RejectedList />
      )}
    </>
  )
}
export default RejectedApplication
