/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { StylesConfig } from 'react-select'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import { Selectable } from 'Components/Survey/FieldComponents/Phase2'
import { Desktop, Mobile, Tablet } from 'Components/Layout/MediaQuery'
import { RootState, store, useAppDispatch } from 'Store'
import {
  fetchAvailableFilters,
  fetchPaginatedFilteredTrainings,
  LocationsType,
  OrganizationsType,
  TrainingFilters,
  TrainingTypes,
  SubjectAreas
} from 'Features/Training/trainingSlice'

import { format, parse } from 'date-fns'

const selectStyles: StylesConfig = {
  container: provided => ({
    ...provided,
    minHeight: '44px'
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #D6DCE2',
    borderRadius: '3px',
    minHeight: 41
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  singleValue: provided => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px'
  }),
  placeholder: provided => ({
    ...provided,
    color: '#000000',
    fontFamily: 'Barlow',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '19px'
  })
}

const SearchFiltersContainer = styled.div`
  display: 'flex';
  flex-direction: column;
  position: ${(props: { mobile?: boolean }) =>
    props.mobile ? 'fixed' : 'relative'};
  left: ${(props: { mobile?: boolean; minimized?: boolean }) =>
    props.mobile && props.minimized ? '-80%' : '0'};
  top: ${(props: { mobile?: boolean }) => (props.mobile ? '60px' : '0')};
  transition: left 0.3s ease-in-out;
  ${(props: { mobile?: boolean; minimized?: boolean }) =>
    props.mobile
      ? `
  width: 80%;
  ${props.minimized ? '' : 'box-shadow: 10px 10px 10px -10px rgba(0,0,0,0.41);'}
  `
      : ''}
  h2 {
    color: #252525;
    font-weight: 100;
    font-family: Barlow;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0;
  }
  .selectables {
    display: flex;
    flex-wrap: wrap;
    > div {
      margin-bottom: 10px;
    }
  }
  .filters {
    margin-top: ${(props: { mobile?: boolean }) =>
      props.mobile ? '0' : '20px'};
    padding: 15px;
    background-color: ${(props: { mobile?: boolean }) =>
      props.mobile ? '#f4f5f7' : '#fff'};
    .single-filter {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
      label {
        display: block;
        color: #2a2a2a;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1;
        padding-bottom: 10px;
      }
      .text-input {
        position: relative;
        input {
          border: none;
          border-bottom: 1px solid #d6dce2;
          width: 100%;
          outline: none;
          padding: 5px;
          background-color: ${(props: { mobile?: boolean }) =>
            props.mobile ? '#f4f5f7' : '#fff'};
        }
        > svg {
          position: absolute;
          right: 5px;
          top: calc(50% - 7.5px);
          width: 15px;
          height: 15px;
          color: #d60c17;
          font-size: 19px;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
        }
      }
    }
  }
`
const SearchButton = styled.button`
  width: 100%;
  height: 37px;
  border: none;
  border-radius: 6px;
  background-color: #d60c17;
  outline: none;
  color: #ffffff;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-top: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #b60c17;
  }
`
const MobileContainer = styled.div`
  position: fixed;
  .extend-icon {
    position: absolute;
    color: white;
    font-size: 16px;
    padding: 10px;
    top: 20px;
    right: -36px;
    border-radius: 0 6px 6px 0;
    background-color: #d60c17;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.41);
    cursor: pointer;
  }
`
const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #d6dce2;
  border-radius: 3px;
  padding: 15px;
  outline: none;
  width: 100%;
  margin-bottom: 10px;
`
const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const DatePickerObject = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 2px 0px 2px;
`

const TrainingSearchFilters = () => {
  const dispatch = useAppDispatch()
  const { selectedFilters } = useSelector((state: any) => state.training)
  const {
    availableFilters: {
      locations,
      organizations,
      subject_areas,
      training_types
    }
  } = useSelector((state: RootState) => state.training)
  const [mobileMinimized, setMobileMinimized] = useState<boolean>(true)
  const { t } = useTranslation()
  const setFilters = (filtersToSelect: TrainingFilters) => {
    store.dispatch({
      type: 'training/updateFilters',
      payload: filtersToSelect
    })
  }
  useEffect(() => {
    dispatch(fetchAvailableFilters())
  }, [])
  const parseDate = (selectedDate: string) => {
    try {
      const date = parse(selectedDate, 'dd.MM.yyyy', new Date()).toISOString()
      return date
    } catch (e) {
      return ''
    }
  }
  const search = () => {
    dispatch(
      fetchPaginatedFilteredTrainings({
        page: 1,
        filters: {
          free_text_search: selectedFilters.free_text_search
            ? selectedFilters.free_text_search
            : '',
          end_time: selectedFilters.end_time
            ? parseDate(selectedFilters.end_time)
            : '',
          start_time: selectedFilters.start_time
            ? parseDate(selectedFilters.start_time)
            : '',
          locations: selectedFilters.locations
            ? selectedFilters.locations.map((loc: any) => loc.label)
            : [],
          organizations: selectedFilters.organizations
            ? selectedFilters.organizations.map(
                (organization: any) => organization.value
              )
            : [],
          training_types: selectedFilters.training_types
            ? selectedFilters.training_types
            : [],
          subject_areas: selectedFilters.subject_areas
            ? selectedFilters.subject_areas.map((subject: any) => subject.label)
            : [],
          order: 'start_time'
        }
      })
    )
    setMobileMinimized(true)
  }

  const renderFreeTextSearch = () => (
    <div className="single-filter">
      <label>{t('free-text-search')}</label>
      <div className="text-input">
        <input
          type="text"
          onChange={e =>
            setFilters({
              ...selectedFilters,
              free_text_search: e.target.value
            })
          }
        />
        <FontAwesomeIcon icon={['fal', 'search']} />
      </div>
    </div>
  )

  const renderLocationSearch = () => (
    <div className="single-filter">
      <label>{t('location-filter-title')}</label>
      <div className="text-input">
        <Select
          isMulti
          placeholder={t('location-placeholder')}
          styles={selectStyles}
          value={selectedFilters.locations ? selectedFilters.locations : []}
          options={
            locations
              ? locations.map((location: LocationsType) => ({
                  label: location.name,
                  value: location.id
                }))
              : undefined
          }
          onChange={(locs: any) => {
            setFilters({
              ...selectedFilters,
              locations: (locs || []).map((location: LocationsType) => location)
            })
          }}
        />
      </div>
    </div>
  )

  const renderOrganizationSearch = () => (
    <div className="single-filter">
      <label>{t('training-organizer')}</label>
      <div className="text-input">
        <Select
          placeholder={t('training-organizer-placeholder')}
          styles={selectStyles}
          isMulti
          value={
            selectedFilters.organizations ? selectedFilters.organizations : []
          }
          options={
            organizations
              ? organizations.map((organization: OrganizationsType) => ({
                  label: organization.name,
                  value: organization.id
                }))
              : undefined
          }
          onChange={(providers: any) =>
            setFilters({
              ...selectedFilters,
              organizations: (providers || []).map((title: any) => title)
            })
          }
        />
      </div>
    </div>
  )
  const renderTrainingType = () => (
    <div className="single-filter">
      <label>{t('select-execution-type')}</label>
      <div className="selectables">
        {training_types.map((e: TrainingTypes) => (
          <Selectable
            key={e.value}
            selected={selectedFilters.training_types.includes(e.value)}
            onClick={() =>
              setFilters({
                ...selectedFilters,
                training_types: selectedFilters.training_types.includes(e.value)
                  ? selectedFilters.training_types.filter(
                      (et: string) => et !== e.value
                    )
                  : [...selectedFilters.training_types, e.value]
              })
            }
          >
            {e.label}
          </Selectable>
        ))}
      </div>
    </div>
  )

  const renderDateOptions = () => (
    <div className="single-filter">
      <DatePickerContainer>
        <DatePickerObject>
          <label>{t('start_time')}</label>
          <StyledDatePicker
            dateFormat="dd.MM.yyyy"
            locale="fi"
            placeholderText={t('from-on')}
            selected={
              selectedFilters.start_time
                ? parse(selectedFilters.start_time, 'dd.MM.yyyy', new Date())
                : null
            }
            onChange={(date: Date) =>
              setFilters({
                ...selectedFilters,
                start_time: format(date, 'dd.MM.yyyy')
              })
            }
          />
        </DatePickerObject>
        <DatePickerObject>
          <label>{t('end_time')}</label>
          <StyledDatePicker
            dateFormat="dd.MM.yyyy"
            locale="fi"
            placeholderText={t('to-finish')}
            selected={
              selectedFilters.end_time
                ? parse(selectedFilters.end_time, 'dd.MM.yyyy', new Date())
                : null
            }
            onChange={(date: Date) =>
              setFilters({
                ...selectedFilters,
                end_time: format(date, 'dd.MM.yyyy')
              })
            }
          />
        </DatePickerObject>
      </DatePickerContainer>
    </div>
  )

  const renderSubjectAreas = () => (
    <div className="single-filter">
      <label>{t('select-subject-area')}</label>
      <div className="text-input">
        <Select
          isMulti
          placeholder={t('professional-title-placeholder')}
          styles={selectStyles}
          value={
            selectedFilters.subject_areas ? selectedFilters.subject_areas : []
          }
          options={
            subject_areas
              ? subject_areas.map((subject_area: SubjectAreas) => ({
                  label: subject_area.title,
                  value: subject_area.id
                }))
              : undefined
          }
          onChange={(subjects: any) => {
            setFilters({
              ...selectedFilters,
              subject_areas: (subjects || []).map(
                (subject: SubjectAreas) => subject
              )
            })
          }}
        />
      </div>
    </div>
  )
  const renderSearch = () => (
    <>
      <div className="filters">
        {renderFreeTextSearch()}
        {renderLocationSearch()}
        {renderSubjectAreas()}
        {renderTrainingType()}
        {renderOrganizationSearch()}
        {renderDateOptions()}
        <SearchButton type="button" onClick={() => search()}>
          {t('search')}
        </SearchButton>
      </div>
    </>
  )

  return (
    <>
      <Desktop>
        <SearchFiltersContainer>
          <h2>{t('targeted-search')}</h2>
          {renderSearch()}
        </SearchFiltersContainer>
      </Desktop>
      <Tablet>
        <MobileContainer>
          <SearchFiltersContainer mobile minimized={mobileMinimized}>
            <div className="extend-icon">
              <FontAwesomeIcon
                icon={['fas', mobileMinimized ? 'search-plus' : 'search-minus']}
              />
            </div>
            {renderSearch()}
          </SearchFiltersContainer>
        </MobileContainer>
      </Tablet>
      <Mobile>
        <MobileContainer>
          <SearchFiltersContainer mobile minimized={mobileMinimized}>
            <div
              className="extend-icon"
              onClick={() => setMobileMinimized((state: boolean) => !state)}
            >
              <FontAwesomeIcon
                icon={['fas', mobileMinimized ? 'search-plus' : 'search-minus']}
              />
            </div>
            {renderSearch()}
          </SearchFiltersContainer>
        </MobileContainer>
      </Mobile>
    </>
  )
}

export default TrainingSearchFilters
