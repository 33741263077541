/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  fetchSingleOrganizationRegistrationRequest,
  approveOrganizationRegistrationRequest,
  denyOrganizationRegistrationRequest
} from 'Features/Organization/organizationRegistrationSlice'
import { RootState, useAppDispatch } from 'Store'
import { Desktop, Mobile, Tablet } from 'Components/Layout/MediaQuery'

const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;

  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${props => props.theme.breakpoints.lg} {
    width: 60%;
    height: 60%;
  }
`
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`
const CloseButtonContainer = styled.div`
  display: flex;
  margin: 35px;
  justify-content: flex-end;
`
const CancelButton = styled.div`
  height: 37px;
  width: 175px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: Barlow;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  color: #bcc2c6;
`
export const ApproveRequest = styled(CancelButton)`
  margin-left: 20px;
  background-color: #6ed663;
  color: #ffffff;
`
export const DeclineRequest = styled(CancelButton)`
  margin-left: 20px;
  background-color: #ff6174;
  color: #ffffff;
`
const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`
const ModalBackground = styled.div`
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  @media screen and ${props => props.theme.breakpoints.lg} {
    width: 60%;
  }
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  label {
    color: #252525;
    font-weight: 600;
    font-family: Barlow;
    font-size: 14px;
    margin-top: 10px;
  }
  span {
    color: red;
  }
  .form-select {
    border: 1px solid #d6dce2;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;
  }
`
const Title = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 20px;
  margin-bottom: 30px;
`
const FormContainer = styled.div`
  display: flex;
  margin-right: 10px;
  min-width: 50%;
  flex-direction: column;
  @media screen and ${props => props.theme.breakpoints.sm} {
    flex-direction: row;
  }
`
const OrganizationDataField = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
`
const OrganizationDescriptionField = styled.div`
  height: auto;
  width: 100%;
  border: 1px solid #d6dce2;
`
const OrganizationCheckBoxField = styled(OrganizationDataField)`
  display: flex;
`
const RequestDetails = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const OrganizationTypeInput = styled.input`
  height: 15px;
  width: 15px;
`
const DecisionContainer = styled.div`
  display: flex;
  margin-left: 10px;
  min-width: 50%;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
`
const DecisionNotes = styled.textarea`
  font-family: inherit;
  width: 100%;
  height: 100px;
  resize: none;
`
const RegistrationRequestModal = ({
  modalToggle,
  requestId
}: {
  modalToggle: (arg0: boolean) => void
  requestId: number
}) => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (requestId)
      dispatch(fetchSingleOrganizationRegistrationRequest(requestId))
  }, [dispatch])
  const { singleOrganizationRegistrationRequest } = useSelector(
    (state: RootState) => state.organizationRegistration
  )
  const companyDetails = {
    companyName: singleOrganizationRegistrationRequest.name,
    companyVat: singleOrganizationRegistrationRequest.vat_number,
    companyEmail: singleOrganizationRegistrationRequest.username,
    companyDescription: singleOrganizationRegistrationRequest.description,
    isEmployer: singleOrganizationRegistrationRequest.is_employer,
    isTrainer: singleOrganizationRegistrationRequest.is_trainer,
    companyUrl: singleOrganizationRegistrationRequest.url,
    requesterName: `${singleOrganizationRegistrationRequest.first_name} ${singleOrganizationRegistrationRequest.last_name}`
  }
  const [decisionNotes, setDecisionNotes] = useState<string>('')
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDecisionNotes(event.target.value)
  }
  const approveRequest = () => {
    dispatch(
      approveOrganizationRegistrationRequest({
        id: requestId,
        body: { decision_notes: decisionNotes }
      })
    ).then(() => modalToggle(false))
  }
  const declineRequest = () => {
    dispatch(
      denyOrganizationRegistrationRequest({
        id: requestId,
        body: { decision_notes: decisionNotes }
      })
    ).then(() => modalToggle(false))
  }
  const renderMobileButtons = () => {
    return (
      <CloseButtonContainer>
        <CancelButton onClick={() => modalToggle(false)}>
          {t('cancel')}
        </CancelButton>
        <DeclineRequest onClick={() => declineRequest()}>
          {t('decline')}
        </DeclineRequest>
        <ApproveRequest onClick={() => approveRequest()}>
          {t('accept')}
        </ApproveRequest>
      </CloseButtonContainer>
    )
  }
  const renderDesktopButtons = () => {
    return (
      <CloseButtonContainer>
        <CancelButton onClick={() => modalToggle(false)}>
          {t('cancel')}
        </CancelButton>
        <DeclineRequest onClick={() => declineRequest()}>
          {t('decline-request')}
        </DeclineRequest>
        <ApproveRequest onClick={() => approveRequest()}>
          {t('approve-request')}
        </ApproveRequest>
      </CloseButtonContainer>
    )
  }
  return (
    <TransparentBackground>
      <CloseContainer>
        <TopCloseButton onClick={() => modalToggle(false)}>
          <div>{t('close')}</div>
          <CloseIcon icon={['fal', 'times-circle']} />
        </TopCloseButton>
      </CloseContainer>
      <ModalBackground>
        <ModalContent>
          <Title> {t('registration-request-details')}</Title>
          <FormContainer>
            <RequestDetails>
              <label>{t('organization-name')}</label>
              <OrganizationDataField>
                {companyDetails.companyName}
              </OrganizationDataField>
              <label>{t('business-id')}</label>
              <OrganizationDataField>
                {companyDetails.companyVat}
              </OrganizationDataField>
              <label>{t('requester')}</label>
              <OrganizationDataField>
                {companyDetails.requesterName}
              </OrganizationDataField>
              <label>{t('www-site')}</label>
              <OrganizationDataField>
                {companyDetails.companyUrl}
              </OrganizationDataField>
              <label>{t('organization-type')}</label>
              <OrganizationCheckBoxField>
                <OrganizationTypeInput
                  type="checkbox"
                  checked={companyDetails.isEmployer}
                  disabled
                />
                <OrganizationDataField>
                  {t('employment-services')}
                </OrganizationDataField>
              </OrganizationCheckBoxField>
              <OrganizationCheckBoxField>
                <OrganizationTypeInput
                  type="checkbox"
                  checked={companyDetails.isTrainer}
                  disabled
                />
                <OrganizationDataField>{t('education')}</OrganizationDataField>
              </OrganizationCheckBoxField>
              <label>{t('organization-description')}</label>
              <OrganizationDataField>
                <OrganizationDescriptionField>
                  {companyDetails.companyDescription}
                </OrganizationDescriptionField>
              </OrganizationDataField>
            </RequestDetails>
            <DecisionContainer>
              <label>{t('decision-notes')}</label>
              <DecisionNotes
                placeholder={t('decision-notes-placeholder')}
                onChange={event => handleChange(event)}
              />
            </DecisionContainer>
          </FormContainer>
        </ModalContent>
        <Desktop>{renderDesktopButtons()}</Desktop>
        <Tablet>{renderDesktopButtons()}</Tablet>
        <Mobile>{renderMobileButtons()}</Mobile>
      </ModalBackground>
    </TransparentBackground>
  )
}

export default RegistrationRequestModal
