import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  AsyncThunk,
  createAction,
  PayloadAction
} from '@reduxjs/toolkit'

import { getAxiosInstance } from 'Api'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

/* eslint-disable camelcase */
interface FrontPageState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  data: {
    embedded_video_url: string
    carousel_items: Array<any>
  }
}
/* eslint-enable camelcase */

const initialState: FrontPageState = {
  loading: 'idle',
  data: {
    embedded_video_url: '',
    carousel_items: []
  }
}

const fetchFrontPageSettings = createAsyncThunk('frontPage/fetch', async () => {
  const response = await getAxiosInstance().get(`/content_settings/`)
  return { data: response.data }
})

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending')
}
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected')
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled')
}

export const resetAction = createAction('reset-tracked-loading-state')

/* eslint-disable no-param-reassign */
const frontPageSlice = createSlice({
  name: 'frontPage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        fetchFrontPageSettings.fulfilled,
        (state, action: PayloadAction<any>) => {
          // eslint-disable-next-line no-param-reassign
          state.data = action.payload.data
        }
      )
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})
/* eslint-enable no-param-reassign */

export default frontPageSlice.reducer
export { fetchFrontPageSettings }
