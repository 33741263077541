import React from 'react'
import styled from 'styled-components/macro'
import Navbar from 'Components/AdminPage/AdminNavbar'
import Sidebar from 'Components/AdminPage/AdminSidebar'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { useHistory } from 'react-router-dom'
import LoadingIndicator from 'Components/LoadingIndicator'

const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  margin-top: 60px;
`
const ContentContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  display: flex;
`

export interface BaseState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

const AdminLayout = ({ children }: { children: React.ReactNode }) => {
  const { user } = useSelector((state: RootState) => state.auth)
  const history = useHistory()
  if (!user.is_employer && user.is_trainer) {
    history.push('/education-panel')
  }
  const isLoading = useSelector((state: RootState) =>
    Object.values(state).some(partialState => {
      const castedState = partialState as BaseState
      return castedState.loading === 'pending'
    })
  )
  return (
    <MainContainer>
      <Navbar />
      <ContentContainer>
        <Sidebar />
        {children}
      </ContentContainer>
      {isLoading && <LoadingIndicator />}
    </MainContainer>
  )
}

export default AdminLayout
