import React from 'react'
// import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import getYear from 'date-fns/getYear'
import parse from 'date-fns/parse'

const InformationPanel = styled.div`
  padding-top: 20px;
  margin-right: 108px;
  margin-left: 32px;
  flex-direction: column;
  display: flex;
  padding-bottom: 17px;
  border-bottom: 1px solid #d6dce2;
`

const EducationElement = styled.div`
  display: flex;
  flex-direction: column;
`

const ElementTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 3px 0px 3px 0px;
`
const ElementDetail = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`
const ElementHeader = styled.div`
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
`

const EducationComponent = ({ educations }: { educations: any }) => {
  // const { t } = useTranslation()
  return (
    <>
      {educations.map((education: any) => (
        <InformationPanel key={education}>
          <EducationElement>
            <ElementHeader>{education.education_program}</ElementHeader>
            <ElementDetail>
              Koulutuspaikan tiedot näkyvät täydessä profiilissa
            </ElementDetail>
            <ElementTitle>
              {getYear(parse(education.start_date, 'yyyy-mm-dd', new Date()))} -
              {getYear(parse(education.end_date, 'yyyy-mm-dd', new Date()))}{' '}
              {education.education_institute}
            </ElementTitle>
          </EducationElement>
        </InformationPanel>
      ))}
    </>
  )
}

export default EducationComponent
