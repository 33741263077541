import { MemberProfileLocation } from 'Features/MemberProfile/memberProfileSlice'

/* eslint-disable camelcase */
export type TrainingType = {
  id: number
  title: string
  type: string
}
export const initTrainingType = (trainingType: string, t: any) => {
  const trainingTypes = [
    {
      id: 0,
      title: t('educations'),
      type: 'training'
    },
    {
      id: 1,
      title: t('web-course'),
      type: 'web'
    }
  ]
  const trainingTypeObject = trainingTypes.find(
    (trainingObject: TrainingType) => {
      return trainingObject.type === trainingType
    }
  )

  return trainingTypeObject
}

export type ParsedLocationType = {
  id: number
  value: string
  label: string
}
export const initLocations = (currentLocation: MemberProfileLocation) => {
  if (currentLocation) {
    const parsedLocation: ParsedLocationType = {
      id: currentLocation.id,
      value: currentLocation.name,
      label: currentLocation.name
    }
    return parsedLocation
  }
  return undefined
}
export type MemberStatus = {
  id: number
  value: boolean
  title: string
}
export const initMemberStatus = (currentMemberStatus: boolean, t: any) => {
  const onlyForMemberSelections: MemberStatus[] = [
    {
      id: 0,
      value: true,
      title: t('yes')
    },
    {
      id: 1,
      value: false,
      title: t('no')
    }
  ]
  switch (currentMemberStatus) {
    case true:
      return onlyForMemberSelections[0]
    case false:
      return onlyForMemberSelections[1]
    default:
      return undefined
  }
}
