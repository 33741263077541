export interface GroupItem {
  id: number
  name: string
}

const ADMIN_GROUP = 'admin_group'
const ORGANIZATION_ADMIN_GROUP = 'organization_admin_group'
const JOB_SEEKER_GROUP = 'job_seeker_group'
const HOUSEHOLD_ADMIN_GROUP = 'household_user_group'

const isJobSeeker = (groups: Array<GroupItem>) => {
  return groups.some(g => g.name === JOB_SEEKER_GROUP)
}

const isOrganizationAdmin = (groups: Array<GroupItem>) => {
  return groups.some(g => g.name === ORGANIZATION_ADMIN_GROUP)
}
const isHouseholdAdmin = (groups: Array<GroupItem>) => {
  return groups.some(g => g.name === HOUSEHOLD_ADMIN_GROUP)
}
const isAdmin = (groups: Array<GroupItem>) => {
  return groups.some(g => g.name === ADMIN_GROUP)
}

const isAdminOrOrganizationAdmin = (groups: Array<GroupItem>) => {
  return groups.some(
    g => g.name === ORGANIZATION_ADMIN_GROUP || g.name === ADMIN_GROUP
  )
}
const isHouseholdOrOrganizationAdmin = (groups: Array<GroupItem>) => {
  return groups.some(
    g => g.name === ORGANIZATION_ADMIN_GROUP || g.name === HOUSEHOLD_ADMIN_GROUP
  )
}
export {
  isJobSeeker,
  isOrganizationAdmin,
  isAdmin,
  isAdminOrOrganizationAdmin,
  isHouseholdAdmin,
  isHouseholdOrOrganizationAdmin
}
