import { LocationOption } from 'Components/JobGuard/SavedSearches'
import { searchJobAdvertisements } from 'Features/JobAdvertisement/jobAdvertisementSlice'
import {
  Competences,
  LanguageSkills
} from 'Features/MemberProfile/memberProfileSlice'
import { DrivingLicenseType } from 'Features/DrivingLicense/drivingLicenseSlice'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import JobAdvertisementResult, {
  JobAdvertisementWithLocIds
} from './JobAdvertisementResult'
import Pagination from './Pagination'

/* eslint-disable camelcase */
export interface JobAdvertisement {
  archived: boolean
  automatic_archiving: boolean
  id: number
  applications: Array<object>
  description: string
  duration: string
  end_date: string | null
  locations: Array<LocationOption>
  office: string
  salary: string
  search_end_date: string
  search_release_date: string
  search_start_date: string
  start_date: string
  title: string
  job_type: string
  website: string
  organization: any
  images: Array<string>
  professional_titles: Array<number>
  competences: Array<Competences>
  driving_licenses: Array<DrivingLicenseType>
  language_skill_requirements: Array<LanguageSkills>
  likeit_id?: string
}
/* eslint-enable camelcase */

const SearchResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    color: #252525;
    font-weight: 100;
    font-family: Barlow;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0;
  }
`

const JobSearchResults = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    data: { results, count },
    defaultView
  } = useSelector((state: any) => state.jobAdvertisement)
  const filters = 'WIP'
  useEffect(() => {
    if (defaultView) {
      dispatch(
        searchJobAdvertisements({
          free_text_search: '',
          professional_titles: [],
          employment_types: [],
          organizations: [],
          locations: []
        })
      )
    }
  }, [filters])
  return (
    <SearchResultsContainer>
      <h2>
        {defaultView
          ? t('newest-job-advertisements')
          : `${t('search-results')} (${t('results-count')}: ${count})`}
      </h2>
      {(results || []).map((r: JobAdvertisementWithLocIds) => (
        <JobAdvertisementResult result={r} key={r.id} />
      ))}
      <Pagination />
    </SearchResultsContainer>
  )
}

export default JobSearchResults
