import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SubSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  span {
    color: #252525;
    font-family: Barlow;
    font-size: 14px;
    font-weight: 600;
  }
`
const SelectedDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const SelectedData = styled.div`
  border-radius: 9.5px;
  background-color: #e7eaef;
  width: fit-content;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding: 5px 10px;
  font-weight: 500;
  margin-top: 12px;
  margin-right: 12px;
`
const SimpleSelectedDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
`
const SimpleSelectedData = styled.div`
  width: fit-content;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding-top: 10px;
  font-weight: 600;
`
const defaultProps = {
  simpleList: false
}
const ProfileFeaturesComponent = ({
  title,
  simpleList,
  data
}: {
  title: string
  simpleList?: boolean
  data: any
}) => {
  const { t } = useTranslation()
  return (
    <SubSectionContainer>
      <span>{t(title)}</span>
      {simpleList ? (
        <SimpleSelectedDataContainer>
          {data.map((item: any) => (
            <SimpleSelectedData key={item}>{item}</SimpleSelectedData>
          ))}
        </SimpleSelectedDataContainer>
      ) : (
        <SelectedDataContainer>
          {data.map((item: any) => (
            <SelectedData key={item}>{item}</SelectedData>
          ))}
        </SelectedDataContainer>
      )}
    </SubSectionContainer>
  )
}

ProfileFeaturesComponent.defaultProps = defaultProps
export default ProfileFeaturesComponent
