import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Selectable } from 'Components/NewAdvertisement/FieldComponents/Phase2'
import { createMemberContact } from 'Features/MemberContact/memberContactSlice'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'Store'

interface AddConversationModalProps {
  show: boolean
  onClose: () => void
  organizationId: number | null
  recipientId: number
}

interface ModalContainerProps {
  show: boolean
}

const ModalContainer = styled.div`
  position: fixed;
  visibility: ${(props: ModalContainerProps) =>
    props.show ? 'visible' : 'hidden'};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(37, 37, 37, 0.65);
  .content {
    position: relative;
    width: 100%;
    max-width: 845px;
    background-color: #ffffff;
    margin: auto;
    margin-top: 20vh;
    padding: 30px 60px;
    h1 {
      color: #252525;
      font-family: Barlow;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 29px;
      margin: 0;
      margin-bottom: 25px;
      font-weight: 400;
    }
    label {
      display: block;
      color: #333;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 10px;
    }
    .close-button {
      position: absolute;
      display: flex;
      align-items: center;
      color: #ffffff;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      top: -25px;
      right: 0;
      cursor: pointer;
      transition: color 0.3s ease-in-out;
      svg {
        margin-left: 5px;
      }
      &:hover {
        color: #ddd;
      }
    }
    .form-field {
      &:not(:last-of-type) {
        margin-bottom: 25px;
      }
    }
    .details {
      background: #f8f8f8;
      margin: 0 -60px;
      .form-field {
        padding: 15px 60px;
        border-top: 1px solid #d6dce2;
        margin-bottom: 0;
        &:last-of-type {
          border-bottom: 1px solid #d6dce2;
        }
        label {
          margin-bottom: 10px;
        }
        span.info-text {
          color: #252525;
          font-family: Barlow;
          font-size: 14px;
          font-style: italic;
          letter-spacing: 0;
          line-height: 17px;
          margin-left: 15px;
        }
        input,
        textarea {
          margin-left: 15px;
          width: 100%;
          background: transparent;
          border: none;
          outline: none;
          color: #252525;
          font-family: Barlow;
          font-size: 14px;
          font-style: italic;
          letter-spacing: 0;
          line-height: 17px;
          resize: none;
        }
      }
      .receiver {
        display: inline-block;
        cursor: default;
      }
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      button {
        cursor: pointer;
        outline: none;
        text-transform: uppercase;
        border-radius: 6px;
        border: none;
        background-color: #d60c17;
        padding: 10px 30px;
        color: #ffffff;
        font-family: Barlow;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        &:first-of-type {
          background: transparent;
          color: #a8aeb4;
          font-family: Barlow;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          margin-right: 20px;
        }
      }
    }
  }
`
/* eslint-disable camelcase */
type RequestBody = {
  member_profile: number
  contact_message: string
  organization: number
}
/* eslint-enable camelcase */

const ConversationRequestModal = ({
  show,
  onClose,
  organizationId,
  recipientId
}: AddConversationModalProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [message, setMessage] = useState<string>('')

  const sendMessage = async () => {
    if (organizationId === null) {
      return
    }
    const requestBody: RequestBody = {
      member_profile: recipientId,
      contact_message: message,
      organization: organizationId
    }
    const { error } = await dispatch(createMemberContact(requestBody))
    if (error) {
      const errorObj: {
        [key: string]: { [key: string]: any }
      } = JSON.parse(error.message)
      const errors: Array<string> = []
      Object.keys(errorObj).map((k: string) => {
        errors.push(`${t(k)}`)
      })
      // eslint-disable-next-line no-undef
      toast.error(
        <>
          <strong>{t('fill-missing-fields')}:</strong>
          {errors.map((e: string) => (
            <p key={e}>
              <strong>{e.split(' - ')[0]}</strong>
            </p>
          ))}
        </>
      )
    } else {
      toast.success(t('member-contact-sent'))
      onClose()
    }
  }
  return (
    <ModalContainer show={show}>
      <div className="content">
        <span className="close-button" onClick={onClose}>
          {t('close')} <FontAwesomeIcon icon={['fal', 'times-circle']} />
        </span>
        <h1>{t('create-conversation-request')}</h1>
        <div className="details">
          <div className="form-field">
            <label>{t('receiver')}</label>
            {recipientId !== null ? (
              <Selectable
                className="receiver"
                selected={false}
                key={recipientId}
              >
                {t('profile')}#{recipientId}
              </Selectable>
            ) : (
              <span className="info-text">{t('no-receivers')}</span>
            )}
          </div>
          <div className="form-field">
            <label>{t('message')}</label>
            <textarea
              placeholder={t('message-placeholder')}
              rows={7}
              onChange={e => setMessage(e.target.value)}
              value={message}
            />
          </div>
        </div>
        <div className="actions">
          <button type="button" onClick={onClose}>
            {t('cancel')}
          </button>
          <button type="button" onClick={sendMessage}>
            {t('send-request')}
          </button>
        </div>
      </div>
    </ModalContainer>
  )
}

export default ConversationRequestModal
