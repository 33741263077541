import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import FormFields from './FormFields'
import FormTabs from './FormTabs'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const TitleArea = styled.div`
  display: flex;
  align-items: center;
  span {
    color: #252525;
    font-family: Barlow;
    font-size: 24px;
  }
`
const Form = styled.div`
  overflow: auto;
  background: #fff;
  flex-grow: 1;
  height: 100%;
  margin-top: 10px;
`

const VaultFieldContent = ({
  formIndex,
  onFormIndexChange
}: {
  formIndex: number
  onFormIndexChange: (index: number) => void
}) => {
  const { t } = useTranslation()

  return (
    <FormContainer>
      <TitleArea>
        <span>{t('photo-vault')}</span>
      </TitleArea>
      <Form>
        <FormTabs
          currentTab={formIndex}
          onTabChange={(index: number) => onFormIndexChange(index)}
        />
        <FormFields currentTab={formIndex} />
      </Form>
    </FormContainer>
  )
}

export default VaultFieldContent
