/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import actionGenerator from 'Util/actionGenerator'
import { getAxiosInstance } from 'Api'
import { AxiosError } from 'axios'
import {
  MemberStatus,
  ParsedLocationType,
  TrainingType
} from 'Components/EducationPage/NewTraining/ModifyTrainingInitializers'
import { ImageType } from 'Features/ImageBank/imageBankSlice'

interface TrainingState {
  entities: []
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  currentPage: number
  trainingCount: number
  trainingData: Array<SingleTrainingType> | undefined
  singleTrainingData: SingleTrainingType | undefined
  values: NewTrainingValuesType
  defaultView: boolean
  paginatedFilteredData: Array<SingleTrainingType> | undefined
  availableFilters: AvailableFilters
  selectedFilters: TrainingFilters
}
const initialState: TrainingState = {
  entities: [],
  loading: 'idle',
  currentPage: 1,
  trainingCount: 0,
  trainingData: undefined,
  defaultView: true,
  singleTrainingData: undefined,
  values: {},
  paginatedFilteredData: [],
  availableFilters: {
    locations: [],
    organizations: [],
    subject_areas: [],
    training_types: []
  },
  selectedFilters: {
    free_text_search: '',
    end_time: '',
    start_time: '',
    locations: [],
    organizations: [],
    training_types: [],
    subject_areas: []
  }
}
export type TrainingLocation = {
  id: number
  name: string
}
export type TrainingLocationType = {
  address: string
  id: number
  location: TrainingLocation
}
export type NewTrainingValuesType = {
  training_type?: TrainingType
  id?: number
  subject?: string
  images?: Array<ImageType>
  description?: string
  start_time?: string
  enrollment_deadline?: string
  eventilla_id?: string | null
  end_time?: string
  continuous_training?: boolean
  confirmed?: boolean
  positions?: number
  price?: string
  member_price?: string
  target_audience?: string
  trainer?: string
  web_link?: string
  only_for_members?: MemberStatus
  subject_areas?: string
  hidden?: boolean
  locations?: ParsedLocationType
  address?: string
  organization?: { id: number; name: string; logo: string }
}
export type SingleTrainingType = {
  created_at: string
  updated_at: string
  training_type: string
  id: number
  subject: string
  images?: Array<{ id: number; name: string; url: string }>
  description: string
  start_time: string
  enrollment_deadline: string
  eventilla_id: string | null
  end_time: string
  continuous_training: boolean
  confirmed: boolean
  positions: number
  price?: string
  member_price?: string
  target_audience?: string
  trainer?: string
  web_link?: string
  only_for_members: boolean
  subject_areas: string
  hidden: boolean
  training_location: TrainingLocationType
  organization: { id: number; name: string; logo: string }
}
export type TrainingFilters = {
  free_text_search?: string
  end_time?: string
  start_time?: string
  locations?: Array<string>
  organizations?: Array<number>
  training_types?: Array<string>
  subject_areas?: Array<string>
  status?: string
  order?: string
}
type FetchTrainingsResponse = { results: Array<SingleTrainingType> }
const fetchTrainings = createAsyncThunk<FetchTrainingsResponse, undefined>(
  'training/fetch_trainings',
  async () => {
    const response = await getAxiosInstance().get<FetchTrainingsResponse>(
      `/training/`
    )
    return response.data
  }
)
export const upcomingStatus = 'upcoming'
export const historyStatus = 'passed'
export const ongoingStatus = 'ongoing'

type FetchPaginatedFilteredTrainings = {
  page: number
  filters?: TrainingFilters
}
const fetchPaginatedFilteredTrainings = createAsyncThunk(
  'training/paginate_filter_search',
  async (body: FetchPaginatedFilteredTrainings) => {
    if (body.filters) {
      const { filters } = body
      const { page } = body
      const free_text_search =
        filters && filters.free_text_search
          ? `&free_search=${filters.free_text_search}`
          : ''
      const end_time =
        filters && filters.end_time ? `&end_time=${filters.end_time}` : ''
      const locations =
        filters && filters.locations
          ? filters.locations
              .map((location: string) => {
                return `&locations=${location}`
              })
              .join('')
          : ''
      const subject_areas =
        filters && filters.subject_areas
          ? filters.subject_areas
              .map((subject: string) => {
                return `&subject_areas=${subject}`
              })
              .join('')
          : ''

      const organizations =
        filters && filters.organizations
          ? filters.organizations
              .map((organization: number) => {
                return `&organizations=${organization}`
              })
              .join('')
          : ''
      const training_types =
        filters && filters.training_types
          ? filters.training_types
              .map((training_type: string) => {
                return `&training_type=${training_type}`
              })
              .join('')
          : ''
      const status =
        filters && filters.status ? `&status=${filters.status}` : ''
      const start_time =
        filters && filters.start_time ? `&start_time=${filters.start_time}` : ''
      const order = filters && filters.order ? `&order_by=${filters.order}` : ''
      const response = await getAxiosInstance().get(
        `/training/?page=${page}${free_text_search}${end_time}${locations}${organizations}${training_types}${subject_areas}${start_time}${end_time}${status}${order}`
      )

      return { data: response.data, page }
    }
    const { page } = body
    const response = await getAxiosInstance().get(`/training/?page=${page}`)
    return { data: response.data, page }
  }
)
type FetchTrainingWithIdRequest = number
type FetchTrainingWithIdResponse = SingleTrainingType
const fetchTrainingWithId = createAsyncThunk<
  FetchTrainingWithIdResponse,
  FetchTrainingWithIdRequest
>('training/fetch_with_id', async (trainingId: FetchTrainingWithIdRequest) => {
  const response = await getAxiosInstance().get(`/training/${trainingId}/`)
  return response.data
})
export interface ValidationErrors {
  [key: string]: string[]
}
export type CreateNewTrainingRequest = {
  training_type?: string
  subject?: string
  description?: string
  start_time?: string
  end_time?: string
  continuous_training?: boolean
  enrollment_deadline?: string
  price?: string
  member_price?: string
  target_audience?: string
  images?: number[]
  trainer?: string
  web_link?: string
  only_for_members?: boolean
  training_location?: TrainingLocationType
  organization?: number
  positions?: string
}
const createNewTraining = createAsyncThunk<any, CreateNewTrainingRequest>(
  'training/create_new_training',
  async (body: { [key: string]: any }) => {
    try {
      const response = await getAxiosInstance().post(`/training/`, body)
      return { data: response.data }
    } catch (err) {
      const error: AxiosError<ValidationErrors> = err // cast the error for access
      if (!error.response) {
        throw err
      }
      const errorValue = {
        status: error.response.status,
        data: error.response.data
      }
      return errorValue
    }
  }
)
type CreateNewTrainingLocationRequest = {
  address: string
  location: number
}
type CreateNewTrainingLocationResponse = { id: number }
const createNewTrainingLocation = createAsyncThunk(
  'training/create_new_training_location',
  async (body: CreateNewTrainingLocationRequest) => {
    try {
      const response = await getAxiosInstance().post<CreateNewTrainingLocationResponse>(
        `/training_location/`,
        body
      )
      return { data: response.data }
    } catch (err) {
      const error: AxiosError<ValidationErrors> = err // cast the error for access
      if (!error.response) {
        throw err
      }
      const errorValue = {
        status: error.response.status,
        data: error.response.data
      }
      return errorValue
    }
  }
)
type ModifyTrainingRequest = {
  body: { [key: string]: any }
  id: number
}
const modifyTraining = createAsyncThunk(
  'newAd/modifyAdvertisement',
  async ({ body, id }: ModifyTrainingRequest) => {
    try {
      const response = await getAxiosInstance().patch(`/training/${id}/`, body)
      return { data: response.data }
    } catch (err) {
      const error: AxiosError<ValidationErrors> = err // cast the error for access
      if (!error.response) {
        throw err
      }
      const errorValue = {
        status: error.response.status,
        data: error.response.data
      }
      return errorValue
    }
  }
)
type DeleteTrainingRequest = number
const deleteTraining = createAsyncThunk(
  'training/delete_training',
  async (id: DeleteTrainingRequest) => {
    const response = await getAxiosInstance().delete<CreateNewTrainingLocationResponse>(
      `/training/${id}/`
    )
    return response.data
  }
)
const toggleHiddenTraining = createAsyncThunk(
  'training/hideTraining',
  async (id: number) => {
    const response = await getAxiosInstance().get<number>(
      `/training/${id}/toggle_hidden/`
    )
    return response.data
  }
)
type AvailableFilters = {
  locations: Array<LocationsType>
  organizations: Array<OrganizationsType>
  subject_areas: Array<SubjectAreas>
  training_types: Array<TrainingTypes>
}
export type LocationsType = {
  name: string
  id: number
}
export type OrganizationsType = {
  name: string
  id: number
}
export type SubjectAreas = {
  title: string
  id: number
}
export type TrainingTypes = {
  label: string
  value: string
}
const fetchAvailableFilters = createAsyncThunk(
  'training/fetch_available_filters',
  async () => {
    const response = await getAxiosInstance().get(
      '/training/available_filters/'
    )
    return response.data
  }
)
const genericActions = actionGenerator('training')
const trainingSlice = createSlice({
  name: genericActions.name,
  initialState,
  reducers: {
    updateNewAd(state, action) {
      return {
        ...state,
        values: action.payload
      }
    },
    updateAd(state) {
      state.singleTrainingData!.confirmed = true
    },
    resetNewAd(state) {
      return {
        ...state,
        values: initialState.values,
        singleTrainingData: initialState.singleTrainingData
      }
    },
    changePage(state, action) {
      return {
        ...state,
        currentPage: action.payload
      }
    },
    updateFilters(state, action) {
      return {
        ...state,
        selectedFilters: action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(genericActions.ResetAction, () => initialState)
      .addCase(fetchTrainings.fulfilled, (state, action) => {
        state.trainingData = action.payload.results
      })
      .addCase(fetchTrainingWithId.fulfilled, (state, action) => {
        state.singleTrainingData = action.payload
      })
      .addCase(fetchPaginatedFilteredTrainings.fulfilled, (state, action) => {
        state.defaultView = false
        state.trainingCount = action.payload.data.count
        state.paginatedFilteredData = action.payload.data.results
      })
      .addCase(fetchAvailableFilters.fulfilled, (state, action) => {
        state.availableFilters = action.payload
      })
      .addMatcher(genericActions.isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(genericActions.isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(genericActions.isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})

export default trainingSlice.reducer
export {
  fetchTrainingWithId,
  createNewTraining,
  fetchTrainings,
  createNewTrainingLocation,
  deleteTraining,
  modifyTraining,
  fetchPaginatedFilteredTrainings,
  fetchAvailableFilters,
  toggleHiddenTraining
}
