import React from 'react'
import styled from 'styled-components/macro'
import ProfilePicture from 'Assets/profile-picture.svg'
import { ProfileProps } from './index'

interface AvatarProps {
  src: string
}

const Avatar = styled.div`
  width: 54px;
  height: 54px;
  background: url(${(props: AvatarProps) => props.src});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 16px;
`

const PersonalDetailsContainer = styled.div`
  display: flex;
  margin-top: ${(props: ProfileProps) => (props.columnStyle ? '20px' : '3px')};
  .details {
    margin-top: 3px;
    display: flex;
    flex-direction: column;
    strong {
      color: #252525;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 8px;
    }
    span {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
`

const PersonalDetails = ({
  user,
  columnStyle
}: {
  user: any
  columnStyle?: boolean
}) => {
  const renderAvatar = () => {
    let src = ProfilePicture
    if (user.avatar) {
      src = user.avatar
    }
    return <Avatar src={src} />
  }
  return (
    <PersonalDetailsContainer columnStyle={columnStyle}>
      {renderAvatar()}
      <div className="details">
        <strong>
          {user.first_name} {user.last_name}
        </strong>
        <span>{user.email}</span>
      </div>
    </PersonalDetailsContainer>
  )
}

PersonalDetails.defaultProps = {
  columnStyle: false
}

export default PersonalDetails
