import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import Select from 'react-select'
import {
  InfoTooltip,
  TooltipBackground
} from 'Components/EducationPage/NewTraining/FieldComponents/Phase1'
import { Fields } from 'Components/EducationPage/NewTraining/CommonTrainingCreationProps'
import { setAdValues } from 'Util/trainingUtils'

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .input-row-item {
    display: flex;
    flex-direction: column;
    width: 40%;
  }
`
const LocationSelect = styled(Select)`
  width: 100%;
  margin-bottom: 15px;
`

const Phase4 = ({
  createTraining,
  adId
}: {
  createTraining: () => void
  adId: string | undefined
}) => {
  const { values } = useSelector((state: RootState) => state.training)

  const displaySelection = (e: Event) => {
    setAdValues('locations', e)
  }
  const { locations } = useSelector(
    (state: RootState) => state.newAdvertisement
  )
  const jobLocations = locations
    ? locations.map(({ name, id }: { name: string; id: number }) => ({
        id,
        value: name,
        label: name
      }))
    : undefined

  const { t } = useTranslation()
  return (
    <Fields>
      <div className="rows" style={{ width: '100%' }}>
        <InputRow>
          <div className="input-row-item">
            <label>
              {t('price')} (€ / {t('includes-vat')})
            </label>
            <input
              className="form-select"
              size={30}
              type="number"
              step=".01"
              pattern="[0-9]"
              placeholder="0"
              onChange={e => setAdValues('price', e.target.value)}
              value={values.price}
            />
          </div>
          <div className="input-row-item">
            <label>
              {t('member-price')} (€ / {t('includes-vat')})
            </label>
            <input
              className="form-select"
              size={30}
              type="number"
              step=".01"
              pattern="[0-9]"
              placeholder="0"
              onChange={e => setAdValues('member_price', e.target.value)}
              value={values.member_price}
            />
          </div>
        </InputRow>
        <InputRow>
          <div className="input-row-item">
            <label>{t('website')}</label>
            <input
              className="form-select"
              size={30}
              value={values.web_link}
              placeholder="https://esimerkki.fi"
              onChange={e => setAdValues('web_link', e.target.value)}
            />
          </div>
          <div className="input-row-item">
            <label>{t('trainer')}</label>
            <input
              className="form-select"
              value={values.trainer}
              placeholder="Matti Meikäläinen"
              onChange={e => setAdValues('trainer', e.target.value)}
            />
          </div>
        </InputRow>

        <InputRow>
          <div className="input-row-item">
            <label>
              {t('location')} <span> *</span>
              <TooltipBackground>
                <span className="tooltip">
                  <InfoTooltip color="#0859AC" icon={['fal', 'info-circle']} />
                  <span className="tooltiptext">
                    {t('training-web-location')}
                  </span>
                </span>
              </TooltipBackground>
            </label>

            <LocationSelect
              isDisabled={values?.training_type?.type === 'web'}
              placeholder="Valitse sijainti"
              value={
                values.locations && jobLocations ? values.locations : undefined
              }
              options={jobLocations}
              onChange={(e: Event) => displaySelection(e)}
            />
          </div>

          <div className="input-row-item">
            <label>
              {t('address')}
              <span> *</span>
              <TooltipBackground>
                <span className="tooltip">
                  <InfoTooltip color="#0859AC" icon={['fal', 'info-circle']} />
                  <span className="tooltiptext">
                    {t('training-web-location')}
                  </span>
                </span>
              </TooltipBackground>
            </label>
            <input
              disabled={values?.training_type?.type === 'web'}
              className="form-select"
              placeholder="Osoite 4A"
              onChange={e => setAdValues('address', e.target.value)}
              value={values.address}
            />
          </div>
        </InputRow>
        <div>
          <div
            className="additional-info"
            style={{ width: '35%', float: 'right' }}
          >
            <button type="button" onClick={createTraining}>
              {adId ? t('save-changes') : t('create-jobad')}
            </button>
          </div>
        </div>
      </div>
    </Fields>
  )
}

export default Phase4
