/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import Logo from 'Assets/rakennusliitto-logo.png'
import LoginModal from 'Features/Auth/LoginModal'
import { Link, useHistory, withRouter } from 'react-router-dom'
import type { Location } from 'history'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Desktop, Mobile, Tablet } from 'Components/Layout/MediaQuery'
import Select from 'react-select'
import { RootState, useAppDispatch } from 'Store'
import {
  fetchOrganizations,
  genericActions as organizationGeneric
} from 'Features/Organization/organizationSlice'
import DropdownMenu, { DropdownProps } from 'Components/DropdownMenu'
import { useTranslation } from 'react-i18next'
import {
  userLogout,
  genericActions,
  checkActiveSession
} from 'Features/Auth/authSlice'
import {
  isAdmin,
  isHouseholdAdmin,
  isJobSeeker,
  isOrganizationAdmin
} from 'Util/permissions'
import organizationIsHousehold from 'Util/organizationIsHousehold'

interface StyledLinkProps {
  isActive: boolean
}
interface MobileMenuProps {
  open: boolean
  height: Number
}

const NavbarContainer = styled.div`
  max-height: 60px;
  min-height: 60px;
  width: 100%;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;
`
const NavbarContent = styled.div`
  max-height: 60px;
  min-height: 60px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
  img {
    height: 30px;
    width: 30px;
  }
  svg.hamburger {
    position: absolute;
    right: 15px;
    top: calc(50% - 10px);
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
`
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 60px;
  margin: -10px;
  border-right: 1px solid #e7eaef;
  @media screen and ${props => props.theme.breakpoints.lg} {
    width: 196px;
  }
  img {
    margin-right: 10px;
  }
`

const CompanySelect = styled(Select)`
  width: 204px;
`

const MobileMenuItems = styled.div`
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: ${(props: MobileMenuProps) =>
    props.open ? `${props.height}px` : '0px'};
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease-in-out;
  overflow-y: hidden;
  a {
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin-right: 50px;
    text-decoration: none;
    padding: 10px 20px;
    &.outside-link {
      color: #252525;
    }
  }
`
const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  @media screen and ${props => props.theme.breakpoints.lg} {
    &:last-of-type {
      padding-bottom: 10px;
    }
  }
`
const IconArea = styled.div`
  width: 20px;
  text-align: center;
`

const LoginLink = styled.div`
  color: #5c5f63;
  font-family: Barlow;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  cursor: ${(props: { cursor: string }) => props.cursor};
`
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
const StyledLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  display: flex;
  ${(props: StyledLinkProps) =>
    props.isActive
      ? `
      font-weight: bold;
      color: #D60C17;
  `
      : 'color: #252525;'};
`
const LogoText = styled.div`
  color: #5c5f63;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &.bottom {
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 1.63px;
    line-height: 20px;
  }
`
const LogoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ChangeView = styled.div`
  display: flex;
  height: 60px;
  padding: 10px 40px 10px 0px;
  p {
    color: #252525;
    font-family: Barlow;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: right;
    padding: 0px 16px 0px 16px;
  }
`

const ActionsTab = styled.div`
  display: flex;
  justify-content: flex-end;
`

const SubMenuItems = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  a {
    font-family: Barlow;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    padding: 8px 0px 8px 0px;
    &.outside-link {
      color: white;
    }
  }
`
const AdminNavbar = ({ location }: { location: Location }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchOrganizations())
    dispatch(checkActiveSession())
  }, [dispatch])
  const companyInformation = useSelector((state: RootState) => state.admin)
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  )
  const { data } = useSelector((state: RootState) => state.organization)
  const [mobileHeight, setMobileHeight] = useState<Number>(0)
  const [loginOpen, setLoginOpen] = useState<boolean>(false)
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)
  const { results } = data
  const history = useHistory()
  const valueLabelPairs = results
    ? results.map(
        ({ id, name }: { id: number; name: string }, index: number) => ({
          value: id,
          label: name,
          itemIndex: index
        })
      )
    : undefined
  const isHousehold = organizationIsHousehold()
  React.useEffect(() => {
    localStorage.setItem(
      'currentlySelectedOrganization',
      companyInformation.itemIndex.toString()
    )
  }, [companyInformation.itemIndex])

  const ChangeCurrentCompany = (value: Event) => {
    history.push(`/admin-panel`)
    dispatch({
      type: 'jobs/resetAdvertisementState'
    })
    dispatch({ type: 'company/changeCompany', payload: value })
  }
  const userOptions: Array<DropdownProps> = []
  if (user.groups && isJobSeeker(user.groups)) {
    userOptions.push({
      icon: 'user-circle',
      title: t('user-profile'),
      action: () => {
        history.push('/profile')
      }
    })
  }
  if (user.groups && isAdmin(user.groups)) {
    userOptions.push({
      icon: 'briefcase',
      title: t('super-admin-panel'),
      action: () => {
        history.push('/super-admin-panel')
      }
    })
  }
  if (user.groups && isOrganizationAdmin(user.groups) && user.is_employer) {
    userOptions.push({
      icon: 'briefcase',
      title: t('admin-panel'),
      action: () => {
        history.push('/admin-panel')
      }
    })
  }
  if (user.groups && isOrganizationAdmin(user.groups) && user.is_trainer) {
    userOptions.push({
      icon: 'briefcase',
      title: t('education-panel'),
      action: () => {
        history.push('/education-panel')
      }
    })
  }
  if (user.groups && isHouseholdAdmin(user.groups) && user.is_household) {
    userOptions.push({
      icon: 'briefcase',
      title: t('admin-panel'),
      action: () => {
        history.push('/admin-panel')
      }
    })
  }
  userOptions.push({
    icon: 'sign-out',
    title: t('log-out'),
    action: () => {
      dispatch(userLogout())
      dispatch(genericActions.ResetAction())
      dispatch(organizationGeneric.ResetAction())
    }
  })

  const renderMobileNav = () => (
    <>
      <ChangeView>
        {valueLabelPairs && companyInformation ? (
          <>
            <p>Vaihda yritysnäkymää</p>
            <CompanySelect
              defaultValue={{
                label: valueLabelPairs[companyInformation.itemIndex]
                  ? valueLabelPairs[companyInformation.itemIndex].label
                  : '',
                value: valueLabelPairs[companyInformation.itemIndex]
                  ? valueLabelPairs[companyInformation.itemIndex].value
                  : ''
              }}
              options={valueLabelPairs}
              onChange={(e: Event) => ChangeCurrentCompany(e)}
            />
          </>
        ) : null}
      </ChangeView>
      <MobileMenuItems
        ref={(el: HTMLDivElement) => {
          if (!el) {
            return
          }

          if (mobileHeight !== el.scrollHeight) {
            setMobileHeight(el.scrollHeight)
          }
        }}
        open={isMobileMenuOpen}
        height={mobileHeight}
      >
        <StyledLink
          isActive={location.pathname === '/admin-panel'}
          to="/admin-panel"
        >
          <IconArea>
            <FontAwesomeIcon icon={['far', 'home']} />
          </IconArea>
          Etusivu
        </StyledLink>
        <StyledLink isActive={false} to="/admin-panel/ilmoitukset/avoimet">
          <IconArea>
            <FontAwesomeIcon icon={['far', 'file-alt']} />
          </IconArea>
          Ilmoitukset
        </StyledLink>
        <SubMenuItems>
          <>
            <StyledLink
              isActive={
                location.pathname === '/admin-panel/ilmoitukset/avoimet' ||
                location.pathname === '/admin-panel/ilmoitukset/avoimet/uusi'
              }
              to="/admin-panel/ilmoitukset/avoimet"
            >
              Avoimet
            </StyledLink>
            <StyledLink
              isActive={
                location.pathname === '/admin-panel/ilmoitukset/historia'
              }
              to="/admin-panel/ilmoitukset/historia"
            >
              Historia
            </StyledLink>
          </>
        </SubMenuItems>
        <StyledLink
          isActive={location.pathname === '/admin-panel/viestit'}
          to="/admin-panel/viestit"
        >
          <IconArea>
            <FontAwesomeIcon icon={['far', 'envelope']} />
          </IconArea>
          Viestit
        </StyledLink>
        <StyledLink
          isActive={location.pathname === '/admin-panel/tyontekijahaku'}
          to="/admin-panel/tyontekijahaku"
        >
          <IconArea>
            <FontAwesomeIcon icon={['far', 'hard-hat']} />
          </IconArea>
          Työntekijähaku
        </StyledLink>
        <SubMenuItems>
          <>
            <StyledLink isActive={false} to="/admin-panel/tyontekijahaku/etsi">
              Etsi työntekijöitä
            </StyledLink>
            <StyledLink
              isActive={
                location.pathname === '/admin-panel/tyontekijahaku/pankki'
              }
              to="/admin-panel/tyontekijahaku/pankki"
            >
              Työntekijäpankki
            </StyledLink>
          </>
        </SubMenuItems>
        <StyledLink isActive={false} to="/admin-panel/asetukset/tiedot">
          <IconArea>
            <FontAwesomeIcon icon={['far', 'hammer']} />
          </IconArea>
          Asetukset
        </StyledLink>
        {!isHousehold && (
          <SubMenuItems>
            <>
              <StyledLink
                isActive={location.pathname === '/admin-panel/asetukset/tiedot'}
                to="/admin-panel/asetukset/tiedot"
              >
                Yrityksen tiedot
              </StyledLink>
              <StyledLink
                isActive={
                  location.pathname === '/admin-panel/asetukset/hallinta'
                }
                to="/admin-panel/asetukset/hallinta"
              >
                Käyttäjätilien hallinta
              </StyledLink>
            </>
          </SubMenuItems>
        )}
        <ActionsContainer>
          {isAuthenticated ? (
            <LoginLink cursor="default">
              {t('hey')}, {user.first_name}
            </LoginLink>
          ) : (
            <LoginLink cursor="pointer" onClick={() => setLoginOpen(true)}>
              Kirjaudu
            </LoginLink>
          )}
        </ActionsContainer>
      </MobileMenuItems>
      <FontAwesomeIcon
        onClick={() => setMobileMenuOpen(state => !state)}
        icon={['far', 'bars']}
        className="hamburger"
      />
    </>
  )
  return (
    <NavbarContainer>
      <NavbarContent>
        <Desktop>
          <LogoContainer>
            <img src={Logo} alt="Rakennusliitto logo" />
            <LogoTextContainer>
              <LogoText>Työnvälityksen</LogoText>
              <LogoText className="bottom">hallintapaneeli</LogoText>
            </LogoTextContainer>
          </LogoContainer>
          <ActionsTab>
            <ChangeView>
              {valueLabelPairs && companyInformation ? (
                <>
                  <p>Vaihda yritysnäkymää</p>
                  <CompanySelect
                    defaultValue={{
                      label: valueLabelPairs[companyInformation.itemIndex]
                        ? valueLabelPairs[companyInformation.itemIndex].label
                        : '',
                      value: valueLabelPairs[companyInformation.itemIndex]
                        ? valueLabelPairs[companyInformation.itemIndex].value
                        : ''
                    }}
                    options={valueLabelPairs}
                    onChange={(e: Event) => ChangeCurrentCompany(e)}
                  />
                </>
              ) : null}
            </ChangeView>
            <ActionsContainer>
              {isAuthenticated ? (
                <>
                  <LoginLink cursor="default">Hei, {user.first_name}</LoginLink>
                  <DropdownMenu
                    options={userOptions}
                    menuPosition="leftBottom"
                    objectId={0}
                  />
                </>
              ) : (
                <LoginLink cursor="pointer" onClick={() => setLoginOpen(true)}>
                  Kirjaudu sisään
                </LoginLink>
              )}
            </ActionsContainer>
          </ActionsTab>
        </Desktop>
        <Tablet>{renderMobileNav()}</Tablet>
        <Mobile>{renderMobileNav()}</Mobile>
      </NavbarContent>
      <LoginModal
        modalOpen={loginOpen}
        closeModal={() => setLoginOpen(false)}
      />
    </NavbarContainer>
  )
}

export default withRouter(AdminNavbar)
