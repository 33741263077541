import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker, { registerLocale } from 'react-datepicker'
import fi from 'date-fns/locale/fi'
import { format, parse } from 'date-fns'
import organizationIsHousehold from 'Util/organizationIsHousehold'
import { Fields } from '../FormFields'
import AddPhotoModal from '../FormComponents/AddPhotoModal'

registerLocale('fi', fi)

export const SelectableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  &.margin-small {
    margin-top: 10px;
  }
  & + label {
    margin-top: 40px;
  }
`
export const Selectable = styled.div`
  border-radius: 9.5px;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? '#D60C17' : '#E7EAEF'};
  color: ${(props: { selected: boolean }) =>
    props.selected ? '#FFFFFF' : '#252525'};
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding: 5px 10px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 12px;
  margin-bottom: 12px;
`
const ImageArea = styled.div`
  height: 166px;
  border: 1px solid #d6dce2;
  border-radius: 3px;
  background-color: #f4f5f7;
  text-align: center;
  .image-placeholder {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    .image-tooltip {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      font-style: italic;
    }
  }
  &:hover {
    cursor: pointer;
  }
`
const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #d6dce2;
  border-radius: 3px;
  padding: 15px;
  outline: none;
  width: 100%;
  margin-bottom: 10px;
`
const Phase2 = ({
  values,
  setValue,
  nextForm
}: {
  values: { [key: string]: any }
  setValue: Function
  nextForm: () => void
}) => {
  const { t } = useTranslation()
  const changeJobBegingDate = (date: Date) => {
    setValue('start_date', format(date, 'dd.MM.yyyy'))
  }
  const isHousehold = organizationIsHousehold()
  const jobTypes = [
    {
      id: 0,
      title: t('work-type-fixed'),
      type: 'fixed'
    },
    {
      id: 1,
      title: t('work-type-continuous'),
      type: 'continuous'
    }
  ]
  const employmentType = [
    {
      id: 0,
      title: t('full-time'),
      type: 'full-time'
    },
    {
      id: 1,
      title: t('part-time'),
      type: 'part-time'
    },
    {
      id: 2,
      title: t('practice'),
      type: 'practice'
    }
  ]

  const [showPhotoModal, setShowPhotoModal] = useState(false)
  return (
    <>
      {showPhotoModal ? (
        <AddPhotoModal
          modalToggle={setShowPhotoModal}
          setValue={setValue}
          values={values}
        />
      ) : null}
      <Fields style={{ flexDirection: 'column' }}>
        <div className="rows" style={{ width: '100%' }}>
          <label>
            {t('add-image-to-ad')}{' '}
            <span style={{ color: 'black', fontWeight: 500 }}>
              ({t('not-mantadory')}, {t('training-image-size-suggestion')})
            </span>
          </label>
          <ImageArea onClick={() => setShowPhotoModal(true)}>
            <div className="image-placeholder">
              <FontAwesomeIcon
                style={{ width: '100%' }}
                className="fa-5x"
                color="#D6DCE2"
                icon={['far', 'image']}
              />
              <span className="image-tooltip"> lisää kuva ilmoitukseen</span>
            </div>
          </ImageArea>
          <label>
            {t('ad-title')} <span>*</span>
          </label>
          <input
            className="form-select"
            value={values.ad_title}
            placeholder={t('type-ad-title')}
            onChange={e => setValue('ad_title', e.target.value)}
          />
          <label>
            {t('job_type')} <span>*</span>
          </label>
          <SelectableContainer>
            {jobTypes
              ? jobTypes.map((c: any) => (
                  <Selectable
                    key={c.id}
                    selected={(values.job_type
                      ? values.job_type.title
                      : []
                    ).includes(c.title)}
                    onClick={() => {
                      setValue('job_type', c)
                    }}
                  >
                    {c.title}
                  </Selectable>
                ))
              : null}
          </SelectableContainer>

          <label>
            {t('start_date')} <span>*</span>
          </label>
          <div>
            <StyledDatePicker
              dateFormat="dd.MM.yyyy"
              locale="fi"
              placeholderText="01.01.1900"
              selected={
                values.start_date
                  ? parse(values.start_date, 'dd.MM.yyyy', new Date())
                  : null
              }
              onChange={(date: Date) => changeJobBegingDate(date)}
            />
          </div>
          {!isHousehold && (
            <>
              <label>
                {t('employment_type')} <span>*</span>
              </label>
              <SelectableContainer>
                {employmentType
                  ? employmentType.map((c: any) => (
                      <Selectable
                        key={c.id}
                        selected={(values.employment_type
                          ? values.employment_type.title
                          : []
                        ).includes(c.title)}
                        onClick={() => {
                          setValue('employment_type', c)
                        }}
                      >
                        {c.title}
                      </Selectable>
                    ))
                  : null}
              </SelectableContainer>
            </>
          )}

          <div>
            <div
              className="additional-info"
              style={{ width: '35%', float: 'right' }}
            >
              <button type="button" onClick={nextForm}>
                {t('next')}
              </button>
            </div>
          </div>
        </div>
      </Fields>
    </>
  )
}

export default Phase2
