/* eslint-disable camelcase */
import {
  createSlice,
  AnyAction,
  AsyncThunk,
  createAction,
  PayloadAction,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { getAxiosInstance } from 'Api'
import { DrivingLicenseType } from 'Features/DrivingLicense/drivingLicenseSlice'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

interface ContactUser {
  phone: string
  id: number
  email: string
  first_name: string
  last_name: string
}

export interface MemberContact {
  contact_message: string
  created_at: string
  id: number
  member_profile: number
  organization: number
  status: 'pending' | 'accepted' | 'rejected'
  user_details: ContactUser | null
}

interface MemberProfileState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  error: any
  profile: object | null
  singleProfileData: FetchMemberProfileByIdResponse
  filteredProfiles: FilteredProfilesType
  filterLocations: Array<string>
  filterGroups: Array<string>
  filterTitles: Array<string>
  filterName: string
  orderBy: string
  onlyActiveJobSeekers: boolean
  languages: Array<LanguageItem>
}

interface MemberProfileBody {
  is_open_profile: boolean
  is_active_job_seeker: boolean
  possibility_to_use_own_tools: boolean
  driving_licenses: Array<number>
  competences: Array<number>
  language_skills: Array<object>
  job_experiences: Array<object>
  educations: Array<object>
  location_ids: Array<number>
  professional_title_ids: Array<number>
}

export interface PartialMemberProfileBody {
  is_open_profile?: boolean
  is_active_job_seeker?: boolean
  possibility_to_use_own_tools?: boolean
  driving_licenses?: Array<number>
  competences?: Array<number>
  language_skills?: Array<object>
  job_experiences?: Array<object>
  educations?: Array<object>
  location_ids?: Array<number>
  professional_title_ids?: Array<number>
}

const initialState: MemberProfileState = {
  loading: 'idle',
  error: null,
  profile: null,
  singleProfileData: {
    additional_info: '',
    competences: [],
    driving_licenses: [],
    educations: [],
    experiences: [],
    job_experiences: [],
    id: undefined,
    is_open_profile: false,
    is_active_job_seeker: false,
    language_skills: [],
    locations: [],
    possibility_to_use_own_tools: false,
    full_name: '',
    professional_titles: [],
    recommenders: [],
    member_contacts: [],
    updated_at: '',
    user: {
      created_at: '',
      email: '',
      first_name: '',
      phone: '',
      id: undefined,
      last_name: '',
      updated_at: ''
    }
  },
  filteredProfiles: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: []
    },
    page: 1
  },
  filterLocations: [],
  filterGroups: [],
  filterTitles: [],
  filterName: '',
  languages: [],
  orderBy: '',
  onlyActiveJobSeekers: true
}
type FilteredProfilesType = {
  data: {
    count: number
    next: string | null
    previous: string | null
    results: Array<FetchPaginatedEmployeesResponse>
  }
  page: number
}

const createMemberProfile = createAsyncThunk(
  'memberProfile/create',
  async (body: MemberProfileBody) => {
    try {
      const response = await getAxiosInstance().post(`/profile/`, body)
      return { data: response.data }
    } catch (err) {
      throw Error(JSON.stringify(err.response.data))
    }
  }
)

const savePartialProfile = createAsyncThunk(
  'memberProfile/savePartial',
  async (body: { [key: string]: any }) => {
    const response = await getAxiosInstance().post(
      `/profile/save_partial/`,
      body
    )
    return { data: response.data }
  }
)

const updateMemberProfile = createAsyncThunk(
  'memberProfile/update',
  async ({ id, body }: { id: number; body: MemberProfileBody }) => {
    try {
      const response = await getAxiosInstance().put(`/profile/${id}/`, body)
      return { data: response.data }
    } catch (err) {
      throw Error(JSON.stringify(err.response.data))
    }
  }
)

const partialUpdateMemberProfile = createAsyncThunk(
  'memberProfile/partialUpdate',
  async ({ id, body }: { id: number; body: PartialMemberProfileBody }) => {
    try {
      const response = await getAxiosInstance().patch(`/profile/${id}/`, body)
      return { data: response.data }
    } catch (err) {
      throw Error(JSON.stringify(err.response.data))
    }
  }
)

const fetchMemberProfile = createAsyncThunk('memberProfile/fetch', async () => {
  const response = await getAxiosInstance().get(`/profile/`)
  return { data: response.data }
})

export const toggleMemberProfileOpenness = createAsyncThunk(
  'memberProfile/toggle_openness',
  async () => {
    try {
      const response = await getAxiosInstance().post(
        `/profile/toggle_openness/`
      )
      return { data: response.data }
    } catch (err) {
      throw Error(JSON.stringify(err.response.data))
    }
  }
)

const getLanguages = createAsyncThunk(
  'memberProfile/getLanguages',
  async () => {
    const response = await getAxiosInstance().get(`/profile/get_languages/`)
    return response.data
  }
)

export type FetchMemberProfileByIdResponse = {
  additional_info: string
  competences: Array<Competences>
  driving_licenses: Array<DrivingLicenseType>
  educations: Array<Educations>
  experiences: Array<Experiences>
  id: number | undefined
  is_open_profile: boolean
  is_active_job_seeker: boolean
  job_experiences: any
  language_skills: Array<LanguageSkills>
  locations: Array<Locations>
  possibility_to_use_own_tools: boolean
  full_name: string
  professional_titles: Array<ProfessionalTitles>
  recommenders: Array<Recommender>
  updated_at: string
  user: User
  member_contacts: Array<MemberContact>
}
export type Competences = {
  created_at: string
  id: number
  title: string
  updated_at: string
}
type Educations = {
  created_at: string
  description: string
  education_institute: string
  education_program: string
  end_date: string
  id: number
  member_profile: number
  start_date: string
  updated_at: string
}
export type Experiences = {
  created_at: string
  description: string
  end_date: string
  id: number
  member_profile: number
  start_date: string
  title: string
  updated_at: string
}
export type LanguageSkills = {
  created_at: string
  id: number
  language: {
    full_name: string
    id: number
    name: string
  }
  level_of_competence: number
  member_profile: number
  updated_at: string
}
export type Locations = {
  created_at: string
  id: number
  level: number
  lft: number
  name: string
  parent: number | null
  rght: number
  tree_id: number
  updated_at: string
}
export type ProfessionalTitles = {
  aliases: Array<number>
  created_at: string
  id: number
  professional_title_group: number
  title: string
  updated_at: string
}
export type Recommender = {
  created_at: string
  email: string
  id: number
  member_profile: number
  name: string
  phone: string
  updated_at: string
}
type User = {
  created_at: string
  email: string
  first_name: string
  phone: string
  id: number | undefined
  last_name: string
  updated_at: string
}
const fetchMemberProfileById = createAsyncThunk<
  FetchMemberProfileByIdResponse,
  number
>('memberProfile/fetchById', async (id: number) => {
  const response = await getAxiosInstance().get(`/profile/${id}/`)
  return response.data
})
const deleteMemberProfile = createAsyncThunk<undefined, number>(
  'memberProfile/deleteMember',
  async (id: number) => {
    const response = await getAxiosInstance().delete(`/profile/${id}/`)
    return response.data
  }
)
type EmployeesFilters = {
  professional_titles: Array<string>
  professional_title_groups: Array<string>
  locations: Array<string>
  favorites: boolean
  organization?: number
  full_name: string
  only_job_seekers?: boolean
  only_active_job_seekers?: boolean
  orderBy?: string
}
type FetchPaginatedEmployeesRequest = {
  page: number
  filters: EmployeesFilters
}

export type LocationType = {
  id: number
  value: string
  label: string
}

export type MemberProfileLocation = {
  id: number
  name: string
}
type ProfessionalTitleGroupType = {
  id: number
  title: string
}
export type MemberProfileProfessionalTitle = {
  id: number
  professional_title_group: ProfessionalTitleGroupType
  title: string
}
type RecommenderType = {
  id: number
  name: string
  phone: string
  email: string
}
export type FetchPaginatedEmployeesResponse = {
  driving_license_count: number
  driving_licenses: Array<DrivingLicenseType>
  id: number
  location_count: number
  locations: Array<MemberProfileLocation>
  possibility_to_use_own_tools: boolean
  is_active_job_seeker: boolean
  professional_title_count: number
  professional_title_group_count: number
  professional_titles: Array<MemberProfileProfessionalTitle>
  recommender_count: number
  recommenders: Array<RecommenderType>
  user: number
  full_name: string
  member_contacts: Array<MemberContact>
  member_profile_favorite: number | null
}
export interface LanguageItem {
  id: number
  name: string
  full_name: string
}
type LanguageItemListPayload = Array<LanguageItem>

const fetchPaginatedEmployees = createAsyncThunk(
  'memberProfile/paginate_search',
  async (body: FetchPaginatedEmployeesRequest) => {
    if (body.filters) {
      const { filters } = body
      const { page } = body
      const params = {
        page,
        locations:
          filters.locations.length > 0
            ? filters.locations.join(',')
            : undefined,
        professional_titles:
          filters.professional_titles.length > 0
            ? filters.professional_titles.join(',')
            : undefined,
        professional_title_groups:
          filters.professional_title_groups.length > 0
            ? filters.professional_title_groups.join(',')
            : undefined,
        favorites: filters.favorites,
        organization: filters.organization,
        full_name: filters.full_name,
        only_job_seekers: filters.only_job_seekers,
        only_active_job_seekers: filters.only_active_job_seekers,
        order_by: filters.orderBy,
        limit: 20
      }

      const response = await getAxiosInstance().get(`/profile/`, { params })
      return { data: response.data, page }
    }
    const { page } = body
    const response = await getAxiosInstance().get(`/jobs/?page=${page}`)
    return { response, page }
  }
)

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending')
}
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected')
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled')
}

export const resetAction = createAction('reset-tracked-loading-state')

/* eslint-disable no-param-reassign */
const memberProfileSlice = createSlice({
  name: 'memberProfile',
  initialState,
  reducers: {
    changeLocations(state, action) {
      return {
        ...state,
        filterLocations: action.payload
      }
    },
    changeGroups(state, action) {
      return {
        ...state,
        filterGroups: action.payload
      }
    },
    changeTitles(state, action) {
      return {
        ...state,
        filterTitles: action.payload
      }
    },
    changeName(state, action) {
      return {
        ...state,
        filterName: action.payload
      }
    },
    changePage(state, action) {
      return {
        ...state,
        filterTitles: action.payload
      }
    },
    changeSortedBy(state, action) {
      return {
        ...state,
        orderBy: action.payload
      }
    },
    changeOnlyActiveJobSeekers(state, action) {
      return {
        ...state,
        onlyActiveJobSeekers: action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        fetchMemberProfile.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { results } = action.payload.data
          if (results) {
            const [profile] = results
            state.profile = profile || null
          }
        }
      )
      .addCase(fetchMemberProfileById.fulfilled, (state, action) => {
        state.singleProfileData = action.payload
      })
      .addCase(
        createMemberProfile.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.profile = action.payload.data || null
        }
      )
      .addCase(
        fetchPaginatedEmployees.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.filteredProfiles = action.payload
        }
      )
      .addCase(
        createMemberProfile.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload
        }
      )
      .addCase(
        updateMemberProfile.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.profile = action.payload.data || null
        }
      )
      .addCase(
        partialUpdateMemberProfile.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.profile = action.payload.data || null
        }
      )
      .addCase(
        getLanguages.fulfilled,
        (state, action: PayloadAction<LanguageItemListPayload>) => {
          state.languages = action.payload
        }
      )
      .addCase(
        updateMemberProfile.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload
        }
      )
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})
/* eslint-enable no-param-reassign */

export default memberProfileSlice.reducer
export {
  createMemberProfile,
  deleteMemberProfile,
  fetchMemberProfile,
  updateMemberProfile,
  savePartialProfile,
  fetchPaginatedEmployees,
  fetchMemberProfileById,
  partialUpdateMemberProfile,
  getLanguages
}
