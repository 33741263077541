/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DropdownMenu from 'Components/DropdownMenu'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { AddConversationModal } from 'Components/Modals'
import { JobApplicationType } from 'Features/JobApplication/jobApplicationSlice'
import ApplicationObject from './ApplicationObject'
import RejectedApplication from './RejectedApplication'

type ListTypes = {
  name: string
  received: string
  id: number
  applicationId: number
}
type RejectedApplicationType = {
  id: number
  applicationId: number
  name: string
  profile_features: object
  received: string
  status: string
}
interface ColumnProps {
  col: {
    id: string
    list: Array<ListTypes>
  }
  adId: string
  columnIndex: number
  modalToggle: any
  setSelectedApplication: any
  rejectedApplications: Array<RejectedApplicationType>
}
const StatusBar = styled.div`
  background: #70e764;
  width: 100%;
  min-height: 5px;
  max-height: 5px;
`
const ColumnTitle = styled.div`
  height: 30px;
  width: 100%;
  color: #5c5f63;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 10px;
  user-select: none;
  justify-content: space-between;
`
const ApplicationColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 280px;
  background: #fff;
  text-align: center;
  max-height: 80vh;
`
const DroppableArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: none;
  background-color: #fff;
  height: 100%;
`
const EmptyDroppableArea = styled.div`
  flex-direction: column;
  margin: 10px;
  border: none;
  background-color: #fff;
  border: 1px dashed #979797;
  background-color: #f4f5f7;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
const DragToolTip = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  font-style: italic;
  user-select: none;
  position: absolute;
`
const RejectedApplications = styled.div`
  width: 100%;
  color: #5c5f63;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  .title {
    padding: 6px;
  }
  .line {
    border-bottom: 1px solid #dcdcdc;
  }
`

const Column = ({
  col: { id, list },
  adId,
  columnIndex,
  modalToggle,
  setSelectedApplication,
  rejectedApplications
}: ColumnProps) => {
  const { singleAdData } = useSelector(
    (state: RootState) => state.advertisement
  )
  const [recipients, setRecipients] = useState<Array<number>>([])
  const sendMassMessage = () => {
    switch (id) {
      case 'receivedApplications': {
        if (!singleAdData) {
          return
        }
        const apps: Array<JobApplicationType> = singleAdData.job_applications.filter(
          (app: JobApplicationType) => app.status === 'received'
        )
        setRecipients(apps?.map(a => a.member_profile.user.id))
        break
      }
      case 'inReview': {
        if (!singleAdData) {
          return
        }
        const apps: Array<JobApplicationType> = singleAdData.job_applications.filter(
          (app: JobApplicationType) => app.status === 'review'
        )
        setRecipients(apps?.map(a => a.member_profile.user.id))
        break
      }
      case 'inInterview': {
        if (!singleAdData) {
          return
        }
        const apps: Array<JobApplicationType> = singleAdData.job_applications.filter(
          (app: JobApplicationType) => app.status === 'interview'
        )
        setRecipients(apps?.map(a => a.member_profile.user.id))
        break
      }
      case 'inHired': {
        if (!singleAdData) {
          return
        }
        const apps: Array<JobApplicationType> = singleAdData.job_applications.filter(
          (app: JobApplicationType) => app.status === 'hired'
        )
        setRecipients(apps?.map(a => a.member_profile.user.id))
        break
      }
      default: {
        if (!singleAdData) {
          return
        }
        const apps: Array<JobApplicationType> = singleAdData.job_applications.filter(
          (app: JobApplicationType) => app.status === 'received'
        )
        setRecipients(apps?.map(a => a.member_profile.user.id))
        break
      }
    }
  }
  const columnSettings = [
    {
      icon: 'envelope',
      title: 'Lähetä massaviesti',
      action: sendMassMessage
    }
  ]
  const { t } = useTranslation()
  const { itemIndex } = useSelector((state: RootState) => state.admin)
  const {
    data: { results }
  } = useSelector((state: RootState) => state.organization)
  const organizationId = results[itemIndex]?.id
  return (
    <>
      <Droppable droppableId={id}>
        {provided => (
          <ApplicationColumn>
            <StatusBar />
            <ColumnTitle>
              {t(id)}
              <DropdownMenu options={columnSettings} objectId={0} />
            </ColumnTitle>
            {list.length > 0 ? (
              <DroppableArea
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {list.map((data, index) => (
                  <ApplicationObject
                    key={data.id}
                    adId={adId}
                    applicantName={data.name}
                    applicantId={data.id}
                    arrivalDate={data.received}
                    applicationId={data.applicationId}
                    index={index}
                    modalToggle={modalToggle}
                    setSelectedApplication={setSelectedApplication}
                    sendMessage={async () => {
                      await setSelectedApplication(data.id)
                      if (singleAdData) {
                        const app = singleAdData.job_applications.find(
                          (a: JobApplicationType) => a.id === data.applicationId
                        )
                        if (app) {
                          setRecipients([
                            (app as JobApplicationType).member_profile.id
                          ])
                        }
                      }
                    }}
                  />
                ))}
                {provided.placeholder}
              </DroppableArea>
            ) : (
              <>
                <EmptyDroppableArea
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {provided.placeholder}
                  <DragToolTip> {t('drag-and-drop-ad')}</DragToolTip>
                </EmptyDroppableArea>
              </>
            )}
            {columnIndex === 0 ? (
              <RejectedApplications>
                <div className="title">{t('rejected-applications')}</div>
                <div className="line" />
                <RejectedApplication
                  rejectedApplications={rejectedApplications}
                  adId={adId}
                  modalToggle={modalToggle}
                  setSelectedApplication={setSelectedApplication}
                />
              </RejectedApplications>
            ) : null}
          </ApplicationColumn>
        )}
      </Droppable>
      <AddConversationModal
        advertisement={singleAdData}
        recipients={recipients}
        show={recipients.length > 0}
        onClose={() => setRecipients([])}
        organizationId={organizationId}
      />
    </>
  )
}

export default Column
