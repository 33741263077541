/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  deleteOrganizationUser,
  fetchPaginatedUsers
} from 'Features/UserManagement/userManagementSlice'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { RootState, useAppDispatch } from 'Store'
import SuperAdminUserPagination from 'Components/FrontendPagination/SuperAdminUserPagination'
import { OrganizationType } from 'Features/Organization/organizationSlice'
import DropdownMenu from 'Components/DropdownMenu'
import ObjectTableStyles from 'Components/ObjectTable'
import UserDeleteConfirmationModal from './UserDeleteConfirmationModal'
import AddNewUserModal from './AddNewUserModal'

const {
  ObjectTableBackground,
  ObjectTableContainer,
  ObjectTableTitle,
  ObjectTableTopComponent,
  ObjectTableBottomComponent,
  ObjectTableHeader,
  ObjectTableContent,
  ObjectTableItem,
  ObjectTableHeaderItem,
  ObjectTableBottomNewObjectDiv,
  ObjectTableSortCaretIcon
} = ObjectTableStyles

type UserProps = {
  username: string
  first_name: string
  last_name: string
  is_admin: boolean
  id: number
  organizations: Array<number>
}

const TopNewUser = styled.div`
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  display: flex;
  &:hover {
    cursor: pointer;
  }
  color: #252525;
  margin: 20px 0px;
  @media screen and ${props => props.theme.breakpoints.md} {
    margin: 0px;
  }
`
const NewUserPlusIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`
const StyledCheckbox = styled.div`
  color: #252525;
  font-size: 14px;
  font-family: Barlow;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  @media screen and ${props => props.theme.breakpoints.md} {
    margin: 0px;
  }
  .input {
    margin: 0px;
  }
`
const ObjectTableFooter = styled(ObjectTableStyles.ObjectTableFooter)`
  display: flex;
  justify-content: space-between;
  padding: 28px 20px 28px 20px;
`
const ObjectTableOptions = styled(ObjectTableStyles.ObjectTableOptions)`
  margin-left: auto;
`
const DeleteUser = styled.div`
  text-decoration: none;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
`
const TrashCanIcon = styled(FontAwesomeIcon)`
  color: #252525;
  margin: 0px 0px 0px 10px;
`
const ChangeView = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
  @media screen and ${props => props.theme.breakpoints.md} {
    margin: 0px;
  }
`
const CompanySelect = styled(Select)`
  width: 204px;
  margin-left: 5px;
`
const CompanySelectTitle = styled.div`
  font-family: Barlow;
  font-size: 14px;
`

const DataContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const SelectedData = styled.div`
  border-radius: 9.5px;
  background-color: #e7eaef;
  width: fit-content;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding: 5px 10px;
  font-weight: 500;
`
const TooltipBackground = styled.span`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip:hover {
    font-weight: 600;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
    color: #222;
    text-align: start;
    border: 1px solid #d6dce2;
    font-family: Barlow;
    padding: 5px 10px;
    border-radius: 3px;
    position: absolute;
    font-weight: 500;
    z-index: 1;
    margin-left: -10px;
    margin-bottom: 3px;
    top: 30px;
    transform: translateX(-100%);
  }

  .tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 15px;
    color: #222;
    right: 100%;
    margin-top: -10px;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`
const TooltipObject = styled.div`
  line-height: normal;
`

const OrganizationNameContainer = ({
  organizationNames
}: {
  organizationNames: Array<string> | undefined
}) => {
  const { t } = useTranslation()
  if (organizationNames) {
    return (
      <DataContainer>
        {organizationNames.length < 2 ? (
          organizationNames.map((name: string) => (
            <SelectedData key={name}>{name}</SelectedData>
          ))
        ) : (
          <TooltipBackground>
            <SelectedData className="tooltip">
              {t('multiple')}({organizationNames.length})
              <span className="tooltiptext">
                {organizationNames.map((name: string) => (
                  <TooltipObject key={name}>{name}</TooltipObject>
                ))}
              </span>
            </SelectedData>
          </TooltipBackground>
        )}
      </DataContainer>
    )
  }
  return null
}
type CompanyType = {
  itemIndex: number
  label: string
  value: number
}
const UserManagementComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [selectedCompany, setSelectedCompany] = useState<CompanyType | null>(
    null
  )
  const [selectedUser, setSelectedUser] = useState<number | undefined>(
    undefined
  )
  const [filterAdmins, setFilterAdmins] = useState(false)
  const companyInformation = useSelector((state: RootState) => state.admin)
  const { data } = useSelector((state: any) => state.organization)
  const { results } = data
  const { paginatedUserData, selectedFilters } = useSelector(
    (state: RootState) => state.userManagement
  )
  const organizationUsers = paginatedUserData
    ? paginatedUserData.map(
        ({
          username,
          first_name,
          last_name,
          id,
          is_admin,
          organizations
        }: UserProps) => ({
          username,
          first_name,
          last_name,
          is_admin,
          id,
          organizations
        })
      )
    : undefined
  const body = {
    page: 1,
    filters: {
      is_admin: filterAdmins,
      organization_id: selectedCompany?.value,
      orderBy: selectedFilters.orderBy
    }
  }

  const [newUserModalOpen, setNewUserModalOpen] = useState(false)
  const fetchPaginatedUserWithParams = async () => {
    await dispatch(fetchPaginatedUsers(body))
  }
  useEffect(() => {
    fetchPaginatedUserWithParams()
  }, [dispatch, selectedCompany, filterAdmins])

  const organizationName = (organizationIds: Array<number>) => {
    const organizationNames: Array<string> = []
    if (results) {
      organizationIds.map((id: number) => {
        const organization = results.find((o: OrganizationType) => o.id === id)
        organizationNames.push(organization?.name)
      })
      return organizationNames
    }
  }

  const [listOrder, setListOrder] = useState({
    first_name: 1,
    last_name: 1,
    username: 1
  })

  type ColumnType = 'first_name' | 'last_name' | 'username'

  const changeSort = (newSort: string) => {
    dispatch({
      type: 'manageUsers/changePage',
      payload: 1
    })
    dispatch({
      type: 'manageUsers/changeSortedBy',
      payload: newSort
    })
    const sortBody = {
      page: 1,
      filters: {
        is_admin: filterAdmins,
        organization_id: selectedCompany?.value,
        orderBy: newSort
      }
    }
    dispatch(fetchPaginatedUsers(sortBody))
  }

  const sortList = (column: ColumnType) => {
    setListOrder({ ...listOrder, [column]: listOrder[column] * -1 })
    if (listOrder[column] * -1 > 0) {
      changeSort(`${column}`)
    } else {
      changeSort(`-${column}`)
    }
  }

  type CaretProps = {
    columnName: ColumnType
  }

  const CaretIcon = ({ columnName }: CaretProps) => {
    if (selectedFilters.orderBy.includes(columnName)) {
      if (listOrder[columnName] === 1) {
        return <ObjectTableSortCaretIcon icon={['fas', 'caret-up']} />
      }
      return <ObjectTableSortCaretIcon icon={['fas', 'caret-down']} />
    }
    return null
  }

  const valueLabelPairs = results
    ? results.map(
        ({ id, name }: { id: number; name: string }, index: number) => ({
          value: id,
          label: name,
          itemIndex: index
        })
      )
    : undefined

  const Checkbox = () => {
    return (
      <StyledCheckbox onClick={() => setFilterAdmins(!filterAdmins)}>
        <input
          type="checkbox"
          checked={filterAdmins}
          onChange={() => setFilterAdmins(!filterAdmins)}
        />
        {t('show-only-admin-users')}
      </StyledCheckbox>
    )
  }

  const handleSelectChange = (value: CompanyType | null) => {
    let modifiedValue = value
    if (modifiedValue === null) {
      modifiedValue = null
    }
    setSelectedCompany(modifiedValue)
  }

  const SelectCompany = () => {
    return (
      <ChangeView>
        {valueLabelPairs && companyInformation ? (
          <>
            <CompanySelectTitle>
              {t('filter-by-organizations')}
            </CompanySelectTitle>
            <CompanySelect
              placeholder="Valitse..."
              value={selectedCompany}
              options={valueLabelPairs}
              isClearable
              onChange={(value: CompanyType | null) =>
                handleSelectChange(value)
              }
            />
          </>
        ) : null}
      </ChangeView>
    )
  }

  const newUser = () => {
    setSelectedUser(undefined)
    setNewUserModalOpen(true)
  }
  const modifyUser = (userId: number) => {
    setSelectedUser(userId)
    setNewUserModalOpen(true)
  }
  const userOptions = [
    {
      icon: 'pen',
      title: t('edit'),
      action: (objectId: number) => {
        modifyUser(objectId)
      }
    }
  ]
  const UserComponent = ({ user }: { user: UserProps }) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    return (
      <ObjectTableItem>
        {deleteModalOpen ? (
          <UserDeleteConfirmationModal
            modalToggle={setDeleteModalOpen}
            deleteUser={deleteOrganizationUser}
            userDetails={user}
            fetchPaginatedUserWithParams={fetchPaginatedUserWithParams}
          />
        ) : null}
        <ObjectTableHeaderItem>{user.username}</ObjectTableHeaderItem>
        <ObjectTableHeaderItem>{user.first_name}</ObjectTableHeaderItem>
        <ObjectTableHeaderItem>{user.last_name}</ObjectTableHeaderItem>
        {user.is_admin ? (
          <ObjectTableHeaderItem>{t('yes')}</ObjectTableHeaderItem>
        ) : (
          <ObjectTableHeaderItem>{t('no')}</ObjectTableHeaderItem>
        )}
        <ObjectTableHeaderItem>
          <OrganizationNameContainer
            organizationNames={organizationName(user.organizations)}
          />
        </ObjectTableHeaderItem>
        <ObjectTableOptions>
          <DeleteUser onClick={() => setDeleteModalOpen(true)}>
            {t('remove')}
            <TrashCanIcon icon={['far', 'trash']} />
          </DeleteUser>
          <DropdownMenu options={userOptions} objectId={user.id} />
        </ObjectTableOptions>
      </ObjectTableItem>
    )
  }
  const organizationUsersList = organizationUsers
    ? organizationUsers.map((user: UserProps) => {
        return <UserComponent key={user.id} user={user} />
      })
    : []

  return (
    <>
      {newUserModalOpen ? (
        <AddNewUserModal
          modalToggle={setNewUserModalOpen}
          valueLabelPairs={valueLabelPairs}
          userId={selectedUser}
          fetchPaginatedUserWithParams={fetchPaginatedUserWithParams}
        />
      ) : null}
      <ObjectTableBackground>
        <ObjectTableTopComponent>
          <ObjectTableTitle>{t('user-management')}</ObjectTableTitle>
          <Checkbox />
          <SelectCompany />
          <TopNewUser onClick={() => setNewUserModalOpen(true)}>
            <div> {t('add-new-user')}</div>
            <NewUserPlusIcon
              style={{ marginLeft: '8px' }}
              color="#0A4EA2"
              icon={['fal', 'plus-circle']}
            />
          </TopNewUser>
        </ObjectTableTopComponent>
        <ObjectTableBottomComponent>
          <ObjectTableContainer>
            <ObjectTableHeader>
              <ObjectTableHeaderItem onClick={() => sortList('username')}>
                {t('user')}
                <CaretIcon columnName="username" />
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem onClick={() => sortList('first_name')}>
                {t('first-name')} <CaretIcon columnName="first_name" />
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem onClick={() => sortList('last_name')}>
                {t('last-name')}
                <CaretIcon columnName="last_name" />
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>{t('super-admin')}</ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                {t('organizations')}
              </ObjectTableHeaderItem>
            </ObjectTableHeader>
            <ObjectTableContent>{organizationUsersList}</ObjectTableContent>
            <ObjectTableFooter>
              <ObjectTableBottomNewObjectDiv onClick={() => newUser()}>
                {t('add-new-user')}
                <NewUserPlusIcon
                  style={{ marginTop: '14px' }}
                  color="#0A4EA2"
                  icon={['fal', 'plus-circle']}
                />
              </ObjectTableBottomNewObjectDiv>
              <SuperAdminUserPagination
                organizationId={selectedCompany?.value}
                isAdmin={filterAdmins}
              />
            </ObjectTableFooter>
          </ObjectTableContainer>
        </ObjectTableBottomComponent>
      </ObjectTableBackground>
    </>
  )
}

export default UserManagementComponent
