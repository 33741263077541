import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import ProfileDetails from 'Components/AdStatusBoard/ModalComponents/ProfileDetails'
import { RootState, useAppDispatch } from 'Store'
import {
  fetchMemberProfileById,
  Locations,
  ProfessionalTitles,
  Competences,
  LanguageSkills
} from 'Features/MemberProfile/memberProfileSlice'
import { DrivingLicenseType } from 'Features/DrivingLicense/drivingLicenseSlice'
import { useSelector } from 'react-redux'
import ProfileFeatures from 'Components/AdStatusBoard/ModalComponents/ProfileFeatures'
import InformationList from 'Components/AdStatusBoard/ModalComponents/InformationList'
import WorkExperienceItem from 'Components/AdStatusBoard/ModalComponents/WorkExperienceItem'
import ApplicantDetails from 'Components/AdStatusBoard/ModalComponents/ApplicantDetails'
import EducationItem from 'Components/AdStatusBoard/ModalComponents/EducationItem'
import RecommendersItem from 'Components/AdStatusBoard/ModalComponents/RecommendersItem'

const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;

  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${props => props.theme.breakpoints.lg} {
    width: 60%;
  }
`
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`
const CloseButtonContainer = styled.div`
  display: flex;
  margin: 35px;
  justify-content: flex-end;
`
const CloseButton = styled.div`
  height: 37px;
  width: 136px;
  border-radius: 6px;
  background-color: #d60c17;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
`
const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`
const ModalBackground = styled.div`
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  @media screen and ${props => props.theme.breakpoints.lg} {
    width: 60%;
  }
`
const TopBar = styled.div`
  height: 37px;
  background-color: #d60c17;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    text-align: center;
    color: #ffffff;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`
const InformationPanel = styled.div`
  margin-right: 108px;
  margin-left: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  @media screen and ${props => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`

const ApplicationDetailsModal = ({
  selectedUser,
  modalToggle
}: {
  selectedUser: any
  modalToggle: any
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchMemberProfileById(selectedUser.id))
  }, [dispatch])
  const { singleProfileData } = useSelector(
    (state: RootState) => state.memberProfile
  )
  const languageProficiencies = [t('basics'), t('good')]
  const locations = singleProfileData
    ? singleProfileData.locations.map((location: Locations) => {
        return location.name
      })
    : []
  const additionalInfo = singleProfileData
    ? singleProfileData.additional_info
    : ''
  let professionalTitles = singleProfileData
    ? singleProfileData.professional_titles.map(
        (professionalTitle: ProfessionalTitles) => {
          return professionalTitle.title
        }
      )
    : []
  professionalTitles = professionalTitles.filter(
    (v, i) => professionalTitles.indexOf(v) === i
  )
  const competences = singleProfileData
    ? singleProfileData.competences.map((competence: Competences) => {
        return competence.title
      })
    : []
  const languages = singleProfileData
    ? singleProfileData.language_skills.map((language: LanguageSkills) => {
        return `${language.language.full_name} - ${
          languageProficiencies[language.level_of_competence]
        }`
      })
    : []
  const drivingLicenses = singleProfileData
    ? singleProfileData.driving_licenses.map(
        (drivingLicense: DrivingLicenseType) => {
          return drivingLicense.license_type
        }
      )
    : []
  const recommenders = singleProfileData ? singleProfileData.recommenders : []
  const experiences = singleProfileData ? singleProfileData.job_experiences : []
  const educations = singleProfileData ? singleProfileData.educations : []
  const userDetails = singleProfileData
    ? singleProfileData.member_contacts.find(c => c.user_details !== null)
        ?.user_details
    : null

  return (
    <TransparentBackground>
      <CloseContainer>
        <TopCloseButton onClick={() => modalToggle(false)}>
          <div>{t('close')}</div>
          <CloseIcon icon={['fal', 'times-circle']} />
        </TopCloseButton>
      </CloseContainer>
      <ModalBackground>
        <TopBar>
          <span>
            {userDetails
              ? `${userDetails.first_name} ${userDetails.last_name}`
              : `${t('profile')} - #${selectedUser.id}`}
          </span>
        </TopBar>
        <ModalContent>
          <InformationPanel>
            <ProfileDetails
              applicantName={
                userDetails
                  ? `${userDetails.first_name} ${userDetails.last_name}`
                  : t('profile')
              }
              applicantId={selectedUser.id}
              anonymous={!userDetails}
              additionalInfo={
                userDetails
                  ? {
                      phone: userDetails.phone,
                      email: userDetails.email
                    }
                  : undefined
              }
            />
          </InformationPanel>
          <InformationPanel>
            <ProfileFeatures title="working-areas" data={locations} />
          </InformationPanel>
          <InformationPanel>
            <ApplicantDetails additionalInfo={additionalInfo} />
          </InformationPanel>
          <InformationPanel>
            <ProfileFeatures
              title="professional-titles"
              data={professionalTitles}
            />
            <ProfileFeatures title="competences" data={competences} />
            <ProfileFeatures
              title="driving-license-types"
              data={drivingLicenses}
            />
            <ProfileFeatures
              title="language-competence"
              simpleList
              data={languages}
            />
          </InformationPanel>
          <InformationList listTitle="work-experience" />
          <WorkExperienceItem experiences={experiences} />
          <InformationList listTitle="education" />
          <EducationItem educations={educations} />
          <InformationList listTitle="recommenders" />
          <RecommendersItem
            recommenders={recommenders}
            anonymous={!userDetails}
          />
        </ModalContent>
        <CloseButtonContainer>
          <CloseButton onClick={() => modalToggle(false)}>
            {t('close')}
          </CloseButton>
        </CloseButtonContainer>
      </ModalBackground>
    </TransparentBackground>
  )
}

export default ApplicationDetailsModal
