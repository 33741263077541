/* eslint-disable camelcase */
import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import {
  FetchPaginatedEmployeesResponse,
  MemberProfileProfessionalTitle,
  MemberProfileLocation
} from 'Features/MemberProfile/memberProfileSlice'
import { DrivingLicenseType } from 'Features/DrivingLicense/drivingLicenseSlice'
import DropdownMenu from 'Components/DropdownMenu'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'Store'
import { MemberSearchPagination } from 'Components/FrontendPagination'
import ObjectTableStyles from 'Components/ObjectTable'
import DeleteEmployeeModal from './DeleteEmployeeModal'
import EmployeeDetailsModal from './EmployeeDetailsModal'
import { SurveyForm } from '../ModifyMemberProfile'

const {
  ObjectTableBackground,
  ObjectTableContainer,
  ObjectTableTitle,
  ObjectTableTopComponent,
  ObjectTableBottomComponent,
  ObjectTableHeader,
  ObjectTableContent,
  ObjectTableItem,
  ObjectTableHeaderItem,
  ObjectTableSortCaretIcon,
  ObjectTableTop,
  ObjectTableObjectTitle,
  ObjectTableAngleRightIcon
} = ObjectTableStyles

const ObjectTableHeaderOptionsBlock = styled(
  ObjectTableStyles.ObjectTableHeaderOptionsBlock
)`
  width: 15%;
`
const ObjectTableOptions = styled(ObjectTableStyles.ObjectTableOptions)`
  width: 15%;
`
const CustomLink = styled.div`
  text-decoration: none;
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 14px;
  &:hover {
    cursor: pointer;
    font-weight: 600;
    svg {
      font-size: 1.2em;
    }
  }
`
const SelectedData = styled.div`
  border-radius: 9.5px;
  background-color: #e7eaef;
  width: fit-content;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding: 5px 10px;
  font-weight: 500;
`
const DataContainer = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const TooltipBackground = styled.span`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip:hover {
    font-weight: 600;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
    color: #222;
    text-align: start;
    border: 1px solid #d6dce2;
    font-family: Barlow;
    padding: 5px 10px;
    border-radius: 3px;
    position: absolute;
    font-weight: 500;
    z-index: 1;
    margin-left: -10px;
    margin-bottom: 3px;
    top: 30px;
    transform: translateX(-100%);
  }

  .tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 15px;
    color: #222;
    right: 100%;
    margin-top: -10px;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`
const TooltipObject = styled.div`
  line-height: normal;
`
const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`
const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;

  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${props => props.theme.breakpoints.lg} {
    width: 60%;
    height: 60%;
  }
`
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`

const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`
const EmployeeList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteEmployee, setShowDeleteEmployee] = useState(false)
  const [recipientId, setRecipientId] = useState<number>()
  const [currentFormIndex, setCurrentFormIndex] = React.useState<number>(0)
  const { filteredProfiles, orderBy } = useSelector(
    (state: RootState) => state.memberProfile
  )
  const { data } = filteredProfiles
  const openUserDetailsModal = (userProfile: any) => {
    setRecipientId(userProfile)
    setShowModal(true)
  }
  const openUserModal = (userProfile: any) => {
    setRecipientId(userProfile)
    setShowEditModal(true)
  }
  const openDeleteModal = (employeeId: number) => {
    setRecipientId(employeeId)
    setShowDeleteEmployee(true)
  }
  const [listOrder, setListOrder] = useState({
    full_name: 1
  })

  type ColumnType = 'full_name'

  const changeSort = (newSort: string) => {
    dispatch({
      type: 'memberProfile/changeSortedBy',
      payload: newSort
    })
  }

  const sortList = (column: ColumnType) => {
    setListOrder({ ...listOrder, [column]: listOrder[column] * -1 })
    if (listOrder[column] * -1 > 0) {
      changeSort(`${column}`)
    } else {
      changeSort(`-${column}`)
    }
  }

  type CaretProps = {
    columnName: ColumnType
  }

  const CaretIcon = ({ columnName }: CaretProps) => {
    if (orderBy.includes(columnName)) {
      if (listOrder[columnName] === 1) {
        return <ObjectTableSortCaretIcon icon={['fas', 'caret-up']} />
      }
      return <ObjectTableSortCaretIcon icon={['fas', 'caret-down']} />
    }
    return null
  }

  const profileOptions = [
    {
      icon: 'pen',
      title: t('modify-profile'),
      action: (objectId: number) => {
        openUserModal(objectId)
      }
    },
    {
      icon: 'trash',
      title: t('remove'),
      action: (objectId: number) => {
        openDeleteModal(objectId)
      }
    }
  ]
  const renderJobTitle = (memberProfile: FetchPaginatedEmployeesResponse) => {
    if (memberProfile && memberProfile.full_name) {
      return `${memberProfile.full_name}`
    }
    return `${t('profile')}#${memberProfile.id}`
  }
  const memberProfiles = data.results.map(
    (memberProfile: FetchPaginatedEmployeesResponse) => {
      return (
        <ObjectTableItem key={memberProfile.id}>
          <ObjectTableObjectTitle>
            {renderJobTitle(memberProfile)}
          </ObjectTableObjectTitle>
          <DataContainer>
            {memberProfile.location_count < 2 ? (
              memberProfile.locations.map((location: MemberProfileLocation) => (
                <SelectedData key={location.id}>{location.name}</SelectedData>
              ))
            ) : (
              <TooltipBackground>
                <SelectedData className="tooltip">
                  {t('multiple')}({memberProfile.location_count})
                  <span className="tooltiptext">
                    {memberProfile.locations.map(
                      (location: MemberProfileLocation) => (
                        <TooltipObject key={location.id}>
                          {location.name}
                        </TooltipObject>
                      )
                    )}
                  </span>
                </SelectedData>
              </TooltipBackground>
            )}
          </DataContainer>
          <DataContainer>
            {memberProfile.professional_title_group_count < 2 ? (
              <>
                {memberProfile.professional_titles[0] ? (
                  <SelectedData>
                    {
                      memberProfile.professional_titles[0]
                        .professional_title_group.title
                    }
                  </SelectedData>
                ) : null}
              </>
            ) : (
              <TooltipBackground>
                <SelectedData className="tooltip">
                  {t('multiple')}({memberProfile.professional_title_group_count}
                  )
                  <span className="tooltiptext">
                    {memberProfile.professional_titles.map(
                      (professionalTitle: MemberProfileProfessionalTitle) => (
                        <TooltipObject key={professionalTitle.id}>
                          {professionalTitle.professional_title_group.title}
                        </TooltipObject>
                      )
                    )}
                  </span>
                </SelectedData>
              </TooltipBackground>
            )}
          </DataContainer>
          <DataContainer>
            {memberProfile.professional_title_count < 2 ? (
              memberProfile.professional_titles.map(
                (professionalTitle: MemberProfileProfessionalTitle) => (
                  <SelectedData key={professionalTitle.id}>
                    {professionalTitle.title}
                  </SelectedData>
                )
              )
            ) : (
              <TooltipBackground>
                <SelectedData className="tooltip">
                  {t('multiple')}({memberProfile.professional_title_count})
                  <span className="tooltiptext">
                    {memberProfile.professional_titles.map(
                      (professionalTitle: MemberProfileProfessionalTitle) => (
                        <TooltipObject key={professionalTitle.id}>
                          {professionalTitle.title}
                        </TooltipObject>
                      )
                    )}
                  </span>
                </SelectedData>
              </TooltipBackground>
            )}
          </DataContainer>
          <DataContainer>
            {memberProfile.driving_license_count < 2 ? (
              memberProfile.driving_licenses.map(
                (drivingLicense: DrivingLicenseType) => (
                  <SelectedData key={drivingLicense.id}>
                    {drivingLicense.license_type}
                  </SelectedData>
                )
              )
            ) : (
              <TooltipBackground>
                <SelectedData className="tooltip">
                  {t('multiple')}({memberProfile.driving_license_count})
                  <span className="tooltiptext">
                    {memberProfile.driving_licenses.map(
                      (drivingLicense: DrivingLicenseType) => (
                        <TooltipObject key={drivingLicense.id}>
                          {drivingLicense.license_type}
                        </TooltipObject>
                      )
                    )}
                  </span>
                </SelectedData>
              </TooltipBackground>
            )}
          </DataContainer>

          <ObjectTableHeaderItem>
            {memberProfile.recommender_count} {t('pcs')}
          </ObjectTableHeaderItem>
          <ObjectTableOptions>
            <CustomLink onClick={() => openUserDetailsModal(memberProfile)}>
              {t('show-user-details')}
              <ObjectTableAngleRightIcon icon={['far', 'angle-right']} />
            </CustomLink>
            <DropdownMenu
              options={profileOptions}
              objectId={memberProfile.id}
            />
          </ObjectTableOptions>
        </ObjectTableItem>
      )
    }
  )
  return (
    <>
      {showEditModal ? (
        <TransparentBackground>
          <CloseContainer>
            <TopCloseButton onClick={() => setShowEditModal(false)}>
              <div>{t('close')}</div>
              <CloseIcon icon={['fal', 'times-circle']} />
            </TopCloseButton>
          </CloseContainer>
          <ModalContent>
            {recipientId ? (
              <SurveyForm
                modalToggle={setShowEditModal}
                formIndex={currentFormIndex}
                onFormIndexChange={(index: number) =>
                  setCurrentFormIndex(index)
                }
                employeeId={recipientId}
              />
            ) : null}
          </ModalContent>
        </TransparentBackground>
      ) : null}
      {showModal ? (
        <EmployeeDetailsModal
          selectedUser={recipientId}
          modalToggle={setShowModal}
        />
      ) : null}
      {showDeleteEmployee && recipientId ? (
        <DeleteEmployeeModal
          modalToggle={setShowDeleteEmployee}
          employeeId={recipientId}
        />
      ) : null}
      <ObjectTableBackground>
        <ObjectTableTopComponent>
          <ObjectTableTop>
            <ObjectTableTitle>{t('search-results')}:</ObjectTableTitle>
          </ObjectTableTop>
        </ObjectTableTopComponent>
        <ObjectTableBottomComponent>
          <ObjectTableContainer>
            <ObjectTableHeader>
              <ObjectTableHeaderItem onClick={() => sortList('full_name')}>
                {t('user')}
                <CaretIcon columnName="full_name" />
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>{t('working-area')}</ObjectTableHeaderItem>
              <ObjectTableHeaderItem>{t('field')}</ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                {t('professional-titles')}
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                {t('driving-license')}
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>{t('recommenders')}</ObjectTableHeaderItem>
              <ObjectTableHeaderOptionsBlock />
            </ObjectTableHeader>
            <ObjectTableContent>{memberProfiles}</ObjectTableContent>
          </ObjectTableContainer>
          <MemberSearchPagination />
        </ObjectTableBottomComponent>
      </ObjectTableBackground>
    </>
  )
}
export default EmployeeList
