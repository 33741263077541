/* eslint-disable camelcase */
import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { patchSingleUser } from 'Features/UserManagement/userManagementSlice'
import { toast } from 'react-toastify'
import { RootState, useAppDispatch } from 'Store'
import { checkActiveSession } from 'Features/Auth/authSlice'

const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;

  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${props => props.theme.breakpoints.lg} {
    width: 60%;
    height: 60%;
  }
`
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`
const CloseButtonContainer = styled.div`
  display: flex;
  margin: 35px;
  justify-content: flex-end;
`
const CancelButton = styled.div`
  height: 37px;
  width: 175px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: Barlow;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  color: #bcc2c6;
`
const AddUserButtom = styled(CancelButton)`
  margin-left: 20px;
  background-color: #d60c17;
  color: #ffffff;
`
const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`
const ModalBackground = styled.div`
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  @media screen and ${props => props.theme.breakpoints.lg} {
    width: 60%;
  }
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  label {
    color: #252525;
    font-weight: 600;
    font-family: Barlow;
    font-size: 14px;
    margin-bottom: 10px;
  }
  span {
    color: red;
  }
  .form-select {
    border: 1px solid #d6dce2;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;
  }
`
const Title = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 20px;
  margin-bottom: 30px;
`

const NewUserForm = styled.form`
  display: flex;
  flex-direction: column;
`

const ChangeEmailModal = ({
  modalToggle
}: {
  modalToggle: (arg0: boolean) => void
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { user } = useSelector((state: RootState) => state.auth)
  const [email, setEmail] = useState(user.email || '')

  const handleSubmission = () => {
    if (user && user.id !== null) {
      const newDetails = {
        id: user.id,
        email
      }
      dispatch(patchSingleUser(newDetails)).then(({ error }: any) => {
        if (error) {
          const errorObj: {
            [key: string]: { [key: string]: any }
          } = JSON.parse(error.message)
          const errors: Array<string> = []
          Object.entries(errorObj).map(([key, value]) => {
            const errorKey = `${key}`
            errors.push(`${t(errorKey)} - ${value}`)
          })
          // eslint-disable-next-line no-undef
          toast.error(
            <>
              <strong>{t('fill-missing-fields')}:</strong>
              {errors.map((e: string) => (
                <p key={e}>
                  <strong>{e.split(' . ')[0]}</strong>
                </p>
              ))}
            </>
          )
        } else {
          dispatch(checkActiveSession()).then(() => modalToggle(false))
        }
      })
    }
  }
  return (
    <TransparentBackground>
      <CloseContainer>
        <TopCloseButton onClick={() => modalToggle(false)}>
          <div>{t('close')}</div>
          <CloseIcon icon={['fal', 'times-circle']} />
        </TopCloseButton>
      </CloseContainer>
      <ModalBackground>
        <ModalContent>
          <Title> {t('change-email')}</Title>
          <NewUserForm>
            <label>
              {t('set-new-email')} <span>*</span>
            </label>
            <input
              className="form-select"
              size={30}
              value={email}
              placeholder={t('email')}
              onChange={e => setEmail(e.target.value)}
            />
          </NewUserForm>
        </ModalContent>
        <CloseButtonContainer>
          <CancelButton onClick={() => modalToggle(false)}>
            {t('cancel')}
          </CancelButton>
          <AddUserButtom onClick={() => handleSubmission()}>
            {t('change-email')}
          </AddUserButtom>
        </CloseButtonContainer>
      </ModalBackground>
    </TransparentBackground>
  )
}

export default ChangeEmailModal
