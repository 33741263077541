/* eslint-disable no-console */
import React from 'react'
import styled from 'styled-components'
import { SuperAdminLayout } from 'Components/Layout/SuperAdminLayout'
import SuperAdminUserManagementComponent from 'Components/SuperAdminPage/OrganizationManagement/OrganizationDetails'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`

const SuperAdminUserManagement = (props: any) => {
  const { computedMatch } = props
  return (
    <SuperAdminLayout>
      <ContentContainer>
        <SuperAdminUserManagementComponent
          organizationId={computedMatch.params.organizationId}
        />
      </ContentContainer>
    </SuperAdminLayout>
  )
}
export default SuperAdminUserManagement
