/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppDispatch, RootState, useAppDispatch } from 'Store'
import { fetchJobApplications } from 'Features/JobApplication/jobApplicationSlice'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import { parseISO } from 'date-fns'
import { format } from 'date-fns/esm'

const ApplicationInfo = styled.div`
  display: flex;
  width: 100%;
`
const ApplicationInfoTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
`
const ElementIcon = styled(FontAwesomeIcon)`
  color: #a8aeb4;
  margin-right: 4px;
  min-width: 20px;
  max-width: 20px;
`
const AngleRightIcon = styled(FontAwesomeIcon)`
  color: #0a4ea2;
  margin: 0px 0px 0px 10px;
  font-size: 0.8em;
`
const IconContainer = styled.div`
  min-width: 40px;
  padding: 5px;
`
const ApplicationDetailsContainer = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  display: flex;
`
const ApplicationDetailsComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`
const ApplicantDetailsParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const ApplicantDetails = styled.div`
  color: ${(props: { receivedApplications: boolean }) =>
    props.receivedApplications ? '#0a4ea2' : '#333'};
  font-family: Barlow;
  font-size: 14px;
  font-weight: ${(props: { receivedApplications: boolean }) =>
    props.receivedApplications ? 600 : 300};
`
const ArrivalDate = styled.div`
  color: #2a2a2a;
  font-family: Barlow;
  font-size: 12px;
`
const OpenApplicationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  white-space: pre;
`
const OpenApplicationButton = styled.div`
  text-decoration: none;
  color: #0a4ea2;
  text-align: end;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
  }
`
const openApplication = (
  history: History<unknown> | string[],
  dispatch: AppDispatch,
  applicationId: number | undefined,
  adId: number | undefined
) => {
  dispatch({
    type: 'jobApplication/changeSelectedApplication',
    payload: applicationId
  })
  history.push(`/admin-panel/ilmoitukset/${adId}`)
}

const ApplicationInformation = () => {
  const history = useHistory()

  const dispatch = useAppDispatch()
  const { itemIndex } = useSelector((state: RootState) => state.admin)
  const {
    data: { results: organizationResults }
  } = useSelector((state: RootState) => state.organization)
  const organizationId =
    organizationResults && typeof itemIndex === 'number'
      ? organizationResults[itemIndex]?.id
      : null
  useEffect(() => {
    if (!organizationId) {
      return
    }
    dispatch(fetchJobApplications({ organization_id: organizationId }))
  }, [dispatch, organizationId])
  const {
    list: { results }
  } = useSelector((state: RootState) => state.jobApplication)
  const latestApplicationUser = results[0]
    ? results[0].member_profile.user
    : undefined

  const adTitle = results[0] ? results[0].job_advertisement_title : undefined
  const adId = results[0] ? results[0].job_advertisement : undefined
  const applicationId = results[0] ? results[0].id : undefined

  const { t } = useTranslation()
  return (
    <ApplicationInfo>
      <IconContainer>
        <ElementIcon icon={['far', 'briefcase']} />
      </IconContainer>
      <ApplicationDetailsContainer>
        <ApplicationDetailsComponent>
          <ApplicationInfoTitle>
            {t('latest-applications')}
          </ApplicationInfoTitle>
          <ApplicantDetailsParent>
            <ApplicantDetails
              receivedApplications={typeof results[0] !== 'undefined'}
            >
              {latestApplicationUser
                ? `${latestApplicationUser.first_name} ${latestApplicationUser.last_name}`
                : t('no-applications-received')}
            </ApplicantDetails>
            {results[0] ? (
              <ArrivalDate>
                {t('received')}{' '}
                {format(parseISO(results[0].created_at), 'dd.MM.yyyy')}
              </ArrivalDate>
            ) : null}
          </ApplicantDetailsParent>
        </ApplicationDetailsComponent>
      </ApplicationDetailsContainer>
      {results[0] ? (
        <>
          <ApplicationDetailsContainer>
            <ApplicationDetailsComponent>
              <ApplicationInfoTitle>{t('ad')}</ApplicationInfoTitle>
              <ApplicantDetailsParent>
                <ApplicantDetails
                  receivedApplications={typeof results[0] !== 'undefined'}
                >
                  {adTitle}
                </ApplicantDetails>
              </ApplicantDetailsParent>
            </ApplicationDetailsComponent>
          </ApplicationDetailsContainer>

          <OpenApplicationContainer>
            <OpenApplicationButton
              onClick={() =>
                openApplication(history, dispatch, applicationId, adId)
              }
            >
              <ElementIcon icon={['far', 'file-alt']} />
              {t('open-application')}
              <AngleRightIcon icon={['far', 'angle-right']} />
            </OpenApplicationButton>
          </OpenApplicationContainer>
        </>
      ) : null}
    </ApplicationInfo>
  )
}

export default ApplicationInformation
