import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationFI from 'Assets/Locales/fi/translation.json'

const resources = {
  fi: {
    translation: translationFI
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'fi',
  fallbackLng: 'fi',

  interpolation: {
    escapeValue: false
  }
})
