/* eslint-disable no-console */
import React from 'react'
import styled from 'styled-components'
import EducationLayout from 'Components/Layout/EducationLayout'
import NewTraining from 'Components/EducationPage/NewTraining/NewTraining'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`

const ModifyTrainingAd = (props: any) => {
  const { computedMatch } = props
  return (
    <EducationLayout>
      <ContentContainer>
        <NewTraining adId={computedMatch.params.adId} />
      </ContentContainer>
    </EducationLayout>
  )
}
export default ModifyTrainingAd
