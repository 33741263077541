import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { fetchLocations } from 'Features/NewAdvertisement/newAdvertisementSlice'
import { useHistory } from 'react-router-dom'
import { RootState, store, useAppDispatch } from 'Store'
import { toast } from 'react-toastify'
import {
  createNewTraining,
  createNewTrainingLocation,
  CreateNewTrainingRequest,
  modifyTraining
} from 'Features/Training/trainingSlice'
import { useSelector } from 'react-redux'
import { ImageType } from 'Features/ImageBank/imageBankSlice'
import { NewTrainingFormProps } from 'Components/EducationPage/NewTraining/CommonTrainingCreationProps'
import FormFields from './FormFields'
import FormTabs from './FormTabs'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const TitleArea = styled.div`
  display: flex;
  align-items: center;
  h3 {
    color: #252525;
    font-family: Barlow;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 29px;
    font-weight: 200;
    margin: 0 0 0 15px;
  }
  span {
    border-radius: 6px;
    background-color: #d60c17;
    color: #ffffff;
    font-family: Barlow;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    padding: 8px 15px;
    font-weight: 600;
    white-space: nowrap;
  }
`
const Form = styled.div`
  background: #fff;
  flex-grow: 1;
  height: 100%;
  margin-top: 10px;
`

const NewTrainingForm = ({
  companyId,
  companyLogo,
  companyName,
  editorState,
  setEditorState,
  formIndex,
  onFormIndexChange,
  adId
}: NewTrainingFormProps) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    dispatch(fetchLocations())
  }, [dispatch])
  const { t } = useTranslation()
  const { values } = useSelector((state: RootState) => state.training)

  const displayError = (data: any, additionalError?: string) => {
    const errorObj: { [key: string]: { [key: string]: any } } = JSON.parse(
      data.message
    )
    const errors: Array<string> = []
    Object.keys(errorObj).map((k: string) => {
      errors.push(`${t(k)}`)
    })
    if (additionalError) {
      errors.push(additionalError)
    }
    toast.error(
      <>
        <strong>{t('fill-missing-fields')}:</strong>
        {errors.map((e: string) => (
          <p key={e}>
            <strong>{e.split(' - ')[0]}</strong>
          </p>
        ))}
      </>
    )
  }
  const renderTitle = () => {
    if (adId) {
      return t('modify-ad')
    }
    return t('training-title-section')
  }
  const formatDate = (dateType: String) => {
    try {
      switch (dateType) {
        case 'endDate':
          return values.end_time
        case 'startDate':
          return values.start_time
        default:
          break
      }
    } catch (e) {
      return ''
    }
    return ''
  }
  const formatImageId = (image: Array<ImageType> | undefined) => {
    if (image) {
      const imageIdArray: number[] = image.map(i => {
        return i.id
      })
      return imageIdArray
    }
    return undefined
  }

  const trainingCreation = async (newLocationResponse?: any) => {
    const body: CreateNewTrainingRequest = {
      training_type: values.training_type
        ? values.training_type.type
        : undefined,
      subject: values.subject,
      description: values.description,
      start_time: formatDate('startDate'),
      end_time: values.continuous_training
        ? values.end_time
        : formatDate('endDate'),
      continuous_training: values.continuous_training,
      enrollment_deadline: values.enrollment_deadline,
      price: values.price ? parseFloat(values.price).toFixed(2) : undefined,
      member_price: values.member_price
        ? parseFloat(values.member_price).toFixed(2)
        : undefined,
      target_audience: values.target_audience,
      images: formatImageId(values.images),
      trainer: values.trainer,
      web_link: values.web_link,
      only_for_members: values.only_for_members
        ? values.only_for_members.value
        : undefined,
      organization: companyId,
      training_location: newLocationResponse?.payload.data.id || undefined
    }
    if (adId) {
      const newTrainingResponse = await dispatch(
        modifyTraining({ body, id: parseInt(adId, 10) })
      )
      if (newTrainingResponse.error) {
        displayError(newTrainingResponse.error)
      } else {
        store.dispatch({ type: 'training/resetNewAd' })
        history.push('/super-admin-panel/trainings')
      }
    } else {
      const modifyTrainingResponse = await dispatch(createNewTraining(body))
      if (modifyTrainingResponse.error) {
        displayError(modifyTrainingResponse.error)
      } else {
        store.dispatch({ type: 'training/resetNewAd' })
        history.push('/super-admin-panel/trainings')
      }
    }
  }

  const createTraining = async () => {
    if (values?.training_type?.type === 'web') {
      trainingCreation()
    } else if (values.address && values.locations) {
      await dispatch(
        createNewTrainingLocation({
          address: values.address,
          location: values.locations.id
        })
      ).then((newLocationResponse: any) => {
        if (newLocationResponse.payload.status) {
          displayError(newLocationResponse.payload.data)
        } else {
          trainingCreation(newLocationResponse)
        }
      })
    }
  }
  return (
    <FormContainer>
      <TitleArea>
        <span>{formIndex + 1} / 4</span>
        <h3>{renderTitle()}</h3>
      </TitleArea>
      <Form>
        <FormTabs
          currentTab={formIndex}
          onTabChange={(index: number) => onFormIndexChange(index)}
        />
        <FormFields
          currentTab={formIndex}
          editorState={editorState}
          setEditorState={setEditorState}
          companyLogo={companyLogo}
          companyName={companyName}
          nextForm={() => onFormIndexChange(formIndex + 1)}
          createTraining={createTraining}
          adId={adId}
        />
      </Form>
    </FormContainer>
  )
}

export default NewTrainingForm
