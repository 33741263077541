import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import JobBackground from 'Assets/job-background.jpg'
import { useTranslation } from 'react-i18next'
import MemberProfile from 'Components/MemberProfile'
import { useDispatch } from 'react-redux'
import { checkActiveSession } from 'Features/Auth/authSlice'
import ChangeEmailModal from './ChangeEmailModal'
import DeleteMemberModal from './DeleteMemberProfile'

const UserProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  .background {
    background: url('${JobBackground}');
    background-color: #ddd;
    background-attachment: fixed;
    background-position: center 80%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-settings-container {
    background: #fff;
    .profile-settings {
      padding: 46px 25px 76px 25px;
      display: flex;
      max-width: 1440px;
      width: 100%;
      margin: auto;
      flex-direction: column;
      @media screen and ${props => props.theme.breakpoints.lg} {
        flex-direction: row;
      }
      .profile-management {
        width: 100%;
        @media screen and ${props => props.theme.breakpoints.lg} {
          width: 50%;
        }
        h2 {
          margin-top: 0;
          color: #252525;
          font-family: Barlow;
          font-size: 24px;
          letter-spacing: 0;
          line-height: 29px;
          font-weight: 400;
        }
      }
      .settings {
        .modal-button {
          color: #0a4ea2;
          font-family: Barlow;
          font-size: 14px;
          letter-spacing: 0;
          text-decoration: none;
          margin-bottom: 20px;
          &:hover {
            cursor: pointer;
          }
        }
        display: flex;
        width: 100%;
        padding-top: 50px;
        @media screen and ${props => props.theme.breakpoints.lg} {
          width: 50%;
          padding-top: 0;
        }
        flex-direction: column;
        @media screen and ${props => props.theme.breakpoints.lg} {
          margin-left: 60px;
        }
        h3 {
          color: #252525;
          font-family: Barlow;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 22px;
          font-weight: 500;
          margin-top: 0;
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #d6dce2;
        }
        a {
          color: #0a4ea2;
          font-family: Barlow;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 17px;
          text-decoration: none;
          padding-bottom: 20px;
        }
      }
    }
  }
`

const UserProfile = () => {
  const { t } = useTranslation()
  const [changeEmailModal, setChangeEmailModal] = useState(false)
  const [deleteMemberProfileModal, setDeleteMemberProfileModal] = useState(
    false
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(checkActiveSession())
  }, [])
  return (
    <UserProfileContainer>
      <div className="background" />
      <div className="profile-settings-container">
        <div className="profile-settings">
          <div className="profile-management">
            <h2>{t('profile-management')}</h2>
            <MemberProfile columnStyle />
          </div>
          <div className="settings">
            {changeEmailModal ? (
              <ChangeEmailModal modalToggle={setChangeEmailModal} />
            ) : null}
            {deleteMemberProfileModal ? (
              <DeleteMemberModal modalToggle={setDeleteMemberProfileModal} />
            ) : null}
            <h3>{t('settings')}</h3>
            <div
              className="modal-button"
              onClick={() => setChangeEmailModal(true)}
            >
              {t('change-email')}
            </div>
            <div
              className="modal-button"
              onClick={() => setDeleteMemberProfileModal(true)}
            >
              {t('delete-user-profile')}
            </div>
          </div>
        </div>
      </div>
    </UserProfileContainer>
  )
}

export default UserProfile
