/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAxiosInstance } from 'Api'

interface OfficeState {
  values: object
  locations: Array<LocationsType>
}
const initialState: OfficeState = {
  values: {},
  locations: []
}
type LocationsType = {
  id: number
  name: string
  parent_id: number | null
}
export type NewOfficeRequestType = {
  name: string
  address: string
  phone_number: string
  email: string
  is_main_office: boolean
  organization: number
  location: number | undefined
}
type CreateNewOfficeRequest = {
  body: NewOfficeRequestType
}

const createNewOffice = createAsyncThunk<unknown, CreateNewOfficeRequest>(
  'office/createNewOffice',
  async ({ body }: CreateNewOfficeRequest) => {
    try {
      const response = await getAxiosInstance().post(`/office/`, body)
      return { data: response.data }
    } catch (err) {
      throw Error(JSON.stringify(err.response.data))
    }
  }
)
type DeleteOfficeRequest = number
const deleteOffice = createAsyncThunk<unknown, DeleteOfficeRequest>(
  'office/deleteOffice',
  async (id: DeleteOfficeRequest) => {
    const response = await getAxiosInstance().delete(`/office/${id}/`)
    return { data: response.data }
  }
)
type PatchOfficeRequestType = {
  name?: string
  address?: string
  phone_number?: string
  email?: string
  is_main_office?: boolean
  organization?: number
  location?: number | undefined
}
type PatchOfficeRequest = {
  id: number
  body: PatchOfficeRequestType
}
const patchOffice = createAsyncThunk<unknown, PatchOfficeRequest>(
  'office/patchOffice',
  async ({ id, body }: PatchOfficeRequest) => {
    try {
      const response = await getAxiosInstance().patch(`/office/${id}/`, body)
      return { data: response.data }
    } catch (err) {
      throw Error(JSON.stringify(err.response.data))
    }
  }
)

const newAdSlice = createSlice({
  name: 'newAd',
  initialState,
  reducers: {},
  extraReducers: {}
})

export default newAdSlice.reducer
export { createNewOffice, deleteOffice, patchOffice }
