/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import deleteOrganizationRegistrationRequest, {
  approveOrganizationRegistrationRequest,
  denyOrganizationRegistrationRequest
} from 'Features/Organization/organizationRegistrationSlice'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ConfirmationBackground = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const ConfirmationType = styled.div`
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 14px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`
const ConfirmationPanel = styled.div``
const ConfirmationPanelTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
`
const ConfirmationPanelActions = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`
const AcceptButton = styled.div`
  height: 24px;
  min-width: 97px;
  max-width: 97px;
  border-radius: 4px;
  background-color: #6ed663;
  font-family: Barlow;
  color: #fff;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
`
const DeclineButton = styled.div`
  height: 24px;
  min-width: 97px;
  max-width: 97px;
  border-radius: 4px;
  background-color: #ff6174;
  font-family: Barlow;
  color: #fff;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
`
const CancelButton = styled.div`
  height: 24px;
  border-radius: 4px;
  color: #a8aeb4;
  font-family: Barlow;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`
const RequestConfirmationStatus = styled.div`
  font-family: Barlow;
  font-size: 14px;
  display: flex;
  align-items: center;
`
const StatusSymbol = styled(FontAwesomeIcon)`
  font-size: 1.2em;
  margin-right: 5px;
`
const RejectedSymbol = styled(StatusSymbol)`
  color: #ff6174;
`
const AcceptedSymbol = styled(StatusSymbol)`
  color: #6ed663;
`
type RegistrationConfirmationType = {
  requestId: number
  isEmployer: boolean
  isTrainer: boolean
}
type ResistrationStatus = 'pending' | 'rejected' | 'accepted'
const RegistrationConfirmation = ({
  requestId,
  isEmployer,
  isTrainer
}: RegistrationConfirmationType) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [confirmationPanelOpen, setConfirmationPanelOpen] = useState(false)
  const [requestStatus, setRequestStatus] = useState<ResistrationStatus>(
    'pending'
  )
  const DeleteRequest = () => {
    // dispatch(deleteOrganizationRegistrationRequest(requestId, ''))
  }
  const ApproveRequest = () => {
    setRequestStatus('accepted')
    setConfirmationPanelOpen(false)
    dispatch(
      approveOrganizationRegistrationRequest({
        id: requestId,
        body: { decision_notes: '' }
      })
    )
  }
  const DenyRequest = () => {
    setRequestStatus('rejected')
    setConfirmationPanelOpen(false)
    dispatch(
      denyOrganizationRegistrationRequest({
        id: requestId,
        body: { decision_notes: '' }
      })
    )
  }
  const dynamicPanelTitle = () => {
    let dynamicTitle
    if (isEmployer) {
      dynamicTitle = 'Hyväksy työnvälityspalveluun'
    }
    if (isTrainer) {
      dynamicTitle = 'Hyväksy koulutuspalveluun'
    }
    if (isTrainer && isEmployer) {
      dynamicTitle = 'Hyväksy työnvälitys- ja koulutuspalveluun'
    }
    return dynamicTitle
  }
  const confirmationStatus = () => {
    let confirmationStatusType
    if (isEmployer) {
      confirmationStatusType = t('employment-services')
    }
    if (isTrainer) {
      confirmationStatusType = t('education-service')
    }
    if (isTrainer && isEmployer) {
      confirmationStatusType = t('employment-training-services')
    }
    switch (requestStatus) {
      case 'pending':
        return (
          <ConfirmationType onClick={() => setConfirmationPanelOpen(true)}>
            {t('waiting-for-approve')}
          </ConfirmationType>
        )
      case 'accepted':
        return (
          <RequestConfirmationStatus>
            <AcceptedSymbol icon={['fas', 'check']} />
            {`${confirmationStatusType} ${t('activated')}`}
          </RequestConfirmationStatus>
        )
      case 'rejected':
        return (
          <RequestConfirmationStatus>
            <RejectedSymbol icon={['fas', 'times']} />
            {t('application-rejected')}
          </RequestConfirmationStatus>
        )
      default:
        return null
    }
  }
  return (
    <ConfirmationBackground>
      {confirmationPanelOpen ? (
        <ConfirmationPanel>
          <ConfirmationPanelTitle>{dynamicPanelTitle()}</ConfirmationPanelTitle>
          <ConfirmationPanelActions>
            <AcceptButton onClick={ApproveRequest}>{t('accept')}</AcceptButton>
            <DeclineButton onClick={DenyRequest}>{t('decline')}</DeclineButton>
            <CancelButton onClick={() => setConfirmationPanelOpen(false)}>
              {t('cancel')}
            </CancelButton>
          </ConfirmationPanelActions>
        </ConfirmationPanel>
      ) : (
        confirmationStatus()
      )}
    </ConfirmationBackground>
  )
}

export default RegistrationConfirmation
