import {
  fetchPaginatedFilteredTrainings,
  fetchTrainings,
  SingleTrainingType
} from 'Features/Training/trainingSlice'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import styled from 'styled-components/macro'
import Pagination from './Pagination'
import TrainingObject from './TrainingObject'

const SearchResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    color: #252525;
    font-weight: 100;
    font-family: Barlow;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0;
  }
`
const PaginationContainer = styled.div`
  margin-top: 20px;
`

const TrainingSearchResults = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { defaultView } = useSelector((state: RootState) => state.training)
  const { trainingCount } = useSelector((state: RootState) => state.training)

  const { paginatedFilteredData } = useSelector(
    (state: RootState) => state.training
  )
  useEffect(() => {
    dispatch(fetchTrainings())
    if (defaultView) {
      dispatch(
        fetchPaginatedFilteredTrainings({
          page: 1,
          filters: {
            free_text_search: '',
            end_time: '',
            start_time: '',
            locations: [],
            organizations: [],
            training_types: [],
            subject_areas: [],
            order: 'start_time'
          }
        })
      )
    }
  }, [dispatch])
  return (
    <SearchResultsContainer>
      <h2>
        {defaultView
          ? t('newest-job-advertisements')
          : `${t('search-results')} (${t('results-count')}: ${trainingCount})`}
      </h2>
      {(paginatedFilteredData || []).map((r: SingleTrainingType) => (
        <TrainingObject data={r} key={r.id} />
      ))}
      <PaginationContainer>
        <Pagination />
      </PaginationContainer>
    </SearchResultsContainer>
  )
}

export default TrainingSearchResults
