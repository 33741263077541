import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { registerLocale } from 'react-datepicker'
import fi from 'date-fns/locale/fi'
import { convertToRaw, EditorState } from 'draft-js'
import { setAdValues } from 'Util/trainingUtils'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import AddPhotoModal from '../FormComponents/AddPhotoModal'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Fields } from '../CommonTrainingCreationProps'

registerLocale('fi', fi)

export const SelectableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  &.margin-small {
    margin-top: 10px;
  }
  & + label {
    margin-top: 40px;
  }
`
export const Selectable = styled.div`
  border-radius: 9.5px;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? '#D60C17' : '#E7EAEF'};
  color: ${(props: { selected: boolean }) =>
    props.selected ? '#FFFFFF' : '#252525'};
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding: 5px 10px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 12px;
  margin-bottom: 12px;
`
const ImageArea = styled.div`
  height: 166px;
  border: 1px solid #d6dce2;
  border-radius: 3px;
  background-color: #f4f5f7;
  text-align: center;
  .image-placeholder {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    .image-tooltip {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      font-style: italic;
    }
  }
  &:hover {
    cursor: pointer;
  }
`

const TextEditor = styled(Editor)``

const Phase2 = ({
  editorState,
  setEditorState,
  nextForm
}: {
  editorState: EditorState
  setEditorState: any
  nextForm: () => void
}) => {
  const { t } = useTranslation()

  const [showPhotoModal, setShowPhotoModal] = useState(false)
  const convertEditorData = (currentEditorState: any) => {
    setEditorState(currentEditorState)
    setAdValues(
      'description',
      draftToHtml(convertToRaw(currentEditorState.getCurrentContent()))
    )
  }
  return (
    <>
      {showPhotoModal ? (
        <AddPhotoModal modalToggle={setShowPhotoModal} />
      ) : null}
      <Fields style={{ flexDirection: 'column' }}>
        <div className="rows" style={{ width: '100%' }}>
          <label>
            {t('add-image-to-ad')}{' '}
            <span style={{ color: 'black', fontWeight: 500 }}>
              ({t('not-mantadory')}, {t('training-image-size-suggestion')})
            </span>
          </label>
          <ImageArea onClick={() => setShowPhotoModal(true)}>
            <div className="image-placeholder">
              <FontAwesomeIcon
                style={{ width: '100%' }}
                className="fa-5x"
                color="#D6DCE2"
                icon={['far', 'image']}
              />
              <span className="image-tooltip"> {t('add-image-to-ad')} </span>
            </div>
          </ImageArea>
          <label>
            {t('training-demo')} <span>*</span>
          </label>
          <TextEditor
            editorState={editorState}
            onEditorStateChange={e => convertEditorData(e)}
            placeholder={t('description')}
            editorClassName="rasfgedg"
            wrapperClassName="rasfasrwgedg"
          />
          <div>
            <div
              className="additional-info"
              style={{ width: '35%', float: 'right' }}
            >
              <button type="button" onClick={nextForm}>
                {t('next')}
              </button>
            </div>
          </div>
        </div>
      </Fields>
    </>
  )
}

export default Phase2
