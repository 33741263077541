/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { LocationOption } from 'Components/JobGuard/SavedSearches'
import { Selectable } from 'Components/Survey/FieldComponents/Phase2'
import { OrganizationType } from 'Features/Organization/organizationSlice'
import { RootState } from 'Store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const JOB_TYPES = (t: (k: string) => string) => ({
  continuous: t('continuous'),
  fixed: t('fixed')
})

/* eslint-disable camelcase */
export interface JobAdvertisementWithLocIds {
  duration: string
  id: number
  job_type: string
  locations: Array<number>
  organization?: OrganizationType
  title: string
  likeit_id?: string
}
/* eslint-enable camelcase */

const JobAdvertisementResultContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 7px 7px -10px rgba(0, 0, 0, 0.41);
  margin-top: 20px;
  display: flex;
  h6 {
    color: #252525;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin: 0 0 10px 0;
  }
  p {
    color: #5c5f63;
    font-family: Barlow;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    margin: 0;
    &:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }
  .job-info {
    flex-grow: 1;
    padding: 20px 15px;
    cursor: pointer;
    .job-locations {
      float: right;
    }
  }
  .logo-container {
    width: 115px;
    height: 100%;
    border-left: 1px solid #d6dce2;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      width: 100%;
      height: 100%;
      background: url(${({ result }: { result: JobAdvertisementWithLocIds }) =>
        result.organization?.logo});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .icon {
      width: 100%;
      height: 100%;
    }
  }
`
const PlaceholderIcon = styled(FontAwesomeIcon)`
  padding: 1rem;
`
const HouseholdAdIcon = styled(FontAwesomeIcon)`
  height: 100%;
  width: 100%;
`

const JobAdvertisementResult = ({
  result
}: {
  result: JobAdvertisementWithLocIds
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { filters } = useSelector((state: RootState) => state.jobAdvertisement)
  const jobTypes: { [key: string]: string } = JOB_TYPES(t)
  return (
    <JobAdvertisementResultContainer result={result}>
      <div
        className="job-info"
        onClick={() => history.push(`/jobs/${result.id}`)}
      >
        <div className="job-locations">
          {result.locations.map((locId: number) => {
            const locObj = filters.locations.find(
              (loc: LocationOption) => loc.id === locId
            )
            if (locObj) {
              return (
                <Selectable selected={false} key={locId}>
                  {locObj.name}
                </Selectable>
              )
            }
            return null
          })}
        </div>
        <h6>{result.title}</h6>
        <p>
          {jobTypes[result.job_type]}
          {result.duration ? `, ${result.duration}` : ''}
        </p>
      </div>
      {result.organization?.logo ? (
        <div className="logo-container">
          <div className="logo" />
        </div>
      ) : (
        <div className="logo-container">
          {result?.organization?.is_household ? (
            <HouseholdAdIcon className="icon" icon={['fas', 'house']} />
          ) : (
            <PlaceholderIcon
              color="#ddd"
              icon={['fas', 'tools']}
              className="logo"
            />
          )}
        </div>
      )}
    </JobAdvertisementResultContainer>
  )
}

export default JobAdvertisementResult
