import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useSelector, useDispatch } from 'react-redux'
import { fetchProfessionalTitleGroups } from 'Features/ProfessionalTitle/professionalTitleSlice'
import { fetchCompetences } from 'Features/Competence/competenceSlice'
import { fetchDrivingLicenses } from 'Features/DrivingLicense/drivingLicenseSlice'
import {
  createNewAdvertisement,
  modifyAdvertisement,
  fetchLocations
} from 'Features/NewAdvertisement/newAdvertisementSlice'
import { OfficeType } from 'Features/Organization/organizationSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { store } from 'Store'
import { toast } from 'react-toastify'
import { parse, format } from 'date-fns'
import { EditorState } from 'draft-js'
import FormFields from './FormFields'
import FormTabs from './FormTabs'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const TitleArea = styled.div`
  display: flex;
  align-items: center;
  h3 {
    color: #252525;
    font-family: Barlow;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 29px;
    font-weight: 200;
    margin: 0 0 0 15px;
  }
  span {
    border-radius: 6px;
    background-color: #d60c17;
    color: #ffffff;
    font-family: Barlow;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    padding: 8px 15px;
    font-weight: 600;
    white-space: nowrap;
  }
`
const Form = styled.div`
  background: #fff;
  flex-grow: 1;
  height: 100%;
  margin-top: 10px;
`
type ObjectType = {
  id: number
  value: string
  label: string
}
type LanguageObject = {
  level: number
  id: number
}
const NewAdForm = ({
  adId,
  companyId,
  values,
  companyLogo,
  companyName,
  offices,
  setValues,
  editorState,
  setEditorState,
  formIndex,
  onFormIndexChange
}: {
  adId: string | undefined
  companyId: number
  values: any
  companyLogo: string
  companyName: string
  offices: Array<OfficeType>
  setValues: Function
  editorState: EditorState
  setEditorState: any
  formIndex: number
  onFormIndexChange: Function
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(fetchProfessionalTitleGroups())
    dispatch(fetchCompetences())
    dispatch(fetchDrivingLicenses())
    dispatch(fetchLocations())
  }, [dispatch])
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const linkStart =
    pathname.indexOf('super-admin-panel') !== -1
      ? 'super-admin-panel'
      : 'admin-panel'
  const {
    professionalTitle: {
      groups: { results: groupResults }
    },
    competence: {
      data: { results: competenceResults }
    },
    drivingLicense: {
      data: { results: drivingLicenseResults }
    }
  } = useSelector((state: any) => state)
  const setAdValues = (key: string, value: string) => {
    const newState = { ...values }
    newState[key] = value
    setValues(newState)
  }
  const renderTitle = () => {
    switch (formIndex) {
      case 0:
        if (adId) {
          return t('modify-ad')
        }
        return t('ad-title-section-0')
      case 1:
        return t('ad-title-section-1')
      case 2:
        return t('ad-title-section-2')
      case 3:
        return t('ad-title-section-3')
      default:
        return t('ad-title-section-0')
    }
  }
  const formatDate = (dateType: String) => {
    try {
      switch (dateType) {
        case 'endDate':
          return format(
            parse(values.search_end_date, 'dd.mm.yyyy', new Date()),
            'yyyy-mm-dd'
          )
        case 'startDate':
          return format(
            parse(values.start_date, 'dd.mm.yyyy', new Date()),
            'yyyy-mm-dd'
          )
        case 'searchStartDate':
          return format(
            parse(values.search_start_date, 'dd.mm.yyyy', new Date()),
            'yyyy-mm-dd'
          )
        default:
          break
      }
    } catch (e) {
      return ''
    }
    return ''
  }
  const mapImages = (imageObject: any) => {
    if (imageObject) {
      const imageArray = [imageObject.id]
      return imageArray
    }
    return undefined
  } // Chanching when backend image system is reworked
  const mapLanguages = (languageObject: LanguageObject[]) => {
    const languages = languageObject.map((l: LanguageObject) => {
      return {
        language: l.id,
        level_of_competence: l.level
      }
    })
    return languages
  }
  const createAdvertisement = async () => {
    const body = {
      archived: false,
      automatic_archiving: false,
      locations: values.locations
        ? values.locations.map((location: ObjectType) => location.id)
        : undefined,
      description: values.ad_description,
      duration: values.duration,
      search_end_date: formatDate('endDate'),
      search_start_date: formatDate('searchStartDate'),
      start_date: formatDate('startDate'),
      images: values.job_image ? mapImages(values.job_image) : undefined,
      offices: values.offices
        ? values.offices.map((office: ObjectType) => office.id)
        : undefined,
      job_type: values.job_type ? values.job_type.type : undefined,
      employment_type: values.employment_type
        ? values.employment_type.type
        : undefined,
      website: values.website,
      title: values.ad_title,
      organization: companyId,
      competences: values.competences,
      driving_licenses: values.driving_licenses,
      language_skill_requirements: values.languages
        ? mapLanguages(values.languages)
        : undefined,
      professional_titles: values.professional_titles
    }
    if (adId) {
      const { error }: { error: Error } = await dispatch<any>(
        modifyAdvertisement({ body, id: parseInt(adId, 10) })
      )
      if (error) {
        const errorObj: { [key: string]: { [key: string]: any } } = JSON.parse(
          error.message
        )
        const errors: Array<string> = []
        Object.keys(errorObj).map((k: string) => {
          errors.push(`${t(k)}`)
        })
        toast.error(
          <>
            <strong>{t('fill-missing-fields')}:</strong>
            {errors.map((e: string) => (
              <p key={e}>
                <strong>{e.split(' - ')[0]}</strong>
              </p>
            ))}
          </>
        )
      } else {
        store.dispatch({ type: 'newAd/resetNewAd' })
        history.push(`/${linkStart}/ilmoitukset/avoimet`)
      }
    } else {
      const { error }: { error: Error } = await dispatch<any>(
        createNewAdvertisement(body)
      )
      if (error) {
        const errorObj: { [key: string]: { [key: string]: any } } = JSON.parse(
          error.message
        )
        const errors: Array<string> = []
        Object.keys(errorObj).map((k: string) => {
          errors.push(`${t(k)}`)
        })
        toast.error(
          <>
            <strong>{t('fill-missing-fields')}:</strong>
            {errors.map((e: string) => (
              <p key={e}>
                <strong>{e.split(' - ')[0]}</strong>
              </p>
            ))}
          </>
        )
      } else {
        store.dispatch({ type: 'newAd/resetNewAd' })
        history.push(`/${linkStart}/ilmoitukset/avoimet`)
      }
    }
  }

  return (
    <FormContainer>
      <TitleArea>
        <span>{formIndex + 1} / 4</span>
        <h3>{renderTitle()}</h3>
      </TitleArea>
      <Form>
        <FormTabs
          currentTab={formIndex}
          onTabChange={(index: number) => onFormIndexChange(index)}
        />
        <FormFields
          adId={adId}
          groupResults={groupResults}
          competenceResults={competenceResults}
          drivingLicenseResults={drivingLicenseResults}
          companyLogo={companyLogo}
          companyName={companyName}
          currentTab={formIndex}
          offices={offices}
          values={values}
          editorState={editorState}
          setEditorState={setEditorState}
          setValue={setAdValues}
          nextForm={() => onFormIndexChange(formIndex + 1)}
          createProfile={createAdvertisement}
        />
      </Form>
    </FormContainer>
  )
}

export default NewAdForm
