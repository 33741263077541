import React from 'react'
import styled from 'styled-components/macro'
import { Link, withRouter } from 'react-router-dom'
import type { Location } from 'history'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Desktop } from 'Components/Layout/MediaQuery'
import { useTranslation } from 'react-i18next'

interface StyledLinkProps {
  isActive: boolean
}

const SidebarContainer = styled.div`
  width: 196px;
  min-width: 196px;
  background: #232733;
  height: calc(100vh - 60px);
`
const TopSidebar = styled.div`
  min-height: fit-content;
  background: #2a2e3b;
  padding-bottom: 20px;
`
const BottomSidebar = styled.div`
  min-height: fit-content;
  background: #232733;
`
const IconArea = styled.div`
  width: 30px;
  text-align: center;
`

const MenuItems = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  svg {
    margin-right: 10px;
  }
  a {
    font-family: Barlow;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    &.outside-link {
      color: white;
    }
  }
`
const SubMenuItems = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 31px;
  a {
    font-family: Barlow;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    padding: 4px 0px 8px 10px;
    &.outside-link {
      color: white;
    }
  }
`

const SidebarMenuItems = styled.div`
  margin: 15px 0px 0px 11px;
`

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
const StyledLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  font-weight: 600;
  display: flex;
  ${(props: StyledLinkProps) =>
    props.isActive
      ? `
      font-weight: bold;
      color: #5C5F63;
  `
      : 'color: white;'}
`
/* eslint-enable react/jsx-props-no-spreading */
/* eslint-enable @typescript-eslint/no-unused-vars */

const EducationSidebar = ({ location }: { location: Location }) => {
  const { t } = useTranslation()
  return (
    <Desktop>
      <SidebarContainer>
        <TopSidebar>
          <MenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={location.pathname === '/education-panel'}
                to="/education-panel"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'home']} />
                </IconArea>
                {t('front-page')}
              </StyledLink>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink isActive={false} to="/education-panel/ilmoitukset">
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'chalkboard-teacher']} />
                </IconArea>
                {t('trainings')}
              </StyledLink>
              <SubMenuItems>
                <StyledLink
                  isActive={
                    location.pathname === '/education-panel/ilmoitukset' ||
                    location.pathname === '/education-panel/uusi'
                  }
                  to="/education-panel/ilmoitukset"
                >
                  {t('upcoming-educations')}
                </StyledLink>
                <StyledLink
                  isActive={location.pathname === '/education-panel/nykyiset'}
                  to="/education-panel/nykyiset"
                >
                  {t('ongoing-trainings')}
                </StyledLink>
                <StyledLink
                  isActive={location.pathname === '/education-panel/historia'}
                  to="/education-panel/historia"
                >
                  {t('past-trainings')}
                </StyledLink>
              </SubMenuItems>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={location.pathname === '/education-panel/viestit'}
                to="/education-panel/viestit"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'envelope']} />
                </IconArea>
                {t('messages')}
              </StyledLink>
            </SidebarMenuItems>
          </MenuItems>
        </TopSidebar>
        <BottomSidebar>
          <MenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={false}
                to="/education-panel/asetukset/tiedot"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'hammer']} />
                </IconArea>
                Asetukset
              </StyledLink>
              <SubMenuItems>
                <StyledLink
                  isActive={
                    location.pathname === '/education-panel/asetukset/tiedot'
                  }
                  to="/education-panel/asetukset/tiedot"
                >
                  Yrityksen tiedot
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname === '/education-panel/asetukset/hallinta'
                  }
                  to="/education-panel/asetukset/hallinta"
                >
                  Käyttäjätilien hallinta
                </StyledLink>
              </SubMenuItems>
            </SidebarMenuItems>
          </MenuItems>
        </BottomSidebar>
      </SidebarContainer>
    </Desktop>
  )
}

export default withRouter(EducationSidebar)
