import React, { FormEvent, useState } from 'react'
import styled from 'styled-components/macro'
import Logo from 'Assets/rakennusliitto-logo.png'
import {
  organizationRegistrationRequest,
  OrganizationRegistrationRequestRequestBodyType
} from 'Features/Organization/organizationRegistrationSlice'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'Store'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import LoadingIndicator from 'Components/LoadingIndicator'
import { BaseState } from 'Components/Layout/AdminLayout'

const Container = styled.div`
  text-align: center;
  border-radius: 3px;
  background-color: #fff;
  margin: 0px auto;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
`
const LoginTitle = styled.div`
  color: #222;
  margin: 30px 0px;
  font-style: Barlow;
  font-size: 16px;
`
const LoginForm = styled.form`
  width: 100%;
`
const LoginLabel = styled.label`
  font-size: 14px;
  font-family: Barlow;
  color: #222;
  display: block;
  margin-bottom: 7px;
  font-weight: bold;
  text-align: left;
  first-letter {
    text-transform: capitalize;
  }
`
const LoginButton = styled.button`
  cursor: pointer;
  width: 280px;
  border: none;
  background-color: #d60c17;
  color: #ffffff;
  padding: 10px;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 15px;
`

const LoginInput = styled.input`
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background: #fff;
  border: 1px solid #d6dce2;
  color: #222;
  &:disabled {
    background: #dddddd;
  }
  &:last-of-type {
    margin-bottom: 5px;
  }
`
const CheckboxContainer = styled.div`
  display: flex;
  margin-bottom: 3px;
  &:last-of-type {
    margin-bottom: 15px;
  }
`
const OrganizationTypeInput = styled.input`
  height: 15px;
  width: 15px;
`
const OrganizationTypeLabel = styled.label`
  font-size: 15px;
  font-family: Barlow;
  margin-left: 15px;
`
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and ${props => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`
const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  @media screen and ${props => props.theme.breakpoints.md} {
    margin: 0px 30px;
  }
`
const SuccessfulRegistration = styled.div`
  width: 100%;
  height: 380px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const ReturnButton = styled(LoginButton)``

type UpdateRegistrationRequestDataType = {
  dataKey: string
  data: string | number
}
const NewOrganization = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  )

  const isLoading = useSelector((state: RootState) =>
    Object.values(state).some(partialState => {
      const castedState = partialState as BaseState
      return castedState.loading === 'pending'
    })
  )

  const initialData = {
    name: '',
    description: ' ',
    url: '',
    vat_number: '',
    is_employer: false,
    is_trainer: false,
    first_name: user.first_name,
    last_name: user.last_name,
    username: user.username
  }

  const [
    registrationRequestData,
    setRegistrationRequestData
  ] = useState<OrganizationRegistrationRequestRequestBodyType>(initialData)
  const [requestSuccessful, setRequestSuccessful] = useState(false)

  const createNewOrganization = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(
      organizationRegistrationRequest({
        body: registrationRequestData
      })
    ).then((data: any) => {
      if (data.payload.status) {
        const errorObj = data.payload.data
        const errors: Array<string> = []
        Object.entries(errorObj).map(([key, value]) => {
          const errorKey = `registration-${key}`
          errors.push(`${t(errorKey)} - ${value}`)
        })
        toast.error(
          <>
            <strong>{t('fill-missing-fields')}:</strong>
            {errors.map((e: string) => (
              <p key={e}>
                <strong>{e.split(' . ')[0]}</strong>
              </p>
            ))}
          </>
        )
      } else {
        setRequestSuccessful(true)
        toast.success(
          <>
            <strong>{t('registration-successful')}</strong>
          </>
        )
      }
    })
  }

  const updateRegistrationRequestData = ({
    dataKey,
    data
  }: UpdateRegistrationRequestDataType) => {
    setRegistrationRequestData({ ...registrationRequestData, [dataKey]: data })
  }
  const history = useHistory()
  return (
    <Container>
      <img src={Logo} alt="Rakennusliitto logo" />
      {!requestSuccessful ? (
        <>
          <LoginTitle>{t('create-organization-title')}</LoginTitle>
          <LoginForm onSubmit={createNewOrganization}>
            <ContentContainer>
              <ContentColumn>
                <LoginLabel>{t('first-name')}</LoginLabel>
                <LoginInput
                  type="text"
                  placeholder={t('input-first-name')}
                  disabled={isAuthenticated}
                  value={
                    isAuthenticated
                      ? initialData.first_name
                      : registrationRequestData.first_name
                  }
                  onChange={e =>
                    updateRegistrationRequestData({
                      dataKey: 'first_name',
                      data: e.target.value
                    })
                  }
                />
                <LoginLabel>{t('last-name')}</LoginLabel>
                <LoginInput
                  type="text"
                  placeholder={t('input-last-name')}
                  disabled={isAuthenticated}
                  value={
                    isAuthenticated
                      ? initialData.last_name
                      : registrationRequestData.last_name
                  }
                  onChange={e =>
                    updateRegistrationRequestData({
                      dataKey: 'last_name',
                      data: e.target.value
                    })
                  }
                />
                <LoginLabel>{t('email')}</LoginLabel>
                <LoginInput
                  type="text"
                  placeholder={t('input-email')}
                  disabled={isAuthenticated}
                  value={
                    isAuthenticated
                      ? initialData.username
                      : registrationRequestData.username
                  }
                  onChange={e =>
                    updateRegistrationRequestData({
                      dataKey: 'username',
                      data: e.target.value
                    })
                  }
                />
              </ContentColumn>
              <ContentColumn>
                <LoginLabel>{t('organization-name')}</LoginLabel>
                <LoginInput
                  type="text"
                  placeholder={t('input-organization-name')}
                  onChange={e =>
                    updateRegistrationRequestData({
                      dataKey: 'name',
                      data: e.target.value
                    })
                  }
                />
                <LoginLabel>{t('business-id')}</LoginLabel>
                <LoginInput
                  type="text"
                  placeholder={t('input-business-id')}
                  onChange={e =>
                    updateRegistrationRequestData({
                      dataKey: 'vat_number',
                      data: e.target.value
                    })
                  }
                />
                <LoginLabel>{t('organization-type')}</LoginLabel>
                <CheckboxContainer>
                  <OrganizationTypeInput
                    id="isEmployer"
                    type="checkbox"
                    onChange={e =>
                      updateRegistrationRequestData({
                        dataKey: 'is_employer',
                        data: e.target.value
                      })
                    }
                  />
                  <OrganizationTypeLabel htmlFor="isEmployer">
                    {t('employment-services')}
                  </OrganizationTypeLabel>
                </CheckboxContainer>
                <CheckboxContainer>
                  <OrganizationTypeInput
                    id="isTrainer"
                    type="checkbox"
                    onChange={e =>
                      updateRegistrationRequestData({
                        dataKey: 'is_trainer',
                        data: e.target.value
                      })
                    }
                  />
                  <OrganizationTypeLabel htmlFor="isTrainer">
                    {t('education')}
                  </OrganizationTypeLabel>
                </CheckboxContainer>
                <LoginLabel>{t('website')}</LoginLabel>
                <LoginInput
                  type="text"
                  placeholder={t('example-website')}
                  onChange={e =>
                    updateRegistrationRequestData({
                      dataKey: 'url',
                      data: e.target.value
                    })
                  }
                />
                <LoginLabel>{t('description')}</LoginLabel>
                <LoginInput
                  type="text"
                  placeholder={t('input-organization-description')}
                  onChange={e =>
                    updateRegistrationRequestData({
                      dataKey: 'description',
                      data: e.target.value
                    })
                  }
                />
              </ContentColumn>
            </ContentContainer>
            <LoginButton type="submit">{t('send-application')}</LoginButton>
          </LoginForm>
        </>
      ) : (
        <SuccessfulRegistration>
          {t('thank-you-for-registration')}
          <ReturnButton onClick={() => history.push('')}>
            {t('return-to-front-page')}
          </ReturnButton>
        </SuccessfulRegistration>
      )}
      {isLoading && <LoadingIndicator />}
    </Container>
  )
}

export default NewOrganization
