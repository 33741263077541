import { AnyAction, createSlice } from '@reduxjs/toolkit'
import actionGenerator from 'Util/actionGenerator'

interface CompanyState {
  itemIndex: number
}

const initialState: CompanyState = {
  itemIndex: parseInt(
    localStorage.getItem('currentlySelectedOrganization') || '0',
    10
  )
}

const genericActions = actionGenerator('auth')

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    changeCompany(state, action: AnyAction) {
      return {
        ...state,
        itemIndex: action.payload.itemIndex
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(genericActions.ResetAction, () => initialState)
  }
})

export default companySlice.reducer
