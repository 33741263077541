import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { OfficeType } from 'Features/Organization/organizationSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import organizationIsHousehold from 'Util/organizationIsHousehold'
import ModifyLocationModal from './ModifyLocationModal'

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d6dce2;
  @media screen and ${props => props.theme.breakpoints.md} {
    flex-direction: row;
    border-right: 1px solid #d6dce2;
    border-bottom: none;
  }
`
const LogoBackground = styled.div`
  background-color: #e7eaef;
  height: 143px;
  min-width: 190px;
  padding: 10px;
`
const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CompanyLogo = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
const HouseholdLogo = styled(FontAwesomeIcon)`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &:first-child {
    width: 100%;
  }
`
const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0px;
  @media screen and ${props => props.theme.breakpoints.md} {
    margin: 0px 50px 0px 30px;
  }
`
const DetailsObject = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  p {
    color: #252525;
    font-family: Barlow;
    font-size: 16px;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
`
const ModifiableDetails = styled.div`
  display: flex;
  flex-direction: row;
`
const DetailsTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
`
const DisabledTitle = styled(DetailsTitle)`
  color: #a8aeb4;
`
export const ModifyMainOfficeButton = styled(FontAwesomeIcon)`
  margin-left: 8px;
  color: #0a4ea2;
  &:hover {
    cursor: pointer;
  }
`
type CompanyDetailTypes = {
  companyLogo: string
  companyName: string
  companyVat: string
  companyEmail: string
  companyId: number
}
const CompanyDetailsComponent = ({
  companyDetails,
  mainOffice
}: {
  companyDetails: CompanyDetailTypes | undefined
  mainOffice: OfficeType | undefined
}) => {
  const { t } = useTranslation()
  const [modifyLocationModal, setModifyLocationModal] = useState(false)
  const isHousehold = organizationIsHousehold()

  return (
    <>
      {modifyLocationModal && companyDetails && mainOffice ? (
        <ModifyLocationModal
          modalToggle={setModifyLocationModal}
          companyName={companyDetails.companyName}
          officeDetails={mainOffice}
          companyId={companyDetails.companyId}
          mainOffice
          singleOffice
        />
      ) : null}
      <LeftColumn>
        <LogoBackground>
          <LogoContainer>
            {isHousehold ? (
              <HouseholdLogo className="icon" icon={['fas', 'house']} />
            ) : (
              <CompanyLogo
                style={{
                  backgroundImage: `url(${
                    companyDetails ? companyDetails.companyLogo : ''
                  })`
                }}
              />
            )}
          </LogoContainer>
        </LogoBackground>
        <DetailsList>
          {companyDetails ? (
            <>
              <DetailsObject>
                {companyDetails.companyName ? (
                  <>
                    <DetailsTitle>
                      {isHousehold ? t('household-name') : t('company-name')}
                    </DetailsTitle>
                    <p>{companyDetails.companyName}</p>
                  </>
                ) : (
                  <DisabledTitle>{t('company-name')}</DisabledTitle>
                )}
              </DetailsObject>
              {!isHousehold && (
                <>
                  <DetailsObject>
                    {companyDetails.companyVat ? (
                      <>
                        <DetailsTitle>{t('business-id')}</DetailsTitle>
                        <p>{companyDetails.companyVat}</p>
                      </>
                    ) : (
                      <DisabledTitle>{t('business-id')}</DisabledTitle>
                    )}
                  </DetailsObject>
                  <ModifiableDetails>
                    {mainOffice && mainOffice.name ? (
                      <>
                        <DetailsObject>
                          <DetailsTitle>
                            {t('main-office-location')}
                          </DetailsTitle>
                          <p>{mainOffice.name}</p>
                        </DetailsObject>

                        <ModifyMainOfficeButton
                          icon={['far', 'pen']}
                          onClick={() => setModifyLocationModal(true)}
                        />
                      </>
                    ) : (
                      <DetailsObject>
                        <DisabledTitle>
                          {t('main-office-location')}
                        </DisabledTitle>
                      </DetailsObject>
                    )}
                  </ModifiableDetails>
                  <DetailsObject>
                    {mainOffice && mainOffice.address ? (
                      <>
                        <DetailsTitle>{t('address')}</DetailsTitle>
                        <p>{mainOffice.address}</p>
                      </>
                    ) : (
                      <DisabledTitle>{t('address')}</DisabledTitle>
                    )}
                  </DetailsObject>
                  <DetailsObject>
                    {mainOffice && mainOffice.location.name ? (
                      <>
                        <DetailsTitle>{t('district')}</DetailsTitle>
                        <p>{mainOffice.location.name}</p>
                      </>
                    ) : (
                      <DisabledTitle>{t('district')}</DisabledTitle>
                    )}
                  </DetailsObject>
                  <DetailsObject>
                    {mainOffice && mainOffice.email ? (
                      <>
                        <DetailsTitle>{t('email')}</DetailsTitle>
                        <p>{mainOffice.email}</p>
                      </>
                    ) : (
                      <DisabledTitle>{t('email')}</DisabledTitle>
                    )}
                  </DetailsObject>
                  <DetailsObject>
                    {mainOffice && mainOffice.phone_number ? (
                      <>
                        <DetailsTitle>{t('phone')}</DetailsTitle>
                        <p>{mainOffice.phone_number}</p>
                      </>
                    ) : (
                      <DisabledTitle>{t('phone')}</DisabledTitle>
                    )}
                  </DetailsObject>
                </>
              )}
            </>
          ) : null}
        </DetailsList>
      </LeftColumn>
    </>
  )
}

export default CompanyDetailsComponent
