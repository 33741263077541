import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import ApplicationDetails from 'Components/AdStatusBoard/ApplicationDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

const Button = styled.button`
  display: flex;
  position: absolute;
  right: 25px;
  top: 30px;
  align-items: center;
  border-radius: 6px;
  background-color: #d60c17;
  border: none;
  color: #ffffff;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding: 10px 25px;
  svg {
    margin-right: 10px;
    font-size: 13px;
  }
`

const Phase1 = () => {
  const { user } = useSelector((state: any) => state.auth)
  const history = useHistory()
  const { t } = useTranslation()
  return user.member_profile ? (
    <>
      <ApplicationDetails
        anonymous={false}
        applicantDetails={user.member_profile}
        applicantName={`${user.first_name} ${user.last_name}`}
      />
      <Button
        onClick={() => history.push('/create-member-profile?callback=/profile')}
      >
        <FontAwesomeIcon icon={['fas', 'pen']} /> {t('modify-profile')}
      </Button>
    </>
  ) : null
}

export default Phase1
