import { fetchJobGuards } from 'Features/JobGuard/jobGuardSlice'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { SavedSearches } from '.'

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: #33333377;
  visibility: ${({ show }: { show: boolean }) => (show ? 'visible' : 'hidden')};
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  .modal-content {
    position: relative;
    width: 100%;
    max-width: 900px;
    display: flex;
    padding: 20px;
    background: #fff;
    flex-direction: column;
    margin-bottom: 50px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 6px;
    h3 {
      color: #313131;
      font-family: Barlow;
      font-size: 32px;
      letter-spacing: 0;
      line-height: 38px;
      margin: 0;
      margin-bottom: 20px;
      font-weight: 200;
    }
  }
`
const CloseButton = styled.button`
  border: none;
  background: #d60c17;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 25px;
  top: 25px;
  color: #fff;
  border-radius: 100%;
  font-size: 16px;
  height: 30px;
  width: 30px;
  line-height: 0;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`
const JobGuardModal = ({
  show,
  closeModal
}: {
  show: boolean
  closeModal: () => void
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchJobGuards())
  }, [])
  return (
    <ModalContainer show={show}>
      <div className="modal-content">
        <CloseButton onClick={() => closeModal()}>&times;</CloseButton>
        <h3>{t('job-guard-settings')}</h3>
        <SavedSearches />
      </div>
    </ModalContainer>
  )
}

export default JobGuardModal
