import TrainingDetails from 'Components/Trainings/TrainingAdvertisementDetails'
import EducationLayout from 'Components/Layout/EducationLayout'
import React from 'react'
import styled from 'styled-components'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`
const SingleTrainingAdvertisement = (props: any) => {
  const { computedMatch } = props

  return (
    <EducationLayout>
      <ContentContainer>
        <TrainingDetails trainingId={computedMatch.params.id} educationPanel />
      </ContentContainer>
    </EducationLayout>
  )
}

export default SingleTrainingAdvertisement
