import React from 'react'
import styled from 'styled-components'
import AdminLayout from 'Components/Layout/AdminLayout'
import EmployeeSearchComponent from 'Components/EmployeeSearch/'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`

const EmployeeSearch = () => {
  return (
    <AdminLayout>
      <ContentContainer>
        <EmployeeSearchComponent />
      </ContentContainer>
    </AdminLayout>
  )
}
export default EmployeeSearch
