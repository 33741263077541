import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'Store'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState
} from 'draft-js'
import {
  ContentType,
  fetchContentManagement,
  updateContentManagement
} from 'Features/ContentManagement/contentManagementSlice'
import { toast } from 'react-toastify'
import { unwrapResult } from '@reduxjs/toolkit'

const Header = styled.div`
  margin-bottom: 15px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const HeaderTitle = styled.div`
  font-family: Barlow;
  font-size: 18px;
  font-weight: 600;
`
const SaveButton = styled.div`
  height: 100%;
  min-width: 97px;
  max-width: 97px;
  border-radius: 4px;
  background-color: #6ed663;
  font-family: Barlow;
  color: #fff;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
`
const SuperAdminContentManagement = ({
  contentPage
}: {
  contentPage: string
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [editorState, setEditorState] = useState<EditorState>()
  const [currentContent, setCurrentContent] = useState<ContentType>()

  const initializeEditorState = (contentObject: ContentType) => {
    const desc = convertFromHTML(contentObject.content_text)
    const content = ContentState.createFromBlockArray(
      desc.contentBlocks,
      desc.entityMap
    )
    setEditorState(EditorState.createWithContent(content))
  }

  const findCorrectContent = (contentArray: ContentType[]) => {
    if (contentArray) {
      contentArray.forEach((contentObject: ContentType) => {
        if (contentObject.name.toUpperCase() === contentPage?.toUpperCase()) {
          setCurrentContent(contentObject)
          initializeEditorState(contentObject)

          return contentObject
        }
        return undefined
      })
    }
  }

  useEffect(() => {
    if (contentPage) {
      dispatch(fetchContentManagement())
        .then(unwrapResult)
        .then((res: any) => {
          findCorrectContent(res.results)
        })
    }
  }, [dispatch, contentPage])

  const convertEditorData = (editorData: EditorState) => {
    return draftToHtml(convertToRaw(editorData.getCurrentContent()))
  }
  const updateContent = async () => {
    if (currentContent && editorState) {
      await dispatch(
        updateContentManagement({
          id: currentContent.id,
          body: { content_text: convertEditorData(editorState) }
        })
      ).then(({ error }: any) => {
        if (error) {
          toast.error(<strong>{t('error-happened')}</strong>)
        } else {
          toast.success(<strong>{t('modified-successfully')}</strong>)
        }
      })
    }
  }

  const getHeaderText = () => {
    switch (contentPage) {
      case 'duunimylly':
        return t('job-mill-content-management')
      case 'palkkamylly':
        return t('salary-mill-content-management')
      case 'taitomylly':
        return t('skill-mill-content-management')
      case 'suuntamylly':
        return t('direction-mill-content-management')
      default:
        return ''
    }
  }

  const title = getHeaderText()

  return (
    <>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
        <SaveButton onClick={() => updateContent()}>{t('save')}</SaveButton>
      </Header>
      <Editor
        editorState={editorState}
        onEditorStateChange={e => setEditorState(e)}
        placeholder="Muokkaa valittua sisältösivua"
        editorClassName="rasfgedg"
        wrapperClassName="rasfasrwgedg"
      />
    </>
  )
}

export default SuperAdminContentManagement
