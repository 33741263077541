import React from 'react'
import styled from 'styled-components'
import { SuperAdminLayout } from 'Components/Layout/SuperAdminLayout'
import SuperAdminStatisticsComponent from 'Components/SuperAdminPage/SuperAdminStatistics'

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px;
  overflow: auto;
  flex: 1;
  background: linear-gradient(150.47deg, #32384c 0%, #212634 100%);
`

const SuperAdminStatistics = () => {
  return (
    <SuperAdminLayout>
      <ContentContainer>
        <SuperAdminStatisticsComponent />
      </ContentContainer>
    </SuperAdminLayout>
  )
}
export default SuperAdminStatistics
