import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

const ToggleContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 20px;
  width: 100px;
  border: 1px solid #ccd2d7;
  border-radius: 14.5px;
  background-color: ${({ isOn }: { isOn: boolean }) =>
    isOn ? '#fff' : '#ebefe7'};
  display: flex;
  align-items: center;
  padding: 10px 5px;
  user-select: none;
  cursor: default;
  .status-circle {
    position: absolute;
    left: ${({ isOn }: { isOn: boolean }) =>
      isOn ? 'calc(100% - 19px)' : '5px'};
    transition: left 0.2s ease-in-out;
    box-sizing: border-box;
    height: 14px;
    width: 14px;
    background: ${({ isOn }: { isOn: boolean }) =>
      isOn
        ? 'linear-gradient(180deg, #46FF65 0%, #4AD95E 100%, #59B04F 100%)'
        : 'linear-gradient(180deg, #CB0000 0%, #FF0505 100%, #59B04F 100%)'};
    border: 1px solid
      ${({ isOn }: { isOn: boolean }) => (isOn ? '#62BB3C' : '#e74040')};
    border-radius: 100%;
  }
  .status-text {
    color: #7f7f7f;
    font-family: Barlow;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1;
    text-align: center;
    flex-grow: 1;
    margin-left: ${({ isOn }: { isOn: boolean }) => (isOn ? '0' : '14px')};
    margin-right: ${({ isOn }: { isOn: boolean }) => (isOn ? '14px' : '0')};
  }
`

const Toggle = ({ isOn }: { isOn: boolean }) => {
  const { t } = useTranslation()
  const status = isOn ? t('in-use') : t('not-in-use')
  return (
    <ToggleContainer className="toggle" isOn={isOn}>
      <span className="status-circle" />
      <span className="status-text">{status}</span>
    </ToggleContainer>
  )
}

export default Toggle
