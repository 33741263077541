/* eslint-disable camelcase */
/* eslint-disable no-console */
import {
  createSlice,
  AnyAction,
  AsyncThunk,
  createAction,
  PayloadAction,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { getAxiosInstance } from 'Api'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

interface JobApplicationState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  data: Object
  list: {
    results: Array<JobApplicationType>
  }
  statusChanging: boolean
  selectedApplicationId: number | undefined
}
export type JobApplicationType = {
  cover_letter: string
  created_at: string
  id: number
  job_advertisement: number
  job_advertisement_title: string
  member_profile: JobApplicationMemberProfileType
  status: string
}
type JobApplicationMemberProfileType = {
  competences: []
  created_at: string
  driving_licenses: []
  educations: []
  experiences: []
  id: 1
  is_open_profile: boolean
  is_active_job_seeker: boolean
  job_experiences: []
  language_skills: []
  possibility_to_use_own_tools: boolean
  recommenders: []
  updated_at: string
  user: UserType
}
interface JobApplicationFilters {
  limit?: number
  organization_id?: number
}
type UserType = {
  id: number
  first_name: string
  last_name: string
  organizations: []
}
const initialState: JobApplicationState = {
  loading: 'idle',
  data: {},
  list: {
    results: []
  },
  statusChanging: false,
  selectedApplicationId: undefined
}

const fetchJobApplications = createAsyncThunk(
  'jobApplication/fetch',
  async (filters?: JobApplicationFilters) => {
    let url = '/application/'
    if (filters) {
      const { limit, organization_id } = filters
      if (limit || organization_id) {
        const filterString = Object.keys(filters).map(
          (filter: string) =>
            `${filter}=${filters[filter as keyof JobApplicationFilters]}`
        )
        url = `${url}?${filterString.join('&')}`
      }
    }
    const response = await getAxiosInstance().get(url)
    return { data: response.data }
  }
)

const sendJobApplication = createAsyncThunk(
  'jobApplication/sendJobApplication',
  async (body: any) => {
    const response = await getAxiosInstance().post(`/application/`, body)
    return { data: response.data }
  }
)
type UpdateApplicationStatusRequest = {
  status: string
}
const updateApplicationStatus = createAsyncThunk(
  'jobApplication/update_status',
  async ({
    id,
    body
  }: {
    id: number
    body: UpdateApplicationStatusRequest
  }) => {
    const response = await getAxiosInstance().patch(`/application/${id}/`, body)
    return response.data
  }
)

const rejectApplication = createAsyncThunk(
  'jobApplication/reject_application',
  async ({ id }: { id: number }) => {
    const response = await getAxiosInstance().patch(`/application/${id}/`, {
      status: 'rejected'
    })
    return response.data
  }
)
const returnApplication = createAsyncThunk(
  'jobApplication/return_application',
  async ({ id }: { id: number }) => {
    const response = await getAxiosInstance().patch(`/application/${id}/`, {
      status: 'received'
    })
    return response.data
  }
)
const deleteApplication = createAsyncThunk(
  'jobApplication/delete_application',
  async ({ id }: { id: number }) => {
    const response = await getAxiosInstance().delete(`/application/${id}/`)
    return response.data
  }
)
/* eslint-enable camelcase */

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending')
}
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected')
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled')
}

export const resetAction = createAction('reset-tracked-loading-state')

/* eslint-disable no-param-reassign */
const jobApplicationSlice = createSlice({
  name: 'jobApplication',
  initialState,
  reducers: {
    changeSelectedApplication(state, action: AnyAction) {
      return {
        ...state,
        selectedApplicationId: action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        sendJobApplication.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.data = action.payload.data
        }
      )
      .addCase(
        fetchJobApplications.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.list = action.payload.data
        }
      )
      .addCase(updateApplicationStatus.pending, state => {
        state.statusChanging = true
      })
      .addCase(updateApplicationStatus.fulfilled, state => {
        state.statusChanging = false
      })
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})
/* eslint-enable no-param-reassign */

export default jobApplicationSlice.reducer
export {
  sendJobApplication,
  fetchJobApplications,
  updateApplicationStatus,
  rejectApplication,
  returnApplication,
  deleteApplication
}
