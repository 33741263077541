import React from 'react'
import AdminLayout from 'Components/Layout/AdminLayout'
import styled from 'styled-components'
import AdminActiveJobs from 'Components/AdminPage/AdminActiveJobs'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`

const AdminPanel = () => {
  return (
    <AdminLayout>
      <ContentContainer>
        <AdminActiveJobs />
      </ContentContainer>
    </AdminLayout>
  )
}
export default AdminPanel
