import React from 'react'
import styled from 'styled-components'
import SuperAdminEducations from 'Components/SuperAdminPage/SuperAdminEducations'
import { SuperAdminLayout } from 'Components/Layout/SuperAdminLayout'
import { historyStatus } from 'Features/Training/trainingSlice'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`

const AdminEducations = () => {
  return (
    <SuperAdminLayout>
      <ContentContainer>
        <SuperAdminEducations status={historyStatus} />
      </ContentContainer>
    </SuperAdminLayout>
  )
}
export default AdminEducations
