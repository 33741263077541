import {
  MemberStatus,
  TrainingType
} from 'Components/EducationPage/NewTraining/ModifyTrainingInitializers'
import { NewTrainingValuesType } from 'Features/Training/trainingSlice'
import { store } from 'Store'
import { format, parseISO } from 'date-fns'
import { ImageType } from 'Features/ImageBank/imageBankSlice'
import { TFunction } from 'i18next'

export const setAdValues = (key: string, value: any) => {
  const {
    training: { values }
  } = store.getState()

  const newState: Record<string, any> = { ...values }
  newState[key] = value
  store.dispatch({ type: 'training/updateNewAd', payload: newState })
}

export const isTrainingTypeSelected = (
  values: NewTrainingValuesType,
  trainingType: TrainingType
) => {
  if (values.training_type && values.training_type.title) {
    if (values.training_type.title === trainingType.title) {
      return true
    }
  }
  return false
}

export const setConfirmed = () => {
  store.dispatch({ type: 'training/updateAd' })
}

export const selectedImageIds = (images: Array<ImageType> | undefined) => {
  if (images && images.length > 0) {
    const imageIds = images.map(image => {
      return image.id
    })
    return imageIds
  }
  return undefined
}

export const setSelectedImages = (
  currentImages: Array<ImageType> | undefined,
  photoDetails: ImageType
) => {
  if (selectedImageIds(currentImages)?.includes(photoDetails.id)) {
    const newImages = currentImages?.filter(
      image => image.id !== photoDetails.id
    )
    setAdValues('images', newImages)
  } else {
    setAdValues('images', [...(currentImages || []), photoDetails])
  }
}
export const trainingSummaryImageUrl = (
  trainingImages: Array<ImageType> | undefined
) => {
  if (trainingImages && trainingImages.length > 0) {
    return trainingImages[0].url
  }
  return undefined
}
export const displayValue = (valueName: any) => {
  return valueName || 'Ei valittu'
}
export const displayMemberLimitations = (
  limitation: MemberStatus,
  t: TFunction
) => {
  if (limitation) {
    if (limitation.value) {
      return t('yes')
    }
    return t('no')
  }
  return undefined
}

export const shortenDescription = (description: string | undefined) => {
  let shortenedDescription
  if (description) {
    const descToUse = description.replace(/<\/?[^>]+(>|$)/g, '')
    shortenedDescription = `${descToUse.substring(0, 30)}...`
  }

  return shortenedDescription
}
export const displayTime = (timeStamp: string | undefined) => {
  if (timeStamp) {
    const date = format(parseISO(timeStamp), 'dd.MM.yyyy HH:mm')
    return `${date}`
  }
  return undefined
}
export const displayDate = (timeStamp: string | undefined) => {
  if (timeStamp) {
    const date = format(parseISO(timeStamp), 'dd.MM.yyyy')
    return `${date}`
  }
  return undefined
}
export const displayPrice = (price: string | undefined) => {
  if (price) {
    return `${price}€`
  }
  return undefined
}
