import React from 'react'
import styled from 'styled-components/macro'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import {
  Selectable,
  SelectableContainer
} from 'Components/EducationPage/NewTraining/FieldComponents/Phase1'
import { setAdValues } from 'Util/trainingUtils'
import { format, parseISO } from 'date-fns'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { NewTrainingValuesType } from 'Features/Training/trainingSlice'
import { Fields } from '../CommonTrainingCreationProps'

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .input-row-item {
    display: flex;
    flex-direction: column;
    width: 40%;
  }
`

const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #d6dce2;
  border-radius: 3px;
  padding: 15px;
  outline: none;
  width: 100%;
  margin-bottom: 10px;
`
type OnlyForMembersType = {
  id: number
  value: boolean
  title: string
}

const isTrainingTypeSelected = (
  values: NewTrainingValuesType,
  comparison: OnlyForMembersType
) => values.only_for_members?.value === comparison.value

const Phase3 = ({ nextForm }: { nextForm: () => void }) => {
  const { values } = useSelector((state: RootState) => state.training)

  const changeJobDate = (date: Date, valueName: string) => {
    setAdValues(valueName, date.toISOString())
  }
  const changeEnrollmentDate = (date: Date) => {
    const enrollmentDeadline = format(date, 'yyyy-MM-dd')
    setAdValues('enrollment_deadline', enrollmentDeadline)
  }
  const { t } = useTranslation()
  const onlyForMemberSelections: OnlyForMembersType[] = [
    {
      id: 0,
      value: true,
      title: t('yes')
    },
    {
      id: 1,
      value: false,
      title: t('no')
    }
  ]
  return (
    <Fields>
      <div className="rows" style={{ width: '100%' }}>
        <InputRow>
          <div className="input-row-item">
            <label>
              {t('training-starts')}
              <span>*</span>
            </label>
            <StyledDatePicker
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeSelect
              locale="fi"
              placeholderText="01.01.1900 00:00"
              selected={values.start_time ? parseISO(values.start_time) : null}
              onChange={(date: Date) => {
                changeJobDate(date, 'start_time')
              }}
            />
          </div>
          {values.continuous_training ? (
            <div />
          ) : (
            <div className="input-row-item">
              <label>
                {t('training-ends')}
                <span>*</span>
              </label>
              <StyledDatePicker
                dateFormat="dd.MM.yyyy HH:mm"
                locale="fi"
                showTimeSelect
                placeholderText="01.01.1900 00:00"
                selected={values.end_time ? parseISO(values.end_time) : null}
                onChange={(date: Date) => changeJobDate(date, 'end_time')}
              />
            </div>
          )}
        </InputRow>
        <InputRow>
          <div className="input-row-item">
            <label>{t('only-for-members')}</label>
            <SelectableContainer className="margin-small">
              {onlyForMemberSelections
                ? onlyForMemberSelections.map(c => (
                    <Selectable
                      key={c.id}
                      selected={isTrainingTypeSelected(values, c)}
                      onClick={() => {
                        setAdValues('only_for_members', c)
                      }}
                    >
                      {c.title}
                    </Selectable>
                  ))
                : null}
            </SelectableContainer>
          </div>
          <div className="input-row-item">
            <label>{t('open-spots')}</label>
            <input
              className="form-select"
              size={30}
              type="number"
              pattern="[0-9]"
              placeholder="0"
              onChange={e => setAdValues('positions', e.target.value)}
              value={values.positions}
            />
          </div>
        </InputRow>
        <InputRow>
          {values.continuous_training ? (
            <div />
          ) : (
            <div className="input-row-item">
              <label>{t('enrollment_deadline')}</label>
              <StyledDatePicker
                dateFormat="dd.MM.yyyy"
                locale="fi"
                placeholderText="01.01.1900"
                selected={
                  values.enrollment_deadline
                    ? parseISO(values.enrollment_deadline)
                    : null
                }
                onChange={(date: Date) => {
                  changeEnrollmentDate(date)
                }}
              />
            </div>
          )}
        </InputRow>
        <div>
          <div
            className="additional-info"
            style={{ width: '35%', float: 'right' }}
          >
            <button type="button" onClick={nextForm}>
              {t('next')}
            </button>
          </div>
        </div>
      </div>
    </Fields>
  )
}

export default Phase3
