import { JobsAuthenticated, JobsUnauthenticated } from 'Components/Jobs'
import MainLayout from 'Components/Layout/MainLayout'
import Search from 'Components/Search'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

const SingleJobAdvertisement = ({
  match: {
    params: { id }
  }
}: {
  match: { params: { id: string } }
}) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth)
  return (
    <MainLayout>
      <Search type="job" />
      {isAuthenticated ? (
        <JobsAuthenticated jobId={id} />
      ) : (
        <JobsUnauthenticated />
      )}
    </MainLayout>
  )
}

export default SingleJobAdvertisement
