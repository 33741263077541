/* eslint-disable camelcase */
import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  AsyncThunk,
  createAction,
  PayloadAction
} from '@reduxjs/toolkit'

import { getAxiosInstance } from 'Api'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

interface ProfessionalTitleState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  groups: GroupsType
  titles: Array<ProfessionalTitleType>
}

const initialState: ProfessionalTitleState = {
  loading: 'idle',
  groups: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  titles: []
}
type GroupsType = {
  count: number
  next: string | null
  previous: string | null
  results: Array<GroupsResultsType>
}
export type GroupsResultsType = {
  created_at: string | null
  id: number
  professional_titles: Array<ProfessionalTitleType>
  title: string
  updated_at: string
}
export type ProfessionalTitleType = {
  aliases: Array<number>
  created_at: string
  id: number
  professional_title_group: number
  title: string
  updated_at: string
}
const fetchProfessionalTitleGroups = createAsyncThunk(
  'professionalTitle/fetchGroups',
  async () => {
    const response = await getAxiosInstance().get(`/professional_title_group/`)
    return { data: response.data }
  }
)
const fetchProfessionalTitles = createAsyncThunk(
  'professionalTitle/fetchTitles',
  async () => {
    const response = await getAxiosInstance().get(`/professional_title/`)
    return { data: response.data }
  }
)

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending')
}
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected')
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled')
}

export const resetAction = createAction('reset-tracked-loading-state')

/* eslint-disable no-param-reassign */
const professionalTitleSlice = createSlice({
  name: 'professionalTitle',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        fetchProfessionalTitleGroups.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.groups = action.payload.data
        }
      )
      .addCase(fetchProfessionalTitles.fulfilled, (state, action) => {
        state.titles = action.payload.data
      })
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})
/* eslint-enable no-param-reassign */

export default professionalTitleSlice.reducer
export { fetchProfessionalTitleGroups, fetchProfessionalTitles }
