import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Select, { StylesConfig } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { setShrinked } from 'Features/JobExperience/jobExperienceSlice'

const MIN_YEAR_SELECTION = 1950
export const getDurationOptions = (t: (key: string) => string) => [
  { value: 'year', label: t('year') },
  { value: 'month', label: t('month') },
  { value: 'day', label: t('day') }
]
export const getYearOptions = () => {
  const options = []
  let currentYear = new Date().getFullYear()
  while (currentYear > MIN_YEAR_SELECTION) {
    options.push({ value: currentYear, label: currentYear })
    currentYear -= 1
  }
  return options
}
export const selectStyles: StylesConfig = {
  container: provided => ({
    ...provided,
    maxWidth: '100px'
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #D6DCE2',
    borderRadius: '3px',
    height: 41,
    minHeight: 41
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  singleValue: provided => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px'
  })
}
export const borderlessSelectStyling: StylesConfig = {
  container: provided => ({
    ...provided,
    width: '100%',
    maxWidth: '120px'
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    height: 41,
    minHeight: 41
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  singleValue: provided => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px'
  }),
  input: provided => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px'
  })
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e7eaef;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 16px 18px;
  margin-bottom: 18px;
  input.smaller-input.form-select {
    max-width: none;
    padding: 12px;
  }
  .form-row {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px dashed #d6dce2;
    padding-bottom: 30px;
    margin-bottom: 30px;
    .form-field {
      display: flex;
      flex-direction: column;
      &:first-of-type {
        margin-right: 20px;
      }
      input.smaller-input.form-select {
        margin-bottom: 0;
        max-width: 100px;
      }
      label {
        white-space: nowrap;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    user-select: none;
    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
      }
    }
    span.delete {
      color: #d60c17;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1;
      svg {
        color: #d60c17;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
    span.shrink {
      color: #5c5f63;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 1;
      text-align: center;
      svg {
        color: #0a4ea2;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }
  .work-exp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    span {
      color: #0a4ea2;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }
    svg {
      color: #0a4ea2;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
`

const JobExperinceForm = ({
  experience,
  onChange,
  deleteExperience
}: {
  experience: any
  onChange: (exp: any) => void
  deleteExperience: () => void
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const shrinked = useSelector(
    ({ jobExperience: jobExperienceState }: { jobExperience: any }) =>
      jobExperienceState.shrinked
  )
  const onJobExperienceChange = ({
    target
  }: {
    target: { name: string; value: string | number }
  }) => {
    const newExperience = { ...experience }
    newExperience[target.name] = target.value
    onChange(newExperience)
  }
  if (shrinked.includes(experience.id)) {
    const durationType = getDurationOptions(t)
      .find(dt => dt.value === experience.durationType)
      ?.label.toLowerCase()
    const employer = experience.employer || t('untitled-employer')
    const workAssignment = experience.workAssignment || t('untitled-assignment')
    const duration =
      experience.durationType && experience.duration
        ? `${experience.duration} ${durationType}`
        : null
    const experienceString = [employer, workAssignment, duration]
      .filter((str: string) => str)
      .join(', ')
    return (
      <FormContainer>
        <div
          className="work-exp"
          onClick={() => dispatch(setShrinked(experience.id))}
        >
          <span>{experienceString}</span>
          <FontAwesomeIcon icon={['fal', 'angle-down']} />
        </div>
      </FormContainer>
    )
  }
  return (
    <FormContainer>
      <label>
        {t('employer')} <span>*</span>
      </label>
      <input
        name="employer"
        className="smaller-input form-select"
        value={experience.employer}
        onChange={e => onJobExperienceChange(e)}
      />
      <div className="form-row">
        <div className="form-field">
          <label>
            {t('start-year')} <span>*</span>
          </label>
          <Select
            placeholder={t('select')}
            options={getYearOptions()}
            value={getYearOptions().find(
              year => year.value === experience.startYear
            )}
            styles={selectStyles}
            onChange={(data: any) =>
              onJobExperienceChange({
                target: { name: 'startYear', value: data.value }
              })
            }
          />
        </div>
        <div className="form-field">
          <label>{t('duration')}</label>
          <input
            className="smaller-input form-select"
            name="duration"
            value={experience.duration}
            onChange={e => onJobExperienceChange(e)}
            type="number"
          />
        </div>
        <Select
          placeholder={t('select')}
          options={getDurationOptions(t)}
          value={getDurationOptions(t).find(
            type => type.value === experience.durationType
          )}
          styles={borderlessSelectStyling}
          onChange={(data: any) =>
            onJobExperienceChange({
              target: { name: 'durationType', value: data.value }
            })
          }
        />
      </div>
      <label>
        {t('work-assignment')} <span>*</span>
      </label>
      <input
        name="workAssignment"
        className="smaller-input form-select"
        value={experience.workAssignment}
        onChange={e => onJobExperienceChange(e)}
      />
      <label>{t('work-description')}</label>
      <textarea
        name="workDescription"
        className="smaller-input form-select"
        rows={4}
        value={experience.workDescription}
        onChange={e => onJobExperienceChange(e)}
      />
      <div className="actions">
        <span className="delete" onClick={deleteExperience}>
          {t('remove-job-experience')}{' '}
          <FontAwesomeIcon icon={['fal', 'times-circle']} />
        </span>
        <span
          className="shrink"
          onClick={() => dispatch(setShrinked(experience.id))}
        >
          {t('shrink')} <FontAwesomeIcon icon={['fal', 'angle-up']} />
        </span>
      </div>
    </FormContainer>
  )
}

export default JobExperinceForm
