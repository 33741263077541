/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  AsyncThunk,
  createAction,
  PayloadAction
} from '@reduxjs/toolkit'

import { getAxiosInstance } from 'Api'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>
type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

interface ConversationListItem {
  id: number
  subject: string
  created_at: string
}

interface TargetedAudience {
  users?: Array<number | string | undefined>
  organizations?: Array<number | string | undefined>
  job_advertisements?: Array<number | undefined>
}

export interface ConversationItem {
  subject: string
  targeted_audience: TargetedAudience
  organization?: number
}

export interface ConversationListPayload {
  count: number
  results: Array<ConversationListItem>
  next: string | null
  previous: string | null
}

interface ConversationDetailedListPayload {
  count: number
  results: Array<ConversationDetailsPayload>
  next: string | null
  previous: string | null
}

interface ConversationForAdPayload {
  adId: number | undefined
  data: ConversationListPayload
}

interface ConversationMessageSender {
  id: number
  created_at: string
  sender_type: string
  user: Record<string, unknown>
  organization: Record<string, unknown>
}

export interface ConversationMessage {
  id: number
  created_at: string
  content: string
  sender: ConversationMessageSender
  read_by: Array<ConversationMessageSender>
}

export interface ConversationDetailsPayload {
  id: number
  created_at: string
  subject: string
  messages: Array<ConversationMessage>
  targeted_audience: TargetedAudience
  conversation_type: 'mass-message' | 'single-message'
}

interface ConversationCreatePayload {
  id: number
}

interface ConversationState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  data: {
    results: Array<ConversationListItem>
  }
  detailedData: {
    results: Array<ConversationDetailsPayload>
  }
  adConversations: Record<number, ConversationListPayload>
  createdId: number | null
  conversationDetails: Record<number, ConversationDetailsPayload>
}

const initialState: ConversationState = {
  loading: 'idle',
  data: {
    results: []
  },
  detailedData: {
    results: []
  },
  adConversations: {},
  conversationDetails: {},
  createdId: null
}

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending')
}
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected')
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled')
}
export const resetAction = createAction('reset-tracked-loading-state')
const fetchConversations = createAsyncThunk(
  'conversation/fetch',
  async (organization_id?: number) => {
    let url = '/conversation/'
    if (organization_id) {
      url = `${url}?organization_id=${organization_id}`
    }
    const response = await getAxiosInstance().get(url)
    return response.data
  }
)
const fetchDetailedConversations = createAsyncThunk(
  'conversation/fetchDetailed',
  async (organization_id?: number) => {
    let url = '/conversation/?detailed=true'
    if (organization_id) {
      url = `${url}&organization_id=${organization_id}`
    }
    const response = await getAxiosInstance().get(url)
    return response.data
  }
)
const fetchConversationsForAd = createAsyncThunk(
  'conversation/fetchForAd',
  async (adId: number | undefined) => {
    const response = await getAxiosInstance().get(
      `/conversation/?advertisement_id=${adId}`
    )
    return {
      adId,
      data: response.data
    }
  }
)
const fetchConversationDetails = createAsyncThunk(
  'conversation/fetchDetails',
  async (conversationId: number | undefined) => {
    const response = await getAxiosInstance().get(
      `/conversation/${conversationId}/`
    )
    return response.data
  }
)
const createConversation = createAsyncThunk(
  'conversation/create',
  async (body: ConversationItem) => {
    const response = await getAxiosInstance().post(`/conversation/`, body)
    return response.data
  }
)
const markAsRead = createAsyncThunk(
  'conversation/markAsRead',
  async ({
    id,
    organizationId
  }: {
    id: number
    organizationId?: number | null
  }) => {
    const body = organizationId ? { organization: organizationId } : undefined
    const response = await getAxiosInstance().post(
      `/message/${id}/mark_as_read/`,
      body
    )
    return response.data
  }
)

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        fetchConversations.fulfilled,
        (
          state: ConversationState,
          action: PayloadAction<ConversationListPayload>
        ) => {
          state.data = action.payload
        }
      )
      .addCase(
        fetchDetailedConversations.fulfilled,
        (
          state: ConversationState,
          action: PayloadAction<ConversationDetailedListPayload>
        ) => {
          state.detailedData = action.payload
        }
      )
      .addCase(
        fetchConversationsForAd.fulfilled,
        (
          state: ConversationState,
          action: PayloadAction<ConversationForAdPayload>
        ) => {
          if (!action.payload.adId) {
            return
          }
          state.adConversations[action.payload.adId] = action.payload.data
        }
      )
      .addCase(
        createConversation.fulfilled,
        (
          state: ConversationState,
          action: PayloadAction<ConversationCreatePayload>
        ) => {
          if (!action.payload.id) {
            return
          }
          state.createdId = action.payload.id
        }
      )
      .addCase(
        fetchConversationDetails.fulfilled,
        (
          state: ConversationState,
          action: PayloadAction<ConversationDetailsPayload>
        ) => {
          state.conversationDetails[action.payload.id] = action.payload
        }
      )
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})
export default conversationSlice.reducer
export {
  fetchConversations,
  fetchConversationsForAd,
  createConversation,
  fetchConversationDetails,
  fetchDetailedConversations,
  markAsRead
}
