import { TableContainer } from 'Components/AdminPage/AdminMessages'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import styled from 'styled-components/macro'
import {
  fetchMemberContacts,
  partialUpdateMemberContact,
  PartialMemberContact
} from 'Features/MemberContact/memberContactSlice'
import { fetchOrganizations } from 'Features/Organization/organizationSlice'

const ConversationRequestsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: -35px;
  margin-bottom: -30px;
  p.no-contacts {
    text-align: center;
    color: #252525;
    font-family: Barlow;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    margin: 0;
    width: 100%;
    padding: 15px 0;
  }
  > div {
    width: 100%;
  }
  .header {
    min-width: 0;
  }
  .row {
    min-width: 0;
    font-family: Barlow;
    font-size: 14px;
  }
  button {
    border: none;
    border-radius: 6px;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    &.accept {
      background-color: #6ed663;
    }
    &.decline {
      margin-left: 10px;
      background-color: #ff6174;
    }
  }
`

const Phase3 = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    list: { results },
    loading
  } = useSelector((state: RootState) => state.memberContact)
  const {
    data: { results: orgResults }
  } = useSelector((state: RootState) => state.organization)
  useEffect(() => {
    dispatch(fetchMemberContacts())
    dispatch(fetchOrganizations())
  }, [])
  const updateMemberContact = async (body: PartialMemberContact) => {
    await dispatch(partialUpdateMemberContact(body))
    dispatch(fetchMemberContacts())
  }
  if (!results.length && loading !== 'pending') {
    return (
      <ConversationRequestsContainer>
        <p className="no-contacts">{t('no-member-contacts')}</p>
      </ConversationRequestsContainer>
    )
  }
  return (
    <ConversationRequestsContainer>
      <TableContainer colCount={4}>
        <div className="header">
          <div className="col">{t('organization')}</div>
          <div className="col">{t('message')}</div>
          <div className="col" />
          <div className="col">{t('status')}</div>
        </div>
        {results.map(c => (
          <div className="row" key={c.id}>
            <div className="col bolded">
              {(orgResults || []).find(o => o.id === c.organization)?.name}
            </div>
            <div className="col">{c.contact_message}</div>
            <div className="col" />
            <div className="col">
              {c.status === 'pending' ? (
                <>
                  <button
                    className="accept"
                    type="button"
                    onClick={() =>
                      updateMemberContact({ id: c.id, status: 'accepted' })
                    }
                  >
                    {t('accept')}
                  </button>
                  <button
                    className="decline"
                    type="button"
                    onClick={() =>
                      updateMemberContact({ id: c.id, status: 'rejected' })
                    }
                  >
                    {t('decline')}
                  </button>
                </>
              ) : (
                t(c.status)
              )}
            </div>
          </div>
        ))}
      </TableContainer>
    </ConversationRequestsContainer>
  )
}

export default Phase3
