import React from 'react'
import { useTranslation } from 'react-i18next'
import EmployeeFilter from '../EmployeeSearch/SearchForEmployees/EmployeeFilter'
import EmployeeList from '../EmployeeSearch/SearchForEmployees/EmployeeList'

const EmployeeBank = () => {
  const { t } = useTranslation()
  return (
    <>
      <EmployeeFilter title={t('employee-bank')} />
      <EmployeeList />
    </>
  )
}
export default EmployeeBank
