import React from 'react'
import EmployeeFilter from './SearchForEmployees/EmployeeFilter'
import EmployeeList from './SearchForEmployees/EmployeeList'

const EmployeeSearch = () => {
  return (
    <>
      <EmployeeFilter />
      <EmployeeList />
    </>
  )
}
export default EmployeeSearch
