import React from 'react'
import styled from 'styled-components'
import { PhotoObject, NewPhotoObject } from './PhotoObject'
import { Fields } from '../FormFields'

const PhotoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Phase2 = ({
  imageObjects,
  organizationId
}: {
  imageObjects: Array<object>
  organizationId: any
}) => {
  return (
    <Fields style={{ flexDirection: 'column' }}>
      <PhotoGrid>
        {imageObjects
          ? imageObjects.map((item: any) => (
              <PhotoObject
                key={item.id}
                photoDetails={item}
                organizationId={organizationId}
              />
            ))
          : null}
        <NewPhotoObject organizationId={organizationId} />
      </PhotoGrid>
    </Fields>
  )
}

export default Phase2
