import { fetchTermsOfService } from 'Features/TermsOfService/termsOfServiceSlice'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { getAxiosInstance } from 'Api'
import styled from 'styled-components/macro'
import { checkActiveSession } from 'Features/Auth/authSlice'
import { useHistory, useLocation } from 'react-router-dom'

interface LocationState {
  from: string
}

const TosAgreementContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  .content-container {
    max-width: 760px;
    width: 100%;
    margin: auto;
    padding: 0 15px;
    .content {
      background: #fff;
      padding: 30px;
      border: 1px solid #e7eaef;
      p {
        color: #252525;
        font-family: Barlow;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        font-weight: 500;
        margin: 0;
      }
      button {
        padding: 10px 20px;
        border-radius: 6px;
        background-color: #d60c17;
        color: #ffffff;
        font-family: Barlow;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        border: none;
        cursor: pointer;
      }
      .tos {
        margin: 1rem 0;
        color: #252525;
        font-family: Barlow;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        font-weight: 500;
        margin: 1.5rem 0;
        padding: 15px;
        border: 1px solid #e7eaef;
        max-height: 500px;
        overflow-y: auto;
        ::-webkit-scrollbar {
          width: 10px;
        }
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border: 2px solid #fff;
        }
        ::-webkit-scrollbar-thumb {
          background: #888;
          border: 2px solid #fff;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }
`

function AgreeTos() {
  const {
    auth: {
      user: {
        is_employer: isEmployer,
        is_trainer: isTrainer,
        id,
        tos_agreed: tosAgreed
      },
      isAuthenticated
    },
    termsOfService: { termsOfService }
  } = useSelector((state: RootState) => state)
  const { t } = useTranslation()
  const history = useHistory()
  const {
    state: { from }
  }: { state: LocationState } = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchTermsOfService())
    dispatch(checkActiveSession())
  }, [])
  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/')
    }
    if (tosAgreed) {
      history.push(from)
    }
  }, [tosAgreed, isAuthenticated])
  const termsToShow = () => {
    if (isEmployer) {
      return termsOfService[0]?.employer_terms
    }
    if (isTrainer) {
      return termsOfService[0]?.training_provider_terms
    }

    return termsOfService[0]?.employer_terms
  }

  if (!id) {
    return null
  }
  return (
    <TosAgreementContainer>
      <div className="content-container">
        <div className="content">
          <p>{t('tos-intro')}</p>
          <div
            className="tos"
            dangerouslySetInnerHTML={{ __html: termsToShow() }} // eslint-disable-line
          />
          <button
            type="button"
            onClick={() =>
              getAxiosInstance()
                .patch(`/user/${id}/`, { tos_agreed: true })
                .then(() => dispatch(checkActiveSession()))
            }
          >
            {t('accept')}
          </button>
        </div>
      </div>
    </TosAgreementContainer>
  )
}

export default AgreeTos
