import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseISO } from 'date-fns'
import { SingleTrainingType } from 'Features/Training/trainingSlice'
import JobBackground from 'Assets/job-background.jpg'
import RakennusliittoLogo from 'Assets/suomi-vaaka-rgb.png'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { displayValue } from 'Util/trainingUtils'

const ObjectContainer = styled.div`
  background: #fff;
  box-shadow: 0 7px 7px -10px rgba(0, 0, 0, 0.41);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  @media screen and ${props => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`
const DetailsBlock = styled.div`
  display: flex;
  @media screen and ${props => props.theme.breakpoints.md} {
    width: 50%;
  }
`
const EventillaImage = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  padding: 15px;
  align-items: center;
  .image-area {
    background: url(${RakennusliittoLogo});
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`
const TrainingImage = styled.div`
  width: 50%;
  height: 100%;
  background: url(${(props: { imageUrl: string | undefined }) =>
    props.imageUrl || JobBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const TrainingTitleBlock = styled.div`
  height: 100%;
  padding: 10px;
  width: 60%;
  background: #e7eaef;
  display: flex;
  flex-direction: column;
`
const TrainingType = styled.div`
  color: #a8aeb4;
  text-transform: uppercase;
  font-family: 'Source Code Pro';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
`
const TrainingTitle = styled.div`
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  overflow-wrap: break-word;
`
const TrainingOrganizer = styled.div`
  color: #5c5f63;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: auto;
`
const TrainingDetailsBlock = styled.div`
  background: #fff;
  padding: 5px 5px;
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: space-between;
  @media screen and ${props => props.theme.breakpoints.md} {
    padding: 25px 20px;
  }
`
const TrainingDetailsObject = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0px;
`
const ElementIcon = styled(FontAwesomeIcon)`
  color: #a8aeb4;
  margin-right: 4px;
  min-width: 20px;
  max-width: 20px;
`
const TrainingDetails = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`
const AdditionalDetailsButton = styled.button`
  height: 51px;
  width: 100%;
  border-radius: 8px;
  background-color: #d60c17;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  border: none;
  outline: none;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`
const ButtonContainer = styled.div`
  width: 40%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const displayTime = (timeStamp: string) => {
  if (timeStamp) {
    const date = format(parseISO(timeStamp), 'dd.MM.yyyy HH:mm')
    return `${date}`
  }
  return undefined
}
const defineTrainingType = (trainingType: string) => {
  const { t } = useTranslation()
  if (trainingType === 'web') {
    return (
      <>
        <FontAwesomeIcon icon={['far', 'house-signal']} /> {t('web-course')}
      </>
    )
  }
  return t('training')
}

const renderOrganizationName = (
  organization: { id: number; name: string } | null
) => {
  if (organization) {
    return <TrainingOrganizer>{organization.name}</TrainingOrganizer>
  }
  return <TrainingOrganizer>Rakennusliitto</TrainingOrganizer>
}
const TrainingObject = ({ data }: { data: SingleTrainingType }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <ObjectContainer>
      <DetailsBlock>
        {data.eventilla_id ? (
          <EventillaImage>
            <div className="image-area" />
          </EventillaImage>
        ) : (
          <TrainingImage
            imageUrl={
              data.images && data.images[0] ? data.images[0].url : undefined
            }
          />
        )}

        <TrainingTitleBlock>
          <TrainingType>{defineTrainingType(data.training_type)}</TrainingType>
          <TrainingTitle>{data.subject}</TrainingTitle>
          {renderOrganizationName(data.organization)}
        </TrainingTitleBlock>
      </DetailsBlock>
      <DetailsBlock>
        <TrainingDetailsBlock>
          {data.training_location ? (
            <TrainingDetailsObject>
              <ElementIcon icon={['far', 'map-marker-alt']} />
              <TrainingDetails>{`${data.training_location.address}, ${data.training_location.location.name}`}</TrainingDetails>
            </TrainingDetailsObject>
          ) : null}
          <TrainingDetailsObject>
            <ElementIcon icon={['far', 'calendar']} />
            <TrainingDetails>
              {data.continuous_training
                ? `${displayTime(data.start_time)} - ${displayValue(
                    t('continuous')
                  )}`
                : `${displayTime(data.start_time)} - ${displayTime(
                    data.end_time
                  )}`}
            </TrainingDetails>
          </TrainingDetailsObject>
        </TrainingDetailsBlock>
        <ButtonContainer>
          <AdditionalDetailsButton
            onClick={() => history.push(`/trainings/${data.id}`)}
          >
            {data.eventilla_id
              ? t('only-for-rakennusliitto-members')
              : t('additional-information-and-registration')}
          </AdditionalDetailsButton>
        </ButtonContainer>
      </DetailsBlock>
    </ObjectContainer>
  )
}
export default TrainingObject
