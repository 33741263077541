import React from 'react'
import styled from 'styled-components'
import TrainingsBanner from './TrainingsBanner'
import TrainingSearch from './TrainingSearch'

const TrainingsContainer = styled.div`
  background: #fff;
`
const TrainingsResults = ({ trainingId }: { trainingId?: string | null }) => {
  return (
    <TrainingsContainer>
      <TrainingsBanner />
      <TrainingSearch trainingId={trainingId} />
    </TrainingsContainer>
  )
}
TrainingsResults.defaultProps = {
  trainingId: null
}
export default TrainingsResults
