/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const DropDown = styled.div`
  display: flex;
  .dropdown {
    position: absolute;
    margin-top: 40px;
  }
  .dropdown-content {
    background: #fff;
    position: absolute;
    min-width: 220px;
    overflow: auto;
    border: 1px solid #dcdddf;
    background-color: #d60c17;
    z-index: 1;
    line-height: 16px;
  }
  .dropdown-content div {
    padding: 10px;
    text-decoration: none;
    display: block;
    text-align: start;
    cursor: default;
    color: #fff;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    &:hover {
      background-color: #ddd;
      cursor: pointer;
    }
  }
`
const EllipsisIcon = styled(FontAwesomeIcon)`
  font-size: 1.5em;
  height: 20px;
  color: #5c5f63;
  &:hover {
    cursor: pointer;
  }
`
const LinkTitle = styled.div`
  font-family: barlow;
  color: #252525;
  margin-right: 50px;
  svg {
    vertical-align: middle;
    margin-left: 0.5rem;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

interface DropdownProps {
  title: string
  action: () => void
}

const NavigationDropdownMenu = ({
  options,
  dropdownLinkTitle
}: {
  options: DropdownProps[]
  dropdownLinkTitle: string
}) => {
  const myRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const [settingsOpen, setSettingsOpen] = useState(false)
  const openObjects = () => {
    setSettingsOpen(!settingsOpen)
  }
  const DropdownElement = (elementDetails: DropdownProps) => {
    const onClickAction = () => {
      elementDetails.action()
      setSettingsOpen(false)
    }
    return <div onClick={onClickAction}>{elementDetails.title}</div>
  }
  const handleClickOutside = (e: { target: any }) => {
    const node = myRef.current
    if (node?.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setSettingsOpen(false)
  }
  useEffect(() => {
    if (settingsOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [settingsOpen])
  return (
    <DropDown onClick={() => openObjects()}>
      <LinkTitle>
        {t(dropdownLinkTitle)}
        <EllipsisIcon
          className="dropbtn"
          icon={['far', 'angle-down']}
          onClick={() => openObjects()}
        />
      </LinkTitle>
      <div className="dropdown" ref={myRef}>
        {settingsOpen ? (
          <div id="myDropdown" className="dropdown-content">
            {options
              ? options.map((opt: DropdownProps) => (
                  <DropdownElement
                    key={opt.title}
                    title={opt.title}
                    action={opt.action}
                  />
                ))
              : null}
          </div>
        ) : null}
      </div>
    </DropDown>
  )
}

export default NavigationDropdownMenu
