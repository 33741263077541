import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

interface TabProps {
  active: boolean
}

const TabContainer = styled.div`
  display: flex;
  background: #f4f5f7;
  user-select: none;
`
const Tab = styled.span`
  cursor: pointer;
  padding: 4px 3px;
  color: #5c5f63;
  font-family: Barlow;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  background: ${(props: TabProps) => (props.active ? '#FFF' : '#E7EAEF')};
  border-right: 4px solid #f4f5f7;
  white-space: nowrap;
  @media screen and ${props => props.theme.breakpoints.lg} {
    font-size: 14px;
    padding: 8px 13px;
  }
`

const FormTabs = ({
  currentTab,
  onTabChange
}: {
  currentTab: number
  onTabChange: Function
}) => {
  const { t } = useTranslation()
  return (
    <TabContainer>
      <Tab active={currentTab === 0} onClick={() => onTabChange(0)}>
        {t('user-details')}
      </Tab>
      <Tab active={currentTab === 1} onClick={() => onTabChange(1)}>
        {t('knowledge')}
      </Tab>
      <Tab active={currentTab === 2} onClick={() => onTabChange(2)}>
        {t('work-experience')}
      </Tab>
      <Tab active={currentTab === 3} onClick={() => onTabChange(3)}>
        {t('education')}
      </Tab>
      <Tab active={currentTab === 4} onClick={() => onTabChange(4)}>
        {t('additional-information')}
      </Tab>
    </TabContainer>
  )
}

export default FormTabs
