import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState, useAppDispatch } from 'Store'
import { fetchPaginatedEmployees } from 'Features/MemberProfile/memberProfileSlice'
import { useSelector } from 'react-redux'
import { fetchLocations } from 'Features/NewAdvertisement/newAdvertisementSlice'
import {
  fetchProfessionalTitleGroups,
  fetchProfessionalTitles,
  GroupsResultsType,
  ProfessionalTitleType
} from 'Features/ProfessionalTitle/professionalTitleSlice'
import { useLocation } from 'react-router-dom'
import { NameSearchField } from 'Components/EmployeeSearch/SearchForEmployees/EmployeeFilter'

const StyledCheckbox = styled.div`
  color: #252525;
  font-size: 14px;
  font-family: Barlow;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  @media screen and ${props => props.theme.breakpoints.md} {
    margin: 0px;
  }
  .input {
    margin: 0px;
  }
`
const FilterBackground = styled.div`
  width: 100%;
  background: #fff;
  padding: 0px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  @media screen and ${props => props.theme.breakpoints.xs} {
    padding: 20px;
  }
`
const SearchTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
`
const SelectionsArea = styled.div`
  display: flex;
  margin: 26px 0px 10px 0px;
  flex-direction: column;
  @media screen and ${props => props.theme.breakpoints.lg} {
    flex-direction: row;
  }
`
const SelectionObjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 280px;
  margin: 0px 36px 10px 0px;
  margin-bottom: 5px @media screen and ${props => props.theme.breakpoints.lg} {
    margin: 0px 36px 0px 0px;
  }
`
const SelectionObjectTitle = styled.div`
  color: #2a2a2a;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
`
const AreaSelection = styled(Select)`
  margin-top: 8px;
  width: auto;
  @media screen and ${props => props.theme.breakpoints.xs} {
    width: 280px;
  }
`
const SearchButton = styled.div`
  height: 37px;
  border-radius: 6px;
  background-color: #d60c17;
  border: none;
  outline: none;
  align-items: center;
  color: #ffffff;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  width: 273px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 0px 20px 0px 20px;
  position: relative;
  svg {
    position: absolute;
    right: 15px;
  }
  @media screen and ${props => props.theme.breakpoints.xs} {
    padding: 0px 30px 0px 30px;
  }
`
const SearchIcon = styled(FontAwesomeIcon)``
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
type EventType = {
  id: number
  label: string
  value: string
}
type OptionsListType = {
  id: number
  value: string
  label: string
}
const MultiSelectionObject = ({
  optionsList,
  optionsTitle,
  optionsPlaceholder,
  changeType
}: {
  optionsList: Array<OptionsListType>
  optionsTitle: string
  optionsPlaceholder: string
  changeType: string
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    dispatch(
      fetchPaginatedEmployees({
        page: 1,
        filters: {
          professional_titles: [],
          professional_title_groups: [],
          locations: [],
          favorites: location.pathname.indexOf('/pankki') !== -1,
          full_name: '',
          only_active_job_seekers: true
        }
      })
    )
  }, [dispatch])
  const handleChange = (e: Array<EventType> | null) => {
    if (e === null) {
      dispatch({
        type: `memberProfile/${changeType}`,
        payload: []
      })
    } else {
      const values = [...e].map(opt => opt.value)
      dispatch({
        type: `memberProfile/${changeType}`,
        payload: values
      })
    }
  }
  return (
    <SelectionObjectContainer>
      <SelectionObjectTitle>{t(optionsTitle)}</SelectionObjectTitle>
      <AreaSelection
        isMulti
        hideSelectedOptions
        name="colors"
        placeholder={t(optionsPlaceholder)}
        options={optionsList}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(e: Array<EventType>) => handleChange(e)}
      />
    </SelectionObjectContainer>
  )
}

const EmployeeFilter = ({ title }: { title?: string }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { locations } = useSelector(
    (state: RootState) => state.newAdvertisement
  )
  const { groups } = useSelector((state: RootState) => state.professionalTitle)
  const { titles } = useSelector((state: RootState) => state.professionalTitle)
  const { results } = groups
  const { orderBy } = useSelector((state: RootState) => state.memberProfile)
  useEffect(() => {
    dispatch(fetchLocations())
    dispatch(fetchProfessionalTitleGroups())
    dispatch(fetchProfessionalTitles())
  }, [dispatch])

  const jobLocations = locations
    ? locations.map(({ name, id }: { name: string; id: number }) => ({
        id,
        value: name,
        label: name
      }))
    : []
  const professionalGroups = results
    ? results.map((result: GroupsResultsType) => ({
        id: result.id,
        value: result.title,
        label: result.title
      }))
    : []
  const professionalTitles = titles
    ? titles.map((result: ProfessionalTitleType) => ({
        id: result.id,
        value: result.title,
        label: result.title
      }))
    : []
  const { filterLocations } = useSelector(
    (state: RootState) => state.memberProfile
  )
  const { filterGroups } = useSelector(
    (state: RootState) => state.memberProfile
  )
  const { filterTitles } = useSelector(
    (state: RootState) => state.memberProfile
  )
  const { filterName } = useSelector((state: RootState) => state.memberProfile)
  const { onlyActiveJobSeekers } = useSelector(
    (state: RootState) => state.memberProfile
  )

  const filterResults = () => {
    dispatch(
      fetchPaginatedEmployees({
        page: 1,
        filters: {
          professional_titles: filterTitles,
          professional_title_groups: filterGroups,
          locations: filterLocations,
          favorites: location.pathname.indexOf('/pankki') !== -1,
          full_name: filterName,
          orderBy,
          only_active_job_seekers: onlyActiveJobSeekers
        }
      })
    )
  }
  const changeOnlyActiveJobSeekers = () => {
    dispatch({
      type: `memberProfile/changeOnlyActiveJobSeekers`,
      payload: !onlyActiveJobSeekers
    })
  }
  const OnlyActiveJobseekersCheckbox = () => {
    return (
      <StyledCheckbox onClick={() => changeOnlyActiveJobSeekers()}>
        <input
          type="checkbox"
          checked={onlyActiveJobSeekers}
          onChange={() => changeOnlyActiveJobSeekers()}
        />
        {t('show-only-active-job-seekers')}
      </StyledCheckbox>
    )
  }
  useEffect(() => {
    dispatch(
      fetchPaginatedEmployees({
        page: 1,
        filters: {
          professional_titles: filterTitles,
          professional_title_groups: filterGroups,
          locations: filterLocations,
          favorites: location.pathname.indexOf('/pankki') !== -1,
          full_name: filterName,
          orderBy,
          only_active_job_seekers: onlyActiveJobSeekers
        }
      })
    )
  }, [orderBy])
  return (
    <FilterBackground>
      <SearchTitle>{title || t('search-employees')}</SearchTitle>
      <SelectionsArea>
        <NameSearchField filterName={filterName} />
        <MultiSelectionObject
          optionsList={jobLocations}
          optionsTitle="working-area"
          optionsPlaceholder="location-placeholder"
          changeType="changeLocations"
        />
        <MultiSelectionObject
          optionsList={professionalGroups}
          optionsTitle="select-field"
          optionsPlaceholder="Valitse ala"
          changeType="changeGroups"
        />
        <MultiSelectionObject
          optionsList={professionalTitles}
          optionsTitle="select-professional-title"
          optionsPlaceholder="Valitse ammattinimike"
          changeType="changeTitles"
        />
        <OnlyActiveJobseekersCheckbox />
      </SelectionsArea>
      <ButtonContainer>
        <SearchButton onClick={() => filterResults()}>
          <>
            {t('search')}
            <SearchIcon icon={['fas', 'search']} />
          </>
        </SearchButton>
      </ButtonContainer>
    </FilterBackground>
  )
}

EmployeeFilter.defaultProps = {
  title: null
}

export default EmployeeFilter
