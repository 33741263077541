import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

const SearchResults = styled.div`
  display: flex;
  align-items: center;
`

const SearchResultsComponent = ({ count }: { count: number }) => {
  const { t } = useTranslation()
  return (
    <SearchResults>
      <div>
        {t('search-results')}: {count}
      </div>
    </SearchResults>
  )
}

export default SearchResultsComponent
