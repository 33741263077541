import React from 'react'
import EducationLayout from 'Components/Layout/EducationLayout'
import styled from 'styled-components'
import EducationFrontpage from 'Components/EducationPage/EducationFrontpage'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`

const EducationPanel = () => {
  return (
    <EducationLayout>
      <ContentContainer>
        <EducationFrontpage />
      </ContentContainer>
    </EducationLayout>
  )
}
export default EducationPanel
