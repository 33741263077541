/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ObjectTableStyles from 'Components/ObjectTable'
import { useTranslation } from 'react-i18next'
import { RootState, useAppDispatch } from 'Store'
import { useSelector } from 'react-redux'
import {
  fetchDocuments,
  deleteDocument,
  DocumentType
} from 'Features/ContentManagement/contentManagementSlice'
import { toast } from 'react-toastify'
import DocumentDeleteModal from './DocumentDeleteModal'
import PhotoUploadModal from './FieldComponents/FileUploadModal'

const {
  ObjectTableBackground,
  ObjectTableContainer,
  ObjectTableTitle,
  ObjectTableTopComponent,
  ObjectTableBottomComponent,
  ObjectTableTop,
  ObjectTableHeader,
  ObjectTableContent,
  ObjectTableItem,
  ObjectTableBottomNewObjectButton,
  ObjectTableHeaderItem
} = ObjectTableStyles

const AlignedOptionsBlock = styled(ObjectTableStyles.ObjectTableOptions)`
  margin-left: auto;
`
const ObjectTableFooter = styled(ObjectTableStyles.ObjectTableFooter)`
  display: flex;
  justify-content: space-between;
  padding: 28px 20px 28px 20px;
`
const TrashCanIcon = styled(FontAwesomeIcon)`
  color: #252525;
  margin: 0px 0px 0px 10px;
`
const Options = styled.div`
  white-space: nowrap;
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  justify-content: space-evenly;
`
const DeleteOrganization = styled.div`
  text-decoration: none;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
`
const DocumentName = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
`
const CopyButton = styled(FontAwesomeIcon)`
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
`
const CopyUrlButton = styled.div`
  height: 90%;
  margin-left: 20px;
  margin-right: 20px;
  width: 200px;
  border-radius: 4px;
  background-color: #6ed663;
  font-family: Barlow;
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`
const SuperAdminDocumentManagement = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [addDocumentModalOpen, setAddDocumentModalOpen] = useState(false)
  const { documents } = useSelector(
    (state: RootState) => state.contentManagement
  )
  const fetchFiltered = () => {
    dispatch(fetchDocuments())
  }

  useEffect(() => {
    fetchFiltered()
  }, [dispatch, addDocumentModalOpen])
  const copyTextToClipboard = (documentUrl: string) => {
    navigator.clipboard.writeText(documentUrl)
    toast.success(<strong>{t('link-copied-to-clipboard')}</strong>)
  }

  const DocumentObject = ({ data }: { data: DocumentType }) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    return (
      <ObjectTableItem key={data.id}>
        {deleteModalOpen ? (
          <DocumentDeleteModal
            modalToggle={setDeleteModalOpen}
            deleteDocument={deleteDocument}
            documentDetails={data}
          />
        ) : null}
        <DocumentName>{data.document_name}</DocumentName>
        <CopyUrlButton onClick={() => copyTextToClipboard(data.document)}>
          Kopioi upotuslinkki <CopyButton icon={['far', 'copy']} />
        </CopyUrlButton>
        <AlignedOptionsBlock>
          <Options>
            <DeleteOrganization onClick={() => setDeleteModalOpen(true)}>
              {t('remove')}
              <TrashCanIcon icon={['far', 'trash']} />
            </DeleteOrganization>
          </Options>
        </AlignedOptionsBlock>
      </ObjectTableItem>
    )
  }
  const documentObjects = documents
    ? documents.map(
        (
          document
          // eslint-disable-next-line consistent-return
        ) => {
          return <DocumentObject data={document} key={document.id} />
        }
      )
    : null

  return (
    <ObjectTableBackground>
      {addDocumentModalOpen ? (
        <PhotoUploadModal modalToggle={setAddDocumentModalOpen} />
      ) : null}
      <ObjectTableTopComponent>
        <ObjectTableTop>
          <ObjectTableTitle>{t('document-management')}</ObjectTableTitle>
        </ObjectTableTop>
      </ObjectTableTopComponent>
      <ObjectTableBottomComponent>
        <ObjectTableContainer>
          <ObjectTableHeader>
            <ObjectTableHeaderItem>{t('document-name')}</ObjectTableHeaderItem>
          </ObjectTableHeader>
          <ObjectTableContent>{documentObjects}</ObjectTableContent>
          <ObjectTableFooter>
            <ObjectTableBottomNewObjectButton
              onClick={() => setAddDocumentModalOpen(true)}
            >
              {t('add-new-document')}
              <FontAwesomeIcon
                style={{ marginTop: '14px' }}
                color="#0A4EA2"
                icon={['fal', 'plus-circle']}
              />
            </ObjectTableBottomNewObjectButton>
          </ObjectTableFooter>
        </ObjectTableContainer>
      </ObjectTableBottomComponent>
    </ObjectTableBackground>
  )
}

export default SuperAdminDocumentManagement
