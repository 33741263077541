/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import {
  fetchOrganizations,
  OrganizationType,
  deleteOrganization
} from 'Features/Organization/organizationSlice'
import ObjectTableStyles from 'Components/ObjectTable'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from 'Store'
import SearchResult from 'Components/FrontendPagination/SearchResults'
import OrganizationDeleteConfirmationModal from './OrganizationDeleteModal'

const {
  ObjectTableBackground,
  ObjectTableTitle,
  ObjectTableTopComponent,
  ObjectTableBottomComponent,
  ObjectTableTop,
  ObjectTableHeader,
  ObjectTableContent,
  ObjectTableObjectTitle,
  ObjectTableAngleRightIcon,
  ObjectTableSortCaretIcon
} = ObjectTableStyles
const Options = styled.div`
  white-space: nowrap;
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  justify-content: space-evenly;
`
const ShowOrganization = styled(Link)`
  text-decoration: none;
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 14px;
  margin-right: 20px;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`
const ObjectTableContainer = styled(ObjectTableStyles.ObjectTableContainer)`
  min-width: 1100px;
`

const ObjectTableItem = styled(ObjectTableStyles.ObjectTableItem)`
  min-height: 44px;
  height: auto;
`
const ObjectTableHeaderItem = styled(ObjectTableStyles.ObjectTableHeaderItem)`
  width: 13%;
`
const SearchIcon = styled(FontAwesomeIcon)`
  right: 25px;
  position: relative;
  color: #d60c17;
  &:hover {
    cursor: pointer;
  }
`
const SearchBarContainer = styled.div`
  display: flex;
  background: #ffffff;
`
const SearchBar = styled.div`
  background: #fff;
  padding: 20px;
`
const InputContainer = styled.div`
  width: 35%;
  min-width: 300px;
`
const StyledInput = styled.input`
  border: 1px solid #d6dce2;
  border-radius: 3px;
  padding: 15px;
  outline: none;
  font-family: Barlow;
  width: 100%;
`
const DeleteOrganization = styled.div`
  text-decoration: none;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
`
const TrashCanIcon = styled(FontAwesomeIcon)`
  color: #252525;
  margin: 0px 0px 0px 10px;
`

const OrganizationManagement = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchOrganizations())
  }, [dispatch])
  const { data } = useSelector((state: RootState) => state.organization)
  const { results } = data

  const [searchName, setSearchName] = useState<string>('')
  const [searchVat, setSearchVat] = useState<string>('')

  const [listOrder, setListOrder] = useState({
    organizationName: 1,
    businessId: 1,
    employmentServices: 1,
    education: 1
  })
  const [sortedBy, setSortedBy] = useState('organizationName')
  type ColumnType =
    | 'organizationName'
    | 'businessId'
    | 'employmentServices'
    | 'education'

  const sortList = (column: ColumnType) => {
    setSortedBy(column)
    setListOrder({ ...listOrder, [column]: listOrder[column] * -1 })
  }

  type CaretProps = {
    columnName:
      | 'organizationName'
      | 'businessId'
      | 'employmentServices'
      | 'education'
  }
  const CaretIcon = ({ columnName }: CaretProps) => {
    if (sortedBy === columnName) {
      if (listOrder[columnName] === 1) {
        return <ObjectTableSortCaretIcon icon={['fas', 'caret-up']} />
      }
      return <ObjectTableSortCaretIcon icon={['fas', 'caret-down']} />
    }
    return null
  }
  const OrganizationComponent = ({
    organization
  }: {
    organization: OrganizationType
  }) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    return (
      <ObjectTableItem>
        {deleteModalOpen ? (
          <OrganizationDeleteConfirmationModal
            modalToggle={setDeleteModalOpen}
            deleteOrganization={deleteOrganization}
            organizationDetails={organization}
          />
        ) : null}
        <ObjectTableObjectTitle>{organization.name}</ObjectTableObjectTitle>
        <ObjectTableHeaderItem>{organization.vat_number}</ObjectTableHeaderItem>
        <ObjectTableHeaderItem>
          {organization.is_trainer ? t('yes') : t('no')}
        </ObjectTableHeaderItem>
        <ObjectTableHeaderItem>
          {organization.is_employer ? t('yes') : t('no')}
        </ObjectTableHeaderItem>
        <Options>
          <ShowOrganization to={`organisaatiot/${organization.id}/`}>
            {t('open-organization-profile')}
            <ObjectTableAngleRightIcon icon={['far', 'angle-right']} />
          </ShowOrganization>
          <DeleteOrganization onClick={() => setDeleteModalOpen(true)}>
            {t('remove')}
            <TrashCanIcon icon={['far', 'trash']} />
          </DeleteOrganization>
        </Options>
      </ObjectTableItem>
    )
  }
  const organizationsList = results
    ? results.map(
        (
          organization: OrganizationType
          // eslint-disable-next-line consistent-return
        ) => {
          return (
            <OrganizationComponent
              key={organization.id}
              organization={organization}
            />
          )
        }
      )
    : null

  return (
    <ObjectTableBackground>
      <ObjectTableTopComponent>
        <ObjectTableTop>
          <ObjectTableTitle>{t('organization-management')}</ObjectTableTitle>
        </ObjectTableTop>
      </ObjectTableTopComponent>
      <ObjectTableBottomComponent>
        <ObjectTableContainer>
          <SearchBarContainer>
            <SearchBar>
              <InputContainer>
                <StyledInput
                  placeholder={t('search-for-organization')}
                  onChange={e => setSearchName(e.target.value)}
                />
                <SearchIcon
                  icon={['fas', 'search']}
                  onClick={() =>
                    dispatch(fetchOrganizations({ name: searchName }))
                  }
                />
              </InputContainer>
            </SearchBar>
            <SearchBar>
              <InputContainer>
                <StyledInput
                  placeholder={t('search-with-vat')}
                  onChange={e => setSearchVat(e.target.value)}
                />
                <SearchIcon
                  icon={['fas', 'search']}
                  onClick={() =>
                    dispatch(fetchOrganizations({ vat: searchVat }))
                  }
                />
              </InputContainer>
            </SearchBar>
            <SearchResult count={results?.length || 0} />
          </SearchBarContainer>
          <ObjectTableHeader>
            <ObjectTableHeaderItem onClick={() => sortList('organizationName')}>
              {t('organization')}
              <CaretIcon columnName="organizationName" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('businessId')}>
              {t('business-id')}
              <CaretIcon columnName="businessId" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('education')}>
              {t('education')}
              <CaretIcon columnName="education" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem
              onClick={() => sortList('employmentServices')}
            >
              {t('employment-services')}
              <CaretIcon columnName="employmentServices" />
            </ObjectTableHeaderItem>
          </ObjectTableHeader>
          <ObjectTableContent>{organizationsList}</ObjectTableContent>
        </ObjectTableContainer>
      </ObjectTableBottomComponent>
    </ObjectTableBackground>
  )
}

export default OrganizationManagement
