import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import organizationIsHousehold from 'Util/organizationIsHousehold'

interface TabProps {
  active: boolean
}

const TabContainer = styled.div`
  display: flex;
  background: #f4f5f7;
  user-select: none;
`
const Tab = styled.span`
  border-radius: 3px 3px 0 0;
  display: flex;
  align-items: center;
  background-color: #e7eaef;
  height: 45px;
  font-weight: 600;
  cursor: pointer;
  padding: 0px 26px 0px 15px;
  color: ${(props: TabProps) => (props.active ? '#0A4EA2' : '#5c5f63')};
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  background: ${(props: TabProps) => (props.active ? '#FFF' : '#E7EAEF')};
  border-right: 4px solid #f4f5f7;
  white-space: nowrap;
`
const TabIcon = styled(FontAwesomeIcon)`
  color: #a8aeb4;
  margin-right: 10px;
`
const FormTabs = ({
  currentTab,
  onTabChange
}: {
  currentTab: number
  onTabChange: Function
}) => {
  const { t } = useTranslation()
  const isHousehold = organizationIsHousehold()
  return (
    <TabContainer>
      <Tab active={currentTab === 0} onClick={() => onTabChange(0)}>
        <TabIcon icon={['fas', 'folder']} fontSize="1.1em" />
        {t('illustrations')}
      </Tab>
      {!isHousehold && (
        <Tab active={currentTab === 1} onClick={() => onTabChange(1)}>
          <TabIcon icon={['fas', 'folder']} fontSize="1.1em" />
          {t('company-photos')}
        </Tab>
      )}
    </TabContainer>
  )
}

export default FormTabs
