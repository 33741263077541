import React from 'react'
import styled from 'styled-components'
import AdStatusBoard from 'Components/AdStatusBoard'
import { SuperAdminLayout } from 'Components/Layout/SuperAdminLayout'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`

const SuperAdminAdStatusPage = (props: any) => {
  const { computedMatch } = props
  return (
    <SuperAdminLayout>
      <ContentContainer>
        <AdStatusBoard adId={computedMatch.params.adId} />
      </ContentContainer>
    </SuperAdminLayout>
  )
}
export default SuperAdminAdStatusPage
