import { useSelector } from 'react-redux'
import { RootState } from 'Store'

const organizationIsHousehold = () => {
  const {
    data: { results: organizations }
  } = useSelector((state: RootState) => state.organization)
  const { itemIndex } = useSelector((state: RootState) => state.admin)
  const isHousehold = organizations
    ? organizations[itemIndex].is_household
    : false

  return isHousehold
}

export default organizationIsHousehold
