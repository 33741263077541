import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'
import styled from 'styled-components/macro'

const ApplicationsContainer = styled.div`
  padding: 0 15px;
  margin-top: -15px;
  margin-bottom: -15px;
  display: flex;
  flex-direction: column;
  .job-application {
    display: flex;
    &.header {
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 5px;
    }
    .col {
      font-size: 14px;
      font-family: Barlow;
      width: 50%;
      padding: 5px 0;
      &.bolded {
        color: #0a4ea2;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
        cursor: pointer;
      }
    }
  }
`

const Phase2 = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <ApplicationsContainer>
      <div className="job-application header">
        <div className="col">{t('title')}</div>
        <div className="col">{t('status')}</div>
      </div>
      {user &&
        user.member_profile &&
        user.member_profile.job_applications.map(ap => (
          <div className="job-application" key={ap.id}>
            <div
              className="col bolded"
              onClick={() => history.push(`/jobs/${ap.job_advertisement}/`)}
            >
              {ap.job_advertisement_title}
            </div>
            <div className="col">{t(ap.status)}</div>
          </div>
        ))}
    </ApplicationsContainer>
  )
}

export default Phase2
