import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  FrontPage,
  Trainings,
  Jobs,
  CreateMemberProfile,
  AdminPanel,
  SingleJobAdvertisement,
  AdminJobs,
  AdminNewJob,
  AdStatusPage,
  AdminDetails,
  UserProfile,
  EmployeeSearch,
  AdminLogin,
  SuperAdminPanel,
  EducationPanel,
  NewTraining,
  AdminUserManagement,
  AdminMessages,
  AdminArchivedPage,
  NewOrganization,
  ModifyAd,
  SingleTrainingAdvertisement,
  ModifyTrainingAd,
  AdminTrainings,
  EmployeeBank,
  AdminOrganizationMessages,
  EducationMessages,
  EducationUserManagement,
  EducationDetails,
  AdminPassedTrainings,
  AdminOngoingTrainings,
  AdminSingleTraining,
  AdminRequestPassword,
  AdminRequestPasswordConfirm,
  MemberPalkkaus,
  AgreeTos,
  FrontPageMillContent,
  HouseholdLogin,
  NewHouseholdOrganization
} from 'Pages'
import {
  SuperAdminUserManagement,
  SuperAdminOrganizationDetails,
  SuperAdminOrganizationManagement,
  SuperAdminMessages,
  SuperAdminJobs,
  SuperAdminArchivedPage,
  SuperAdminAdStatusPage,
  SuperAdminModifyAd,
  SuperAdminEducations,
  SuperAdminOngoingEducations,
  SuperAdminPassedEducations,
  SuperAdminSingleTraining,
  SuperAdminModifyTraining,
  SuperAdminStatistics,
  SuperAdminMemberManagement,
  SuperAdminContentManagement,
  SuperAdminDocumentManagement
} from 'Pages/SuperAdminPages'
import {
  authenticateByHash,
  checkActiveSession,
  userLogout
} from 'Features/Auth/authSlice'
import PrivateRoute from 'Util/privateRoute'
import {
  isAdmin,
  isHouseholdOrOrganizationAdmin,
  isJobSeeker,
  isOrganizationAdmin
} from 'Util/permissions'
import LoadingIndicator from 'Components/LoadingIndicator'

const App = () => {
  const dispatch = useDispatch()
  const [hashChecked, setHashChecked] = useState<boolean>(false)
  const params = new URLSearchParams(window.location.search)
  const hash = params.get('hash')
  React.useEffect(() => {
    const checkHashParam = async () => {
      if (hash) {
        await dispatch(userLogout())
        await dispatch(authenticateByHash(hash))
      }
      await dispatch(checkActiveSession())
      setHashChecked(true)
    }
    checkHashParam()
  }, [dispatch])
  if (!hashChecked) {
    return <LoadingIndicator noBg />
  }
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact component={FrontPage} path="/" />
          <Route exact component={Trainings} path="/trainings" />
          <Route
            exact
            component={SingleTrainingAdvertisement}
            path="/trainings/:id"
          />
          <Route exact component={Jobs} path="/jobs" />
          <Route exact component={SingleJobAdvertisement} path="/jobs/:id" />
          <Route exact component={AdminLogin} path="/admin-login" />
          <Route exact component={HouseholdLogin} path="/household-login" />
          <Route exact component={NewOrganization} path="/new-organization" />
          <Route
            exact
            component={NewHouseholdOrganization}
            path="/eident/return/"
          />
          <Route exact component={MemberPalkkaus} path="/palkanmaksu" />
          <PrivateRoute
            exact
            component={CreateMemberProfile}
            path="/create-member-profile"
            permissionFunction={isJobSeeker}
          />
          <PrivateRoute
            exact
            component={UserProfile}
            path="/profile"
            permissionFunction={isJobSeeker}
          />
          <PrivateRoute
            exact
            component={AdminPanel}
            path="/admin-panel"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={AdminJobs}
            path="/admin-panel/ilmoitukset/avoimet"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminJobs}
            path="/super-admin-panel/ilmoitukset/avoimet"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={AdminArchivedPage}
            path="/admin-panel/ilmoitukset/historia"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminArchivedPage}
            path="/super-admin-panel/ilmoitukset/historia"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={AdminNewJob}
            path="/admin-panel/ilmoitukset/avoimet/uusi"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={ModifyAd}
            path="/admin-panel/ilmoitukset/muokkaa/:adId"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminModifyAd}
            path="/super-admin-panel/ilmoitukset/muokkaa/:adId"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={AdStatusPage}
            path="/admin-panel/ilmoitukset/:adId"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminAdStatusPage}
            path="/super-admin-panel/ilmoitukset/:adId"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={AdminDetails}
            path="/admin-panel/asetukset/tiedot"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={EducationDetails}
            path="/education-panel/asetukset/tiedot"
            permissionFunction={isOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={EmployeeBank}
            path="/admin-panel/tyontekijahaku/pankki"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={AdminMessages}
            path="/admin-panel/viestit"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={AdminOrganizationMessages}
            path="/admin-panel/viestit/organisaatio"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <Route exact component={AgreeTos} path="/agree-tos/" />
          <PrivateRoute
            exact
            component={EducationMessages}
            path="/education-panel/viestit"
            permissionFunction={isOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={EmployeeSearch}
            path="/admin-panel/tyontekijahaku/etsi"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminPanel}
            path="/super-admin-panel"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={EducationPanel}
            path="/education-panel"
            permissionFunction={isOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={AdminTrainings}
            path="/education-panel/ilmoitukset"
            permissionFunction={isOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={AdminPassedTrainings}
            path="/education-panel/historia"
            permissionFunction={isOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={AdminOngoingTrainings}
            path="/education-panel/nykyiset"
            permissionFunction={isOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={NewTraining}
            path="/education-panel/uusi"
            permissionFunction={isOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={ModifyTrainingAd}
            path="/education-panel/ilmoitukset/muokkaa/:adId"
            permissionFunction={isOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={AdminSingleTraining}
            path="/education-panel/:id"
            permissionFunction={isOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminOrganizationManagement}
            path="/super-admin-panel/organisaatiot"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminOrganizationDetails}
            path="/super-admin-panel/organisaatiot/:organizationId"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={AdminUserManagement}
            path="/admin-panel/asetukset/hallinta"
            permissionFunction={isHouseholdOrOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={EducationUserManagement}
            path="/education-panel/asetukset/hallinta"
            permissionFunction={isOrganizationAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminUserManagement}
            path="/super-admin-panel/asetukset/hallinta"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminMessages}
            path="/super-admin-panel/viestit"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminEducations}
            path="/super-admin-panel/trainings"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminPassedEducations}
            path="/super-admin-panel/trainings/menneet"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminOngoingEducations}
            path="/super-admin-panel/trainings/nykyiset"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminSingleTraining}
            path="/super-admin-panel/trainings/:id"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminModifyTraining}
            path="/super-admin-panel/trainings/muokkaa/:adId"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminStatistics}
            path="/super-admin-panel/statistics"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminMemberManagement}
            path="/super-admin-panel/jasenet"
            permissionFunction={isAdmin}
          />
          <PrivateRoute
            exact
            component={SuperAdminContentManagement}
            path="/super-admin-panel/content-management/:contentPage"
            permissionFunction={isAdmin}
          />
          <Route
            exact
            component={FrontPageMillContent}
            path="/mylly/:contentPage"
          />
          <PrivateRoute
            exact
            component={SuperAdminDocumentManagement}
            path="/super-admin-panel/document-management"
            permissionFunction={isAdmin}
          />
          <Route
            exact
            component={AdminRequestPassword}
            path="/request-password"
          />
          <Route
            exact
            component={AdminRequestPasswordConfirm}
            path="/password_reset/:uid/:token"
          />
        </Switch>
      </div>
    </Router>
  )
}

export default App
