import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  ConversationDetailsPayload,
  fetchConversationsForAd,
  markAsRead
} from 'Features/Conversation/conversationSlice'
import { SingleAdDataType } from 'Features/JobAdvertisements/advertisementSlice'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'Store'
import { createMessage, MessageItem } from 'Features/Message/messageSlice'
import { JobApplicationType } from 'Features/JobApplication/jobApplicationSlice'
import Message from './Message'

const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  .target-details {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-bottom: 1px solid #d6dce2;
    span {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      &:first-of-type {
        color: #0a4ea2;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
      }
      &:not(:last-of-type) {
        margin-bottom: 6px;
      }
    }
  }
  .conversation-messages {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #ddd;
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #aaa;
      border-radius: 6px;
      border: 2px solid #ddd;
    }
  }
  .reply {
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-top: 1px solid #d6dce2;
    textarea {
      resize: none;
      border: 1px solid #d6dce2;
      background-color: #ffffff;
    }
    button {
      padding: 10px 20px;
      border-radius: 6px;
      background-color: #d60c17;
      color: #ffffff;
      font-family: Barlow;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase;
      border: none;
    }
    button,
    textarea {
      outline: none;
    }
    .actions {
      margin-top: 10px;
      button {
        float: right;
      }
    }
  }
`

export default function Conversation({
  conversation,
  advertisement,
  organizationId,
  onMessageSent
}: {
  conversation: ConversationDetailsPayload | undefined
  advertisement?: SingleAdDataType | null
  organizationId?: number | null
  onMessageSent?: () => void
}) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [replyMessage, setReplyMessage] = useState<string>('')
  let applications: Array<JobApplicationType> = []
  useEffect(() => {
    conversation?.messages.forEach(m => {
      dispatch(markAsRead({ id: m.id, organizationId }))
    })
  }, [conversation?.messages.length, organizationId])
  if (!conversation) {
    return null
  }
  if (advertisement && advertisement.job_applications) {
    applications = advertisement.job_applications.filter(app =>
      conversation.targeted_audience.users?.includes(app.member_profile.user.id)
    )
  }
  const sendReplyMessage = async () => {
    const body: MessageItem = {
      content: replyMessage,
      conversation: conversation.id
    }
    if (organizationId) {
      body.organization = organizationId
    }
    await dispatch(createMessage(body))
    dispatch(fetchConversationsForAd(advertisement?.id))
    if (onMessageSent) {
      onMessageSent()
    }
    setReplyMessage('')
  }
  return (
    <ConversationContainer>
      {applications.length && advertisement ? (
        <div className="target-details">
          <span>
            {applications.length > 1
              ? `${t('multiple')} (${applications.length})`
              : `${applications[0].member_profile.user.first_name} ${applications[0].member_profile.user.last_name}`}
          </span>
          <span>
            {t('application-status')}:{' '}
            {applications.length > 1
              ? Array.from(new Set(applications.map(app => app.status)))
                  .map(status => t(status))
                  .join(', ')
              : t(applications[0].status)}
          </span>
          <span>
            {t('ad')}: {advertisement?.title}
          </span>
        </div>
      ) : null}
      <div className="conversation-messages">
        {conversation.messages.map(m => (
          <Message
            key={m.id}
            message={m}
            conversation={conversation}
            organizationId={organizationId}
          />
        ))}
      </div>
      {conversation.conversation_type === 'single-message' && (
        <div className="reply">
          <textarea
            rows={7}
            onChange={e => setReplyMessage(e.target.value)}
            value={replyMessage}
          />

          <div className="actions">
            <button type="button" onClick={sendReplyMessage}>
              {t('reply-message')}
            </button>
          </div>
        </div>
      )}
    </ConversationContainer>
  )
}

Conversation.defaultProps = {
  advertisement: null,
  organizationId: null,
  onMessageSent: null
}
