import React from 'react'
import styled from 'styled-components'
import { SuperAdminLayout } from 'Components/Layout/SuperAdminLayout'
import { ContentManagement } from 'Components/SuperAdminPage/SuperAdminContentManagement'
import { useParams } from 'react-router-dom'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`

const SuperAdminUserManagement = () => {
  const { contentPage } = useParams<{ contentPage: string }>()
  return (
    <SuperAdminLayout>
      <ContentContainer>
        <ContentManagement contentPage={contentPage} />
      </ContentContainer>
    </SuperAdminLayout>
  )
}
export default SuperAdminUserManagement
