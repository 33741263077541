import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import EducationTrainings from 'Components/EducationPage/EducationTrainings'
import { RootState } from 'Store'
import LatestMessages from 'Components/AdminPage/AdminFrontpage/LatestMessages'
import { upcomingStatus } from 'Features/Training/trainingSlice'

const FrontpageBackground = styled.div`
  width: 100%;
  height: 100%;
`

const FrontpageTopItems = styled.div`
  width: 100%;
  height: 252px;
  display: flex;
`
const CompanyInformation = styled.div`
  padding: 8px;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
`
const InformationRow = styled.div`
  display: flex;
  height: 30%;
  border-bottom: 1px solid #d6dce2;
  &:first-child {
    height: 35%;
  }
  &:last-child {
    height: 35%;
    border-bottom: none;
  }
`

const CompanyLogoContainer = styled.div`
  height: 100%;
  width: 96px;
`
const CompanyLogo = styled.div`
  width: 100%;
  height: 90%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
const CompanyNameContainer = styled.div`
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 10px 0px 10px 27px;
`
const CompanyName = styled.div`
  color: #303030;
  font-size: 16px;
  font-weight: 600;
  font-family: Barlow;
  padding: 5px;
`
const CompanyEmail = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  padding: 5px;
`

const CompanyTopInformation = () => {
  const companyInformation = useSelector((state: RootState) => state.admin)
  const { data } = useSelector((state: RootState) => state.organization)
  const { results } = data
  const companyLogo =
    results && companyInformation
      ? results[companyInformation.itemIndex].logo
      : ''
  const companyName =
    results && companyInformation
      ? results[companyInformation.itemIndex].name
      : ''
  const companyEmail =
    results && companyInformation
      ? results[companyInformation.itemIndex].email
      : ''
  return (
    <>
      <CompanyLogoContainer>
        <CompanyLogo style={{ backgroundImage: `url(${companyLogo})` }} />
      </CompanyLogoContainer>
      <CompanyNameContainer>
        <CompanyName>{companyName}</CompanyName>
        <CompanyEmail>{companyEmail}</CompanyEmail>
      </CompanyNameContainer>
    </>
  )
}

const EducationFrontpage = () => {
  return (
    <FrontpageBackground>
      <FrontpageTopItems>
        <CompanyInformation>
          <InformationRow>
            <CompanyTopInformation />
          </InformationRow>
          <InformationRow>
            <LatestMessages />
          </InformationRow>
          <InformationRow />
        </CompanyInformation>
      </FrontpageTopItems>
      <EducationTrainings status={upcomingStatus} />
    </FrontpageBackground>
  )
}

export default EducationFrontpage
