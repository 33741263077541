import { openLoginModal } from 'Features/Auth/authSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const BannerBackground = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
`
const BannerHeader = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 32px;
`
const BannerContent = styled.div`
  padding: 10px 0px;
  color: #252525;
  font-family: Barlow;
  font-size: 16px;
  max-width: 730px;
  line-height: 27px;
`
const LoginLink = styled.span`
  font-family: Barlow;
  cursor: pointer;
  color: #0a4ea2;
`
const TrainingsBanner = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { user } = useSelector(({ auth }: { auth: any }) => auth)
  return (
    <BannerBackground>
      <BannerHeader>{t('welcome-to-training-search')}</BannerHeader>
      <BannerContent>{t('training-search-info-1')}</BannerContent>
      {user.member_profile ? null : (
        <BannerContent>
          {t('training-search-info-2')}
          <LoginLink onClick={() => dispatch(openLoginModal(true))}>
            {t('from-here')}
          </LoginLink>
        </BannerContent>
      )}
    </BannerBackground>
  )
}
export default TrainingsBanner
