import { savePartialProfile } from 'Features/MemberProfile/memberProfileSlice'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import type { History } from 'history'
import { useHistory } from 'react-router-dom'
import { checkActiveSession } from 'Features/Auth/authSlice'
import { Phase1, Phase2, Phase3, Phase4, Phase5 } from './FieldComponents'

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 7px 7px -10px rgba(0, 0, 0, 0.41);
  label {
    color: #222;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 10px;
    span {
      color: #d60c17;
    }
    &.top-margin {
      margin-top: 10px;
    }
  }
  input.form-select,
  textarea.form-select {
    border: 1px solid #d6dce2;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;
    &:last-of-type {
      margin-bottom: 10px;
    }
    &.borderless {
      width: 100%;
      max-width: none;
      border-left: none;
      border-right: none;
      border-top: none;
      margin-bottom: 0;
      padding: 15px 0;
    }
  }
  .search {
    display: flex;
    align-items: center;
    user-select: none;
    .add-skill {
      white-space: nowrap;
      margin-left: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin-right: 10px;
        color: #252525;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
      }
      svg {
        color: #0a4ea2;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0;
      }
    }
  }
  .search-bar {
    flex-grow: 1;
    margin-right: 10px;
    position: relative;
    svg {
      position: absolute;
      right: 0;
      width: 15px;
      height: 15px;
      top: calc(50% - 7.5px);
      right: 10px;
    }
  }
`
export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and ${props => props.theme.breakpoints.lg} {
    flex-direction: row;
  }
  .rows {
    padding: 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-right: 1px solid #d6dce2;
    @media screen and ${props => props.theme.breakpoints.lg} {
      width: 70%;
    }
  }
  .additional-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 30px;
    @media screen and ${props => props.theme.breakpoints.lg} {
      width: 30%;
    }
    button {
      width: 100%;
      height: 37px;
      border-radius: 6px;
      background-color: #d60c17;
      border: none;
      outline: none;
      color: #ffffff;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      margin-bottom: 12px;
      cursor: pointer;
    }
    a {
      color: #0a4ea2;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      white-space: nowrap;
    }
    .selection-details {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      flex-grow: 1;
      label {
        margin-top: 30px;
        &:first-of-type {
          margin-top: 0;
        }
      }
      .selection {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        span {
          color: #d60c17;
          font-family: Barlow;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          margin-right: 10px;
        }
        svg {
          color: #a8aeb4;
          font-size: 21px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 21px;
          cursor: pointer;
        }
      }
    }
    .work-exp-side {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
      span {
        color: #0a4ea2;
        font-family: Barlow;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        color: #a8aeb4;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }
  .checkbox {
    display: flex;
    align-items: center;
    user-select: none;
    margin-bottom: 20px;
    input {
      height: 17px;
      width: 17px;
      border: 1px solid #979797;
      border-radius: 2px;
      margin-right: 10px;
    }
    label {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      margin-bottom: 0;
    }
  }
`

const FormFields = ({
  currentTab,
  values,
  setValue,
  nextForm,
  createProfile
}: {
  currentTab: number
  values: { [key: string]: any }
  setValue: Function
  nextForm: () => void
  createProfile: () => void
}) => {
  const dispatch = useDispatch()
  const history: History = useHistory()
  const renderFields = () => {
    const savePartial = async () => {
      await dispatch(savePartialProfile(values))
      await dispatch(checkActiveSession())
      history.push('/jobs')
    }
    switch (currentTab) {
      case 0:
        return (
          <Phase1
            values={values}
            nextForm={nextForm}
            setValue={setValue}
            savePartial={savePartial}
          />
        )
      case 1:
        return (
          <Phase2
            values={values}
            nextForm={nextForm}
            setValue={setValue}
            savePartial={savePartial}
          />
        )
      case 2:
        return (
          <Phase3
            values={values}
            nextForm={nextForm}
            setValue={setValue}
            savePartial={savePartial}
          />
        )
      case 3:
        return (
          <Phase4
            values={values}
            nextForm={nextForm}
            setValue={setValue}
            savePartial={savePartial}
          />
        )
      case 4:
        return (
          <Phase5
            values={values}
            setValue={setValue}
            createProfile={createProfile}
          />
        )
      default:
        return (
          <Phase1
            values={values}
            nextForm={nextForm}
            setValue={setValue}
            savePartial={savePartial}
          />
        )
    }
  }
  return <FormFieldsContainer>{renderFields()}</FormFieldsContainer>
}

export default FormFields
