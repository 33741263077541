import MainLayout from 'Components/Layout/MainLayout'
import TrainingsResults from 'Components/Trainings/TrainingsResults'
import Search from 'Components/Search'
import React from 'react'

const SingleTrainingAdvertisement = ({
  match: {
    params: { id }
  }
}: {
  match: { params: { id: string } }
}) => {
  return (
    <MainLayout>
      <Search type="training" />
      <TrainingsResults trainingId={id} />
    </MainLayout>
  )
}

export default SingleTrainingAdvertisement
