import React from 'react'
import styled from 'styled-components'
import HelmetImage from 'Assets/helmet.png'

const ProfileDetails = styled.div`
  width: 100%;
  margin-top: 28px;
  margin-bottom: 46px;
  display: flex;
`
const ProfilePicture = styled.img`
  min-width: 146px;
  min-height: 146px;
  border-radius: 50%;
`
const ProfileDescription = styled.div`
  margin-left: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    color: #252525;
    font-family: Barlow;
    font-size: 18px;
    font-weight: 600;
    &.small {
      font-weight: 300;
    }
  }
  p {
    color: #252525;
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: 0;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
`

interface AdditionalProfileInfo {
  phone: string
  email: string
}

const ProfileDetailsComponent = ({
  applicantName,
  applicantId,
  anonymous,
  additionalInfo
}: {
  applicantName: any
  applicantId: any
  anonymous?: boolean
  additionalInfo?: AdditionalProfileInfo
}) => {
  return (
    <ProfileDetails>
      <ProfilePicture src={HelmetImage} alt="helmet" />
      <ProfileDescription>
        <span>{`${applicantName} ${anonymous ? `#${applicantId}` : ''}`}</span>
        {additionalInfo ? (
          <span className="small">
            {Object.values(additionalInfo)
              .filter(v => v)
              .join(', ')}
          </span>
        ) : null}
      </ProfileDescription>
    </ProfileDetails>
  )
}

ProfileDetailsComponent.defaultProps = {
  anonymous: true,
  additionalInfo: null
}

export default ProfileDetailsComponent
