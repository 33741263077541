/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAsyncThunk,
  SerializedError
} from '@reduxjs/toolkit'
import actionGenerator from 'Util/actionGenerator'
import { getAxiosInstance } from 'Api'

interface PalkkausState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  palkkausToken: string
  error: SerializedError | undefined
}

const initialState: PalkkausState = {
  loading: 'idle',
  palkkausToken: '',
  error: undefined
}

const fetchToken = createAsyncThunk('palkkausToken/fetch', async () => {
  const response = await getAxiosInstance().get(`palkkaus-token`)
  return response.data
})

const genericActions = actionGenerator('palkkaus')

const palkkausSlice = createSlice({
  name: 'palkkaus',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: builder => {
    builder
      .addCase(genericActions.ResetAction, () => initialState)
      .addCase(fetchToken.fulfilled, (state, action) => {
        state.palkkausToken = action.payload
      })
      .addCase(fetchToken.rejected, (state, action) => {
        state.error = action.error
      })
  }
})
export default palkkausSlice.reducer
export { fetchToken }
