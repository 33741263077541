import React, { useState } from 'react'
import styled from 'styled-components/macro'

const OverlayContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 999;
    &:hover {
      background: #efefef33;
    }
  }
`

const YouTubePlayer = ({
  url,
  onClick
}: {
  url: string
  onClick: () => void
}) => {
  const [play, setPlay] = useState<boolean>(false)
  if (play) {
    const urlWithAutoplay =
      url.indexOf('?') !== -1 ? `${url}&autoplay=1` : `${url}?autoplay=1`
    return (
      <iframe
        width="100%"
        title="YouTube video"
        src={urlWithAutoplay}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    )
  }
  return (
    <OverlayContainer>
      <div
        className="overlay"
        onClick={() => {
          setPlay(true)
          onClick()
        }}
      />
      <iframe
        width="100%"
        title="YouTube video"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </OverlayContainer>
  )
}

export default YouTubePlayer
