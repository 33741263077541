import { createAction, AnyAction, AsyncThunk } from '@reduxjs/toolkit'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

const actionGenerator = (name: string) => {
  return {
    name,
    isPendingAction: (action: AnyAction): action is PendingAction => {
      return action.type.startsWith(name) && action.type.endsWith('/pending')
    },
    isRejectedAction: (action: AnyAction): action is RejectedAction => {
      return action.type.startsWith(name) && action.type.endsWith('/rejected')
    },
    isFulfilledAction: (action: AnyAction): action is FulfilledAction => {
      return action.type.startsWith(name) && action.type.endsWith('/fulfilled')
    },
    ResetAction: createAction(`${name}-reset-tracked-loading-state`)
  }
}

export type {
  GenericAsyncThunk,
  PendingAction,
  RejectedAction,
  FulfilledAction
}
export default actionGenerator
