/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import actionGenerator from 'Util/actionGenerator'
import { getAxiosInstance } from 'Api'

interface StatisticsState {
  entities: []
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  statistics: StatisticsType
  graphDetails: Record<string, number>
}
interface GraphParams {
  start_date: string
  end_date: string
}
type StatisticsType = {
  active_ad_count: number
  new_org_request_count: number
  user_count: number
  created_trainings_count: number
  created_messages_count: number
}
const initialState: StatisticsState = {
  entities: [],
  loading: 'idle',
  statistics: {
    active_ad_count: 0,
    new_org_request_count: 0,
    user_count: 0,
    created_trainings_count: 0,
    created_messages_count: 0
  },
  graphDetails: {}
}
const fetchStatistics = createAsyncThunk(
  'statistics/fetch_statistics',
  async () => {
    const response = await getAxiosInstance().get(
      `/statistics/admin_statistics/`
    )
    return response.data
  }
)
const fetchGraphDetails = createAsyncThunk(
  'statistics/fetch_graph_details',
  async (params: GraphParams) => {
    const response = await getAxiosInstance().get(
      `/statistics/statistics_graph/?start_date=${params.start_date}&end_date=${params.end_date}`
    )
    return response.data
  }
)
const genericActions = actionGenerator('statistics')
const authSlice = createSlice({
  name: genericActions.name,
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: builder => {
    builder
      .addCase(genericActions.ResetAction, () => initialState)
      .addCase(fetchStatistics.fulfilled, (state, action) => {
        state.statistics = action.payload
      })
      .addCase(fetchGraphDetails.fulfilled, (state, action) => {
        state.graphDetails = action.payload
      })
      .addMatcher(genericActions.isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(genericActions.isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(genericActions.isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})

export default authSlice.reducer
export { fetchStatistics, fetchGraphDetails }
