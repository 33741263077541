import React from 'react'
import UserProfileComponent from 'Components/UserProfile'
import MainLayout from 'Components/Layout/MainLayout'
import UserProfileTabs from 'Components/UserProfile/UserProfileTabs'

const UserProfile = () => {
  return (
    <MainLayout>
      <UserProfileComponent />
      <UserProfileTabs />
    </MainLayout>
  )
}

export default UserProfile
