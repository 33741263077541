import React from 'react'
import styled from 'styled-components/macro'
import { Link, withRouter } from 'react-router-dom'
import type { Location } from 'history'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Desktop } from 'Components/Layout/MediaQuery'
import organizationIsHousehold from 'Util/organizationIsHousehold'

interface StyledLinkProps {
  isActive: boolean
}

const SidebarContainer = styled.div`
  width: 196px;
  min-width: 196px;
  background: #232733;
  height: calc(100vh - 60px);
`
const TopSidebar = styled.div`
  min-height: fit-content;
  background: #2a2e3b;
  padding-bottom: 20px;
`
const BottomSidebar = styled.div`
  min-height: fit-content;
  background: #232733;
`
const IconArea = styled.div`
  width: 30px;
  text-align: center;
`

const MenuItems = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  svg {
    margin-right: 10px;
  }
  a {
    font-family: Barlow;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    &.outside-link {
      color: white;
    }
  }
`
const SubMenuItems = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 31px;
  a {
    font-family: Barlow;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    padding: 4px 0px 8px 10px;
    &.outside-link {
      color: white;
    }
  }
`

const SidebarMenuItems = styled.div`
  margin: 15px 0px 0px 11px;
`

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
const StyledLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  font-weight: 600;
  display: flex;
  ${(props: StyledLinkProps) =>
    props.isActive
      ? `
      font-weight: bold;
      color: #5C5F63;
  `
      : 'color: white;'}
`
/* eslint-enable react/jsx-props-no-spreading */
/* eslint-enable @typescript-eslint/no-unused-vars */

const AdminSidebar = ({ location }: { location: Location }) => {
  const isHousehold = organizationIsHousehold()
  return (
    <Desktop>
      <SidebarContainer>
        <TopSidebar>
          <MenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={location.pathname === '/admin-panel'}
                to="/admin-panel"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'home']} />
                </IconArea>
                Etusivu
              </StyledLink>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={false}
                to="/admin-panel/ilmoitukset/avoimet"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'file-alt']} />
                </IconArea>
                Ilmoitukset
              </StyledLink>
              <SubMenuItems>
                <StyledLink
                  isActive={
                    location.pathname === '/admin-panel/ilmoitukset/avoimet' ||
                    location.pathname ===
                      '/admin-panel/ilmoitukset/avoimet/uusi'
                  }
                  to="/admin-panel/ilmoitukset/avoimet"
                >
                  Avoimet
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname === '/admin-panel/ilmoitukset/historia'
                  }
                  to="/admin-panel/ilmoitukset/historia"
                >
                  Historia
                </StyledLink>
              </SubMenuItems>
            </SidebarMenuItems>

            <SidebarMenuItems>
              <StyledLink to="/admin-panel/viestit">
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'envelope']} />
                </IconArea>
                Viestit
              </StyledLink>
              <SubMenuItems>
                <StyledLink
                  isActive={location.pathname === '/admin-panel/viestit'}
                  to="/admin-panel/viestit"
                >
                  Ilmoituskohtaiset viestit
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname === '/admin-panel/viestit/organisaatio'
                  }
                  to="/admin-panel/viestit/organisaatio"
                >
                  Viestiketjut
                </StyledLink>
              </SubMenuItems>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={false}
                to="/admin-panel/tyontekijahaku/etsi"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'hard-hat']} />
                </IconArea>
                Työntekijähaku
              </StyledLink>
              <SubMenuItems>
                <StyledLink
                  isActive={
                    location.pathname === '/admin-panel/tyontekijahaku/etsi'
                  }
                  to="/admin-panel/tyontekijahaku/etsi"
                >
                  Etsi työntekijöitä
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname === '/admin-panel/tyontekijahaku/pankki'
                  }
                  to="/admin-panel/tyontekijahaku/pankki"
                >
                  Työntekijäpankki
                </StyledLink>
              </SubMenuItems>
            </SidebarMenuItems>
          </MenuItems>
        </TopSidebar>
        <BottomSidebar>
          <MenuItems>
            <SidebarMenuItems>
              <StyledLink isActive={false} to="/admin-panel/asetukset/tiedot">
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'hammer']} />
                </IconArea>
                Asetukset
              </StyledLink>
              {!isHousehold && (
                <SubMenuItems>
                  <StyledLink
                    isActive={
                      location.pathname === '/admin-panel/asetukset/tiedot'
                    }
                    to="/admin-panel/asetukset/tiedot"
                  >
                    Yrityksen tiedot
                  </StyledLink>
                  <StyledLink
                    isActive={
                      location.pathname === '/admin-panel/asetukset/hallinta'
                    }
                    to="/admin-panel/asetukset/hallinta"
                  >
                    Käyttäjätilien hallinta
                  </StyledLink>
                </SubMenuItems>
              )}
            </SidebarMenuItems>
          </MenuItems>
        </BottomSidebar>
      </SidebarContainer>
    </Desktop>
  )
}

export default withRouter(AdminSidebar)
