import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ModifyMainOfficeButton } from 'Components/AdminSettings/AdminDetails/CompanyDetails'
import {
  OfficeType,
  OrganizationType
} from 'Features/Organization/organizationSlice'
import ModifyLocationModal from 'Components/AdminSettings/AdminDetails/ModifyLocationModal'

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d6dce2;
  @media screen and ${props => props.theme.breakpoints.md} {
    flex-direction: row;
    border-right: 1px solid #d6dce2;
    border-bottom: none;
  }
`
const LogoBackground = styled.div`
  background-color: #e7eaef;
  height: 143px;
  min-width: 190px;
  padding: 10px;
`
const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CompanyLogo = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0px;
  @media screen and ${props => props.theme.breakpoints.md} {
    margin: 0px 50px 0px 30px;
  }
`
const DetailsObject = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  p {
    color: #252525;
    font-family: Barlow;
    font-size: 16px;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
`
const ModifiableDetails = styled.div`
  display: flex;
  flex-direction: row;
`
const DetailsTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
`
const DisabledTitle = styled(DetailsTitle)`
  color: #a8aeb4;
`

const CompanyDetailsComponent = ({
  companyDetails,
  mainOffice
}: {
  companyDetails: OrganizationType
  mainOffice: OfficeType | undefined
}) => {
  const { t } = useTranslation()
  const [modifyLocationModal, setModifyLocationModal] = useState(false)

  return (
    <>
      {modifyLocationModal && companyDetails && mainOffice ? (
        <ModifyLocationModal
          modalToggle={setModifyLocationModal}
          companyName={companyDetails.name}
          officeDetails={mainOffice}
          companyId={companyDetails.id}
          mainOffice
          singleOffice
        />
      ) : null}
      <LeftColumn>
        <LogoBackground>
          <LogoContainer>
            <CompanyLogo
              style={{
                backgroundImage: `url(${
                  companyDetails ? companyDetails.logo : ''
                })`
              }}
            />
          </LogoContainer>
        </LogoBackground>
        <DetailsList>
          {companyDetails ? (
            <>
              <DetailsObject>
                {companyDetails.name ? (
                  <>
                    <DetailsTitle>{t('company-name')}</DetailsTitle>
                    <p>{companyDetails.name}</p>
                  </>
                ) : (
                  <DisabledTitle>{t('company-name')}</DisabledTitle>
                )}
              </DetailsObject>
              <DetailsObject>
                {companyDetails.vat_number ? (
                  <>
                    <DetailsTitle>{t('business-id')}</DetailsTitle>
                    <p>{companyDetails.vat_number}</p>
                  </>
                ) : (
                  <DisabledTitle>{t('business-id')}</DisabledTitle>
                )}
              </DetailsObject>
              <ModifiableDetails>
                {mainOffice && mainOffice.name ? (
                  <>
                    <DetailsObject>
                      <DetailsTitle>{t('main-office-location')}</DetailsTitle>
                      <p>{mainOffice.name}</p>
                    </DetailsObject>

                    <ModifyMainOfficeButton
                      icon={['far', 'pen']}
                      onClick={() => setModifyLocationModal(true)}
                    />
                  </>
                ) : (
                  <DetailsObject>
                    <DisabledTitle>{t('main-office-location')}</DisabledTitle>
                  </DetailsObject>
                )}
              </ModifiableDetails>
              <DetailsObject>
                {mainOffice && mainOffice.address ? (
                  <>
                    <DetailsTitle>{t('address')}</DetailsTitle>
                    <p>{mainOffice.address}</p>
                  </>
                ) : (
                  <DisabledTitle>{t('address')}</DisabledTitle>
                )}
              </DetailsObject>
              <DetailsObject>
                {mainOffice && mainOffice.location.name ? (
                  <>
                    <DetailsTitle>{t('district')}</DetailsTitle>
                    <p>{mainOffice.location.name}</p>
                  </>
                ) : (
                  <DisabledTitle>{t('district')}</DisabledTitle>
                )}
              </DetailsObject>
              <DetailsObject>
                {mainOffice && mainOffice.email ? (
                  <>
                    <DetailsTitle>{t('email')}</DetailsTitle>
                    <p>{mainOffice.email}</p>
                  </>
                ) : (
                  <DisabledTitle>{t('email')}</DisabledTitle>
                )}
              </DetailsObject>
              <DetailsObject>
                {mainOffice && mainOffice.phone_number ? (
                  <>
                    <DetailsTitle>{t('phone')}</DetailsTitle>
                    <p>{mainOffice.phone_number}</p>
                  </>
                ) : (
                  <DisabledTitle>{t('phone')}</DisabledTitle>
                )}
              </DetailsObject>
            </>
          ) : null}
        </DetailsList>
      </LeftColumn>
    </>
  )
}

export default CompanyDetailsComponent
