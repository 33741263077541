/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  NewAdForm,
  NewJobSummary
} from 'Components/SuperAdminPage/SuperAdminModifyJobAd'
import { useSelector } from 'react-redux'
import { RootState, store, useAppDispatch } from 'Store'
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState
} from 'draft-js'
import { fetchWithId } from 'Features/JobAdvertisements/advertisementSlice'
import { format, parse } from 'date-fns'
import draftToHtml from 'draftjs-to-html'
import { useTranslation } from 'react-i18next'
import {
  initLocations,
  initOffices,
  initEmploymentType,
  initJobType,
  initCompetences,
  initDrivingLicenses,
  initLanguageSkills
} from './ModifyAdInitializers'

const ContentContainer = styled.div`
  width: 100%;
  flex: 1;
`
const SurveyContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and ${props => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`
const SurveyContent = styled.div`
  width: 100%;
  max-width: 850px;
  padding: 10px;
  background-color: #f4f5f7;
  @media screen and ${props => props.theme.breakpoints.lg} {
    padding: 20px 40px;
  }
`
const defaultProps = {
  adId: undefined
}
const AdminNewJob = ({ adId }: { adId?: string }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [currentFormIndex, setCurrentFormIndex] = React.useState<number>(0)
  const companyInformation = useSelector((state: RootState) => state.admin)
  const newAdvertisement = useSelector(
    (state: RootState) => state.newAdvertisement
  )
  const { data } = useSelector((state: RootState) => state.organization)
  const { results } = data

  const { locations } = useSelector(
    (state: RootState) => state.newAdvertisement
  )
  const offices =
    results && companyInformation
      ? results[companyInformation.itemIndex].offices
      : []
  const [values, setValues] = useState<any>({})
  const companyId =
    values.organization && values.organization.id
      ? values.organization.id
      : undefined
  const companyName =
    values.organization && values.organization.name
      ? values.organization.name
      : undefined
  const companyLogo =
    values.organization && values.organization.name
      ? values.organization.logo
      : undefined

  const [previousValues, setPreviousValues] = useState({})
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const convertDateToFrontend = (date: string | undefined) => {
    if (date) {
      return format(parse(date, 'yyyy-mm-dd', new Date()), 'dd.mm.yyyy')
    }
    return undefined
  }
  useEffect(() => {
    if (adId && locations) {
      dispatch(fetchWithId(parseInt(adId, 10))).then(
        ({ payload }: { payload: any }) => {
          const newState = { ...values }
          newState.ad_title = payload?.title
          newState.professional_titles = payload?.professional_titles
          newState.competences = initCompetences(payload?.competences)
          newState.duration = payload?.duration
          newState.job_type = initJobType(payload?.job_type, t)
          newState.employment_type = initEmploymentType(
            payload.employment_type,
            t
          )
          newState.locations = initLocations(payload?.locations)
          newState.driving_licenses = initDrivingLicenses(
            payload?.driving_licenses
          )
          newState.languages = initLanguageSkills(
            payload?.language_skill_requirements
          )
          newState.offices = initOffices(payload?.offices, offices)
          newState.search_end_date = convertDateToFrontend(
            payload?.search_end_date
          )
          newState.search_start_date = convertDateToFrontend(
            payload?.search_start_date
          )
          newState.start_date = convertDateToFrontend(payload?.start_date)
          newState.website = payload?.website

          if (payload?.description) {
            const desc = convertFromHTML(payload.description)
            const content = ContentState.createFromBlockArray(
              desc.contentBlocks,
              desc.entityMap
            )
            setEditorState(EditorState.createWithContent(content))
            newState.ad_description = draftToHtml(convertToRaw(content))
          }
          newState.organization = payload.organization
          setValues(newState)
        }
      )
    }
  }, [dispatch, locations])
  useEffect(() => {
    if (values !== previousValues) {
      store.dispatch({ type: 'newAd/updateNewAd', payload: values })
      setPreviousValues(values)
    }
  })
  return (
    <ContentContainer>
      <SurveyContainer>
        <SurveyContent>
          <NewAdForm
            adId={adId}
            companyId={companyId}
            formIndex={currentFormIndex}
            companyLogo={companyLogo}
            companyName={companyName}
            offices={offices}
            onFormIndexChange={(index: number) => setCurrentFormIndex(index)}
            values={values}
            setValues={setValues}
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </SurveyContent>
        <NewJobSummary
          values={newAdvertisement.values}
          companyLogo={companyLogo}
          companyName={companyName}
        />
      </SurveyContainer>
    </ContentContainer>
  )
}

AdminNewJob.defaultProps = defaultProps
export default AdminNewJob
