import React, { useEffect, useState } from 'react'
import { fetchPaginatedJobAdvertisements } from 'Features/JobAdvertisements/advertisementSlice'
import { AdvertisementStatus } from 'Components/AdminPage/AdminActiveJobs'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import styled from 'styled-components/macro'
import { fetchConversationsForAd } from 'Features/Conversation/conversationSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddConversationModal } from 'Components/Modals'
import ConversationDetails from './ConversationDetails'

interface TableContainerProps {
  colCount: number
}

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  overflow-x: auto;
  .row,
  .header {
    min-width: 1000px;
    display: flex;
    .col {
      text-align: left;
      flex-basis: calc(
        100% / ${(props: TableContainerProps) => props.colCount}
      );
      overflow: hidden;
    }
  }
  .col {
    padding: 10px 15px;
    > div {
      width: 100%;
    }
  }
  .header {
    border-bottom: 1px solid #d6dce2;
    .col {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
  .row:nth-of-type(even) {
    background: #f9f9f9;
  }
  .row .col:first-of-type {
    cursor: pointer;
  }
  .row {
    &.active {
      border: 2px solid #a3ccff;
      background-color: #f4f8ff;
    }
    .col.bolded {
      color: #0a4ea2;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 17px;
    }
    .col.clickable {
      cursor: pointer;
    }
  }
  .add-new-message {
    width: 100%;
    height: 44px;
    line-height: 44px;
    border: 1px solid #d6dce2;
    outline: none;
    border-radius: 22px;
    background-color: #f4f5f7;
    display: flex;
    justify-content: space-between;
    padding: 0px 22px 0px 22px;
    margin: 25px 20px;
    font-size: 16px;
    font-family: Barlow;
    font-weight: 600;
    text-decoration: none;
    color: #252525;
    @media screen and ${props => props.theme.breakpoints.xs} {
      width: 25%;
    }
    &:hover {
      cursor: pointer;
    }
  }
`

export const MessagesContainer = styled.div`
  h3 {
    color: #252525;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: 0;
  }
`

interface MessageProps {
  t: (key: string) => string
}

const AdminMessages = ({ t }: MessageProps) => {
  const dispatch = useDispatch()
  const [
    showConversationAddModal,
    setShowConversationAddModal
  ] = useState<boolean>(false)
  const [openedAds, setOpenedAds] = useState<Array<number>>([])
  const { itemIndex } = useSelector((state: RootState) => state.admin)
  const {
    advertisementData: { results: advertisementResults },
    status
  } = useSelector((state: RootState) => state.advertisement)
  const {
    data: { results }
  } = useSelector((state: RootState) => state.organization)
  const { adConversations } = useSelector(
    (state: RootState) => state.conversation
  )
  const updateAdList = () => {
    const body = {
      page: 1,
      organizationId: results[itemIndex].id,
      status,
      orderBy: 'title'
    }
    dispatch(fetchPaginatedJobAdvertisements(body))
  }

  useEffect(() => {
    if (!results) {
      return
    }
    updateAdList()
  }, [dispatch, itemIndex, results])

  useEffect(() => {
    advertisementResults.forEach(d => dispatch(fetchConversationsForAd(d.id)))
  }, [advertisementResults])
  return (
    <>
      <MessagesContainer>
        <h3>{t('advertisement-related-messages')}</h3>
        <TableContainer colCount={5}>
          <div className="header">
            <div className="col">{t('ad')}</div>
            <div className="col">{t('status')}</div>
            <div className="col">{t('applications')}</div>
            <div className="col">{t('conversations')}</div>
            <div className="col">{t('new-messages')}</div>
          </div>
          {(advertisementResults || []).map(ad => (
            <React.Fragment key={ad.id}>
              <div
                className={`row${
                  openedAds.includes(Number(ad.id)) ? ' active' : ''
                }`}
              >
                <div
                  className="col bolded"
                  onClick={() =>
                    setOpenedAds(
                      openedAds.includes(Number(ad.id))
                        ? openedAds.filter(oa => oa !== ad.id)
                        : [...openedAds, Number(ad.id)]
                    )
                  }
                >
                  {ad.title}
                </div>
                <div className="col">
                  <AdvertisementStatus adStatus={ad.ad_status || ''} />
                </div>
                <div className="col bolded">
                  {(ad.job_applications || []).length}
                </div>
                <div className="col bolded">
                  {ad.id ? (adConversations[ad.id] || {}).count : null}
                </div>
              </div>
              <ConversationDetails
                advertisement={ad}
                conversations={adConversations[Number(ad.id)]}
                show={openedAds.includes(Number(ad.id))}
                organizationId={results ? results[itemIndex].id : null}
              />
            </React.Fragment>
          ))}
          <div
            className="add-new-message"
            onClick={() => setShowConversationAddModal(true)}
          >
            {t('add-new-message')}
            <FontAwesomeIcon
              style={{ marginTop: '14px', marginLeft: '8px' }}
              color="#0A4EA2"
              icon={['fal', 'plus-circle']}
            />
          </div>
        </TableContainer>
      </MessagesContainer>
      <AddConversationModal
        show={showConversationAddModal}
        onClose={() => {
          setShowConversationAddModal(false)
          updateAdList()
        }}
        organizationId={results ? results[itemIndex].id : null}
      />
    </>
  )
}

export default withTranslation()(AdminMessages)
