/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAxiosInstance } from 'Api'

interface NewAdvertisementState {
  values: object
  locations: Array<LocationsType>
}
const initialState: NewAdvertisementState = {
  values: {},
  locations: []
}
type LocationsType = {
  id: number
  name: string
  parent_id: number | null
}
const createNewAdvertisement = createAsyncThunk(
  'newAd/createNewAdvertisement',
  async (body: { [key: string]: any }) => {
    try {
      const response = await getAxiosInstance().post(`/jobs/`, body)
      return { data: response.data }
    } catch (err) {
      throw Error(JSON.stringify(err.response.data))
    }
  }
)
type ModifyAdvertisementRequest = {
  body: { [key: string]: any }
  id: number
}
const modifyAdvertisement = createAsyncThunk(
  'newAd/modifyAdvertisement',
  async ({ body, id }: ModifyAdvertisementRequest) => {
    try {
      const response = await getAxiosInstance().patch(`/jobs/${id}/`, body)
      return { data: response.data }
    } catch (err) {
      throw Error(JSON.stringify(err.response.data))
    }
  }
)
type FetchLocationsResponse = Array<LocationsType>

const fetchLocations = createAsyncThunk<FetchLocationsResponse, undefined>(
  'location',
  async () => {
    const response = await getAxiosInstance().get<FetchLocationsResponse>(
      `/location/flatlist/`
    )
    return response.data
  }
)
const newAdSlice = createSlice({
  name: 'newAd',
  initialState,
  reducers: {
    updateNewAd(state, action: AnyAction) {
      return {
        ...state,
        values: action.payload
      }
    },
    resetNewAd(state) {
      return {
        ...state,
        values: initialState.values
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchLocations.fulfilled, (state, action: AnyAction) => {
      state.locations = action.payload
    })
  }
})

export default newAdSlice.reducer
export { createNewAdvertisement, fetchLocations, modifyAdvertisement }
