import React from 'react'
import styled from 'styled-components/macro'
import JobBackground from 'Assets/job-background.jpg'
import { SurveyForm } from 'Components/Survey'

const CreateMemberProfileContainer = styled.div``
const BackgroundImage = styled.div`
  background: url(${JobBackground});
  height: 40vh;
  background-attachment: fixed;
  background-position: center 80%;
  background-repeat: no-repeat;
  background-size: cover;
`
const SurveyContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SurveyContent = styled.div`
  width: 100%;
  max-width: 850px;
  height: 60vh;
  padding: 10px;
  background-color: #f4f5f7;
  @media screen and ${props => props.theme.breakpoints.lg} {
    padding: 20px 40px;
  }
`

const CreateMemberProfile = () => {
  const [currentFormIndex, setCurrentFormIndex] = React.useState<number>(0)
  return (
    <CreateMemberProfileContainer>
      <BackgroundImage />
      <SurveyContainer>
        <SurveyContent>
          <SurveyForm
            formIndex={currentFormIndex}
            onFormIndexChange={(index: number) => setCurrentFormIndex(index)}
          />
        </SurveyContent>
      </SurveyContainer>
    </CreateMemberProfileContainer>
  )
}

export default CreateMemberProfile
