import React from 'react'
import styled from 'styled-components/macro'
import Navbar from 'Components/SuperAdminPage/SuperAdminNavbar'
import Sidebar from 'Components/SuperAdminPage/SuperAdminSidebar'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import LoadingIndicator from 'Components/LoadingIndicator'
import { BaseState } from '../AdminLayout'

const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  margin-top: 60px;
`
const ContentContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  display: flex;
`
const BorderlessContentContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  display: flex;
`
const SuperAdminLayout = ({ children }: { children: React.ReactNode }) => {
  const isLoading = useSelector((state: RootState) =>
    Object.values(state).some(partialState => {
      const castedState = partialState as BaseState
      return castedState.loading === 'pending'
    })
  )
  return (
    <MainContainer>
      <Navbar />
      <ContentContainer>
        <Sidebar />
        {children}
      </ContentContainer>
      {isLoading && <LoadingIndicator />}
    </MainContainer>
  )
}
const BorderlessSuperAdminLayout = ({
  children
}: {
  children: React.ReactNode
}) => {
  return (
    <MainContainer>
      <Navbar />
      <BorderlessContentContainer>
        <Sidebar />
        {children}
      </BorderlessContentContainer>
    </MainContainer>
  )
}

export { SuperAdminLayout, BorderlessSuperAdminLayout }
