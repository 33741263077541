/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import {
  fetchPaginatedJobAdvertisements,
  duplicateAd,
  adPartialUpdate,
  ListableAd
} from 'Features/JobAdvertisements/advertisementSlice'
import ObjectTableStyles from 'Components/ObjectTable'
import { PaginationComponent } from 'Components/FrontendPagination'
import { useSelector } from 'react-redux'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import DropdownMenu from 'Components/DropdownMenu'
import styled from 'styled-components'
import { RootState, useAppDispatch } from 'Store'
import { useTranslation } from 'react-i18next'
import LocationTooltip from './LocationTooltip'

const {
  ObjectTableBackground,
  ObjectTableContainer,
  ObjectTableTitle,
  ObjectTableTopComponent,
  ObjectTableBottomComponent,
  ObjectTableTop,
  ObjectTableTopNewObject,
  ObjectTableHeader,
  ObjectTableContent,
  ObjectTableItem,
  ObjectTableHeaderItem,
  ObjectTableHeaderOptionsBlock,
  ObjectTableObjectTitle,
  ObjectTableStatusIndicator,
  ObjectTableApplicationNumber,
  ObjectTableJobStatus,
  ObjectTableOptions,
  ObjectTableCustomLink,
  ObjectTableBottomNewObject,
  ObjectTableSortCaretIcon,
  ObjectTableAngleRightIcon,
  ObjectTableCompanySelect
} = ObjectTableStyles

const CompanySelectContainer = styled.div`
  display: flex;
  align-items: center;
`
const TopBar = styled(ObjectTableTopComponent)`
  flex-direction: column;
  @media screen and ${props => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`
const ClosedObjectTableStatusIndicator = styled(ObjectTableStatusIndicator)`
  color: #ffcb00;
`
const ArchivedObjectTableStatusIndicator = styled(ObjectTableStatusIndicator)`
  color: #818181;
`
const NotPublishedObjectTableSTatusIndicator = styled(
  ObjectTableStatusIndicator
)`
  color: #e6e6e6;
`

const ObjectTableFooter = styled(ObjectTableStyles.ObjectTableFooter)`
  display: flex;
  justify-content: space-between;
  padding: 28px 20px 28px 20px;
`

export const AdvertisementStatus = ({ adStatus }: { adStatus: string }) => {
  switch (adStatus) {
    case 'open':
      return (
        <ObjectTableJobStatus>
          <ObjectTableStatusIndicator icon={['fas', 'circle']} />
          Haku käynnissä
        </ObjectTableJobStatus>
      )
    case 'closed':
      return (
        <ObjectTableJobStatus>
          <ClosedObjectTableStatusIndicator icon={['fas', 'circle']} />
          Haku sulkeutunut
        </ObjectTableJobStatus>
      )
    case 'archived':
      return (
        <ObjectTableJobStatus>
          <ArchivedObjectTableStatusIndicator icon={['fas', 'circle']} />
          Arkistoitu
        </ObjectTableJobStatus>
      )
    case 'not_published':
      return (
        <ObjectTableJobStatus>
          <NotPublishedObjectTableSTatusIndicator icon={['fas', 'circle']} />
          Julkaisematon
        </ObjectTableJobStatus>
      )
    default:
      return (
        <ObjectTableJobStatus>
          <ObjectTableStatusIndicator icon={['fas', 'circle']} />
          Haku käynnissä
        </ObjectTableJobStatus>
      )
  }
}

const ActiveJobsComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { itemIndex } = useSelector((state: RootState) => state.admin)
  const { advertisementData, status, currentPage } = useSelector(
    (state: RootState) => state.advertisement
  )

  const { data } = useSelector((state: RootState) => state.organization)
  const { results } = data
  const fetchPaginagedAdvertisements = (resultsIndex: number) => {
    const body = {
      page: currentPage,
      organizationId: results[resultsIndex].id,
      status,
      orderBy: 'title'
    }
    dispatch({
      type: 'jobs/changeOrderBy',
      payload: 'title'
    })
    dispatch(fetchPaginatedJobAdvertisements(body))
  }
  useEffect(() => {
    if (results) {
      fetchPaginagedAdvertisements(itemIndex)
    }
  }, [dispatch, itemIndex, results])
  const changeStatus = (newStatus: string) => {
    dispatch({
      type: 'jobs/changeStatus',
      payload: newStatus
    })
    dispatch({
      type: 'jobs/changePage',
      payload: currentPage
    })
    const body = {
      page: currentPage,
      organizationId: results[itemIndex].id,
      status: newStatus,
      orderBy: 'title'
    }
    dispatch(fetchPaginatedJobAdvertisements(body))
  }
  const changeSort = (newSort: string) => {
    dispatch({
      type: 'jobs/changePage',
      payload: currentPage
    })
    const body = {
      page: currentPage,
      organizationId: results[itemIndex].id,
      status,
      orderBy: newSort
    }
    dispatch({
      type: 'jobs/changeOrderBy',
      payload: newSort
    })
    dispatch(fetchPaginatedJobAdvertisements(body))
  }
  const activeJobsAdvertisements =
    advertisementData && advertisementData.results
      ? advertisementData.results.map(
          ({
            id,
            title,
            locations,
            ad_status,
            search_end_date,
            search_start_date,
            organization,
            job_applications
          }: ListableAd) => ({
            id,
            title,
            locations,
            ad_status,
            search_end_date: format(
              parse(search_end_date, 'yyyy-mm-dd', new Date()),
              'dd.mm.yyyy'
            ),
            search_start_date: format(
              parse(search_start_date, 'yyyy-mm-dd', new Date()),
              'dd.mm.yyyy'
            ),
            organization,
            job_applications
          })
        )
      : undefined
  const [listOrder, setListOrder] = useState({
    title: 1,
    ad_status: 1,
    location: 1,
    search_start_date: 1,
    search_end_date: 1,
    application_count: 1
  })
  const [sortedBy, setSortedBy] = useState('title')
  type ColumnType =
    | 'title'
    | 'ad_status'
    | 'location'
    | 'search_start_date'
    | 'search_end_date'
    | 'application_count'

  const sortList = (column: ColumnType) => {
    setSortedBy(column)
    setListOrder({ ...listOrder, [column]: listOrder[column] * -1 })
    if (listOrder[column] * -1 > 0) {
      changeSort(`${column}`)
    } else {
      changeSort(`-${column}`)
    }
  }
  type CaretProps = {
    columnName:
      | 'title'
      | 'ad_status'
      | 'location'
      | 'search_start_date'
      | 'search_end_date'
      | 'application_count'
  }
  const CaretIcon = ({ columnName }: CaretProps) => {
    if (sortedBy === columnName) {
      if (listOrder[columnName] === 1) {
        return <ObjectTableSortCaretIcon icon={['fas', 'caret-up']} />
      }
      return <ObjectTableSortCaretIcon icon={['fas', 'caret-down']} />
    }
    return null
  }
  const history = useHistory()
  const advertisementOptions = [
    {
      icon: 'pen',
      title: 'Muokkaa',
      action: (objectId: number) => {
        history.push(`/admin-panel/ilmoitukset/muokkaa/${objectId}`)
      }
    },
    {
      icon: 'eye',
      title: 'Esikatselu',
      action: (objectId: number) => {
        history.push(`/admin-panel/ilmoitukset/${objectId}`)
      }
    },
    {
      icon: 'copy',
      title: 'Luo kopio',
      action: (objectId: number) => {
        dispatch(duplicateAd(objectId)).then(() =>
          fetchPaginagedAdvertisements(itemIndex)
        )
      }
    },
    {
      icon: 'trash',
      title: 'Arkistoi',
      action: (objectId: number) => {
        dispatch(
          adPartialUpdate({
            id: objectId,
            bodyData: { archived: true, organization: results[itemIndex].id }
          })
        ).then(() => fetchPaginagedAdvertisements(itemIndex))
      }
    }
  ]

  const activeJobItems =
    activeJobsAdvertisements && results
      ? activeJobsAdvertisements.map(item => {
          return (
            <ObjectTableItem key={item.id}>
              <ObjectTableObjectTitle>{item.title}</ObjectTableObjectTitle>
              <LocationTooltip locations={item.locations} />
              <ObjectTableHeaderItem>
                {item.search_start_date}
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                {item.search_end_date}
              </ObjectTableHeaderItem>
              <AdvertisementStatus adStatus={item.ad_status} />
              <ObjectTableApplicationNumber>
                {item.job_applications?.length}
              </ObjectTableApplicationNumber>
              <ObjectTableOptions>
                <ObjectTableCustomLink
                  to={`/admin-panel/ilmoitukset/${item.id}`}
                >
                  {t('open')}
                  <ObjectTableAngleRightIcon icon={['far', 'angle-right']} />
                </ObjectTableCustomLink>
                <DropdownMenu
                  options={advertisementOptions}
                  objectId={item.id}
                />
              </ObjectTableOptions>
            </ObjectTableItem>
          )
        })
      : []
  const stateOptions = [
    {
      label: 'Kaikki',
      value: ''
    },
    {
      label: 'Auki',
      value: 'open'
    },
    {
      label: 'Suljettu',
      value: 'closed'
    },
    {
      label: 'Julkaisematon',
      value: 'not_published'
    }
  ]
  type EventType = {
    label: string
    value: string
  }
  return (
    <ObjectTableBackground>
      <TopBar>
        <ObjectTableTop>
          <ObjectTableTitle>{t('currently-active-ads')}</ObjectTableTitle>
          <CompanySelectContainer>
            <ObjectTableCompanySelect
              defaultValue={stateOptions[0]}
              options={stateOptions}
              onChange={(e: EventType) => changeStatus(e.value)}
            />
          </CompanySelectContainer>
        </ObjectTableTop>
        <ObjectTableTopNewObject to="/admin-panel/ilmoitukset/avoimet/uusi">
          {t('add-new-job-ad')}
          <FontAwesomeIcon
            style={{ marginTop: '14px', marginLeft: '8px' }}
            color="#0A4EA2"
            icon={['fal', 'plus-circle']}
          />
        </ObjectTableTopNewObject>
      </TopBar>
      <ObjectTableBottomComponent>
        <ObjectTableContainer>
          <ObjectTableHeader>
            <ObjectTableHeaderItem onClick={() => sortList('title')}>
              {t('ad')}
              <CaretIcon columnName="title" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('location')}>
              {t('location')}
              <CaretIcon columnName="location" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem
              onClick={() => sortList('search_start_date')}
            >
              {t('publish-date')}
              <CaretIcon columnName="search_start_date" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('search_end_date')}>
              {t('search-ends')}
              <CaretIcon columnName="search_end_date" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('ad_status')}>
              {t('status')}
              <CaretIcon columnName="ad_status" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem
              onClick={() => sortList('application_count')}
            >
              {t('applications')}
              <CaretIcon columnName="application_count" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderOptionsBlock />
          </ObjectTableHeader>
          <ObjectTableContent>{activeJobItems}</ObjectTableContent>
          <ObjectTableFooter>
            <ObjectTableBottomNewObject to="/admin-panel/ilmoitukset/avoimet/uusi">
              {t('add-new-job-ad')}
              <FontAwesomeIcon
                style={{ marginTop: '14px' }}
                color="#0A4EA2"
                icon={['fal', 'plus-circle']}
              />
            </ObjectTableBottomNewObject>
            <PaginationComponent status={status} itemIndex={itemIndex} />
          </ObjectTableFooter>
        </ObjectTableContainer>
      </ObjectTableBottomComponent>
    </ObjectTableBackground>
  )
}

export default ActiveJobsComponent
