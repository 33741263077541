/* eslint-disable camelcase */
import React from 'react'
import ObjectTableStyles from 'Components/ObjectTable'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const DataContainer = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const SelectedData = styled.div`
  border-radius: 9.5px;
  background-color: #e7eaef;
  width: fit-content;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding: 5px 10px;
  font-weight: 500;
`
const TooltipBackground = styled.span`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip:hover {
    font-weight: 600;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
    color: #222;
    text-align: start;
    border: 1px solid #d6dce2;
    font-family: Barlow;
    padding: 5px 10px;
    border-radius: 3px;
    position: absolute;
    font-weight: 500;
    z-index: 1;
    margin-left: -10px;
    margin-bottom: 3px;
    top: 30px;
    transform: translateX(-100%);
  }

  .tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 15px;
    color: #222;
    right: 100%;
    margin-top: -10px;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`
const TooltipObject = styled.div`
  line-height: normal;
`
const { ObjectTableHeaderItem } = ObjectTableStyles

const LocationTooltip = ({
  locations
}: {
  locations: { name: string; id: number }[]
}) => {
  const { t } = useTranslation()
  if (locations.length > 0) {
    return (
      <DataContainer>
        {locations.length < 2 ? (
          <SelectedData>{locations[0].name}</SelectedData>
        ) : (
          <TooltipBackground>
            <SelectedData className="tooltip">
              {`${t('multiple')} (${locations.length})`}
              <span className="tooltiptext">
                {locations.map(location => (
                  <TooltipObject key={location.name}>
                    {location.name}
                  </TooltipObject>
                ))}
              </span>
            </SelectedData>
          </TooltipBackground>
        )}
      </DataContainer>
    )
  }
  return <ObjectTableHeaderItem>{t('not-defined')}</ObjectTableHeaderItem>
}
export default LocationTooltip
