/* eslint-disable no-console */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import {
  deleteOrganizationImage,
  fetchOrganizationImages
} from 'Features/Organization/organizationSlice'
import { fetchPublicImages } from 'Features/ImageBank/imageBankSlice'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import PhotoDeleteConfirmationModal from './PhotoDeleteConfirmationModal'
import PhotoUploadModal from './PhotoUploadModal'

const PhotoObjectContainer = styled.div`
  margin: 5px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @media screen and ${props => props.theme.breakpoints.md} {
    margin: 25px;
  }
`
const PhotoBackground = styled.div`
  background: #e7eaef;
  height: 86px;
  width: 120px;
  padding: 5px;
  @media screen and ${props => props.theme.breakpoints.md} {
    height: 123px;
    width: 170px;
    padding: 10px;
  }
`

const Photo = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
  color: #5c5f63;
  font-family: Barlow;
  font-size: 14px;
`
const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.5em;
  margin-left: 7px;
  color: #a8aeb4;
`
const AddIcon = styled(FontAwesomeIcon)`
  color: #a8aeb4;
  margin-bottom: 10px;
`
const AddPhotoContainer = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  color: #5c5f63;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
`

const PhotoObject = ({
  photoDetails,
  organizationId
}: {
  photoDetails: any
  organizationId: number
}) => {
  const dispatch = useDispatch()
  const deletePhoto = async () => {
    await dispatch(
      deleteOrganizationImage({ id: organizationId, image_pk: photoDetails.id })
    )
    await dispatch(fetchOrganizationImages({ id: organizationId }))
    await dispatch(fetchPublicImages())
  }
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { t } = useTranslation()
  const url = `${photoDetails.url}`
  return (
    <PhotoObjectContainer>
      {deleteModalOpen ? (
        <PhotoDeleteConfirmationModal
          modalToggle={setDeleteModalOpen}
          photoUrl={url}
          deletePhoto={deletePhoto}
        />
      ) : null}
      <TopCloseButton onClick={() => setDeleteModalOpen(true)}>
        <div>{t('remove-photo')}</div>
        <CloseIcon icon={['fal', 'times-circle']} />
      </TopCloseButton>
      <PhotoBackground>
        <Photo style={{ backgroundImage: `url(${url})` }} />
      </PhotoBackground>
    </PhotoObjectContainer>
  )
}
const NewPhotoObject = ({ organizationId }: { organizationId: number }) => {
  const { t } = useTranslation()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  return (
    <PhotoObjectContainer>
      {uploadModalOpen ? (
        <PhotoUploadModal
          modalToggle={setUploadModalOpen}
          organizationId={organizationId}
        />
      ) : null}
      <PhotoBackground onClick={() => setUploadModalOpen(true)}>
        <AddPhotoContainer>
          <AddIcon icon={['fal', 'plus-circle']} size="3x" />
          <div>{t('add-new-photo')}</div>
        </AddPhotoContainer>
      </PhotoBackground>
    </PhotoObjectContainer>
  )
}
const PublicPhotoObject = ({ photoDetails }: { photoDetails: any }) => {
  const url = `${photoDetails.url}`
  return (
    <PhotoObjectContainer>
      <PhotoBackground>
        <Photo style={{ backgroundImage: `url(${url})` }} />
      </PhotoBackground>
    </PhotoObjectContainer>
  )
}

export { PhotoObject, NewPhotoObject, PublicPhotoObject }
