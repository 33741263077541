import React, { useEffect, useState } from 'react'
import {
  getJobAdvertisementSearchFilters,
  ProfessionalTitleOption
} from 'Features/JobAdvertisement/jobAdvertisementSlice'
import {
  createSavedSearch,
  createJobGuard,
  updateSavedSearch,
  fetchJobGuards,
  deleteSavedSearch
} from 'Features/JobGuard/jobGuardSlice'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Select, { StylesConfig } from 'react-select'
import styled from 'styled-components/macro'
import { Toggle } from 'Components/Blocks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from 'Store'

export interface LocationOption {
  id: number
  name: string
}

const selectStyles: StylesConfig = {
  container: provided => ({
    ...provided,
    minHeight: '44px'
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #D6DCE2',
    borderRadius: '3px',
    minHeight: 41
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  singleValue: provided => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px'
  }),
  placeholder: provided => ({
    ...provided,
    color: '#000000',
    fontFamily: 'Barlow',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '19px'
  })
}

const SavedSearchesContainer = styled.div`
  p.disclaimer {
    margin: 0;
    text-align: center;
    color: #252525;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }
  .saved-search-container {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
    padding: 15px;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    &.closed {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .details > p {
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
      svg {
        cursor: pointer;
        margin-left: 10px;
        path {
          fill: #d60c17;
        }
      }
      .toggle {
        margin-right: 20px;
        .status-text {
          color: #7f7f7f;
          font-family: Barlow;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    span {
      color: #0a4ea2;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      cursor: pointer;
      &.hide-link {
        position: absolute;
        right: 15px;
        top: 15px;
      }
    }
    p {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 12px;
      margin: 0;
    }
    label {
      color: #222;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 10px;
    }
    input {
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 12px;
      color: #000000;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 10px;
    }
    .text-input {
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }
  button {
    width: 100%;
    height: 37px;
    border: none;
    border-radius: 6px;
    background-color: #d60c17;
    outline: none;
    color: #ffffff;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    margin-top: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: #b60c17;
    }
  }
`

const SavedSearches = () => {
  const { t } = useTranslation()
  const { results } = useSelector((state: RootState) => state.jobGuard)
  const { user } = useSelector((state: RootState) => state.auth)
  const [unsavedSearch, setUnsavedSearch] = useState<object | null>(null)
  const [openedSearches, setOpenedSearches] = useState<Array<number>>([])
  const [modifiedSearch, setModifiedSearch] = useState<{ [key: number]: any }>(
    {}
  )
  let [jobGuard] = results
  const searches = (jobGuard || {}).searches || []
  const dispatch = useDispatch()
  const { filters } = useSelector((state: RootState) => state.jobAdvertisement)

  useEffect(() => {
    dispatch(getJobAdvertisementSearchFilters())
  }, [])

  const onSavedSearchChange = (
    event: { target: { value: string; name: string } },
    id?: number
  ) => {
    const {
      target: { value, name }
    } = event
    if (id) {
      const newModifiedSearch = { ...modifiedSearch }
      newModifiedSearch[id] = { ...(newModifiedSearch[id] || {}) }
      newModifiedSearch[id][name] = value
      setModifiedSearch(newModifiedSearch)
      return
    }

    setUnsavedSearch((state: any) => ({
      ...state,
      [name]: value
    }))
  }

  const saveSavedSearch = async (s: any) => {
    if (s.id) {
      await dispatch(
        updateSavedSearch({
          ...s,
          job_guard: jobGuard.id
        })
      )
      await dispatch(fetchJobGuards())
      setOpenedSearches(state => state.filter((id: number) => id !== s.id))
      return
    }

    if (!jobGuard) {
      const data: any = await dispatch(createJobGuard({ user: user.id }))
      jobGuard = data.payload.data
    }

    await dispatch(
      createSavedSearch({
        ...s,
        job_guard: jobGuard.id
      })
    )
    await dispatch(fetchJobGuards())
    setUnsavedSearch(null)
  }

  const deleteSearch = async (s: any) => {
    await dispatch(deleteSavedSearch(s.id))
    await dispatch(fetchJobGuards())
  }

  if (!filters) {
    return null
  }

  const renderSavedSearch = (s: any) => {
    if (s.id) {
      s = { ...s, ...(modifiedSearch[s.id] || {}) } // eslint-disable-line
    }
    if (!s.id || openedSearches.includes(s.id)) {
      const savedLocations = (s.locations || []).map((l: any) => {
        const locationObj = (filters.locations || []).find(
          (l2: any) => l2.id === l
        )
        if (locationObj) {
          return {
            label: locationObj.name,
            value: locationObj.id
          }
        }
        return null
      })
      const savedProfessionalTitles = (s.professional_titles || []).map(
        (p: any) => {
          const ptObj = (filters.professional_titles || []).find(
            (p2: ProfessionalTitleOption) => p2.id === p
          )
          if (ptObj) {
            return {
              label: ptObj.title,
              value: ptObj.id
            }
          }
          return null
        }
      )
      return (
        <div className="saved-search-container" key={s.id || 'new'}>
          {s.id && (
            <span
              className="hide-link"
              onClick={() =>
                setOpenedSearches(state => state.filter(s2 => s2 !== s.id))
              }
            >
              {t('hide-search')}
            </span>
          )}
          <label>{t('free-text-search')}</label>
          <input
            name="free_text_search"
            value={s.free_text_search || ''}
            onChange={e => onSavedSearchChange(e, s.id)}
          />
          <label>{t('location-filter-title')}</label>
          <div className="text-input">
            <Select
              isMulti
              placeholder={t('location-placeholder')}
              styles={selectStyles}
              options={(filters.locations || []).map((p: LocationOption) => ({
                label: p.name,
                value: p.id
              }))}
              value={savedLocations}
              onChange={(locations: any) =>
                onSavedSearchChange(
                  {
                    target: {
                      name: 'locations',
                      value: (locations || []).map((loc: any) => loc.value)
                    }
                  },
                  s.id
                )
              }
            />
          </div>
          <label>{t('professional-titles')}</label>
          <div className="text-input">
            <Select
              isMulti
              placeholder={t('professional-title-placeholder')}
              styles={selectStyles}
              options={(filters.professional_titles || []).map(
                (p: ProfessionalTitleOption) => ({
                  label: p.title,
                  value: p.id
                })
              )}
              value={savedProfessionalTitles}
              onChange={(titles: any) =>
                onSavedSearchChange(
                  {
                    target: {
                      name: 'professional_titles',
                      value: (titles || []).map((title: any) => title.value)
                    }
                  },
                  s.id
                )
              }
            />
          </div>
          <button type="button" onClick={() => saveSavedSearch(s)}>
            {t('save')}
          </button>
        </div>
      )
    }
    return (
      <div className="saved-search-container closed" key={s.id || 'new'}>
        <div className="details">
          <p>
            <strong>{t('free-text-search')}: </strong>
            {s.free_text_search || t('empty')}
          </p>
          <p>
            <strong>{t('location-filter-title')}: </strong>
            {s.locations.length
              ? s.locations
                  .map((l1: number) =>
                    filters.locations.find((l2: LocationOption) => l2.id === l1)
                  )
                  .map((l2: LocationOption) => (l2 ? l2.name : ''))
                  .join(', ')
              : t('empty')}
          </p>
          <p>
            <strong>{t('professional-titles')}: </strong>
            {s.professional_titles.length
              ? s.professional_titles
                  .map((p1: number) =>
                    filters.professional_titles.find(
                      (p2: ProfessionalTitleOption) => p2.id === p1
                    )
                  )
                  .map(
                    (p2: ProfessionalTitleOption) => (p2 || { title: '' }).title
                  )
                  .join(', ')
              : t('empty')}
          </p>
        </div>
        <div className="actions">
          <span onClick={() => saveSavedSearch({ ...s, active: !s.active })}>
            <Toggle isOn={s.active} />
          </span>
          <span onClick={() => setOpenedSearches(state => [...state, s.id])}>
            {t('open-search')}
          </span>
          <FontAwesomeIcon
            icon={['far', 'trash']}
            onClick={() => deleteSearch(s)}
          />
        </div>
      </div>
    )
  }
  return (
    <SavedSearchesContainer>
      {searches.map((s: any) => renderSavedSearch(s))}
      {!searches.length && unsavedSearch === null && (
        <p className="disclaimer">{t('empty-saved-searches')}</p>
      )}
      {unsavedSearch && renderSavedSearch(unsavedSearch)}
      {unsavedSearch === null && (
        <button
          type="button"
          className="add-button"
          onClick={() => setUnsavedSearch({})}
        >
          + {t('add-saved-search')}
        </button>
      )}
    </SavedSearchesContainer>
  )
}

export default SavedSearches
