import { parse } from 'date-fns'
import { fetchPaginatedFilteredTrainings } from 'Features/Training/trainingSlice'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'Store'
import styled from 'styled-components/macro'

const PaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  span {
    padding: 5px 10px;
    border-radius: 6px;
    background: #ddd;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #272727;
    cursor: pointer;
    min-width: 40px;
    text-align: center;
    &.ellipsis {
      background: none;
      cursor: default;
    }
    &.active {
      color: white;
      background: #d60c17;
    }
  }
`

const Pagination = () => {
  const dispatch = useAppDispatch()
  const { currentPage, selectedFilters } = useSelector(
    (state: RootState) => state.training
  )
  const { trainingCount } = useSelector((state: any) => state.training)
  const pageCount = Math.ceil(trainingCount / 20)
  const pages = []
  for (let i = currentPage; i < currentPage + 3; i += 1) {
    if (i < pageCount) {
      pages.push(i)
    }
  }
  for (let i = currentPage - 1; i > currentPage - 5; i -= 1) {
    if (i >= 0) {
      pages.push(i)
    }
  }
  const parseDate = (selectedDate: string) => {
    try {
      const date = parse(selectedDate, 'dd.MM.yyyy', new Date()).toISOString()
      return date
    } catch (e) {
      return ''
    }
  }
  return (
    <PaginationContainer>
      {pages.includes(0) ? null : (
        <>
          <span
            key={0}
            onClick={() => {
              const body = {
                page: 1,
                filters: {
                  order: 'start_time' as 'start_time'
                }
              }
              dispatch(fetchPaginatedFilteredTrainings(body))
              dispatch({
                type: 'training/changePage',
                payload: body.page
              })
            }}
            className={currentPage === 1 ? 'active' : ''}
          >
            {1}
          </span>
          <span className="ellipsis">...</span>
        </>
      )}
      {pages
        .sort((a, b) => (b < a ? 1 : -1))
        .map((index: number) => (
          <span
            key={index}
            onClick={() => {
              const body = {
                page: index + 1
              }
              dispatch(
                fetchPaginatedFilteredTrainings({
                  page: index + 1,
                  filters: {
                    free_text_search: selectedFilters.free_text_search
                      ? selectedFilters.free_text_search
                      : '',
                    end_time: selectedFilters.end_time
                      ? parseDate(selectedFilters.end_time)
                      : '',
                    start_time: selectedFilters.start_time
                      ? parseDate(selectedFilters.start_time)
                      : '',
                    locations: selectedFilters.locations
                      ? selectedFilters.locations.map((loc: any) => loc.label)
                      : [],
                    organizations: selectedFilters.organizations
                      ? selectedFilters.organizations.map(
                          (organization: any) => organization.value
                        )
                      : [],
                    training_types: selectedFilters.training_types
                      ? selectedFilters.training_types
                      : [],
                    subject_areas: selectedFilters.subject_areas
                      ? selectedFilters.subject_areas.map(
                          (subject: any) => subject.label
                        )
                      : [],
                    order: 'start_time'
                  }
                })
              )
              dispatch({
                type: 'training/changePage',
                payload: body.page
              })
            }}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </span>
        ))}
      {pages.includes(pageCount - 1) || Number.isNaN(pageCount) ? null : (
        <>
          <span className="ellipsis">...</span>
          <span
            key={pageCount}
            onClick={() => {
              const body = {
                page: pageCount
              }
              dispatch(fetchPaginatedFilteredTrainings(body))
              dispatch({
                type: 'training/changePage',
                payload: body.page
              })
            }}
            className={currentPage === pageCount ? 'active' : ''}
          >
            {pageCount}
          </span>
        </>
      )}
    </PaginationContainer>
  )
}

export default Pagination
