import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  AsyncThunk,
  createAction,
  PayloadAction
} from '@reduxjs/toolkit'

import { getAxiosInstance } from 'Api'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

interface CompetenceItem {
  id: number
  title: string
}

interface CompetenceState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  data: {
    results: Array<CompetenceItem>
  }
}

const initialState: CompetenceState = {
  loading: 'idle',
  data: {
    results: []
  }
}

const fetchCompetences = createAsyncThunk('competence/fetch', async () => {
  const response = await getAxiosInstance().get(`/competence/`)
  return { data: response.data }
})

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending')
}
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected')
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled')
}

export const resetAction = createAction('reset-tracked-loading-state')

/* eslint-disable no-param-reassign */
const competenceSlice = createSlice({
  name: 'competence',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        fetchCompetences.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.data = action.payload.data
        }
      )
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})
/* eslint-enable no-param-reassign */

export default competenceSlice.reducer
export { fetchCompetences }
