/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'Store'
import Select from 'react-select'
import { toast } from 'react-toastify'
import {
  fetchOrganizations,
  patchOrganization,
  OrganizationRequestType,
  OrganizationType,
  OfficeType,
  fetchSingleOrganization
} from 'Features/Organization/organizationSlice'
import { patchOffice } from 'Features/Office/officeSlice'
import organizationIsHousehold from 'Util/organizationIsHousehold'
import { LocationType } from 'Features/MemberProfile/memberProfileSlice'

const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${props => props.theme.breakpoints.lg} {
    width: 60%;
    height: 60%;
  }
`
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`
const CloseButtonContainer = styled.div`
  display: flex;
  margin: 35px;
  justify-content: flex-end;
`
const CancelButton = styled.div`
  height: 37px;
  width: 175px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: Barlow;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  color: #bcc2c6;
`
const AddUserButtom = styled(CancelButton)`
  margin-left: 20px;
  width: fit-content;
  padding: 5px;
  white-space: nowrap;
  background-color: #d60c17;
  color: #ffffff;
`
const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`
const ModalBackground = styled.div`
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  @media screen and ${props => props.theme.breakpoints.lg} {
    width: 60%;
  }
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  label {
    color: #252525;
    font-weight: 600;
    font-family: Barlow;
    font-size: 14px;
    margin-bottom: 10px;
  }
  span {
    color: red;
  }
  .form-select {
    border: 1px solid #d6dce2;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;
  }
`
const Title = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 20px;
  margin-bottom: 30px;
`
const InfoTooltip = styled(FontAwesomeIcon)`
  margin-left: 10px;
`
const TooltipBackground = styled.span`
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
    color: #222;
    text-align: center;
    border: 1px solid #d6dce2;
    font-family: Barlow;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    font-weight: 500;
    z-index: 1;
    margin-left: 10px;
  }

  .tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 15px;
    color: #222;
    right: 100%;
    margin-top: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #00000040 transparent transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`
const OrganizationName = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
`
const NewUserForm = styled.form`
  display: flex;
  flex-direction: column;
`
const LocationSelect = styled(Select)`
  width: 100%;
  margin-bottom: 15px;
`
const ImageUploadContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
`
const ImageUploadArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 0px;
  min-height: 190px;
  max-height: 190px;
  width: 100%;
  padding: 5px;
  border: 1px solid #d6dce2;
  border-radius: 3px;
  text-align: center;
`
const UploadedImagePreview = styled.img`
  width: auto;
  height: 100%;
`
const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CompanyLogo = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
const SelectFileButton = styled.label`
  && {
    background: #0f4ea0;
    height: 37px;
    color: #ffffff;
    user-select: none;
    white-space: nowrap;
    padding: 5px;
    font-family: Barlow;
    font-size: 15px;
    font-weight: 600;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-bottom: 0px;
    &:hover {
      cursor: pointer;
    }
  }
`
type UpdateNewOfficeRequest = {
  key: string
  value: string | number | File | undefined
}

const defaultProps = {
  singleOffice: false
}
const ModifyOrganizationModal = ({
  modalToggle,
  organizationDetails,
  currentMainOffice,
  offices,
  singleOffice
}: {
  modalToggle: (arg0: boolean) => void
  organizationDetails: OrganizationType
  currentMainOffice: OfficeType | undefined
  offices: Array<OfficeType> | undefined
  singleOffice?: boolean
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const initialData = {
    name: organizationDetails.name,
    vat_number: organizationDetails.vat_number,
    description: organizationDetails.description,
    url: organizationDetails.url,
    logo: organizationDetails.logo
  }
  const [
    organizationData,
    setOrganizationData
  ] = useState<OrganizationRequestType>(initialData)
  const [selectedFile, setSelectedFile] = useState<File>()
  const isHousehold = organizationIsHousehold()
  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      setSelectedFile(event.currentTarget.files[0])
    }
  }
  const organizationLocations = offices
    ? offices.map(({ name, id }: { name: string; id: number }) => ({
        id,
        value: name,
        label: name
      }))
    : undefined
  const [mainLocation, setMainLocation] = useState<LocationType | undefined>(
    currentMainOffice
      ? {
          id: currentMainOffice.id,
          value: currentMainOffice.name,
          label: currentMainOffice.name
        }
      : undefined
  )
  function getFormData() {
    const formData = new FormData()
    Object.entries(organizationData).map(([key, value]) => {
      if (value) {
        formData.append(key, value)
      }
    })
    return formData
  }
  const handleSubmission = () => {
    dispatch(
      patchOrganization({ id: organizationDetails.id, body: getFormData() })
    ).then(({ error }: any) => {
      if (error) {
        try {
          const errorObj: {
            [key: string]: { [key: string]: any }
          } = JSON.parse(error.message)
          const errors: Array<string> = []
          Object.keys(errorObj).map((k: string) => {
            errors.push(`${t(k)}`)
          })
          // eslint-disable-next-line no-undef
          toast.error(
            <>
              <strong>{t('fill-missing-fields')}:</strong>
              {errors.map((e: string) => (
                <p key={e}>
                  <strong>{e.split(' - ')[0]}</strong>
                </p>
              ))}
            </>
          )
        } catch (e) {
          toast.error(
            <>
              <strong>{t('fill-missing-fields')}:</strong>
              <p>
                <strong>{t('error-happened')}</strong>
              </p>
            </>
          )
        }
      } else if (mainLocation) {
        dispatch(
          patchOffice({
            id: mainLocation.id,
            body: { is_main_office: true, organization: organizationDetails.id }
          })
        ).then(() => {
          if (singleOffice) {
            dispatch(fetchSingleOrganization(organizationDetails.id)).then(() =>
              modalToggle(false)
            )
          } else {
            dispatch(fetchOrganizations()).then(() => modalToggle(false))
          }
        })
      } else {
        dispatch(fetchOrganizations()).then(() => modalToggle(false))
      }
    })
  }
  const setOrganizationValues = ({ key, value }: UpdateNewOfficeRequest) => {
    setOrganizationData({ ...organizationData, [key]: value })
  }
  useEffect(() => {
    setOrganizationValues({ key: 'logo', value: selectedFile })
  }, [selectedFile])
  const changeLocation = (e: LocationType) => {
    setMainLocation(e)
  }
  return (
    <TransparentBackground>
      <CloseContainer>
        <TopCloseButton onClick={() => modalToggle(false)}>
          <div>{t('close')}</div>
          <CloseIcon icon={['fal', 'times-circle']} />
        </TopCloseButton>
      </CloseContainer>
      <ModalBackground>
        <ModalContent>
          <Title> {t('modify-organization-details')}</Title>
          <NewUserForm>
            <label>
              {t('company-name')} <span>*</span>
            </label>
            <input
              className="form-select"
              size={30}
              value={organizationData.name}
              placeholder={t('office-name')}
              onChange={e =>
                setOrganizationValues({ key: 'name', value: e.target.value })
              }
            />
            {!isHousehold && (
              <>
                <label>
                  {t('business-id')}
                  <span>*</span>
                </label>
                <input
                  className="form-select"
                  size={30}
                  value={organizationData.vat_number}
                  placeholder={t('business-id')}
                  onChange={e =>
                    setOrganizationValues({
                      key: 'vat_number',
                      value: e.target.value
                    })
                  }
                />

                <label>{t('description')}</label>
                <input
                  className="form-select"
                  size={30}
                  value={organizationData.description}
                  placeholder={t('description')}
                  onChange={e =>
                    setOrganizationValues({
                      key: 'description',
                      value: e.target.value
                    })
                  }
                />
                <label>{t('web-site')}</label>
                <input
                  className="form-select"
                  size={30}
                  value={organizationData.url}
                  placeholder="https://esimerkki.fi"
                  onChange={e =>
                    setOrganizationValues({ key: 'url', value: e.target.value })
                  }
                />
              </>
            )}
            <label>{t('company-logo')}</label>
            <ImageUploadContainer>
              <ImageUploadArea>
                {selectedFile ? (
                  <UploadedImagePreview
                    src={
                      selectedFile
                        ? URL.createObjectURL(selectedFile)
                        : undefined
                    }
                    alt="selected_file"
                  />
                ) : (
                  <LogoContainer>
                    <CompanyLogo
                      style={{
                        backgroundImage: `url(${
                          organizationDetails ? organizationDetails.logo : ''
                        })`
                      }}
                    />
                  </LogoContainer>
                )}
              </ImageUploadArea>
              <input
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                name="file"
                accept=".jpeg,.jpg,.png"
                onChange={e => fileChangeHandler(e)}
              />
              <SelectFileButton htmlFor="file-upload">
                {t('select-file')}
              </SelectFileButton>
            </ImageUploadContainer>
            {!isHousehold && (
              <>
                <label>{t('main-office-location')}</label>
                <LocationSelect
                  placeholder="Valitse sijainti"
                  value={mainLocation}
                  options={organizationLocations}
                  onChange={(e: LocationType) => changeLocation(e)}
                />
                <label>
                  {t('organization')} <span>*</span>
                  <TooltipBackground>
                    <span className="tooltip">
                      <InfoTooltip
                        color="#0859AC"
                        icon={['fal', 'info-circle']}
                      />
                      <span className="tooltiptext">
                        {t('informant-information')}
                      </span>
                    </span>
                  </TooltipBackground>
                </label>
                <OrganizationName>{organizationDetails.name}</OrganizationName>
              </>
            )}
          </NewUserForm>
        </ModalContent>
        <CloseButtonContainer>
          <CancelButton onClick={() => modalToggle(false)}>
            {t('cancel')}
          </CancelButton>
          <AddUserButtom onClick={() => handleSubmission()}>
            {t('save-changes')}
          </AddUserButtom>
        </CloseButtonContainer>
      </ModalBackground>
    </TransparentBackground>
  )
}

ModifyOrganizationModal.defaultProps = defaultProps
export default ModifyOrganizationModal
