import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleRight,
  faAngleLeft,
  faAngleUp as regularAngleUp,
  faAngleDown as regularAngleDown,
  faBars,
  faHome,
  faFileAlt,
  faHardHat,
  faHammer,
  faEnvelope,
  faBriefcase,
  faAward,
  faEllipsisV,
  faImage,
  faEyeSlash,
  faEye,
  faPen,
  faCopy,
  faSave,
  faTrash,
  faStar,
  faUndo,
  faBuilding,
  faChartBar,
  faChalkboardTeacher,
  faSignOut,
  faUserCircle,
  faMapMarkerAlt,
  faCalendar,
  faChair,
  faHouseSignal
} from '@fortawesome/pro-regular-svg-icons'
import {
  faSearch,
  faFileCertificate,
  faCircle,
  faCaretDown,
  faCaretUp,
  faStar as fasStar,
  faPen as fasPen,
  faFolder,
  faSearchPlus,
  faSearchMinus,
  faArrowRight,
  faSpinner,
  faTrash as fasTrash,
  faTimes,
  faCheck,
  faCheckCircle,
  faTools,
  faHouse
} from '@fortawesome/pro-solid-svg-icons'
import {
  faPlusCircle,
  faTimesCircle,
  faAngleUp,
  faAngleDown,
  faAngleLeft as faLighAngleLeft,
  faSearch as faLightSearch,
  faInfoCircle
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faAngleRight,
  faLighAngleLeft,
  faFileCertificate,
  faSearch,
  faBars,
  faHome,
  faFileAlt,
  faHardHat,
  faHammer,
  faEnvelope,
  faPlusCircle,
  faTimesCircle,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faBriefcase,
  faLightSearch,
  faAward,
  faEllipsisV,
  faCircle,
  faCaretDown,
  faCaretUp,
  faImage,
  faEyeSlash,
  faInfoCircle,
  faEye,
  faAngleLeft,
  faPen,
  faCopy,
  faSave,
  faTrash,
  faStar,
  fasStar,
  faUndo,
  regularAngleUp,
  regularAngleDown,
  fasPen,
  faFolder,
  faSearchPlus,
  faSearchMinus,
  faArrowRight,
  faSpinner,
  faBuilding,
  faChartBar,
  faChalkboardTeacher,
  fasTrash,
  faSignOut,
  faTimes,
  faCheck,
  faUserCircle,
  faMapMarkerAlt,
  faCalendar,
  faChair,
  faHouseSignal,
  faCheckCircle,
  faTools,
  faHouse
)
