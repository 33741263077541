/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
// src/Item.tsx
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import HelmetImage from 'Assets/helmet.png'
import styled from 'styled-components'
import { rejectApplication } from 'Features/JobApplication/jobApplicationSlice'
import { useTranslation } from 'react-i18next'
import DropdownMenu from 'Components/DropdownMenu'
import { useAppDispatch } from 'Store'
import { fetchWithId } from 'Features/JobAdvertisements/advertisementSlice'
// TypeScript only
interface ItemProps {
  adId: string
  applicantName: string
  applicantId: number
  arrivalDate: string
  applicationId: number
  index: number
  key: number
  modalToggle: any
  setSelectedApplication: any
  sendMessage: () => void
}
const ApplicationCard = styled.div`
  box-sizing: border-box;
  height: 45px;
  width: 99%;
  border: 1px solid #dcdddf;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 4px 7px -5px #a8aeb4;
  user-select: none;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding 6px;
  &:hover {
    background-color: #f4f8ff;
  }
  &:active {
    background-color: #f4f8ff;
  }
`
const ApplicantImage = styled.img`
  border-radius: 50%;
  height: 100%;
`
const ApplicationDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 7px;
`
const ApplicantDetails = styled.div`
  display: flex;
  flex-direction: column;
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
`
const ApplicationLeftBlock = styled.div`
  display: flex;
  height: 100%;
`
const ReceivalDate = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 12px;
`

const ApplicationObject = ({
  adId,
  applicantName,
  applicantId,
  arrivalDate,
  applicationId,
  index,
  modalToggle,
  setSelectedApplication,
  sendMessage
}: ItemProps) => {
  const openApplicationModal = () => {
    modalToggle(true)
    setSelectedApplication(applicantId)
  }
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const applicationOptions = [
    {
      icon: 'envelope',
      title: 'Lähetä viesti',
      action: sendMessage
    },
    {
      icon: 'eye',
      title: 'Näytä hakemus',
      action: openApplicationModal
    },
    {
      icon: 'trash',
      title: 'Hylkää hakemus',
      action: () => {
        dispatch(
          rejectApplication({
            id: applicationId
          })
        ).then(() => dispatch(fetchWithId(parseInt(adId, 10))))
      }
    }
  ]
  return (
    <Draggable draggableId={applicantId.toString()} index={index}>
      {provided => (
        <ApplicationCard
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ApplicationLeftBlock>
            <ApplicantImage src={HelmetImage} alt="helmet" />
            <ApplicationDetails>
              <ApplicantDetails>{applicantName}</ApplicantDetails>
              <ReceivalDate>
                {t('arrived')} {arrivalDate}
              </ReceivalDate>
            </ApplicationDetails>
          </ApplicationLeftBlock>
          <DropdownMenu
            options={applicationOptions}
            menuPosition="leftBottom"
            objectId={applicantId}
          />
        </ApplicationCard>
      )}
    </Draggable>
  )
}

export default ApplicationObject
