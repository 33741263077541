import React from 'react'
import styled from 'styled-components'
import AdminLayout from 'Components/Layout/AdminLayout'
import EmployeeBankComponent from 'Components/EmployeeBank'

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`

const EmployeeBank = () => {
  return (
    <AdminLayout>
      <ContentContainer>
        <EmployeeBankComponent />
      </ContentContainer>
    </AdminLayout>
  )
}
export default EmployeeBank
