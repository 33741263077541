/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const InfoTooltip = styled(FontAwesomeIcon)`
  margin-left: 10px;
`
const TooltipBackground = styled.span`
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
    color: #222;
    text-align: center;
    border: 1px solid #d6dce2;
    font-family: Barlow;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    font-weight: 500;
    z-index: 1;
    margin-left: 10px;
  }

  .tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 15px;
    color: #222;
    right: 100%;
    margin-top: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #00000040 transparent transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`

const Tooltip = ({ tooltipText }: { tooltipText: string }) => {
  const { t } = useTranslation()
  return (
    <TooltipBackground>
      <span className="tooltip">
        <InfoTooltip color="#0859AC" icon={['fal', 'info-circle']} />
        <span className="tooltiptext">{t(tooltipText)}</span>
      </span>
    </TooltipBackground>
  )
}

export default Tooltip
